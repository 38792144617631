import { useContext } from 'react'
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Font,
} from '@react-pdf/renderer'
import { TableToPrint } from './printDetailsPage'
import { StoreContext } from '../../App'
import { PrintUserDetails } from './printUserDetails'

interface MainPrintInterface {
  printTable: boolean
  packingTable: boolean
}

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
  },
  pageColumn: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  notesprivColumn: {
    width: '100%',
    margin: '5%',
    fontSize: 10,
  },
  paramsConfig: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'black',
    top: 12,
    left: '5%',
    marginBottom: '5%',
    textAlign: 'left',
    color: 'black',
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})

const MainPrint = ({ printTable, packingTable }: MainPrintInterface) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  const NewDetailsArray = [
    { header: locales.details.status, value: 'status', editable: 'text' },
    {
      header: locales.details.completable,
      value: 'completable',
    },
    { header: locales.details.tags, value: 'object_tags' },
    { header: 'Ordered date and time', value: 'ordered' },
    { header: 'Created By', value: 'Created by' },
    { header: 'Last Edit', value: 'lastEdit' },
    { header: 'Last Edited By:', value: 'lastEditedBy' },
  ]

  const PaymentArray = [
    {
      header: locales.details.paymentMethod,
      value: 'paymentMethod',
    },
    { header: 'Currency', value: 'currency_name' },
    { header: 'Payment Status', value: 'paymentStatus' },
    { header: locales.details.paid, value: 'paid' },
    { header: locales.details.paymentDifference, value: 'Payment difference' },
    { header: 'Shipping Cost', value: 'shipping_cost' },
    { header: 'Total Discounts', value: 'total discounts' },
    { header: 'Order total (without taxes)', value: 'orderWithOutTaxes' },
    { header: 'Tax Amount', value: 'taxAmount' },
    { header: 'Order total with taxes', value: 'Sum' },
  ]

  const ShippingArray = [
    { header: locales.details.shipping, value: 'shipping' },
    {
      header: locales.details.estimateDate,
      value: 'estimateDate',
      editable: 'date',
    },
  ]
  const ConversationsArray = [
    { header: 'orderDescription', value: 'orderDescription' },
    { header: 'lastExternalComment', value: 'lastExternalComment' },
    { header: 'lastInternalComment', value: 'lastInternalComment' },
  ]

  const AddressArray = [
    {
      header: locales.address.firstname,
      value: 'firstname',
    },
    {
      header: locales.address.lastname,
      value: 'lastname',
    },
    {
      header: locales.address.company,
      value: 'company',
    },
    {
      header: locales.address.city,
      value: 'city',
    },
    {
      header: locales.address.postcode,
      value: 'postcode',
    },
    {
      header: locales.address.street1,
      value: 'street1',
    },
    {
      header: locales.address.street2,
      value: 'street2',
    },
    {
      header: locales.address.country,
      value: 'country',
    },
  ]
  const BillingArray = [
    ...AddressArray,
    { header: 'NIP', value: 'tax_id' },
    { header: 'vies', value: 'vies' },
    { header: 'Regon', value: 'Regon' },
    { header: 'Phone', value: 'phone' },
    { header: 'Email', value: 'email' },
    //{ header: 'regon', value: 'regon' },
    //{ header: 'vatId', value: 'vatId' },
  ]

  /*const GusArray = [
          { header: locales.address.company, value: 'Nazwa' },
          { header: locales.address.city, value: 'Miejscowosc' },
          { header: locales.address.postcode, value: 'KodPocztowy' },
          { header: locales.address.street1, value: 'street1' },
          { header: locales.address.street2, value: 'street2' },
          { header: locales.address.country, value: 'country' },
          { header: 'NIP', value: 'Nip' },
          { header: 'Regon', value: 'Regon' },
        ]*/
  const SystemArray = [
    { header: 'source system order number', value: 'pageId' },
    {
      header: 'source system',
      value: 'type',
    },
    {
      header: 'source system instance',
      value: 'url',
    },
    { header: 'domain', value: 'domain' },
  ]

  const DetailsArrayPacking = [
    { header: locales.details.Description, value: 'Description' },
    { header: locales.details.status, value: 'status' },
    { header: locales.details.completable, value: 'completable' },
    { header: locales.details.shipping, value: 'shipping' },
    { header: locales.details.ordered, value: 'ordered' },
    { header: locales.details.estimateDate, value: 'estimateDate' },
    { header: locales.details.tags, value: 'tags' },
  ]

  return (
    <Document>
      {printTable ? (
        <Page style={styles.page} size={'A4'}>
          <View style={styles.titleText} fixed>
            <Text>
              Customer Order: {store.TableStore.detailsData.order_number}
            </Text>
          </View>
          <View style={styles.paramsConfig}>
            {!packingTable ? (
              <>
                <View style={styles.pageColumn}>
                  {' '}
                  <PrintUserDetails
                    data={store.TableStore.detailsData}
                    columns={NewDetailsArray}
                    title={locales.headers.details}
                  />
                  <PrintUserDetails
                    data={store.TableStore.detailsData}
                    columns={ShippingArray}
                    title={'Shipping Details'}
                  />
                  <PrintUserDetails
                    title={'System'}
                    data={store.TableStore.detailsData.system}
                    columns={SystemArray}
                  />
                  <PrintUserDetails
                    title={'Conversations and Notes'}
                    data={store.TableStore.detailsData}
                    columns={ConversationsArray}
                  />
                </View>
                <View style={styles.pageColumn}>
                  <PrintUserDetails
                    title={'Payment Details'}
                    data={store.TableStore.detailsData}
                    columns={PaymentArray}
                  />
                  <PrintUserDetails
                    data={store.TableStore.detailsData.customer}
                    columns={AddressArray}
                    title={locales.headers.deliveryAddress}
                  />
                  <PrintUserDetails
                    data={store.TableStore.detailsData.customer}
                    columns={BillingArray}
                    title={`${locales.headers.billingAddress}`}
                  />
                </View>
              </>
            ) : (
              <>
                {packingTable ? (
                  <>
                    <View style={styles.pageColumn}>
                      <PrintUserDetails
                        data={store.TableStore.detailsData}
                        columns={DetailsArrayPacking}
                        title={locales.headers.details}
                      />
                    </View>
                    <View style={styles.pageColumn}>
                      <PrintUserDetails
                        data={store.TableStore.detailsData.customer}
                        columns={AddressArray}
                        title={locales.headers.deliveryAddress}
                      />
                    </View>
                    <View style={styles.notesprivColumn}>
                      <Text>{store.TableStore.detailsData.notes_priv}</Text>
                    </View>
                  </>
                ) : (
                  <></>
                )}
                <TableToPrint />
              </>
            )}
          </View>
          <View style={styles.footer} fixed>
            <Text>
              Printed by {store.UserStore.user} on {new Date().toJSON()}
            </Text>
          </View>
        </Page>
      ) : (
        <></>
      )}
      {!printTable ? (
        <Page style={styles.page} size={'A4'}>
          <View style={styles.titleText} fixed>
            <Text>
              Customer Order: {store.TableStore.detailsData.order_number}
            </Text>
          </View>
          <TableToPrint />
          <View style={styles.footer} fixed>
            <Text>
              Printed by {store.UserStore.user} on {new Date().toJSON()}
            </Text>
          </View>
        </Page>
      ) : (
        <></>
      )}
    </Document>
  )
}

export default MainPrint
