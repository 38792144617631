/* eslint-disable */
import { useContext, useEffect, useState } from 'react'

import {
  Container,
  Footer,
  InnerCont,
} from './AddMultipleSupplierProduct.styles'

import { StoreContext } from '../../App'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import AddProductSearchComponent from '../addProductSearch/AddProductSearch.component'
import { FilterLabel, Input } from '../dataInputGeneric/DataInputGeneric.styles'
import { validate } from '../../static/validate'
import { socket } from '../../socket/socket'
import Button from '../button/Button.component'

const AddMultipleSupplierProduct: () => JSX.Element = () => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [isAbleToSave, setIsAbleToSave] = useState(false)

  const initialState = [
    {
      header: 'quantity',
      value: '1',
      error: '',
      rules: ['positive'],
      isActive: true,
    },
    {
      header: 'productID',
      value: '',
      error: '',
      rules: ['required', 'length'],
      isActive: true,
    },
  ]
  const [form, setForm] = useState(initialState)

  const applySearch = (product: any) => {
    const tempForm = [...form]
    tempForm[1].value = product.id
    setForm([...tempForm])
  }

  const handleSave = async () => {
    const data = {
      filterGroups: {
        rules: [
          {
            field: 'id',
            operator: '=',
            valueSource: 'value',
            value: form[1].value,
          },
        ],
        combinator: 'and',
        not: false,
      },
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/stock_view?size=1&from=0`
    const response = await axios.post(url, data, config)
    if (response.data.count) {
      const product = response.data.products[0]
      const keys = store.TableStore.selectedRowId
      const fetchPromises = keys.map((key) => {
        const postUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${key}`
        const postData = {
          id: key,
          products: {
            [form[1].value]: {
              ProductID: product.id,
              Name: product.name,
              ProductCode: product.code,
              SupplierName: product.producer,
              SupplierDeliveryCode: '',
              SupplierOrderId: '',
              OrderLink: '',
              Notes: '',
              ProducerName: product.producer,
              OrderedQuantity: form[0].value,
              SupplierDeliveryDate: '',
              DeliveredQuantity: '0',
              DeliveredDate: '',
            },
          },
        }
        const formattedData = store.TableStore.formatRequestData(postData)
        return axios.post(postUrl, formattedData, config)
      })
      await Promise.all(fetchPromises)
      await toast(locales.successes.success, { type: 'success' })
      await store.SlidingPanelState.setVisibility(false)
      await keys.map((key: any) => {
        socket.emit('editOrder', {
          id: key,
          user: store.UserStore.user,
          page: store.TableStore.getApiRequestFromUrl(),
        })
      })
    } else {
      toast(locales.errors.noOrderId, { type: 'error' })
    }
  }

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
    setIsAbleToSave(changed)
  }, [form])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <AddProductSearchComponent applySearch={applySearch} />
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {locales.headers.quantity}
        </FilterLabel>
        <Input
          type={'number'}
          value={form[0].value}
          onChange={(e) => {
            const updatedForm = [...form]
            const error = validate(form[0].rules, e.target.value)
            updatedForm[0] = { ...form[0], value: e.target.value, error: error }

            setForm(updatedForm)
          }}
          activeStyle={true}
          {...Theme.editProductsStyles.styledInput}
        />
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            if (isAbleToSave) handleSave()
          }}
          active={isAbleToSave}
          {...Theme.buttons.primaryButton}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}
export default AddMultipleSupplierProduct
