/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'
import { TitleText } from '../../components/reports/timeline/Timeline.styles'
import {
  Body,
  Container,
  HeaderCont,
  MediaCont,
  ReturnsButton,
  ReturnsButtonCont,
  StyledMediaImg,
  StyledSelect,
  StyledVideo,
  SubTitleText,
} from './ReturnsDetails.styles'
import { useParams } from 'react-router-dom'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../styles/Loading.styles'
import ReturnsDetailsTable from '../../components/returnsDetailsTable/ReturnsDetailsTable.component'
import Button from '../../components/button/Button.component'
import { Label, Setting, SettingsCategory } from '../reports/reports.styles'

const ReturnsDetails = observer(() => {
  const store = useContext(StoreContext)
  const params = useParams()
  const [totalReturnValue, setTotalReturnValue] = useState(0) // Track total return value
  const [acceptAll, setAcceptAll] = useState(true)

  const { selectedProducts, setSelectedProducts } = store.ReturnsStore

  const handleAcceptPartialReturn = () => {
    const selectedItems = Object.keys(selectedProducts)
      .filter((code) => store.TableStore.selectedRowId.includes(code)) // Only include checked products
      .map((code) => ({
        code,
        amount: selectedProducts[code],
      }))

    // Calculate return value for selected products
    const returnValue = selectedItems.reduce(
      (total, item) =>
        total +
        // @ts-ignore
        detailsProducts.find((p) => p.code === item.code).price * item.amount,
      0,
    )

    console.log('Selected Items:', selectedItems)
    console.log('Return Value:', returnValue)

    // store.ReturnsStore.updatePartialReturn(params.id, selectedItems)
  }

  const decisions = [
    'Exchange of products',
    'Repair',
    'Return Money',
    'Rejection',
  ]
  const [selectedDecision, setSelectedDecision] = useState(
    'Exchange of products',
  )

  const { detailsData, detailsProducts, loaded, change } = store.ReturnsStore

  const orderDetails = store.TableStore.detailsData

  const renderMediaPreview = () => {
    const imageExtentions = ['jpg', 'jpeg', 'png', 'gif', 'svg']

    if (detailsData?.mediaFiles.length === 0)
      return <SubTitleText>No media files were included by user</SubTitleText>
    return detailsData?.mediaFiles.map((file, index) => (
      <div key={index}>
        {imageExtentions.includes(file.split('.')[1].toLowerCase()) ? (
          <StyledMediaImg
            src={`https://returnsdev.apihulk.com/${file}`}
            alt='upload preview'
            width={100}
            height={100}
          />
        ) : (
          <StyledVideo width={260} height={200} controls>
            <source
              src={`https://returnsdev.apihulk.com/${file}`}
              type={file.split('/')[1]}
            />
          </StyledVideo>
        )}
      </div>
    ))
  }

  useEffect(() => {
    if (params.id) {
      store.ReturnsStore.fetchDetailsData(params.id)
    }
  }, [])

  useEffect(() => {
    if (detailsData?.products) {
      detailsData.products.forEach((product) => {
        setSelectedProducts(product.code, product.amount)
      })
    }
  }, [detailsData])

  useEffect(() => {
    if (detailsProducts && orderDetails && orderDetails?.products) {
      const returnValue = Object.keys(selectedProducts)
        .filter((code) => store.TableStore.selectedRowId.includes(code)) // Only include checked products
        .reduce((total, code) => {
          const productPrice = Object.values(
            orderDetails.products as any[],
          ).find((p) => p.code === code).price
          return total + productPrice * selectedProducts[code]
        }, 0)
      setTotalReturnValue(returnValue)
    }
  }, [
    store.TableStore.selectedRowId.length,
    selectedProducts,
    detailsProducts,
    orderDetails,
    change,
  ])

  return (
    <Container loading={!!(loaded || detailsData)}>
      {!loaded || !detailsData || !detailsProducts || !orderDetails ? (
        <LoadingContainer>
          <Wrap />
        </LoadingContainer>
      ) : (
        <HeaderCont>
          <TitleText>Order number: {detailsData.order_id}</TitleText>
          <ReturnsButtonCont>
            <ReturnsButton
              selected={!acceptAll}
              view={'left'}
              onClick={() => setAcceptAll(true)}
            >
              Decide on all products
            </ReturnsButton>
            <ReturnsButton
              view={'right'}
              selected={acceptAll}
              onClick={() => setAcceptAll(false)}
            >
              Decide on selected products
            </ReturnsButton>
          </ReturnsButtonCont>
          {acceptAll ? (
            <></>
          ) : (
            <>
              <TitleText>Select Products to make decision:</TitleText>
              <Body>
                <ReturnsDetailsTable />
              </Body>
            </>
          )}
          <SettingsCategory>
            <TitleText>Return Options</TitleText>
            <Label>Included media files:</Label>
            <MediaCont>{renderMediaPreview()}</MediaCont>
            <Label>Choose Decision:</Label>
            <StyledSelect
              value={selectedDecision}
              onChange={(e) => setSelectedDecision(e.target.value)}
            >
              {decisions.map((decisionOption, index) => (
                <option key={index} value={decisionOption}>
                  {decisionOption}
                </option>
              ))}
            </StyledSelect>
            {!acceptAll ? (
              <>
                <Button
                  margin={'16px 0 0 0'}
                  width={'max-content'}
                  type={'primary'}
                  onClick={handleAcceptPartialReturn}
                >
                  Decide on selected products
                </Button>
                <p>Total Return Value: {totalReturnValue.toFixed(2)} PLN</p>
              </>
            ) : (
              <Button
                margin={'16px 0 0 0'}
                width={'max-content'}
                type={'primary'}
              >
                Decide on all products
              </Button>
            )}
          </SettingsCategory>
        </HeaderCont>
      )}
    </Container>
  )
})

export default ReturnsDetails
