import styled from 'styled-components'
import { darken } from 'polished'
import { breakpoints } from '../../static/breakpoints'

interface ContainerProps {
  padding?: string
  borderRadius?: string
}

export const Container = styled.div<ContainerProps>`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  padding: 0px 16px 16px;
  display: inline-flex;
  flex-direction: column;
`

interface InnerContInterface {
  cols: number
}

export const InnerCont = styled.div<InnerContInterface>`
  height: 80vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  column-count: ${(props) => props.cols};
  width: max-content;
  max-width: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: #aaaaaa;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #303030;
    border-radius: 6px;
    border: 3px solid #aaaaaa;
  }

  @media screen and (max-width: ${breakpoints.vertical}) {
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    column-count: 1;
    overflow: auto;
    scrollbar-width: thin;
  }
`

interface HeaderProps {
  padding?: string
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.padding ? props.padding : '12px 16px 0')};
`
export const HeaderFirstLine = styled.div`
  display: flex;
  align-items: center;
`

interface HeaderTextProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
}

export const HeaderText = styled.p<HeaderTextProps>`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '8px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.3px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

interface HeaderIconProps {
  margin?: string
}

export const HeaderIcon = styled.img<HeaderIconProps>`
  height: 16px;
  margin: ${(props) => (props.margin ? props.margin : '16px 4px 16px 16px')};
  filter: invert(1);
  cursor: pointer;
`

interface StyledSubHeaderProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
  marginTop?: string
}

export const StyledSubHeader = styled.p<StyledSubHeaderProps>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '4px')};
  text-align: left;
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '20px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.25px'};
  color: ${(props) => (props.color ? props.color : '#737373')};
`

interface FormContProps {
  padding?: string
  boxShadow?: string
  trackBgColor?: string
  trackBgRadius?: string
  thumbBgColor?: string
  thumbBgRadius?: string
  thumbBorder?: string
}

/* eslint-disable */
export const FormCont = styled.div<FormContProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props) => (props.padding ? props.padding : '0 16px 32px')};
  max-height: 75vh;
  overflow-y: scroll;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : ' 0 3px 3px rgba(0, 0, 0, 0.2)'};

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.trackBgColor ? props.trackBgColor : '#aaaaaa'};
    border-radius: ${(props) =>
      props.trackBgRadius ? props.trackBgRadius : '3px'};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.thumbBgColor ? props.thumbBgColor : '#303030'};
    border-radius: ${(props) =>
      props.thumbBgRadius ? props.thumbBgRadius : '6px'};
    border: ${(props) =>
      props.thumbBorder ? props.thumbBorder : '3px solid #aaaaaa'};
  }
`

interface FooterProps {
  bgColor?: string
  borderRadius?: string
}

export const Footer = styled.div<FooterProps>`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0 0 4px 4px'};
`
