import { observer } from 'mobx-react'
import { FC, memo, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../App'
import { Bottom, Container, Top } from './TableProducerColumn.styles'
import { NavLink } from 'react-router-dom'
import { toJS } from 'mobx'

/* eslint-disable */
interface Props {
  data: string
  searchData: any[]
}

const TableProducerColumn: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const data = props.searchData.find((e: any) => e.id === props.data)

  return <>{data ? data.name.en_GB : 'No status assigned!'}</>
})
export default memo(TableProducerColumn)
