import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'

export class PackagesStore {
  rootStore
  data = []
  //currentFilters: { value: string; label: string }[] = []
  loaded = false
  pagination: {
    tableSize: number
    pageSize: number
    pageIndex: number
    totalCount: number
  } = { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  convertRulesToFilters = (rules: any[], globalSearch: string) => {
    const filters: {
      global_search: string
      ref_num?: string
      filters: {
        datetime_order?: {
          from?: string
          to?: string
        }
      }
    } = {
      global_search: globalSearch,
      filters: {},
    }
    //test

    rules.forEach((rule: any) => {
      if (rule.field === 'datetime_order') {
        if (!filters.filters.datetime_order) {
          filters.filters.datetime_order = {}
        }
        if (rule.operator === '>') {
          // @ts-ignore
          filters.filters.datetime_order.from = rule.value
        } else if (rule.operator === '<') {
          // @ts-ignore
          filters.filters.datetime_order.to = rule.value
        }
      } else if (rule.field === 'user_reference_number') {
        // @ts-ignore
        filters.ref_num = rule.value
      } else {
        // @ts-ignore
        if (!filters.filters[rule.field]) {
          // @ts-ignore
          filters.filters[rule.field] = []
        }
        // @ts-ignore
        filters.filters[rule.field].push(rule.value)
      }
    })
    // @ts-ignore
    if (Object.keys(filters.filters).length === 0) delete filters.filters
    else
      Object.keys(filters.filters).forEach((field) => {
        if (
          // @ts-ignore
          Array.isArray(filters.filters[field]) &&
          // @ts-ignore
          filters.filters[field].length === 0
        ) {
          // @ts-ignore
          delete filters.filters[field]
        }
      })
    // @ts-ignore
    if (filters.global_search === '') delete filters.global_search
    console.log(filters)
    return filters
  }

  //test

  @action.bound fetchData = async (): Promise<void> => {
    this.loaded = false
    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/packages_view?size=${this.pagination.pageSize}&from=${
      (this.pagination.pageIndex - 1) * this.pagination.pageSize + 1
    }`

    const data = this.convertRulesToFilters(
      this.rootStore.TableStore.appliedQuery.rules,
      this.rootStore.TableStore.appliedGlobalSearch,
    )

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    console.log(url, data, config)
    const response = await axios.post(url, data, config)
    this.data = await response.data.packages
    console.log(toJS(response.data))
    this.pagination.tableSize = await response.data.count
    this.loaded = true
  }

  @action.bound fetchDetailsData = async (): Promise<void> => {
    this.loaded = false
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/packages/order/${this.rootStore.RouteStore.currentDataId}`

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.get(url, config)
    this.data = await response.data
    this.loaded = true
  }
  @action.bound selectAllFunction = async () => {
    if (this.pagination.tableSize > 10000) {
      toast(
        'Current table size is bigger than maximum request size (10000). Please filter data first!',
        { type: 'error' },
      )
      return
    }
    if (
      this.rootStore.TableStore.selectedRowId.length !==
      this.pagination.tableSize
    ) {
      this.loaded = false
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/packages_view?size=${this.pagination.tableSize}&from=1`
      const data = {
        global_search: '',
        filters: {},
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
      const response = await axios.post(url, data, config)
      const preFilteredData = await response.data.packages
      const selectedRowId = [
        ...preFilteredData.map((e: { id: string }) => e.id),
      ]
      this.rootStore.TableStore.selectedRowId = [...new Set(selectedRowId)]

      if (this.rootStore.TableStore.selectedRowId.length > 0)
        this.rootStore.TableStore.allSelectedRows = true
      this.loaded = true
    } else {
      this.rootStore.TableStore.allSelectedRows = false
      this.rootStore.TableStore.selectedRowId = []
      this.fetchData()
    }
  }

  @action.bound setPagination = (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number,
  ) => {
    this.rootStore.SlidingPanelState.setSelectedSlider('')
    this.pagination = {
      tableSize,
      pageSize,
      pageIndex,
      totalCount,
    }
    try {
      this.fetchData()
    } catch (e: any) {
      toast(e.message.toString(), { type: 'error' })
    }
  }

  @action.bound exportPackageDocuments = async (row: any, all: boolean) => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/packages/documents_print`
    const id = row
      .getAllCells()
      .find((e: any) => e.column.id === 'package_id')?.value

    const data = {
      ids: [id],
      documents_types: all ? ['all'] : ['labels'],
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }

    try {
      console.log(url, data, config)
      const response = await axios.post(url, data, config)
      const fileUrl = response.data.url
      const link = document.createElement('a')
      link.href = fileUrl
      link.setAttribute('download', 'download.zip')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error during file download:', error)
    }
  }

  @action.bound clearData = () => {
    this.data = []
  }
}
