/* eslint-disable */
import { useContext, useState } from 'react'

import {
  Container,
  Footer,
  InnerCont,
} from './RemoveMultipleTransactions.styles'

import { StoreContext } from '../../App'

import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import { toJS } from 'mobx'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../styles/Loading.styles'
import Button from '../button/Button.component'

const RemoveMultipleTransactions = () => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const [loading, setLoading] = useState(true)
  const handleSave = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/transactions/remove`
    const objectsList = store.TableStore.selectedRowId
    const data = {
      ids: toJS(store.TableStore.selectedRowId),
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    console.log(url, data, config)
    await axios.post(url, data, config)
    await toast(locales.successes.success, { type: 'success' })
    await store.SlidingPanelState.setVisibility(false)
    await setLoading(true)
    await store.TableStore.fetchData()
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont>
        <p>{locales.warnings.wantToRemove}</p>
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button type={'primary'} onClick={() => handleSave()}>
          {loading ? (
            <LoadingContainer>
              <Wrap></Wrap>
            </LoadingContainer>
          ) : (
            <>{'CONFIRM'}</>
          )}
        </Button>
      </Footer>
    </Container>
  )
}

export default RemoveMultipleTransactions
