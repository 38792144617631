import { observer } from 'mobx-react'
import { FC, useState } from 'react'
import ArrowExpanded from '../../assets/icons/arrow-expanded.svg'
import {
  ItemsTable,
  SingleItemCont,
  SingleItemTitle,
  SingleItemValue,
  StyledTable,
  TableHead,
  Title,
} from './ContactObjectDataPanel.styles'

interface Column {
  header: string
  value: string
}

interface Props {
  columns: Column[]
  title: string | JSX.Element
  rowDisplay?: boolean
  arrowProps?: {
    showArrow: boolean
    expanded: boolean
  }
}

const ContactDataPanel: FC<Props> = observer(
  ({ columns, title, rowDisplay = false, arrowProps }) => {
    const [expanded, setExpanded] = useState(arrowProps?.expanded ?? true)

    return (
      <StyledTable>
        <TableHead active={expanded}>
          <Title>{title}</Title>
          {arrowProps?.showArrow && (
            <img
              onClick={() => setExpanded(!expanded)}
              src={ArrowExpanded}
              alt={'>'}
            />
          )}
        </TableHead>
        {expanded && (
          <ItemsTable rowDisplay={rowDisplay}>
            {columns.map((column, index) => (
              <SingleItemCont rowDisplay={rowDisplay} key={index}>
                <SingleItemTitle rowDisplay={rowDisplay}>
                  {column.header}
                </SingleItemTitle>
                <SingleItemValue rowDisplay={rowDisplay}>
                  {column.value}
                </SingleItemValue>
              </SingleItemCont>
            ))}
          </ItemsTable>
        )}
      </StyledTable>
    )
  },
)

export default ContactDataPanel
