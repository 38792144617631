import styled from 'styled-components'

export const MainCont = styled.form`
  > div {
    margin-bottom: 32px;
  }
`
export const RadioComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  input {
    margin-right: 12px;
  }
`
