import axios from '../../axiosconfig'

export const setDefaultDetails = async (id: string) => {
  const date = new Date()
  await axios.post(
    `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${id}`,
    {
      checkListItems: {
        packLeeflets: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        checkQualityProducts: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        sendSalesInvoiceEmail: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        pickProducts: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        stickShippingLabelOnPackageAndSend: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        controlInvoice: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        packProducts: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        countProducts: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        controlTagsStatusesOrderDetailsOnInvoice: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        generateShipping: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        issueSalesInvoice: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
        printListProducts: {
          date: date.toISOString(),
          username: '',
          selected: false,
        },
      },
    },
    {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  )
}
