import styled from 'styled-components'

interface ContainerInterface {
  secondary?: boolean
  secondaryColor?: string
  primaryColor?: string
  borderRadius?: string
  margin?: string
  padding?: string
  fontSize?: string
}

export const Container = styled.div<ContainerInterface>`
  margin: ${(props) => (props.margin ? props.margin : '4px 0')};
  padding: ${(props) => (props.padding ? props.padding : '4px 0')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '11px')};
  text-align: center;
`
