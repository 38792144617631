import { translations as en } from '../static/languages/en'
import { translations as pl } from '../static/languages/pl'
import { action, makeAutoObservable } from 'mobx'
import { RootStore } from './Root.store'
import axios from '../axiosconfig'
import moment from 'moment'
import { TranslationsInterface } from '../static/languages/interface'

export class TranslationsStore {
  rootStore
  translations: TranslationsInterface = en
  setLanguage = 'en_GB'
  avaliableLanguages: string[] = []
  allLanguages: { value: string; label: string }[] = [
    { value: 'en_GB', label: 'English' },
    { value: 'pl_PL', label: 'Polish' },
    { value: 'sv_SE', label: 'Swedish' },
    { value: 'da_DK', label: 'Danish' },
    { value: 'no_NO', label: 'Norwegian' },
    { value: 'es_ES', label: 'Spanish (Spain)' },
    { value: 'es_MX', label: 'Spanish (Mexico)' },
    { value: 'zh_CN', label: 'Chinese (Simplified)' },
    { value: 'zh_TW', label: 'Chinese (Traditional)' },
    { value: 'fr_FR', label: 'French (France)' },
    { value: 'fr_CA', label: 'French (Canada)' },
    { value: 'de_DE', label: 'German' },
    { value: 'ja_JP', label: 'Japanese' },
    { value: 'pt_BR', label: 'Portuguese (Brazil)' },
    { value: 'pt_PT', label: 'Portuguese (Portugal)' },
    { value: 'ru_RU', label: 'Russian' },
    { value: 'ar_SA', label: 'Arabic (Saudi Arabia)' },
    { value: 'hi_IN', label: 'Hindi' },
    { value: 'bn_BD', label: 'Bengali (Bangladesh)' },
    { value: 'bn_IN', label: 'Bengali (India)' },
    { value: 'ko_KR', label: 'Korean' },
    { value: 'it_IT', label: 'Italian' },
    { value: 'nl_NL', label: 'Dutch' },
  ]

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.initDefaultLanguages()
  }

  @action.bound setAvailableLanguages(avaliableLanguages: string[]) {
    this.avaliableLanguages = avaliableLanguages
  }

  @action.bound
  async initDefaultLanguages() {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/languages/list?showAll=true`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }
    const response = await axios.get(url, config)
    this.setAvailableLanguages(response.data.languages)
  }

  @action.bound
  async setTranslations(language: string) {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/language/${language}`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.get(url, config)

    this.translations = language === 'en_GB' ? en : pl
    this.setLanguage = language
  }

  @action.bound
  async addNewLanguage(language: string) {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/language/${language}`
    const data = {
      language_data: {
        lang_id: language,
        locale: '',
        currency_id: 0,
        active: true,
        order: 0,
      },
      doc: en, // use english as default
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    await axios.post(url, data, config)
    this.setTranslations(language)
  }

  @action.bound getLanguageLabel = (language: string) => {
    const languageLabel = this.allLanguages.find(
      (lang) => lang.value === language,
    )
    return languageLabel?.label
  }

  @action.bound dateFormatter = (
    data: string,
    type: 'date' | 'datetime-local' = 'date', // Specify possible types
    format: string = 'DD.MM.YYYY', // Default input format (can differ)
  ) => {
    // Target format based on the input type (for the HTML input field)
    const targetFormat =
      type === 'datetime-local' ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD'

    // Try to parse the date using the provided input format (e.g., DD.MM.YYYY)
    let momentData = moment(data, format, true)
    // If the provided input format doesn't match, try parsing it using the target format (e.g., YYYY-MM-DD)
    if (!momentData.isValid()) {
      momentData = moment(data, targetFormat, true)
    }

    // Return the formatted date if valid, otherwise return an empty string
    return momentData.isValid() ? momentData.format(targetFormat) : ''
  }

  @action.bound dateDeformatter = (
    data: string,
    type?: string,
    format?: string,
  ) => {
    const momentData = moment(data)
    const defaultFormat =
      type === 'datetime-local' ? 'YYYY-MM-DDThh:mm' : 'YYYY-MM-DD'
    return momentData.format(format || defaultFormat)
  }
}
