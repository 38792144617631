import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext } from 'react'

import { StoreContext } from '../../../App'
import {
  AllSelectCheckbox,
  IndeterminateCheckbox,
  PageRenderedCheckbox,
  PageSelectCheckbox,
} from '../../checkboxSelect/CheckboxSelect.component'

import {
  HeaderCheckboxCont,
  HeaderCheckboxes,
  HeaderCheckboxParagraph,
  HeaderInnerCheckBox,
} from './TableRenderedCheckboxes.styles'
import All from '../../../assets/icons/all.svg'
import Page from '../../../assets/icons/page.svg'

interface Props extends PropsWithChildren {
  data: object[]
  id: string
}

const TableHeaderCheckboxes: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  return (
    <HeaderCheckboxes {...Theme.tableStyles.headerCheckboxes}>
      <HeaderInnerCheckBox>
        <HeaderCheckboxCont>
          <PageRenderedCheckbox id={props.id} data={props.data} />
          <HeaderCheckboxParagraph
            {...Theme.tableStyles.headerCheckboxParagraph}
          >
            <img src={Page} />
          </HeaderCheckboxParagraph>
        </HeaderCheckboxCont>
      </HeaderInnerCheckBox>
    </HeaderCheckboxes>
  )
})
export default TableHeaderCheckboxes
