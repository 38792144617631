import React, { PropsWithChildren, FC, useContext } from 'react'
/* eslint-disable */
import { Container } from './TableReturnsProduct.styles'
import { StoreContext } from '../../../App'
import { observer } from 'mobx-react'

interface Props extends PropsWithChildren {
  cell: any
}

const TableReturnsProduct: FC<Props> = observer(({ cell }: Props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const code = cell.row
    .getAllCells()
    .find((e: any) => e.column.id === 'code')
    ?.getValue()

  const productInfo = store.ReturnsStore.detailsProducts?.find(
    (e) => e.code === code,
  )

  const orderDetails: any = store.TableStore.detailsData.products
    ? Object.values(store.TableStore.detailsData.products).find(
        (e: any) => e.code === code,
      )
    : null

  return (
    <Container>
      {productInfo && orderDetails ? (
        <>
          <p>{productInfo.ean}</p>
          <p>{productInfo.name}</p>
          <p>{productInfo.producer}</p>
          <p>
            {orderDetails.price} {store.TableStore.detailsData.currency_name}
          </p>
        </>
      ) : (
        <p>{'Cannot find product info'}</p>
      )}
    </Container>
  )
})
export default TableReturnsProduct
