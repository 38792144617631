import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../App'
import { useParams } from 'react-router-dom'

import {
  Container,
  OuterCont,
  SettingsList,
  StyledLink,
  StyledMenuItem,
} from './Contacts.styles'

import { IconChevronDown } from '@tabler/icons-react'
import Menu, { Item as MenuItem } from 'rc-menu'
import ContactsTableAddressesComponent from '../../components/contactsTableAdresses/ContactsTableAddresses.component'
import ContactsTableContactsComponent from '../../components/contactsTableContacts/ContactsTableContacts.component'
import ContactsTableIdentitiesComponent from '../../components/contactsTableIdentities/ContactsTableIdentities.component'
import ContactsTableCompaniesComponent from '../../components/contactsTableCompanies/ContactsTableCompanies.component'
import { ReactComponent as ArrowsRight } from '../../assets/icons/arrow-right.svg'
import { ReactComponent as ArrowsLeft } from '../../assets/icons/arrow-left.svg'

import { SettingsOpenButton } from '../settings/settings.styles'

const Contacts = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const { Theme } = store.Theme
  const params = useParams()
  const [openMenu, setOpenMenu] = useState(true)

  const getComponentFromUrl = () => {
    switch (params.page) {
      case 'addresses':
        return <ContactsTableAddressesComponent />
      case 'contacts':
        return <ContactsTableContactsComponent />
      case 'identities':
        return <ContactsTableIdentitiesComponent />
      case 'companies':
        return <ContactsTableCompaniesComponent />
      default:
        return <>{params.page}</>
    }
  }

  return (
    <OuterCont>
      <SettingsList active={openMenu}>
        <Menu
          mode='inline'
          style={{ margin: 0, width: '100%' }}
          expandIcon={<IconChevronDown size='18px' />}
        >
          <StyledMenuItem key='interface'>
            <StyledLink to={'/contacts/addresses'}>Addresses</StyledLink>
          </StyledMenuItem>
          <StyledMenuItem key='contacts'>
            <StyledLink to={'/contacts/contacts'}>Contacts</StyledLink>
          </StyledMenuItem>
          <StyledMenuItem key='identities'>
            <StyledLink to={'/contacts/identities'}>Identities</StyledLink>
          </StyledMenuItem>
          <MenuItem key='companies'>
            <StyledLink to={'/contacts/companies'}>Companies</StyledLink>
          </MenuItem>
        </Menu>
      </SettingsList>
      <SettingsOpenButton
        onClick={() => setOpenMenu(!openMenu)}
        active={openMenu}
      >
        {openMenu ? <ArrowsLeft /> : <ArrowsRight />}
      </SettingsOpenButton>
      <Container active={openMenu} {...Theme.SettingsPage.container}>
        {getComponentFromUrl()}
      </Container>
    </OuterCont>
  )
})
export default Contacts
