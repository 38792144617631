import { observer } from 'mobx-react'
import React, { FC, useContext, useRef } from 'react'

import { StoreContext } from '../../App'

import {
  Container,
  ContainerHeader,
  ExitIcon,
  HeaderImg,
  HeaderLeft,
  HeaderTitle,
} from './SlidingContainer.styles'
import FilterIcon from '../../assets/icons/filter-icon.svg'
import ClearFilters from '../../assets/icons/clear-filters.svg'
import { IconX } from '@tabler/icons-react'

/* eslint-disable */
interface SlidingPanelProps {
  children: React.ReactNode
  contentId: string
  title?: string | JSX.Element
  icon?: any
  handleVisibility?: () => void
}

export const SlidingPanel: FC<SlidingPanelProps> = observer(
  ({ children, contentId, title, icon }: SlidingPanelProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const container = useRef()

    const isVisible = () => {
      return (
        store.SlidingPanelState.selectedSlider === contentId &&
        store.SlidingPanelState.visibility
      )
    }

    return (
      <Container
        visible={isVisible()}
        position={'right'}
        ref={container}
        {...Theme.slidingContainerStyles.container}
      >
        <ContainerHeader>
          <HeaderLeft>
            {icon ? <HeaderImg src={icon} /> : <HeaderImg src={FilterIcon} />}
            <HeaderTitle>{title ? title : 'Header Title'}</HeaderTitle>
          </HeaderLeft>
          <button onClick={() => store.SlidingPanelState.setVisibility(false)}>
            <IconX size={'28px'} />
          </button>
        </ContainerHeader>
        {isVisible() ? children : <></>}
      </Container>
    )
  },
)
