import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'
import { ReplaceEmptyStringsForNulls } from '../columnsConfig'
import { GroupTagType, RootTagType, TagType } from '../types/TagTypes'

export class TagsStore {
  rootStore
  view: 'group' | 'root' | 'tag' = 'group'
  data = []
  tagList = []
  rootTagList = []
  collectionsData = []
  loaded = false
  treeLoaded = true
  filter: 'products' | 'orders' | '' = ''
  avaliableSizes = []
  avaliableIndicies: string[] = []
  avaliableIcons = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.getParams()
    this.getTagData()
  }

  tempurl = `${process.env.REACT_APP_BASE_URL}experience/v1/`
  getUrlBasedOnView = (
    type: 'data' | 'edit' | 'add' | 'update' | 'cut',
    id?: string,
    view?: 'group' | 'root' | 'tag',
  ) => {
    if (type === 'update') {
      return `${this.tempurl}tag/update/${id}`
    } else if (type === 'cut') {
      return `${this.tempurl}tag/cut/${id}`
    }
    switch (view ? view : this.view) {
      case 'root':
        return type === 'data'
          ? `${this.tempurl}roottags_view`
          : `${this.tempurl}tag/roottag/${id ? id : 'create'}`
      case 'group':
        return type === 'data'
          ? `${this.tempurl}grouptags_view`
          : `${this.tempurl}tag/grouptag/${id ? id : 'create'}`
      case 'tag':
        return type === 'data'
          ? `${this.tempurl}tags_view`
          : `${this.tempurl}tag/${id ? id : 'create'}`
      default:
        throw new Error('Invalid view')
    }
  }

  @action.bound getParams = async () => {
    const url = `${this.tempurl}tag`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }
    const icons = await axios.get(`${url}/icons`, config)
    const sizes = await axios.get(`${url}/sizes`, config)
    const indicies = await axios.get(`${url}/roottag/taggables`, config)
    this.avaliableIcons = await icons.data.values
    this.avaliableSizes = await sizes.data.values
    this.avaliableIndicies = await indicies.data.values
  }

  @action.bound getTagData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/tags_view?size=500&from=0`
    const url2 = `${process.env.REACT_APP_BASE_URL}experience/v1/roottags_view?size=500&from=0`
    const data = {
      skipChildren: false,
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.post(url, data, config)
    const response2 = await axios.post(url2, data, config)
    this.tagList = await response.data.tags
    this.rootTagList = await response2.data.tags
  }

  @action.bound getIndicesTags = () => {
    const roots = this.rootTagList.filter((e: RootTagType) =>
      e.indicesAllowed.includes(this.getCurrentIndex()),
    )
    const rootId = [...roots.map((e: RootTagType) => e.id)]
    return this.tagList.filter((e: TagType) => rootId.includes(e.root_id))
  }

  @action.bound fetchData = async () => {
    const currentPagination = this.rootStore.PaginationStore.currentPagination
    const pagination =
      this.rootStore.PaginationStore.pagination[currentPagination]
    console.log('tags pagination')
    console.log(toJS(currentPagination), toJS(pagination))
    this.loaded = false
    const url = `${this.getUrlBasedOnView('data')}?size=${
      pagination.pageSize
    }&from=${(pagination.pageIndex - 1) * pagination.pageSize}`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    console.log('tags', url, config)

    const response = await axios.post(
      url,
      {
        skipChildren: false,
      },
      config,
    )

    this.data = await response.data.tags
    this.loaded = true
  }

  @action.bound getCollectionsData = async () => {
    this.loaded = false

    setTimeout(async () => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collections`
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      const response = await axios.get(url, config)
      console.log(response.data)
      this.collectionsData = await response.data
      this.loaded = true
    }, 3000)
  }

  @action.bound clearCollectionsData = async () => {
    this.collectionsData = []
  }

  @action.bound addTag = async (
    data: object,
    view?: 'root' | 'group' | 'tag',
  ) => {
    const url = this.getUrlBasedOnView('add', undefined, view)
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    try {
      const newData = this.rootStore.TagsStore.formatRequestData(data)
      console.log(url, data, config)
      await axios.post(url, newData, config)
      await toast('Tag added', { type: 'success' })
      setTimeout(() => {
        this.fetchData()
        this.rootStore.SlidingPanelState.setVisibility(false)
      }, 1000)
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast(e.message.toString(), { type: 'error' })
      } else {
        // Handle other types of errors, if necessary
        toast('An unknown error occurred', { type: 'error' })
      }
    }
  }

  @action.bound editTag = async (
    data: object,
    id: string,
    view?: 'group' | 'root' | 'tag',
  ) => {
    const url = this.getUrlBasedOnView('edit', id, view)
    console.log(url)
    console.log(data)
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    try {
      await axios.post(
        url,
        this.rootStore.TableStore.formatRequestData(data),
        config,
      )
      await toast('Tag Edited', { type: 'success' })
      setTimeout(() => {
        this.fetchData()
        this.rootStore.SlidingPanelState.setVisibility(false)
      }, 1000)
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast(e.message.toString(), { type: 'error' })
      } else {
        // Handle other types of errors, if necessary
        toast('An unknown error occurred', { type: 'error' })
      }
    }
  }

  @action.bound insertTag = async (
    tagId: string,
    parentId: string,
    childId: string,
    tagData: any,
  ) => {
    const url = `${this.tempurl}tag/insert/${tagId}`
    if (tagId === '_create')
      tagData = this.rootStore.TagsStore.formatRequestData(tagData) as TagType
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const data = {
      child_id: childId,
      tag: {
        ...tagData,
        parent: parentId,
      },
    }
    console.log(data)
    try {
      await axios.post(url, data, config)
      console.log(data)
      await toast('Tag Inserted', { type: 'success' })
      setTimeout(() => {
        this.fetchData()
        this.rootStore.SlidingPanelState.setVisibility(false)
      }, 1000)
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast(e.message.toString(), { type: 'error' })
      } else {
        // Handle other types of errors, if necessary
        toast('An unknown error occurred', { type: 'error' })
      }
    }
  }
  @action.bound cutTag = async (
    data: object,
    id: string,
    view?: 'group' | 'root' | 'tag',
  ) => {
    const url = this.getUrlBasedOnView('cut', id, view)
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    try {
      await axios.post(url, data, config)
      console.log(data)
      await toast('Tag Edited', { type: 'success' })
      setTimeout(() => {
        this.fetchData()
        this.rootStore.SlidingPanelState.setVisibility(false)
      }, 1000)
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast(e.message.toString(), { type: 'error' })
      } else {
        // Handle other types of errors, if necessary
        toast('An unknown error occurred', { type: 'error' })
      }
    }
  }

  @action.bound setView = (view: 'root' | 'group' | 'tag') => {
    this.view = view
  }

  @action.bound setTreeLoaded = (loading: boolean) => {
    this.treeLoaded = loading
  }

  @action.bound formatRequestData = (
    data: TagType | RootTagType | GroupTagType,
  ) => {
    let formattedData = {}
    Object.entries(data).forEach(([key, value]) => {
      if (ReplaceEmptyStringsForNulls.includes(key) && value === '') {
        formattedData = { ...formattedData, [key]: null }
      } else if (key === 'stockAmountInPackageBool') {
        formattedData = { ...formattedData, [key]: value !== 'false' }
      } else if (value !== '' || Object.keys(value).length)
        formattedData = { ...formattedData, [key]: value }
    })
    console.log(formattedData)
    return formattedData
  }

  @action.bound getCurrentIndex = (): string => {
    switch (this.rootStore.RouteStore.currentPage) {
      case 'customerorders':
      case 'packing':
        return 'customer orders'
      case 'stock':
        return 'products'
      case 'supplierorders':
        return 'purchase orders'
      case 'bank':
        return 'bank transactions'
      default:
        return 'customer orders'
    }
  }

  @action.bound setLoaded = (loaded: boolean) => {
    this.loaded = loaded
  }
}
