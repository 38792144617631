/* eslint-disable */
import { observer } from 'mobx-react'
import { FC, useState } from 'react'
import ArrowExpanded from '../../assets/icons/arrow-expanded.svg'
import {
  ItemsTable,
  SingleItemCont,
  SingleItemTitle,
  SingleItemValue,
  StyledTable,
  TableHead,
  Title,
} from './ContactArrayDataPanel.styles'

interface ArrayItem {
  [key: string]: any
}

interface Props {
  data: ArrayItem[]
  title: string | JSX.Element
  rowDisplay?: boolean
  arrowProps?: {
    showArrow: boolean
    expanded: boolean
  }
}

const ContactArrayDataPanel: FC<Props> = observer(
  ({ data, title, rowDisplay = false, arrowProps }) => {
    const [expanded, setExpanded] = useState(arrowProps?.expanded ?? true)

    return (
      <StyledTable>
        <TableHead active={expanded}>
          <Title>{title}</Title>
          {arrowProps?.showArrow && (
            <img
              onClick={() => setExpanded(!expanded)}
              src={ArrowExpanded}
              alt={'>'}
            />
          )}
        </TableHead>
        {expanded && (
          <ItemsTable rowDisplay={rowDisplay}>
            {[...data, ...data, ...data].map((item, index) => (
              <SingleItemCont rowDisplay={rowDisplay} key={index}>
                {Object.entries(item).map(([key, value]) => (
                  <div key={key}>
                    <SingleItemTitle rowDisplay={rowDisplay}>
                      {key}:
                    </SingleItemTitle>
                    <SingleItemValue rowDisplay={rowDisplay}>
                      {typeof value === 'object' && value !== null
                        ? Object.entries(value)
                            .map(
                              ([subKey, subValue]) =>
                                `${subKey}: ${String(subValue)}`,
                            )
                            .join(', ')
                        : String(value)}
                    </SingleItemValue>
                  </div>
                ))}
              </SingleItemCont>
            ))}
          </ItemsTable>
        )}
      </StyledTable>
    )
  },
)

export default ContactArrayDataPanel
