/* eslint-disable */
import axios from '../../axiosconfig'
import { observer } from 'mobx-react'
import { useContext, useEffect, useRef, useState } from 'react'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'

import { hiddenColumns, ValidateArray } from '../../columnsConfig'
import { StoreContext } from '../../App'

import { InnerCont, Footer } from './EditMultipleStatuses.styles'
import { toast } from 'react-toastify'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'
import { toJS } from 'mobx'
import Button from '../button/Button.component'

interface formInterface {
  columns: Array<any>
  url: string
}

const EditMultipleStatuses = observer(({ url, columns }: formInterface) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const divRef = useRef<any>()
  const dataChange = store.TableStore.dataInput
  const [inputColumns, setInputColumns] = useState(1)
  const [triggerChange, setTriggerChange] = useState(false)
  const [isAbleToSave, setIsAbleToSave] = useState(false)
  const { width, height } = useWindowDimensions()

  const getEmptyValue = (value: string, e: any) => {
    switch (value) {
      case 'text':
      case 'date':
      case 'tagSearch':
      case 'rootSearch':
      case 'groupSearch':
        return ''
      case 'number':
        return 0
      case 'color':
        return '#000000'
      case 'languageObject':
        return {
          en_GB: '',
        }
      case 'stringArraySelect':
      case 'salesChannels':
        return []
      case 'select':
        return e.editCustomOptions.selectValues[0].value
      default:
        return ''
    }
  }
  const initialState = [
    ...columns.map((e) => {
      return {
        header: e.id,
        value: getEmptyValue(e.editCustomOptions.editType, e),
        error: '',
        rules: ValidateArray[e.header] ? ValidateArray[e.header] : [],
        isActive: false,
      }
    }),
  ]

  const [form, setForm] = useState(initialState)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => {
    setForm([...tempForm])
  }

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== '') {
        changed = true
        return
      }
    })
    form.map((e, index) => {
      if (e.error !== '') {
        changed = false
        return
      }
    })
    setIsAbleToSave(changed)
  }, [triggerChange])

  const getUrl = () => {
    return store.StatusesStore.getUrlBasedOnView(
      store.StatusesStore.currentPage,
    )
  }

  const handleSave = async () => {
    let bodyData: any = {}
    form.map((f: any, index) => {
      if (JSON.stringify(f.value) !== JSON.stringify(initialState[index].value))
        bodyData = { ...bodyData, [f.header]: f.value }
    })

    const data = {
      documents_ids: toJS(store.TableStore.selectedRowId),
      [getUrl()]: store.TableStore.formatRequestData(bodyData),
    }

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    console.log(url, data, config)
    await axios.post(url, data, config)
    await store.StatusesStore.fetchData(store.StatusesStore.currentPage)
    store.SlidingPanelState.setVisibility(false)
    await toast(`Success!`, {
      type: 'success',
    })
  }

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 * columns.filter((e) => !hiddenColumns.includes(e.header)).length) /
          (height * 0.8),
      ),
    )
  }, [height])

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
    form.map((e, index) => {
      if (e.error !== '') {
        changed = false
        return
      }
    })
  }, [form])

  return (
    <div>
      <InnerCont ref={divRef} cols={inputColumns}>
        {columns
          ? columns.map((e, index) => {
              const customOptions = e.editCustomOptions
              if (customOptions.editable)
                return (
                  <DataInputGeneric
                    type={'edit'}
                    vals={{ header: e.Header, value: e.value }}
                    editOptions={customOptions}
                    key={`data-input--${index}`}
                    index={index}
                    form={form}
                    setForm={handleSetForm}
                    triggerChange={() => setTriggerChange(!triggerChange)}
                  />
                )
            })
          : null}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            if (isAbleToSave) handleSave()
          }}
          active={isAbleToSave}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </div>
  )
})
export default EditMultipleStatuses
