import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from '../axiosconfig'

export class StoreSettingsStore {
  rootStore
  data = []
  allData = []
  //settingsData = {}
  currentPage = 'currencies'
  loaded = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  getUrlBasedOnView = (type?: string) => {
    switch (type) {
      case 'currencies':
        return 'currencies'
      case 'timezones':
        return 'timezones'
      default:
        return 'currencies'
    }
  }

  @action.bound fetchData = async (type: string): Promise<void> => {
    console.log(toJS(this.rootStore.UserStore.userObject.id))
    this.loaded = false
    this.currentPage = type
    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/storesettings/${this.getUrlBasedOnView(type)}`
    console.log(url)
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.get(url, config)
    this.data = await response.data
    this.loaded = true
  }

  @action.bound fetchAllData = async (): Promise<void> => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.get(url, config)
    this.allData = await response.data
  }

  @action.bound clearData = () => {
    this.data = []
    this.allData = []
    this.loaded = false
  }
}
