import { observer } from 'mobx-react'
import {
  ButtonCont,
  Entry,
  Footer,
  FooterInputCont,
  InnerCont,
  Label,
  Setting,
  SettingsCategory,
  SingleGroup,
  StyledInput,
  ThemeOpenMenu,
} from './ThemeCreator.styles'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../App'

import { mainTheme } from '../../../static/themes/main.theme'
import { toast } from 'react-toastify'
import { PopoverPicker } from './picker/Picker.component'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowsRight } from '../../../assets/icons/arrow-right.svg'
import { ReactComponent as ArrowsLeft } from '../../../assets/icons/arrow-left.svg'
import axios from '../../../axiosconfig'
import Button from '../../button/Button.component'
/* eslint-disable */
export const ThemeCreatorComponent = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [themeName, setThemeName] = useState('')
  const [secondStep, setSecondStep] = useState(false)
  const [editableThemeObject, setEditableThemeObject] = useState<any>(mainTheme)
  const [visible, setVisible] = useState(true)
  const colorInputs = [
    'color',
    'bgColor',
    'inputColor',
    'themebackgroundcolor',
    'themecolor',
  ]
  const saveToLocalStorage = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/themes/${themeName}`
    const body = {
      theme_data: {
        theme_name: themeName,
        active: true,
        order: 0,
        owner_id: '',
      },
      doc: editableThemeObject,
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    await axios.post(url, body, config)
    await toast('Saved!', {
      type: 'success',
    })
    //store.Theme.activateThemeModification(false)
    await toast(
      "Theme successfully Created! You can now select it from the theme's dropdown menu.",
      {
        type: 'success',
      },
    )
    await store.Theme.activateThemeModification(false)
    await setSecondStep(false)
    await store.Theme.initThemes()
  }

  const handleChange =
    (parentKey: string, key: string, innerKey: string) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const mainKeys = parentKey.split('.')
      const updatedObject = { ...editableThemeObject }

      mainKeys.reduce((obj, k, i) => {
        if (i === mainKeys.length - 1) {
          obj[k] = {
            ...obj[k],
            [key]: { ...obj[k][key], [innerKey]: e.target.value },
          }
        }
        return obj[k]
      }, updatedObject)

      setEditableThemeObject(updatedObject)
      store.Theme.switchTheme(updatedObject)
    }

  const handleColorChange =
    (parentKey: string, key: string, innerKey: string) => (color: string) => {
      const mainKeys = parentKey.split('.')
      let updatedObject = { ...editableThemeObject }

      mainKeys.reduce((obj, k, i) => {
        if (i === mainKeys.length - 1) {
          obj[k] = { ...obj[k], [key]: { ...obj[k][key], [innerKey]: color } }
        }
        return obj[k]
      }, updatedObject)
      setEditableThemeObject(updatedObject)
      store.Theme.switchTheme(updatedObject)
    }

  const renderInput = (
    parentKey: string,
    key: string,
    value: any,
    index: number,
  ): any => {
    if (typeof value === 'object') {
      return (
        <SingleGroup>
          <h4>{key}</h4>
          {Object.entries(value).map(
            ([innerKey, innerValue]: any, innerIndex) => (
              <Entry key={`entry-${innerIndex}`}>
                <Label>{`${innerKey}`}</Label>
                {colorInputs.includes(innerKey) ? (
                  <PopoverPicker
                    color={innerValue || '#000000'}
                    onChange={handleColorChange(parentKey, key, innerKey)}
                  />
                ) : (
                  <input
                    type='text'
                    value={innerValue ? innerValue.toString() : ''}
                    onChange={handleChange(parentKey, key, innerKey)}
                  />
                )}
              </Entry>
            ),
          )}
        </SingleGroup>
      )
    } else {
      return null
    }
  }

  const renderSettings = (obj: { [key: string]: any }) =>
    Object.entries(obj).map(([key, value], index) => (
      <Setting>
        <h2>
          <a href={`#${key}`}>{key}</a>
        </h2>
        {Object.entries(value).map(([innerKey, innerValue], innerIndex) =>
          renderInput(key, innerKey, innerValue, innerIndex),
        )}
      </Setting>
    ))

  return (
    <>
      <SettingsCategory active={visible}>
        <h1>Create a new theme</h1>
        <InnerCont>
          {renderSettings(editableThemeObject)}
          <Footer>
            <FooterInputCont>
              <p>{'Name your theme:'}</p>
              <StyledInput
                value={themeName}
                onChange={(e) => setThemeName(e.target.value)}
                type={'text'}
              />
            </FooterInputCont>
            <ButtonCont>
              {!secondStep ? (
                <Link to={'/themeverify'}>
                  <Button
                    type={'primary'}
                    onClick={() => setSecondStep(true)}
                    {...Theme.buttons.primaryButton}
                  >
                    Save
                  </Button>
                </Link>
              ) : (
                <Link to={'/'}>
                  <Button
                    type={'primary'}
                    onClick={saveToLocalStorage}
                    {...Theme.buttons.primaryButton}
                  >
                    Confirm
                  </Button>
                </Link>
              )}
              <Button
                type={'primary'}
                onClick={() => {
                  setEditableThemeObject(mainTheme)
                  store.Theme.activateThemeModification(false)
                  store.Theme.switchTheme(mainTheme, 'mainTheme')
                  toast('Cancelled!', {
                    type: 'info',
                  })
                }}
              >
                Cancel
              </Button>
            </ButtonCont>
          </Footer>
        </InnerCont>
      </SettingsCategory>
      <ThemeOpenMenu active={visible} onClick={() => setVisible(!visible)}>
        {visible ? <ArrowsRight /> : <ArrowsLeft />}
      </ThemeOpenMenu>
    </>
  )
})
