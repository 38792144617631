import { BlobServiceClient } from '@azure/storage-blob'

import { observer } from 'mobx-react'
import React, {
  ChangeEvent,
  DragEvent,
  FC,
  FormEvent,
  PropsWithChildren,
  useContext,
  useRef,
  useState,
} from 'react'
import Select from 'react-select'

import { FileDrop } from 'react-file-drop'
import { StoreContext } from '../../../App'

import {
  DropArea,
  Footer,
  InnerContDrop,
  ModalCont,
  SelectedFile,
  SelectFileParagraph,
  StyledModal,
  DefaultColumnSelect,
} from './TableBankExports.styles'

import axios from '../../../axiosconfig'
import { toast } from 'react-toastify'
import SearchFieldButton from '../searchFieldButton/SearchFieldButton.component'
import Plus from '../../../assets/icons/plus-white.svg'
import SearchSelectComponent from '../../searchSelect/SearchSelect.component'
import Button from '../../button/Button.component'

interface Props extends PropsWithChildren {
  data: object[]
}

interface FileBlob extends Blob {
  name: string
}

const TableBankExports: FC<Props> = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const { Theme } = store.Theme

  const inputRef = useRef<HTMLInputElement>()
  const [file, setFile] = useState<FileBlob | null>(null)
  const [fileOption, setFileOption] = useState<string | null>(null)
  const [active, setActive] = useState(false)

  const options = [
    { value: 'allegro', label: 'allegro' },
    { value: 'amazon', label: 'amazon' },
    { value: 'blue media', label: 'blue media' },
    { value: 'etsy', label: 'etsy' },
    { value: 'paypal', label: 'paypal' },
    { value: 'PKO', label: 'PKO' },
    { value: 'sumup', label: 'sumup' },
    { value: 'Paypro', label: 'Paypro' },
  ]

  const fileHandler = (
    files: FileList | null,
    e: DragEvent<HTMLDivElement>,
  ) => {
    e.preventDefault()
    if (files) setFile(files[0])
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const storageAccountName = 'moodnookblob'
    const sasToken =
      '?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwlacupitfx&se=2030-01-05T19:45:37Z&st=2023-01-05T11:45:37Z&spr=https&sig=de5llLawOuvgxJoZ6XC2bSNQitD5spBwV2kFijl%2F9j4%3D'
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/${sasToken}`,
    )
    const containerClient = blobService.getContainerClient('banktransactions')
    const blobClient = containerClient.getBlockBlobClient(
      `${getFileName()}_${fileOption}_${file?.name || 'unnamed'}`,
    )
    const options = { blobHTTPHeaders: { blobContentType: file?.type } }
    if (file) {
      const response = await blobClient.uploadData(file, options)
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}webhook/v1/banks_blob_upload`,
        {
          url: response._response.request.url,
          type: fileOption,
          user: store.UserStore.user,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
            'Access-Control-Allow-Credentials': 'true',
          },
        },
      )
      setActive(false)
      setFile(null)
      store.SlidingPanelState.setSelectedSlider('')
      toast(`Request sent with status ${res.status}`, {
        type: 'success',
      })
      setTimeout(async () => {
        await store.TableStore.fetchData()
      }, 1000)
    } else toast('No file selected!', { type: 'error' })
  }

  const filePicker = () => {
    if (inputRef.current) inputRef.current.click()
  }

  const onFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      setFile(e.target.files[0])
    }
  }

  const getFileName = () => {
    const date = new Date()
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      '-' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds()
    )
  }

  return (
    <>
      <SearchFieldButton
        text={'Upload Report'}
        onClick={() => setActive(true)}
        icon={<img src={Plus} />}
      />
      {active ? (
        <StyledModal
          open={active}
          onClose={() => {
            setActive(false)
            setFile(null)
          }}
        >
          <ModalCont>
            <InnerContDrop {...Theme.tableBankExportsStyles.innerContDrop}>
              {file ? (
                <SelectedFile {...Theme.tableBankExportsStyles.selectedFile}>
                  <SelectFileParagraph>
                    <b>Selected File:</b> {file?.name}
                  </SelectFileParagraph>
                  <SelectFileParagraph>
                    {'Select File Type:'}
                  </SelectFileParagraph>
                  <SearchSelectComponent
                    Container={DefaultColumnSelect}
                    value={fileOption}
                    onClick={(e) => {
                      setFileOption(e.value)
                    }}
                    options={[{ value: '', label: ' ' }, ...options]}
                  />
                </SelectedFile>
              ) : (
                <FileDrop
                  onTargetClick={filePicker}
                  onDrop={(f, e) => fileHandler(f, e)}
                >
                  <DropArea {...Theme.tableBankExportsStyles.dropArea}>
                    <p>DRAG FILE HERE OR BROWSE</p>
                    <input
                      value=''
                      style={{ display: 'none' }}
                      //@ts-ignore
                      ref={inputRef}
                      type='file'
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onFileInputChange(e)
                      }
                    />
                  </DropArea>
                </FileDrop>
              )}
            </InnerContDrop>
            <Footer>
              <Button
                type={'secondary'}
                onClick={() => {
                  setFile(null)
                  setActive(false)
                }}
              >
                {locales.buttons.cancel}
              </Button>
              <Button type={'primary'} onClick={(e) => handleSubmit(e)}>
                {'Confirm'}
              </Button>
            </Footer>
          </ModalCont>
        </StyledModal>
      ) : (
        <></>
      )}
    </>
  )
})

export default TableBankExports
