import styled from 'styled-components'
import Select from 'react-select'

interface SelectedInterface {
  selected: boolean
}

export const Container = styled.div<SelectedInterface>`
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: visible;
  opacity: ${(props) => (props.selected ? 1 : 0.2)};
`
export const StyledButton = styled.div`
  cursor: pointer;
  flex-grow: 0;
  padding: 8px;
  border-bottom: 1px solid transparent !important;
  text-decoration: none;
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`

export const StyledImage = styled.img`
  max-width: 60px;
`

interface DisabledSelectedInterface {
  disabledSelected: boolean
}

export const StyledInput = styled.input<DisabledSelectedInterface>`
  border-radius: 4px;
  flex-grow: 1;
  height: 32px;
  padding: 8px 6px;
  border: 1px solid #e5e5e5;
  width: 60%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`
