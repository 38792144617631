import styled from 'styled-components'
import { darken } from 'polished'
import Modal from '@mui/material/Modal'

/* eslint-disable */

interface ContButtonProps {
  padding?: string
  margin?: string
  fontSize?: string
  lineHeight?: string
  border?: string
  borderRadius?: string
}

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalCont = styled.div`
  position: absolute;
  max-width: 500px;
  background-color: white;
  border-radius: 4px;

  > p {
    padding: 16px;
  }
`

interface InnerContDropProps {
  padding?: string
  border?: string
  bgColor?: string
}

export const InnerContDrop = styled.div<InnerContDropProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface DropAreaProps {
  padding?: string
  border?: string
  bgColor?: string
}

export const DropArea = styled.div<DropAreaProps>`
  border: ${(props) => (props.border ? props.border : '1px dashed #909090')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${(props) => (props.padding ? props.padding : '32px')};
  margin: 24px;

  p {
    font-weight: 700;
    color: #606060;
    font-size: 14px;
  }
`

interface SelectedFileProps {
  padding?: string
  border?: string
  bgColor?: string
  borderRadius?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const SelectedFile = styled.div<SelectedFileProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  position: relative;
  min-width: 240px;
  margin: 32px;
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
`

export const SelectFileParagraph = styled.p`
  color: #303030;
  font-size: 13px;
  line-height: 30px;
  font-weight: 500;
`

interface FooterProps {
  bgColor?: string
  borderRadius?: string
}

export const Footer = styled.div<FooterProps>`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0 0 4px 4px'};
`

export const FileNameHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledCloseCross = styled.img`
  top: 32px;
  right: 32px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  filter: invert(1);
`
export const ExitCrossCont = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
export const ExitCross = styled.img`
  height: 24px;
  width: 24px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
  filter: invert(1);
`

interface DefaultColumnInputProps {
  borderRadius?: string
  border?: string
  boxShadow?: string
  padding?: string
  margin?: string
}

export const DefaultColumnSelect = styled.select<DefaultColumnInputProps>`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  height: 36px;
  padding: ${(props) => (props.padding ? props.padding : '8px 6px')};
  border: ${(props) => (props.border ? props.border : ' 1px solid #e5e5e5')};
  width: 140px;
  font-size: 14px;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
`
