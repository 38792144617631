/* eslint-disable */
import axios from '../../axiosconfig'
import { observer } from 'mobx-react'
import { useContext, useEffect, useRef, useState } from 'react'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'

import {
  hiddenColumns,
  ReplaceEmptyStringsForNulls,
  ReplaceEmptyStringsForZeros,
  ValidateArray,
} from '../../columnsConfig'
import { StoreContext } from '../../App'

import { InnerCont, Footer } from './EditMultipleProducts.styles'
import { socket } from '../../socket/socket'
import Button from '../button/Button.component'
import { getEmptyValue } from '../createGeneric/CreateGeneric.component'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'

interface formInterface {
  columns: Array<any>
}

const EditMultipleProductsForm = observer(({ columns }: formInterface) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const { Theme } = store.Theme
  const divRef = useRef<any>()
  const dataChange = store.TableStore.dataInput

  const initialState = [
    ...columns.map((e) => {
      return {
        header: e.id,
        value: getEmptyValue(e.editCustomOptions?.editType, e),
        error: '',
        rules: ValidateArray[e] ? ValidateArray[e] : [],
        isActive: false,
      }
    }),
  ]
  const [form, setForm] = useState(initialState)
  const [triggerChange, setTriggerChange] = useState(false)

  const isAbleToSave = () => {
    if (!divRef.current) return false
    const checkBoxes = divRef.current.querySelectorAll('input[type="checkbox"]')
    if (checkBoxes.length === 0) return false
    const length =
      Array.from(checkBoxes).filter((e: any) => e.checked).length > 0
    return length
  }

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => {
    setForm([...tempForm])
  }

  const handleSave = async () => {
    store.TableStore.setLoaded(false)
    let data: any = {
      filterGroups: {
        rules: [],
        combinator: 'or',
        not: false,
      },
    }

    store.TableStore.selectedRowId.map((e: string, index: number) =>
      data.filterGroups.rules.push({
        field: 'id',
        operator: 'contains',
        valueSource: 'value',
        value: e,
      }),
    )

    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/${store.TableStore.getApiRequestFromUrl()}?size=${
      store.TableStore.selectedRowId.length
    }&from=0`

    const response = await axios.post(url, data, {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    })
    const res = await response.data[`${store.TableStore.getResponseParam()}`]
    const currentPage = store.RouteStore.currentPage

    const fetchPromises = await res.map((single: any, i: number) => {
      let bodyData: any = {}

      if (currentPage === 'supplierorders') {
        bodyData = {
          ...bodyData,
          lastEditAuthor: store.UserStore.user,
          id: single.id,
        }
      }

      form.map((f: any, index) => {
        if (f.isActive) {
          //console.log(f)
          if (ReplaceEmptyStringsForNulls.includes(f.header) && f.value === '')
            bodyData = { ...bodyData, [f.header]: null }
          if (f.header === 'status' && f.value === '')
            bodyData = { ...bodyData, [f.header]: '0' }
          else bodyData = { ...bodyData, [f.header]: f.value }
        }
      })

      const id = single.id
      const url = `${
        process.env.REACT_APP_BASE_URL
      }experience/v1/${store.TableStore.getUpadateParam()}/${id}`
      store.TableStore.setData(
        store.TableStore.data.map((e: any) =>
          e.id === single.id ? { ...e, ...bodyData } : e,
        ),
      )
      const formattedData = store.TableStore.formatRequestData(bodyData)
      return axios.post(url, formattedData, {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      })
    })

    await store.TableStore.setLoaded(true)
    await Promise.all(fetchPromises)
    res.map((single: any) => {
      socket.emit('editOrder', {
        id: single.id,
        user: store.UserStore.user,
        page: store.TableStore.getApiRequestFromUrl(),
      })
    })
    store.SlidingPanelState.setVisibility(false)
    //await setTimeout(async () => await store.TableStore.fetchData(), 3000)
  }
  const [inputColumns, setInputColumns] = useState(1)
  const { width, height } = useWindowDimensions()

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
  }, [form])

  return (
    <div>
      <InnerCont cols={2} ref={divRef}>
        {form.length
          ? form.map((e, index) => {
              const customOptions = columns.find(
                (col) => col.id === e.header,
              )?.editCustomOptions
              if (customOptions.editable)
                return (
                  <DataInputGeneric
                    type={'create'}
                    vals={{ header: e.header, value: e.value }}
                    editOptions={customOptions}
                    key={`data-input--${index}`}
                    index={index}
                    form={form}
                    setForm={handleSetForm}
                    triggerChange={() => setTriggerChange(!triggerChange)}
                  />
                )
            })
          : null}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            if (isAbleToSave()) handleSave()
          }}
          active={isAbleToSave()}
          {...Theme.buttons.primaryButton}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </div>
  )
})
export default EditMultipleProductsForm
