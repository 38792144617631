import styled, { keyframes } from 'styled-components'

/* eslint-disable */
interface ContainerProps {
  visible: boolean
  position: 'left' | 'right'
  ref: any
}

const slideRight = keyframes`
    from {
        right: -360px;
    }

    to {
        right: 0px;
    }
`

interface ContainerProps {
  boxShadow?: string
  bgColor?: string
  trackBgColor?: string
  trackBgRadius?: string
  thumbBgColor?: string
  thumbBgRadius?: string
  thumbBorder?: string
}

/* eslint-disable */
export const Container = styled.div<ContainerProps>`
  position: fixed !important;
  top: 0 !important;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  left: ${(props) => props.position === 'left' && '0px'};
  right: ${(props) => props.position === 'right' && '0px'};
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  min-width: min(100vw, 420px);
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  z-index: 101;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0px 0px 5px 0px rgba(0, 0, 0, 0.75)'};
  animation: ${slideRight} 0.2s linear forwards;
  overflow-x: auto;
  box-sizing: border-box;
  flex-basis: content;

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.trackBgColor ? props.trackBgColor : '#aaaaaa'};
    border-radius: ${(props) =>
      props.trackBgRadius ? props.trackBgRadius : '3px'};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.thumbBgColor ? props.thumbBgColor : '#303030'};
    border-radius: ${(props) =>
      props.thumbBgRadius ? props.thumbBgRadius : '6px'};
    border: ${(props) =>
      props.thumbBorder ? props.thumbBorder : '3px solid #aaaaaa'};
  }
`
export const ContainerHeader = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 16px 16px 6px;
  justify-content: space-between;
  align-items: center;
`
export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`

interface HeaderImgProps {
  margin?: string
}

export const HeaderImg = styled.img<HeaderImgProps>`
  height: 20px;
  width: 20px;
  margin-bottom: 4px;
`
export const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #1f1f1f;
  margin-left: 8px;
  text-transform: capitalize;
`
export const ExitIcon = styled.img`
  width: 20px;
  height: 20px;
`
