import styled, { css } from 'styled-components'
import { breakpoints } from '../../static/breakpoints'

interface ContainerProps {
  padding?: string
  bgColor?: string
  loading: boolean
}

export const Container = styled.section<ContainerProps>`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  min-height: calc(100vh - 54px);
  overflow: hidden;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  ${(props) => {
    if (!props.loading)
      return css`
        justify-content: center;
        align-items: center;
      `
  }}
`
export const HeaderCont = styled.div`
  display: flex;
  flex-direction: column;
`
export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
`
export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 35%;
  flex-grow: 2;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: flex-start;
`
export const HeaderInnerRight = styled(HeaderInner)`
  justify-content: flex-end;
  align-items: flex-start;
  flex-grow: 1;
`
export const ButtonCont = styled.div`
  display: flex;
  flex-wrap: wrap;
`

interface HeaderTitleProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const HeaderTitle = styled.p<HeaderTitleProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '26px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  margin-right: 8px;
`
export const ShippingCountry = styled.div`
  display: none;
  @media print {
    display: none;
  }

  &.no-print {
    @media print {
      display: block;
      position: fixed;
      right: 8px;
      top: 8px;
    }
  }
`

interface HeaderButtonProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const HeaderButton = styled.p<HeaderButtonProps>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : '#424242')};

  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  margin-bottom: 6px;
  cursor: pointer;
  margin-right: 16px;

  img {
    cursor: pointer;
    margin-right: 6px;
  }

  @media print {
    display: none;
  }
`

export const PrintSelectedButton = styled(HeaderButton)``

PrintSelectedButton.defaultProps = {
  onClick: () => {
    const selectedElements: NodeListOf<HTMLElement> =
      document.querySelectorAll('.hide-print')
    selectedElements.forEach((element) => {
      element.classList.add('no-print')
    })
    window.print()
    selectedElements.forEach((element) => {
      element.classList.remove('no-print')
    })
  },
}

export const NavButton = styled.a<HeaderButtonProps>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : '#424242')};

  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  margin-bottom: 5px;
  @media print {
    display: none;
  }

  img {
    cursor: pointer;
    margin-right: 6px;
  }
`

interface HeaderButtonProps {
  border?: string
  borderRadius?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
}

/* eslint-disable */
const HeaderButtonProps = css<HeaderButtonProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  outline: none;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  border: ${(props) => (props.border ? props.border : '1px solid')};
  min-width: 150px;
  margin: 4px;

  p {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
    letter-spacing: ${(props) =>
      props.letterSpacing ? props.letterSpacing : '0.5px'};
    flex-grow: 1;
    justify-content: center;
    text-transform: uppercase;
  }
`

/* eslint-enable */
interface SecondaryButtonProps {
  borderColor?: string
  color?: string
  bgColor?: string
}

export const SecondaryButton = styled.button<SecondaryButtonProps>`
  position: relative;
  width: 25%;
  max-width: 200px;
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : '#303030'};
  color: ${(props) => (props.color ? props.color : '#303030')};
  background-color: ${(props) => props.bgColor};
  ${HeaderButtonProps};

  @media print {
    display: none;
  }
`
export const StyledImg = styled.img`
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  width: 12px;
  height: 12px;
  padding: 0;
  display: block;
`
export const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 32px;
  margin-bottom: 40px;
  align-items: stretch;
  align-content: stretch;
  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
  @media print {
    display: flex;
    justify-content: space-between;
  }

  &.no-print {
    @media print {
      display: none !important;
    }
  }
`
export const LeftBody = styled.div`
  padding: 12px 0;
`
export const AddressBody = styled.div`
  margin: 4px;

  > * {
    position: relative;
    height: 100%;
  }

  @media print {
    position: relative;
    width: 50%;
  }
`
export const RightBody = styled.div`
  padding: 12px 24px;
  min-width: 380px;
`
export const HeaderParagraph = styled.p``
