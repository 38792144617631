import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'
import { Container } from '../../styles/LayoutStyles'
import { useContext } from 'react'
import { StoreContext } from '../../App'
import { observer } from 'mobx-react'

const MainPageComponent = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  return (
    <Container>
      <Breadcrumb treeArray={['MainPage']} />
      <div>{`Hello, ${store.UserStore.user}!`}</div>
    </Container>
  )
})
export default MainPageComponent
