import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { breakpoints } from '../../static/breakpoints'
import { MenuItem } from 'rc-menu'

interface ContainerProps {
  color?: string
}

export const OuterCont = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    height: auto;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
`

interface SettingsListInterface {
  active: boolean
}

export const SettingsList = styled.div<SettingsListInterface>`
  position: absolute;
  width: 20%;
  height: calc(100vh - 54px);
  max-height: 100%;
  overflow: auto;
  margin-left: ${(props) => (props.active ? '0' : '-20%')};
  min-height: calc(100vh - 54px);
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
  @media screen and (max-width: ${breakpoints.tablet}) {
    position: relative;

    height: auto;
    width: 100%;
    min-height: 0;
    margin-left: 0;
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
  transition: all 0.2s ease-in-out;

  * {
    transition: all 0.2s ease-in-out;
  }
`

export const StyledLink = styled(Link)`
  position: relative;
  height: 100%;
  width: 100%;
  text-wrap: wrap;
`

interface ContainerProps {
  color?: string
  active: boolean
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.active ? '80%' : '100%')};
  display: inline-block;
  box-sizing: border-box;
  padding: 16px;
  padding-left: 32px;
  margin-left: ${(props) => (props.active ? '20%' : '0')};
  color: ${(props) => (props.color ? props.color : 'black')};
  max-height: calc(100vh - 54px);
  overflow: auto;

  h1 {
    font-size: 24px;
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
  }
`

export const SettingsCategory = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 30px;

  h2 {
    font-size: 18px;
    border-bottom: 1px solid gray;
    margin: 0 0 15px 0;
    padding: 0 0 15px 0;
  }
`

export const Setting = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  select {
    color: black;
    border: 1px solid gray;
    padding: 10px;
    margin-bottom: 20px;
  }
`

export const Label = styled.span`
  font-weight: 500;
`
