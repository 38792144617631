import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { StoreContext } from '../../../App'
import { BlobProvider } from '@react-pdf/renderer'
import ReturnsPrint from '../../../methods/prints/returnsPrint'
import URLIcon from '../../../assets/icons/url.png'
import GeneratePDF from '../../../assets/icons/generate-pdf.svg'

interface ExportReturnDocumentsInterface {
  cell: any
}

const ExportReturnDocuments = observer(
  ({ cell }: ExportReturnDocumentsInterface) => {
    const store = useContext(StoreContext)
    const row = cell.row.getAllCells()
    const id = row.find((e) => e.column.id === 'id')?.getValue()
    const orderId = row.find((e) => e.column.id === 'order_id')?.getValue()
    const data = store.ReturnsStore.data.find((e) => e.id === id)
    const [products, setProducts] = useState<any>(null)
    const [dataFetched, setDataFetched] = useState(false) // Track if data is ready

    const handleGenerate = async () => {
      const returnProducts = await store.ReturnsStore.fetchPrintData(orderId)
      setProducts(returnProducts)
      setDataFetched(true)
    }

    const handlePrint = (blob: Blob) => {
      const fileURL = URL.createObjectURL(blob)
      const a: HTMLAnchorElement = document.createElement('a')

      a.href = fileURL
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
      URL.revokeObjectURL(fileURL)
    }

    return (
      <div>
        {!dataFetched ? (
          <button onClick={handleGenerate}>
            <img style={{ width: '24px' }} src={GeneratePDF} />
          </button> // Initial button for generating
        ) : (
          <BlobProvider
            document={<ReturnsPrint data={data} products={products} />}
          >
            {({ blob, url, loading, error }: any) => (
              <button onClick={() => blob && handlePrint(blob)}>
                <img style={{ width: '24px' }} src={URLIcon} />
              </button>
            )}
          </BlobProvider>
        )}
      </div>
    )
  },
)

export default ExportReturnDocuments
