import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from 'axios'
import { toast } from 'react-toastify'

export interface ViewOption {
  value: string
  label: string
  delete?: boolean
}

export class ViewStore {
  rootStore
  columnOrder: string[] = []
  hiddenColumns: any = {}
  views: any[] = []
  viewsList: ViewOption[] = []
  defaultView = ''
  selectedView = ''
  columnColors: { column: string; color: string }[] = []
  columnBorder: { column: string; border: boolean }[] = []
  loaded = true

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setColumnOrder = (columnOrder: string[]) => {
    this.columnOrder = columnOrder
  }

  @action.bound setSelectedView = (selectedView: string) => {
    this.selectedView = selectedView
  }

  @action.bound setHiddenColumns = (tableName: string, hiddenColumns?: any) => {
    console.log(toJS(hiddenColumns))
    if (Object.keys(hiddenColumns).length) {
      this.hiddenColumns = hiddenColumns
      localStorage.setItem(
        `hiddenColumns-${tableName}`,
        JSON.stringify(hiddenColumns),
      )
    }
  }

  @action.bound setColor = (column: string, color: string) => {
    const columnIndex = this.columnColors.findIndex(
      (col) => col.column === column,
    )
    if (columnIndex !== -1) {
      this.columnColors[columnIndex].color = color
    } else {
      this.columnColors.push({ column, color })
    }
  }

  @action.bound setBorder = (column: string, border: boolean) => {
    const columnIndex = this.columnBorder.findIndex(
      (col) => col.column === column,
    )
    if (columnIndex !== -1) {
      this.columnBorder[columnIndex].border = border
    } else {
      this.columnBorder.push({ column, border })
    }
  }

  @action.bound getDefaultAction = (tableName: string) => {
    if (
      Object.prototype.hasOwnProperty.call(
        this.rootStore.UserStore.defaultAction,
        tableName,
      )
    )
      //@ts-ignore
      return this.rootStore.UserStore.defaultAction[tableName]
  }

  @action.bound clearValues = () => {
    this.columnOrder = []
    this.hiddenColumns = {}
    this.columnColors = []
    this.columnBorder = []
  }

  @action.bound createViewObject = (
    name: string,
    tableName: string,
    privateView: boolean,
    pagination?: number,
  ) => {
    const viewObject = {
      name: name,
      user: toJS(this.rootStore.UserStore.user),
      tableName: tableName,
      tableSize: pagination || null,
      private: privateView,
      allowedUsers: [], //do dorobienia w zapisywaniu
      allowedGroups: [], //do dorobienia w zapisywaniu
      hiddenColumns: toJS(this.hiddenColumns),
      columnOrder: toJS(this.columnOrder),
      columnColors: toJS(this.columnColors),
      columnBorder: toJS(this.columnBorder),
      clipTextColumns: toJS(this.rootStore.TableStore.clipTextColumns),
      appliedPredefinedQuery: toJS(
        this.rootStore.TableStore.appliedPredefinedQuery,
      ),
      sort: toJS(this.rootStore.TableStore.sort),
      favoriteAction: toJS(this.getDefaultAction(tableName)) || '',
      showSearchField: toJS(this.rootStore.TableStore.showSearchField),
      infinitySwitch: toJS(this.rootStore.TableStore.infinitySwitch),
    }

    console.log('viewObject')
    console.log(toJS(viewObject))
    return viewObject
  }

  @action.bound getDefaultView = (tableName: string) => {
    this.defaultView = localStorage.getItem(`defaultView-${tableName}`) || ''
  }

  @action.bound setDefaultView = async (id, tableName: string) => {
    localStorage.setItem(`defaultView-${tableName}`, id)
    this.defaultView = id
    toast(`Default view set successfully ${id}`, { type: 'success' })
  }

  @action.bound saveView = async (
    name: string,
    tableName: string,
    privateView: boolean,
    pagination?: number,
  ) => {
    const url =
      'https://moodnook.westeurope.cloudapp.azure.com:443/api/views/v1/views/_create'

    const viewObject = this.createViewObject(
      name,
      tableName,
      privateView,
      pagination,
    )

    const config = {
      headers: {
        'x-apikey': `5732b217-1763-469a-a49b-7b8231389aa1`,
        username: this.rootStore.UserStore.user,
      },
    }

    console.log(toJS(viewObject))
    const response = await axios.post(url, viewObject, config)
    await toast('View saved successfully', { type: 'success' })
    localStorage.setItem(`viewObject-${name}`, JSON.stringify(viewObject))
  }

  @action.bound getViews = async (tableName: string) => {
    const url =
      'https://moodnook.westeurope.cloudapp.azure.com:443/api/views/v1/views'
    const config = {
      headers: {
        'x-apikey': `5732b217-1763-469a-a49b-7b8231389aa1`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.get(url, config)
    this.views = response.data.filter((e: any) => e.tableName === tableName)
    return this.views
  }

  @action.bound getViewsList = async (views: any[], withDelete: boolean) => {
    const viewsList = [
      { value: '', label: `` },
      ...views.map((e: any) => {
        if (withDelete) {
          return {
            value: e.id,
            label: `${e.name} ${e.private ? `(private)` : `(public)`}`,
            delete: e.private,
          }
        } else
          return {
            value: e.id,
            label: `${e.name} ${e.private ? `(private)` : `(public)`}`,
          }
      }),
    ]

    this.viewsList = viewsList
  }

  @action.bound deleteView = async (id: string) => {
    const url = `https://moodnook.westeurope.cloudapp.azure.com:443/api/views/v1/views/${id}`
    const config = {
      headers: {
        'x-apikey': `5732b217-1763-469a-a49b-7b8231389aa1`,
        'Access-Control-Allow-Methods':
          'GET,HEAD,PUT,PATCH,POST,DELETE,OPTIONS',
      },
    }
    const response = await axios.delete(url, config)
  }

  @action.bound editView = async (
    id: string,
    name: string,
    tableName: string,
    privateView: boolean,
    pagination?: number,
  ) => {
    const url = `https://moodnook.westeurope.cloudapp.azure.com:443/api/views/v1/views/${id}`
    const viewObject = this.createViewObject(
      name,
      tableName,
      privateView,
      pagination,
    )
    const config = {
      headers: {
        'x-apikey': `5732b217-1763-469a-a49b-7b8231389aa1`,
        'Access-Control-Allow-Methods':
          'GET,HEAD,PUT,PATCH,POST,DELETE,OPTIONS',
      },
    }
    const response = await axios.put(url, viewObject, config)
  }

  @action.bound applyView = async (selectedView: string) => {
    this.loaded = false
    const view = this.views.find((e) => e.id === selectedView)
    this.hiddenColumns = view.hiddenColumns
    this.columnOrder = view.columnOrder
    this.columnColors = view.columnColors
    this.columnBorder = view.columnBorder
    this.rootStore.TableStore.clipTextColumns = view.clipTextColumns
    /*this.rootStore.TableStore.appliedPredefinedQuery =
                              view.appliedPredefinedQuery*/
    this.rootStore.TableStore.sort = view.sort
    this.rootStore.TableStore.showSearchField = view.showSearchField
    this.rootStore.TableStore.infinitySwitch = view.infinitySwitch
    this.loaded = true
  }
}
