import Table from '../../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'

import { LanguageBasedCell } from '../tags/edits/languageBasedCell/LanguageBasedCell.component'
import { ArrayDisplayCont } from '../tags/Tags.styles'
import TableBool from '../../_tableGeneric/TableBool/TableBool.component'
import TableProducerColumnComponent from '../../_tableGeneric/tableProducerColumn/TableProducerColumn.component'
/* eslint-disable */

const StatusesTable = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    id: {
      canFilter: false,
      editable: false,
      editType: 'text',
    },
    name: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'en_GB',
    },
    active: {
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: false,
      editable: true,
      editType: 'selectBool',
      mandatory: true,
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    activation_treshold_after_packing: {
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'numberEqual',
    },
    equal: {
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      canSort: false,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    min_shipping_time: {
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'number',
      canBeNull: false,
    },
    max_shipping_time: {
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'number',
      canBeNull: false,
    },
    producers: {
      Cell: (cell: any) => (
        <ArrayDisplayCont>
          {cell.getValue() &&
            cell
              .getValue()
              .map((value: any, index: number) => (
                <div key={`${index}-value-name`}>{value}</div>
              ))}
        </ArrayDisplayCont>
      ),
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'stringArraySelect',
      arrayValues: store.StatusesStore.producers,
    },
    description: {
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'text',
    },
    availability_by_producer_id: {
      Cell: (cell: any) => (
        <TableProducerColumnComponent
          data={cell.getValue()}
          searchData={store.StatusesStore.avaliabilityByProducer}
        />
      ),
      mandatory: true,
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'select',
      selectValues: store.StatusesStore.avaliabilityByProducer.map(
        (e: any) => ({
          value: e.id.toString(),
          label: e.name.en_GB,
        }),
      ),
    },
    availability_date_from: {
      canFilter: true,
      editable: true,
      editType: 'date',
      dateFormat: 'DD.MM.YYYY',
      mandatory: true,
    },
    availability_date_to: {
      canFilter: true,
      editable: true,
      editType: 'date',
      dateFormat: 'DD.MM.YYYY',
      mandatory: true,
    },
    sales_channels: {
      Cell: (cell: any) => (
        <div>
          {cell.getValue() ? (
            cell.getValue().map((e: any, i: number) => (
              <div key={`sales-channels-${i}`}>
                <p>{`${e.channel_name ? e.channel_name : e.channel_id}: ${
                  e.status_id
                }`}</p>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      ),
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'salesChannels',
    },
    note: {
      canFilter: false,
      editable: true,
      editType: 'text',
    },
    icon: {
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'select',
      selectValues: store.TagsStore.avaliableIcons.map((icon) => ({
        value: icon,
        label: icon,
      })),
    },
  }

  useEffect(() => {
    store.StatusesStore.fetchColumnsData()
    store.StatusesStore.setCurrentPage('shippingtime')
    store.StatusesStore.fetchData('shippingtime')
    return () => store.StatusesStore.clearData()
  }, [])

  return (
    <>
      <Table
        config={{
          tableName: 'shippingtime',
          data: store.StatusesStore.data || [],
          fetchData: () => store.StatusesStore.fetchData('shippingtime'),
          columnHeaders: columns,
          displayColumnVisibility: true,
          allowColumnResize: true,
          displaySearch: false,
          displaySelect: true,
          displayExpand: false,
          displayTooltip: false,
          displayEntry: false,
          displayPaginator: false,
          displayOrdinalList: true,
          displayInfinitySwitch: false,
          allowSort: true,
          bank: false,
          actions: {
            display: true,
            label: locales.tableLabels.shippingtime,
            edit: `${process.env.REACT_APP_BASE_URL}experience/v1/shipping_time_status`,
            create: `${process.env.REACT_APP_BASE_URL}experience/v1/shipping_time_status/create`,
            remove: `${process.env.REACT_APP_BASE_URL}experience/v1/shipping_time_status/remove`,
            assignTag: null,
            custom: [],
          },
          actionsMultiple: {
            display: true,
            label: locales.tableLabelsMultiple.shippingtime,
            edit: `${process.env.REACT_APP_BASE_URL}experience/v1/shipping_time_status/multipleupdate`,
            create: null,
            remove: null,
            assignTag: false,
            custom: ['copyToClipboard', 'downloadFile'],
          },
          headline: {
            visible: true,
            title: locales.tableLabels.shippingtime,
            subTitle: 'Statuses Shipping Time',
          },
          breadcrumb: [],
          loaded: store.StatusesStore.loaded,
        }}
      ></Table>
    </>
  )
})
export default StatusesTable
