import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
/* eslint-disable */
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'
import ArrowExpanded from '../../assets/icons/arrow-expanded.svg'
import PrintIcon from '../../assets/icons/print-icon.svg'
import { NavButton, ShippingCountry } from './packingDetails.styles'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../styles/Loading.styles'

import { StoreContext } from '../../App'
import DetailsDropDown from '../../components/detailsDropDown/DetailsDropDown.component'
import DetailsTable from '../../components/detailsTable/DetailsTable.component'
import ProductListTable from '../../components/ListOfProductsTable/ListOfProductsTable.component'
import {
  PrintFooter,
  Body,
  Container,
  HeaderButton,
  HeaderCont,
  HeaderInner,
  HeaderList,
  HeaderTitle,
  SecondaryButton,
  StyledImg,
  HeaderInnerRight,
  ButtonCont,
  LeftBody,
} from '../proformsDetails/ProformsDetails.styles'
import FilterIcon from '../../assets/icons/filter-icon.svg'
import { SlidingPanel } from '../../components/slidingContainer/SlidingContainer.component'
import ActionButton from '../../components/actionButton/actionButton.component'
import { SinglePackagesTableComponent } from '../../components/_settings/packages/SinglePackagesTable.component'
import { BlobProvider } from '@react-pdf/renderer'
import MainPrint from '../../methods/prints/mainPrint'
import StepsButton from '../../components/stepsButton/StepsButton.component'
import Button from '../../components/button/Button.component'

const PackingDetails = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const params = useParams()

  const [priorityDropDown, setPriorityDropDown] = useState(false)
  const [priorityLoading, setPriorityLoading] = useState(false)

  const PriorityList = ['default', 'low', 'medium', 'high']

  const priorityView: any = {
    default: 'Default',
    low: 'High',
    medium: 'Very High',
    high: 'Extremly High',
  }
  const getViewInfo = (e: string) => {
    return priorityView[e]
  }
  const getPriorityColor = () => {
    switch (store.TableStore.detailsData.priority) {
      case 'low':
        return 'yellow'
      case 'medium':
        return 'orange'
      case 'high':
        return 'red'
      default:
        return ''
    }
  }
  const DetailsArray = [
    { header: locales.details.Description, value: 'Description' },
    { header: locales.details.status, value: 'status' },
    {
      header: 'Completable (stock)',
      value: 'completable',
    },
    {
      header: 'Completable After Packing other Invoices',
      value: 'completableMicroserviceInt',
    },
    { header: locales.details.shipping, value: 'shipping' },
    { header: locales.details.ordered, value: 'ordered' },
    { header: locales.details.estimateDate, value: 'estimateDate' },
    { header: locales.details.tags, value: 'tags' },
    { header: locales.details.tags, value: 'object_tags' },
    { header: 'Shopper Id', value: 'order' },
    { header: locales.details.notes_priv, value: 'notes_priv' },
  ]

  const AddressArray = [
    { header: locales.address.firstname, value: 'firstname' },
    { header: locales.address.lastname, value: 'lastname' },
    { header: locales.address.company, value: 'company' },
    { header: locales.address.city, value: 'city' },
    { header: locales.address.postcode, value: 'postcode' },
    { header: locales.address.street1, value: 'street1' },
    { header: locales.address.street2, value: 'street2' },
    { header: locales.address.country, value: 'country' },
  ]

  const SystemArray = [
    { header: 'order_url', value: 'order_url' },
    { header: 'domain', value: 'domain' },
    { header: 'type', value: 'type' },
    { header: 'url', value: 'url' },
  ]

  useEffect(() => {
    store.TableStore.fetchDetailsData(params.id)
    return () => {
      store.TableStore.clearDetailsData()
    }
  }, [])

  return (
    <>
      <Container
        loading={store.TableStore.detailsLoaded}
        {...Theme.proformsDetailsStyles.container}
      >
        {store.TableStore.detailsLoaded ? (
          <>
            <HeaderCont>
              <Breadcrumb
                treeArray={['Panel', 'Customer Orders', 'Order Details']}
              />
              <HeaderList>
                <HeaderInner>
                  <HeaderTitle {...Theme.proformsDetailsStyles.headerTitle}>
                    {locales.headers.customerOrder}:{' '}
                    {store.TableStore.detailsData.order_number}
                  </HeaderTitle>
                  <ShippingCountry className={'hide-print'}>
                    {`Shipping country: 
                  ${store.TableStore.detailsData.customer?.country}`}
                  </ShippingCountry>
                  <ButtonCont>
                    <BlobProvider
                      document={
                        <MainPrint printTable={true} packingTable={true} />
                      }
                    >
                      {({ blob, url, loading, error }: any) => {
                        const handlePrint2 = async () => {
                          const fileURL = URL.createObjectURL(blob)
                          const a: HTMLAnchorElement =
                            document.createElement('a')
                          a.href = fileURL
                          a.target = '_blank'
                          document.body.appendChild(a)
                          a.click()
                        }

                        return (
                          <HeaderButton
                            onClick={handlePrint2}
                            {...Theme.proformsDetailsStyles.headerButton}
                          >
                            <img src={PrintIcon} alt={'Print'} />
                            {locales.buttons.print}
                          </HeaderButton>
                        )
                      }}
                    </BlobProvider>
                    <BlobProvider
                      document={
                        <MainPrint printTable={false} packingTable={true} />
                      }
                    >
                      {({ blob, url, loading, error }: any) => {
                        const handlePrint2 = async () => {
                          const fileURL = URL.createObjectURL(blob)
                          const a: HTMLAnchorElement =
                            document.createElement('a')
                          a.href = fileURL
                          a.target = '_blank'
                          document.body.appendChild(a)
                          a.click()
                        }

                        return (
                          <HeaderButton
                            onClick={handlePrint2}
                            {...Theme.proformsDetailsStyles.headerButton}
                          >
                            <img src={PrintIcon} alt={'Print'} />
                            {locales.buttons.printWithoutData}
                          </HeaderButton>
                        )
                      }}
                    </BlobProvider>
                    <NavButton
                      href={`/customerorders/${params.id}`}
                      {...Theme.proformsDetailsStyles.headerButton}
                    >
                      {locales.buttons.goToCustomerView}
                    </NavButton>
                  </ButtonCont>
                </HeaderInner>
                <HeaderInnerRight>
                  <Button
                    type={'primary'}
                    onClick={() =>
                      store.SlidingPanelState.setSelectedSlider(`actionButton`)
                    }
                    margin={'4px 0 0 0'}
                  >
                    {'More actions'}
                  </Button>
                  <SecondaryButton
                    onClick={() => setPriorityDropDown(!priorityDropDown)}
                    bgColor={getPriorityColor()}
                    {...Theme.proformsDetailsStyles.headerButtonProps}
                    {...Theme.proformsDetailsStyles.secondaryButton}
                  >
                    {!priorityLoading ? (
                      <p>
                        {store.TableStore.detailsData.priority !== 'default'
                          ? getViewInfo(store.TableStore.detailsData.priority)
                          : locales.buttons.setPriority}
                      </p>
                    ) : (
                      <p>
                        <LoadingContainer style={{ transform: 'scale(0.5)' }}>
                          <Wrap></Wrap>
                        </LoadingContainer>
                      </p>
                    )}
                    <StyledImg src={ArrowExpanded} alt={'->'} />
                    {priorityDropDown ? (
                      <DetailsDropDown
                        active={true}
                        setLoading={setPriorityLoading}
                        table={PriorityList}
                      />
                    ) : (
                      <></>
                    )}
                  </SecondaryButton>
                  <StepsButton />
                </HeaderInnerRight>
              </HeaderList>
            </HeaderCont>
            <Body packing={true} className={'hide-print'}>
              <LeftBody>
                <DetailsTable
                  title={locales.headers.details}
                  columns={DetailsArray}
                  data={store.TableStore.detailsData}
                  showArrow={false}
                />
              </LeftBody>
              <LeftBody>
                <DetailsTable
                  title={locales.headers.deliveryAddress}
                  columns={AddressArray}
                  data={store.TableStore.detailsData.customer || {}}
                  showArrow={true}
                />
              </LeftBody>
            </Body>

            <ProductListTable
              view={'packing'}
              data={store.TableStore.detailsData}
            />
            <SinglePackagesTableComponent />
            <PrintFooter>
              Printed by {store.UserStore.user} on {new Date().toJSON()}
            </PrintFooter>
          </>
        ) : (
          <LoadingContainer>
            <Wrap></Wrap>
          </LoadingContainer>
        )}
      </Container>
      <SlidingPanel
        contentId={`actionButton`}
        title={`More actions for order ${store.RouteStore.currentDataId}`}
        icon={FilterIcon}
      >
        <ActionButton />
      </SlidingPanel>
    </>
  )
})
export default PackingDetails
