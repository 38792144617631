import { Container } from '../../styles/LayoutStyles'
import PackingTable from '../../components/packingTable/PackingTable.component'

const Packing = () => {
  return (
    <Container>
      <PackingTable />
    </Container>
  )
}
export default Packing
