import { observer } from 'mobx-react'

import BankTable from '../../components/bankTable/BankTable.component'
import { Container } from '../../styles/LayoutStyles'

const Bank = observer(() => {
  return (
    <Container>
      <BankTable />
    </Container>
  )
})
export default Bank
