import { PropsWithChildren } from 'react'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__progress-bar {
    background-color: #000000;
  }

  .Toastify__progress-bar-theme--light {
    background: #000000;
  }
`
const Toast = () => {
  return (
    <StyledToastContainer
      position='top-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='light'
    />
  )
}
export default Toast
