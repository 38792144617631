import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useEffect, useMemo } from 'react'
import { Row } from '@tanstack/react-table'

import { StoreContext } from '../../../App'
import { IndeterminateCheckbox } from '../../checkboxSelect/CheckboxSelect.component'
import TableEntry from '../tableEntry/TableEntry.component'
import TableTooltip from '../tableTooltip/TableTooltip.component'

import {
  AddonsColumnWrapper,
  ArrowExpandedImg,
  ArrowExpandImg,
  ArrowsContainer,
} from './TableCellFirstColumn.styles'
import ArrowExpand from '../../../assets/icons/arrow-expand.svg'
import ArrowExpanded from '../../../assets/icons/arrow-expanded.svg'

interface Props extends PropsWithChildren {
  row: any
  rowData: any
  displayTooltip: boolean
  displayEntry: boolean
  displayExpand: boolean
}

const TableHeaderCheckboxes: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const id =
    props.row
      .getAllCells()
      .find((e: any) => e.column.id === 'id')
      .getValue() ||
    props.row
      .getAllCells()
      .find((e: any) => e.column.id === 'code')
      .getValue()

  return (
    <AddonsColumnWrapper {...Theme.tableStyles.addonsColumnWrapper}>
      <IndeterminateCheckbox
        row={props.row}
        rowId={props.rowData.id || props.rowData.code}
        selected={store.TableStore.selectedRowId.includes(id)}
      />
      {props.displayTooltip ? <TableTooltip row={props.rowData} /> : <></>}
      {props.displayEntry ? (
        <TableEntry row={props.rowData} signal={'red'} />
      ) : (
        <></>
      )}
      {props.displayExpand ? (
        <ArrowsContainer
          onClick={() => props.row.toggleExpanded()}
          {...Theme.tableStyles.arrowsContainer}
        >
          {props.row.getIsExpanded() ? (
            <ArrowExpandedImg
              src={ArrowExpanded}
              {...Theme.tableStyles.arrowExpandedImg}
            />
          ) : (
            <ArrowExpandImg src={ArrowExpand} />
          )}
        </ArrowsContainer>
      ) : (
        <></>
      )}
    </AddonsColumnWrapper>
  )
})
export default TableHeaderCheckboxes
