import { observer } from 'mobx-react'
import { useContext, useState } from 'react'
import { StoreContext } from '../../../App'
import { ContButton } from './ExportBankTransaction.styles'
import axios from '../../../axiosconfig'

const ExportBankTransaction = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [buttonClick, setButtonClick] = useState(false)
  const [loading, setLoading] = useState(false)

  const getObjectDownload = async () => {
    setLoading(true)
    const urlLink = await store.TableStore.exportBankTransaction()
    const hrefLink = `${urlLink}?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwlacupitfx&se=2030-01-05T19:45:37Z&st=2023-01-05T11:45:37Z&spr=https&sig=de5llLawOuvgxJoZ6XC2bSNQitD5spBwV2kFijl%2F9j4%3D`
    if (hrefLink) {
      const res = await axios.get(hrefLink)
      const data = [res.data]
      const date = new Date()
      const blob = new Blob(data, { type: 'text/plain' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `transaction-${
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        '-' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds()
      }.csv`
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      }, 0)
      //toggleAllRowsSelected(false)
      store.TableStore.setAllSelectedRows(false)
      store.TableStore.setSelectedRows([])
    }
    await setLoading(false)
  }

  return (
    <ContButton
      onClick={() => getObjectDownload()}
      {...Theme.copyToClipBoardStyles.contButton}
    >
      {loading ? 'Loading...' : 'Export Bank Transaction'}
    </ContButton>
  )
})
export default ExportBankTransaction
