import styled from 'styled-components'
import { TimelineDot } from '@mui/lab'
import color from 'color'

export const Container = styled.div`
  display: flex;

  > {
    margin: 0 16px;
  }
`
export const TitleText = styled.h2`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 16px;
`

interface TimelineDotInterface {
  bgColor: string
}

export const TimelineDotStyled = styled(TimelineDot)<TimelineDotInterface>`
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    color(props.bgColor).alpha(0.6).rgb().string()} !important;
`
export const IconCont = styled.img`
  width: 36px;
  height: 36px;
`
