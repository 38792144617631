import { Container } from './TableBool.styles'
import { observer } from 'mobx-react'

interface TableBoolInterface {
  value: boolean
}

const TableBool = observer(({ value }: TableBoolInterface) => {
  const getColor = () => {
    switch (value) {
      case true:
        return 'rgba(0,255,0,0.4)'
      case false:
        return 'rgba(255,0,0,0.4)'
      default:
        return 'transparent'
    }
  }
  return (
    <Container color={getColor()}>
      <input type='checkbox' readOnly checked={!!value} />
    </Container>
  )
})
export default TableBool
