import { observer } from 'mobx-react'
import { Container, HeaderText } from './FeatureDisable.styles'
import FallingStar from '../../assets/icons/falling-star.svg'

export const FeatureDisable = observer(() => {
  return (
    <Container>
      <img src={FallingStar} />
      <HeaderText>This feature is disabled in your current plan.</HeaderText>
    </Container>
  )
})
