import { observer } from 'mobx-react'

import { Container } from '../../styles/LayoutStyles'
import ReturnsTableComponent from '../../components/_settings/returns/ReturnsTable.component'

const Returns = observer(() => {
  return (
    <Container>
      <ReturnsTableComponent />
    </Container>
  )
})
export default Returns
