import { observer } from 'mobx-react'
import {
  Label,
  Setting,
  SettingsCategory,
} from '../../../pages/settings/settings.styles'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../App'
import axios from '../../../axiosconfig'
import { toast } from 'react-toastify'
import Button from '../../button/Button.component'
/* eslint-disable */
const NumerationComponent = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState<any>({
    shop_currencies: [],
    currencies: [],
    default_currency: '',
    language: '',
    timezone: {
      tz: 'Europe/Warsaw',
    },
    customer_orders_count: 0,
    purchase_orders_count: 0,
  })

  const handleChange = (selectedOptions: any, key: string) => {
    setData({
      ...data,
      [key]: selectedOptions,
    })
  }

  const handleSave = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    await axios.post(url, data, config)
    await store.SlidingPanelState.setVisibility(false)
    await toast(locales.successes.success, {
      type: 'success',
      autoClose: 5000,
    })
    await store.StoreSettingsStore.fetchAllData()
  }

  useEffect(() => {
    store.StoreSettingsStore.fetchAllData()
    store.StoreSettingsStore.fetchData('timezones')
  }, [])

  useEffect(() => {
    const tempData: any = store.StoreSettingsStore.allData
    setData(tempData)
    setLoaded(true)
  }, [store.StoreSettingsStore.allData])

  if (loaded)
    return (
      <SettingsCategory>
        <h1>Store Settings Options</h1>
        <h2></h2>

        <Setting>
          <Label>Customer Orders Count:</Label>
          <input
            value={data.customer_orders_count}
            type={'number'}
            step={1}
            //@ts-ignore
            min={store.StoreSettingsStore.allData.customer_orders_count}
            onChange={(e: any) =>
              handleChange(parseInt(e.target.value), 'customer_orders_count')
            }
          />
        </Setting>

        <Setting>
          <Label>Supplier Orders Count:</Label>
          <input
            value={data.purchase_orders_count}
            type={'number'}
            step={1}
            //@ts-ignore
            min={store.StoreSettingsStore.allData.purchase_orders_count}
            onChange={(e: any) =>
              handleChange(parseInt(e.target.value), 'purchase_orders_count')
            }
          />
        </Setting>

        <Button type={'primary'} onClick={() => handleSave()} width={'100px'}>
          {locales.buttons.save}
        </Button>
      </SettingsCategory>
    )
  else return <></>
})
export default NumerationComponent
