import { observer } from 'mobx-react'
import { Container, Input, Textarea } from './TableQuickEdit.styles'
import { useContext, useState, KeyboardEvent } from 'react'
import axios from '../../../axiosconfig'
import { toast } from 'react-toastify'
import { socket } from '../../../socket/socket'
import { StoreContext } from '../../../App'

interface TableQuickEditComponentInterface {
  defaultValue: string
  productId: string
  orderId: string
  columnName: string
  type?: string
}

export const TableQuickEditComponent = observer(
  ({
    defaultValue,
    productId,
    orderId,
    columnName,
    type,
  }: TableQuickEditComponentInterface) => {
    const store = useContext(StoreContext)
    const locales = store.TranslationsState.translations
    const [value, setValue] = useState(defaultValue)

    const handleKeyDown = async (
      event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      if (event.key === 'Enter') {
        const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${orderId}`
        let formattedValue = value
        if (type === 'number' && value === '') {
          formattedValue = '0'
        }
        const data = {
          id: orderId,
          products: {
            [productId]: {
              [columnName]: formattedValue,
            },
          },
        }
        const config = {
          headers: {
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          },
        }
        const response = await axios.post(url, data, config)
        if (response.status === 200) {
          toast(locales.successes.saved, {
            type: 'success',
          })
          socket.emit('editOrder', {
            id: orderId,
            user: store.UserStore.user,
            page: store.TableStore.getApiRequestFromUrl(),
          })
        }
      }
    }

    const sendData = async (value: string) => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${orderId}`
      const data = {
        id: orderId,
        products: {
          [productId]: {
            [columnName]: value,
          },
        },
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      const response = await axios.post(url, data, config)
      if (response.status === 200) {
        toast(locales.successes.saved, {
          type: 'success',
        })
        socket.emit('editOrder', {
          id: orderId,
          user: store.UserStore.user,
          page: store.TableStore.getApiRequestFromUrl(),
        })
      }
    }

    return (
      <Container>
        {type === 'textarea' ? (
          <Textarea
            value={value}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setValue(e.target.value)
            }}
          />
        ) : (
          <Input
            value={value}
            type={type ? type : 'text'}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setValue(e.target.value)
              if (type === 'date') {
                sendData(e.target.value)
              }
            }}
          />
        )}
      </Container>
    )
  },
)
