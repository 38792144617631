/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { hiddenColumns, ValidateArray } from '../../../../columnsConfig'

import { Container, Footer, InnerCont } from './CreateTree.styles'

import { StoreContext } from '../../../../App'
import useWindowDimensions from '../../../../methods/hooks/useWindowDimensions'
import { DataInputGeneric } from '../../../dataInputGeneric/DataInputGeneric.component'
import Button from '../../../button/Button.component'

export interface EditProductsProps extends PropsWithChildren {
  rootId: string
  columns: { [key: string]: any }
  columnHeaders: Array<{ header: string; value: string | object }>
  baseUrl: string
  hideParent?: boolean
  view?: 'group' | 'tag' | 'root'
  left?: boolean
  parentId?: string
  id?: string
  addNewLeftFunction?: (
    formData: object,
    rootId: string,
    parentId: string,
    id: string,
  ) => void
  addNewRightFunction: (formData: object, id: string, rootId: string) => void
}

const CreateTree: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps,
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const { width, height } = useWindowDimensions()
  const [loading, setLoading] = useState(false)
  const divRef = useRef<any>(null)

  console.log('CreateTreeProps:', props)
  console.log(props.columnHeaders)

  const initialState = [
    ...props.columnHeaders.map((e) => {
      return {
        header: e.header,
        value: e.value,
        error: '',
        rules: ValidateArray[e.header] ? ValidateArray[e.header] : [],
        isActive: false,
      }
    }),
  ]
  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)
  const [action, setAction] = useState<{
    type: 'add' | 'delete' | 'none'
    language?: string
  }>({ type: 'none' })

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => {
    setForm([...tempForm])
  }

  const handleSave = async () => {
    if (props.left) {
      await setLoading(true)
      let formData: any = {}
      form.forEach((e) => (formData = { ...formData, [e.header]: e.value }))
      if (formData.hasOwnProperty('priority') && formData.priority.length) {
        formData = { ...formData, priority: parseInt(formData.priority) }
      }
      if (props.addNewLeftFunction)
        props.addNewLeftFunction(
          formData,
          props.rootId,
          props.parentId || '',
          props.id || '',
        )
      await setLoading(false)
    } else {
      await setLoading(true)
      let formData: any = {}
      form.forEach((e) => (formData = { ...formData, [e.header]: e.value }))
      if (formData.hasOwnProperty('priority') && formData.priority.length) {
        formData = { ...formData, priority: parseInt(formData.priority) }
      }
      props.addNewRightFunction(formData, props.id || '', props.rootId)
      await setLoading(false)
    }
  }

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 *
          props.columnHeaders.filter((e) => !hiddenColumns.includes(e.header))
            .length) /
          (height * 0.8),
      ),
    )
  }, [height])

  useEffect(() => {
    if (action.type !== 'none') {
      setAction({ type: 'none' })
    }
  }, [action])

  return (
    <Container cols={inputColumns} {...Theme.editProductsStyles.container}>
      <InnerCont ref={divRef} cols={inputColumns}>
        {props.columnHeaders
          ? props.columnHeaders.map((e, index) => {
              const columnObject = props.columns[e.header]
              const customOptions = {
                ...(columnObject.hasOwnProperty('editable') && {
                  editable: columnObject.editable,
                }),
                ...(columnObject.editType && {
                  editType: columnObject.editType,
                }),
                ...(columnObject.mandatory && {
                  mandatory: columnObject.mandatory,
                }),
                ...(columnObject.canBeNull && {
                  canBeNull: columnObject.canBeNull,
                }),
                ...(columnObject.selectValues && {
                  selectValues: columnObject.selectValues,
                }),
                ...(columnObject.dateFormat && {
                  dateFormat: columnObject.dateFormat,
                }),
                ...(columnObject.arrayValues && {
                  arrayValues: columnObject.arrayValues,
                }),
                ...(columnObject.mandatoryLanguage && {
                  mandatoryLanguage: columnObject.mandatoryLanguage,
                }),
              }
              if (customOptions.editable)
                return (
                  <DataInputGeneric
                    type={'create'}
                    vals={e}
                    editOptions={customOptions}
                    key={`data-input--${index}`}
                    index={index}
                    form={form}
                    setForm={handleSetForm}
                  />
                )
            })
          : null}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            if (!loading) handleSave()
          }}
          active={!loading}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}
export default CreateTree
