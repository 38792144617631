import styled from 'styled-components'
import Select from 'react-select'

export const Container = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: visible;
`
