import styled from 'styled-components'
import { darken } from 'polished'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`
export const Header = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const HeaderLeft = styled.div`
  display: flex;
`
export const HeaderCenter = styled.div`
  display: flex;
`
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-left: 8px;
  }

  > :first-child {
    border-radius: 6px;
  }
`

export const TitleText = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin: 8px;
`

interface ViewButtonInterface {
  selected: boolean
  view?: 'left' | 'right'
}

/* eslint-disable */
export const ViewButton = styled.button<ViewButtonInterface>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #303030;
  padding: 8px 20px;
  border-radius: ${(props) =>
    props.view === 'left'
      ? '6px 0 0 6px'
      : props.view === 'right'
        ? '0 6px 6px 0'
        : '0'};
  border: 1px solid ${(props) => (!props.selected ? '#575757' : '#D6D6D6')};
  background-color: ${(props) => (!props.selected ? '#F5F5F5' : '#FFFFFF')};
`
export const CalendarFilterCont = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin: 4px;
  }
`
export const SearchFieldCont = styled.div`
  margin-left: 16px;
`
