import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext } from 'react'
import { StoreContext } from '../../../App'
import { Container, Text } from './TableDifferentValues.styles'
import { TableQuickEditComponent } from '../TableQuickEdit/TableQuickEdit.component'

interface Props extends PropsWithChildren {
  value?: string
  fromValue?: string
  form?: boolean
  productId?: string
  orderId?: string
  columnName?: string
  type?: string
}

const TableDifferentValues: FC<Props> = observer(
  ({ value, fromValue, form, orderId, productId, columnName, type }: Props) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme

    return (
      <Container active={value !== fromValue}>
        <Text {...Theme.tableLabelStyles.text}>
          {form && productId && orderId && columnName ? (
            <TableQuickEditComponent
              orderId={orderId}
              productId={productId}
              columnName={columnName}
              defaultValue={value ? value : ''}
              type={type}
            />
          ) : (
            <>{value}</>
          )}
        </Text>
      </Container>
    )
  },
)
export default TableDifferentValues
