import styled, { keyframes } from 'styled-components'

interface ContainerProps {
  bgColor?: string
  boxShadow?: string
}

export const Container = styled.div<ContainerProps>`
  display: block;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.3)'};
`
export const OptionsContainer = styled.div`
  margin-left: 95%;
  padding-top: 4px;
`
export const Table = styled.div``

interface RenderedHeaderInterface {
  zIndex?: boolean
}

export const Header = styled.div<RenderedHeaderInterface>`
  position: sticky !important;
  top: 146px;
  background-color: #f5f5f5;
  ${(props) => props.zIndex && 'z-index: 1;'}
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
`
export const HeaderGroup = styled.div`
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  text-overflow: ellipsis;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;

  ::-webkit-scrollbar {
    display: none;
  }
`
export const HeaderText = styled.p`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: left;
  margin-top: 8px;
`

interface columnInterface {
  width: number
}

export const HeaderColumn = styled.div<columnInterface>`
  font-weight: 600;
  width: ${({ width }) => `${width}px`} !important;

  text-transform: capitalize;
  line-break: anywhere;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  font-feature-settings: 'pnum' on, 'lnum' on;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  overflow: visible;
  flex-grow: 1;
  flex-shrink: 0;

  div {
    padding-top: 4px;
  }

  * {
    text-overflow: ellipsis;
  }
`
export const HeaderSearch = styled.div`
  width: 100%;
  position: relative;
`
export const Body = styled.div``

interface RowInterface {
  odd?: boolean
  select: boolean
  oddBgColor?: string
  evenBgColor?: string
  lastChild: boolean
  trackBgColor?: string
  trackBgRadius?: string
  thumbBgColor?: string
  thumbBgRadius?: string
  thumbBorder?: string
}

/* eslint-disable */
export const RowStyle = styled.div<RowInterface>`
  width: 100%;
  display: flex;
  overflow-x: auto;
  background-color: ${(props) =>
    props.select
      ? 'rgba(221,175,77,0.3)'
      : props.odd
      ? props.oddBgColor || '#f5f5f5'
      : props.evenBgColor || '#e5e5e5'};
  scrollbar-width: ${(props) => (props.lastChild ? 'auto' : 'none')};

  ::-webkit-scrollbar {
    display: ${(props) => (props.lastChild ? 'block' : 'none')};
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.trackBgColor ? props.trackBgColor : '#aaaaaa'};
    border-radius: ${(props) =>
      props.trackBgRadius ? props.trackBgRadius : '3px'};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.thumbBgColor ? props.thumbBgColor : '#303030'};
    border-radius: ${(props) =>
      props.thumbBgRadius ? props.thumbBgRadius : '6px'};
    border: ${(props) =>
      props.thumbBorder ? props.thumbBorder : '3px solid #aaaaaa'};
  }
`

/* eslint-enable */
interface CellStylesProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  padding?: string
  wrapText: boolean
  width: number
}

export const CellStyles = styled.div<CellStylesProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-break: anywhere;
  padding: ${(props) => (props.padding ? props.padding : '4px')};
  width: ${({ width }) => `${width}px`} !important;
  display: ${(props) => props.wrapText && 'flex'};
  justify-content: ${(props) => (props.wrapText ? 'center' : 'flex-start')};
  align-items: ${(props) => props.wrapText && 'flex-start'};
  text-align: ${(props) => (props.wrapText ? 'center' : 'left')};
  white-space: ${(props) => !props.wrapText && 'nowrap'};
  overflow-wrap: ${(props) => (props.wrapText ? 'break-word' : 'normal')};
  overflow: ${(props) => !props.wrapText && 'hidden'};
  word-break: ${(props) => (props.wrapText ? 'break-word' : 'keep-all')};
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 0;
`

interface SpinnerContainerProps {
  bgColor?: string
}

export const SpinnerContainer = styled.div<SpinnerContainerProps>`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#e5e5e5')};
`
const spinAnim = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
`

interface SpinnerProps {
  borderColor?: string
}

/* eslint-disable */
export const Spinner = styled.div<SpinnerProps>`
  width: 64px;
  height: 64px;
  border: 8px solid
    ${(props) =>
      props.borderColor
        ? props.borderColor
        : '#303030 transparent #303030 transparent'};
  border-radius: 50%;
  animation: ${spinAnim} 1.2s linear infinite;
`

export const FooterCont = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`
export const FooterValue = styled.div`
  margin: 4px;
  font-size: 14px;
  font-weight: 700;
`
