import Table from '../_tableGeneric/table/Table.component'
import { observer } from 'mobx-react'
import { useContext, useEffect } from 'react'
import { StoreContext } from '../../App'
import TableObjectDisplayComponent from '../_tableGeneric/tableObjectDisplay/TableObjectDisplay.component'
import { toJS } from 'mobx'

const ContactTableIdentities = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  console.log(toJS(store.ContactsStore.identities))

  const columns = {
    id: {
      canSort: false,
      Header: 'ID',
      Cell: (cell: any) => <a href={'/contacts/identities/1'}>details</a>,
    },
    personal_information: {
      Header: 'Personal Information',
      columns: {
        identity_id: {
          canSort: false,
          Header: 'Identity ID',
        },
        first_name: {
          canSort: false,
          Header: 'First Name',
        },
        last_name: {
          canSort: false,
          Header: 'Last Name',
        },
        date_of_birth: {
          canSort: false,
          Header: 'Date of Birth',
        },
      },
    },
    contact_information: {
      Header: 'Contact Information',
      columns: {
        email: {
          canSort: false,
          Header: 'Email',
        },
        phone: {
          canSort: false,
          Header: 'Phone',
        },
        secondary_phone: {
          canSort: false,
          Header: 'Secondary Phone',
        },
        preferred_contact_method: {
          canSort: false,
          Header: 'Preferred Contact Method',
        },
      },
    },
    job_information: {
      Header: 'Job Information',
      columns: {
        job_title: {
          canSort: false,
          Header: 'Job Title',
        },
        department: {
          canSort: false,
          Header: 'Department',
        },
        employment_start_date: {
          canSort: false,
          Header: 'Employment Start Date',
        },
        employment_end_date: {
          canSort: false,
          Header: 'Employment End Date',
        },
      },
    },
    address_references: {
      Header: 'Address References',
      columns: {
        primary_address: {
          canSort: false,
          Header: 'Primary Address',
          Cell: (cell: any) => (
            <TableObjectDisplayComponent data={cell.getValue()} />
          ),
        },
        secondary_address: {
          canSort: false,
          Header: 'Secondary Address',
          Cell: (cell: any) => (
            <TableObjectDisplayComponent data={cell.getValue()} />
          ),
        },
      },
    },
    identification: {
      Header: 'Identification',
      columns: {
        national_id: {
          canSort: false,
          Header: 'National ID',
        },
        passport_number: {
          canSort: false,
          Header: 'Passport Number',
        },
        social_security_number: {
          canSort: false,
          Header: 'Social Security Number',
        },
      },
    },
    external_references: {
      Header: 'External References',
      Cell: (cell: any) => (
        <TableObjectDisplayComponent data={cell.getValue()} />
      ),
      canSort: false,
    },
  }

  useEffect(() => {
    store.ContactsStore.fetchIdentities()
    return () => {
      store.ContactsStore.clearIdentities()
    }
  }, [])

  return (
    <Table
      config={{
        tableName: 'contactsIdentities',
        data: store.ContactsStore.identities || [],
        fetchData: () => store.ContactsStore.fetchIdentities(),
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayOrdinalList: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: null,
        actionsMultiple: null,
        headline: {
          visible: true,
          title: 'Identities',
          subTitle: 'List of identities',
        },
        breadcrumb: [],
        loaded: store.ContactsStore.loaded.identities,
      }}
    />
  )
})

export default ContactTableIdentities
