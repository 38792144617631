import { observer } from 'mobx-react'
import { useContext, useRef } from 'react'
import Profile from '../../assets/icons/profile.png'

import { StoreContext } from '../../App'
import {
  Container,
  Image,
  Option,
  OptionCont,
  SubTitleText,
  TitleText,
} from './HeaderProfile.styles'
import useClickOutside from '../../methods/methods/useClickOutside'
import { ContextHolder } from '@frontegg/react'
import Button from '../button/Button.component'

interface HeaderProfileInterface {
  close: () => void
}

const HeaderProfile = observer((props: HeaderProfileInterface) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const headerRef = useRef(null)
  useClickOutside(headerRef, props.close)

  const signOut = () => {
    const baseUrl = ContextHolder.getContext().baseUrl
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`
  }

  return (
    <Container ref={headerRef}>
      <Image
        src={store.UserStore.image !== '' ? store.UserStore.image : Profile}
      />
      <TitleText>{store.UserStore.user}</TitleText>
      <SubTitleText>{store.UserStore.email}</SubTitleText>
      <Button
        type={'primary'}
        onClick={props.close}
        isLink={true}
        to={'/admin/user/myaccount'}
        width={'170px'}
      >
        {'My Account'}
      </Button>
      <Button
        type={'primary'}
        onClick={props.close}
        isLink={true}
        to={'/admin/user/myuserprofile'}
        width={'170px'}
        margin={'4px 0 0 0'}
      >
        {'My User Profile'}
      </Button>
      <Button
        type={'secondary'}
        onClick={signOut}
        width={'170px'}
        margin={'16px 0 0 0'}
      >
        {'sign out'}
      </Button>

      <OptionCont>
        <Option
          href={'https://wiki.apihulk.com/hc/pl/requests/new'}
          target='_blank'
          rel='noopener noreferrer'
        >
          {'Get Help'}
        </Option>
        <Option
          href={'https://wiki.apihulk.com/'}
          target='_blank'
          rel='noopener noreferrer'
        >
          {'Wiki'}
        </Option>
      </OptionCont>
    </Container>
  )
})

export default HeaderProfile
