/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { hiddenColumns, ValidateArray } from '../../../../columnsConfig'

import { Container, Footer, InnerCont } from './EditTree.styles'

import { StoreContext } from '../../../../App'
import useWindowDimensions from '../../../../methods/hooks/useWindowDimensions'
import { DataInputGeneric } from '../../../dataInputGeneric/DataInputGeneric.component'
import Button from '../../../button/Button.component'
import axios from 'axios'

export interface EditProductsProps extends PropsWithChildren {
  columns: { [key: string]: any }
  columnHeaders: Array<{ header: string; value: string | object }>
  hideParent?: boolean
  view?: 'group' | 'tag' | 'root'
  baseUrl: string
  mainDesc: string
  setData: () => void
}

const EditTree: (props: EditProductsProps) => JSX.Element = ({
  columns,
  columnHeaders,
  hideParent,
  view,
  baseUrl,
  mainDesc,
  setData,
}: EditProductsProps) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const { width, height } = useWindowDimensions()
  const [loading, setLoading] = useState(false)
  const divRef = useRef<any>(null)

  const initialState = [
    ...columns.map((e) => {
      return {
        header: e.header,
        value: e.value,
        error: '',
        rules: ValidateArray[e.header] ? ValidateArray[e.header] : [],
        isActive: false,
      }
    }),
  ]
  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => {
    setForm([...tempForm])
  }

  const handleSave = async () => {
    setLoading(true)
    let formData: any = {}
    form.forEach((e, index) => {
      if (
        JSON.stringify(e.value) !== JSON.stringify(initialState[index].value)
      ) {
        formData = { ...formData, [e.header]: e.value }
      }
    })
    //@ts-ignore
    const id: string = columns.find((e) => e.header === 'id')?.value || ''
    if (mainDesc === 'Tag') {
      await store.TagsStore.editTag(formData, id, view)
    } else {
      const url = `${baseUrl}${id}`
      const data = store.TableStore.formatRequestData(formData)
      const config = {
        headers: {
          'x-apikey': process.env.REACT_APP_API_KEY!,
          username: store.UserStore.user,
        },
      }
      await axios.post(url, data, config)
      store.SlidingPanelState.setVisibility(false)
      setData()
    }
    setLoading(false)
  }

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 * columns.filter((e) => !hiddenColumns.includes(e.header)).length) /
          (height * 0.8),
      ),
    )
  }, [height])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont ref={divRef} cols={inputColumns}>
        {columnHeaders.map((e, index) => {
          const columnObject = columns.find((col) => col.header === e.header)
          const customOptions = {
            ...(columnObject?.editable && { editable: columnObject.editable }),
            ...(columnObject?.editType && { editType: columnObject.editType }),
            ...(columnObject?.mandatory && {
              mandatory: columnObject.mandatory,
            }),
            ...(columnObject?.canBeNull && {
              canBeNull: columnObject.canBeNull,
            }),
            ...(columnObject?.selectValues && {
              selectValues: columnObject.selectValues,
            }),
            ...(columnObject?.dateFormat && {
              dateFormat: columnObject.dateFormat,
            }),
            ...(columnObject?.arrayValues && {
              arrayValues: columnObject.arrayValues,
            }),
            ...(columnObject?.mandatoryLanguage && {
              mandatoryLanguage: columnObject.mandatoryLanguage,
            }),
          }

          return (
            <DataInputGeneric
              type='edit'
              vals={e}
              editOptions={customOptions}
              key={`data-input--${index}`}
              index={index}
              form={form}
              setForm={handleSetForm}
            />
          )
        })}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type='secondary'
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (!loading) handleSave()
          }}
          active={!loading}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}

export default EditTree
