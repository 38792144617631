import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { StoreContext } from '../../App'
import { PrimaryButton, SecondaryButton, TertiaryButton } from './Button.styles'

interface ButtonProps {
  type: 'primary' | 'secondary' | 'tertiary'
  children: React.ReactNode
  onClick?: (e?: any) => void | Promise<void>
  loading?: boolean
  isLink?: boolean
  to?: string
  margin?: string
  width?: string
  size?: 'small' | 'medium' | 'large'
  active?: boolean
}

const Button = observer(
  ({
    type,
    children,
    onClick,
    loading = false,
    isLink = false,
    to = '#',
    margin,
    width,
    size = 'medium',
    active = true,
  }: ButtonProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const [isLoading, setIsLoading] = useState(loading)

    const handleClick = async () => {
      if (!onClick) return
      setIsLoading(true)
      try {
        const result = onClick()
        if (result instanceof Promise) {
          await result
        }
      } finally {
        setIsLoading(false)
      }
    }

    const buttonProps = {
      onClick: handleClick,
      disabled: isLoading,
      isLoading,
      margin,
      width,
      size,
      active,
    }

    const renderButton = () => {
      switch (type) {
        case 'primary':
          return (
            <PrimaryButton {...Theme.buttons.primaryButton} {...buttonProps}>
              {children}
            </PrimaryButton>
          )
        case 'secondary':
          return (
            <SecondaryButton
              {...Theme.buttons.secondaryButton}
              {...buttonProps}
            >
              {children}
            </SecondaryButton>
          )
        case 'tertiary':
          return <TertiaryButton {...buttonProps}>{children}</TertiaryButton>
        default:
          return (
            <PrimaryButton {...Theme.buttons.primaryButton} {...buttonProps}>
              {children}
            </PrimaryButton>
          )
      }
    }

    return isLink ? (
      <Link to={to} style={{ textDecoration: 'none' }}>
        {renderButton()}
      </Link>
    ) : (
      renderButton()
    )
  },
)

export default Button
