import { useDrag, useDrop } from 'react-dnd'
import { SingleTag } from '../../../_tableGeneric/tableNewTags/TableNewTags.styles'
import React, { useContext } from 'react'
import { ArcherElement } from 'react-archer'
import {
  TagContainer,
  Column,
  Row,
  AddButton,
  ButtonCont,
  EditButton,
} from './SingleTreeGeneric.styles'
import { StoreContext } from '../../../../App'
import { observer } from 'mobx-react'
import FilterIcon from '../../../../assets/icons/filter-icon.svg'
import { SlidingPanel } from '../../../slidingContainer/SlidingContainer.component'
import PlusWhite from '../../../../assets/icons/plus-white.svg'
import EditIcon from '../../../../assets/icons/edit.svg'
import CrossWhite from '../../../../assets/icons/cross-white.svg'
import EditTree from '../editTree/EditTree.component'
import { Tooltip } from '@mui/material'
import ChooseTagTree from '../chooseAddTree/ChooseTreeGeneric.component'
import { toast } from 'react-toastify'
import RemoveTagComponent from '../../tags/edits/removeTag/RemoveTag.component'
import Star from '../../../../assets/icons/tagIcons/star.svg'
import Circle from '../../../../assets/icons/tagIcons/circle.svg'
import ExpMark from '../../../../assets/icons/tagIcons/exp_mark.svg'
import Minus from '../../../../assets/icons/tagIcons/minus.svg'
import Plus from '../../../../assets/icons/tagIcons/plus.svg'
import QuestionMark from '../../../../assets/icons/tagIcons/question_mark.svg'
import Rectangle from '../../../../assets/icons/tagIcons/rectangle.svg'
import Smiley from '../../../../assets/icons/tagIcons/smiley.svg'
import Car from '../../../../assets/icons/tagIcons/car.svg'
import Sun from '../../../../assets/icons/tagIcons/sun.svg'
import Cross from '../../../../assets/icons/tagIcons/cross.svg'
import { getEmptyValue } from '../../../createGeneric/CreateGeneric.component'
import AddTreeComponent from '../addTree/AddTree.component'
import ChooseTreeGenericComponent from '../chooseAddTree/ChooseTreeGeneric.component'

/* eslint-disable */
interface SingleTreeTagProps {
  rootId: string
  columns: { [key: string]: any }
  objectData: any
  firstLevel: boolean
  index?: number
  parentId?: string
  data?: any
  firstLevelChild: string
  secondLevelChild: string
  firstLevelParent: string
  secondLevelParent: string
  lang: string
  mainDesc: string
  baseUrl: string
  setData: () => void
  searchComponent: any
  dropFunction: (
    currentTagData: any,
    draggedTagData: any,
    firstLevel: boolean,
  ) => void
  dropPlusFunction: (
    currentTagData: any,
    draggedTagData: any,
    firstLevel: boolean,
  ) => void
  addExistingLeftFunction: (
    currentId: string,
    newId: string,
    parentId: string,
  ) => void
  addExistingRightFunction: (
    currentId: string,
    newId: string,
    parentId: string,
  ) => void
  addNewLeftFunction?: (
    formData: object,
    rootId: string,
    parentId: string,
    id: string,
  ) => void
  addNewRightFunction: (formData: object, id: string, rootId: string) => void
}

export const SingleTreeGenericAddComponent = observer(
  ({
    rootId,
    columns,
    objectData,
    firstLevel,
    index,
    parentId,
    firstLevelChild,
    secondLevelChild,
    firstLevelParent,
    secondLevelParent,
    lang,
    mainDesc,
    baseUrl,
    setData,
    searchComponent,
    dropFunction,
    dropPlusFunction,
    addExistingLeftFunction,
    addExistingRightFunction,
    addNewLeftFunction,
    addNewRightFunction,
  }: SingleTreeTagProps) => {
    const store = useContext(StoreContext)
    const locales = store.TranslationsState.translations
    const [{ isDragging }, dragRef] = useDrag({
      type: 'TAG',
      item: objectData,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    })

    const [{ isOver }, dropRef] = useDrop({
      accept: 'TAG',
      drop: (item: any, monitor) => {
        handleDrop(objectData, item)
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    })

    const [{ isOver: isOverPlus }, dropPlusRef] = useDrop({
      accept: 'TAG',
      drop: (item: any, monitor) => {
        handlePlusDrop(objectData, item)
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    })

    const isDraggingOnChild = (
      draggedTagData: any,
      currentDataId: string,
    ): boolean => {
      if (draggedTagData.id === currentDataId) return true
      if (
        draggedTagData[secondLevelChild] &&
        draggedTagData[secondLevelChild].length > 0
      ) {
        for (const child of draggedTagData[secondLevelChild]) {
          if (isDraggingOnChild(child, currentDataId)) {
            return true
          }
        }
      }
      return false
    }

    function canMoveTag(
      currentTagData: { [key: string]: any },
      draggedTagData: { [key: string]: any },
    ) {
      if (currentTagData.id === draggedTagData.id) {
        return false
      } else if (isDraggingOnChild(draggedTagData, currentTagData.id))
        return false
      return true
    }

    const handleDrop = async (
      currentTagData: { [key: string]: any },
      draggedTagData: { [key: string]: any },
    ) => {
      if (!canMoveTag(currentTagData, draggedTagData)) {
        console.log('Invalid drop action.')
        toast('Invalid drop action.', {
          type: 'error',
        })
      } else {
        dropFunction(currentTagData, draggedTagData, firstLevel)
      }
    }

    const handlePlusDrop = async (
      currentTagData: { [key: string]: any },
      draggedTagData: { [key: string]: any },
    ) => dropPlusFunction(currentTagData, draggedTagData, firstLevel)

    const combinedRef = (node: any) => {
      dragRef(node)
      dropRef(node)
    }

    const divId = parentId ? `${parentId}-${index}` : 'treetagtoot'
    /* eslint-disable */
    const relations: any = parentId
      ? [
          {
            targetId: parentId,
            targetAnchor: 'right',
            sourceAnchor: 'left',
            style: {
              zIndex: 0,
            },
            label: (
              <Tooltip
                title={`${locales.buttons.addNew} ${mainDesc} as parent`}
                placement={'bottom'}
              >
                <AddButton
                  style={{
                    backgroundColor: isOverPlus ? 'lightgray' : undefined,
                  }}
                  ref={dropPlusRef}
                  onClick={() =>
                    store.SlidingPanelState.setSelectedSlider(
                      `createNewParent-${objectData.id}`,
                    )
                  }
                >
                  <img src={PlusWhite} />
                </AddButton>
              </Tooltip>
            ),
          },
        ]
      : []

    const editHeaders = Object.keys(columns).map((header) => {
      return {
        header: header,
        value: objectData[header],
      }
    })

    const columnHeaders = Object.entries(columns).map(
      ([header, params]: any) => {
        return {
          header: header,
          value: getEmptyValue(params.editType, {
            editCustomOptions: {
              selectValues:
                params.editType === 'select' ? params.selectValues : [],
            },
          }),
        }
      },
    )

    const getCopyValues = (header: string) => {
      if (header === 'parent') {
        return !firstLevel ? objectData.id : ''
      } else if (header === 'root_id') {
        return !firstLevel ? objectData.root_id : objectData.id
      } else {
        return objectData[header] ? objectData[header] : ''
      }
    }

    const copyHeaders = editHeaders.map((value) => {
      return {
        header: value.header,
        value: getCopyValues(value.header),
      }
    })

    const mapTableSizes = (size: string) => {
      switch (size) {
        case 'small':
          return '10px'
        case 'default':
          return '12px'
        case 'big':
          return '16px'
        default:
          return '12px'
      }
    }

    const mapIcons = (icon: string) => {
      switch (icon) {
        case 'star':
          return <img src={Star} />
        case 'circle':
          return <img src={Circle} />
        case 'exp-mark':
          return <img src={ExpMark} />
        case 'minus':
          return <img src={Minus} />
        case 'plus':
          return <img src={Plus} />
        case 'question-mark':
          return <img src={QuestionMark} />
        case 'rectangle':
          return <img src={Rectangle} />
        case 'smiley':
          return <img src={Smiley} />
        case 'car':
          return <img src={Car} />
        case 'sun':
          return <img src={Sun} />
        case 'cross':
          return <img src={Cross} />
        default:
          return <></>
      }
    }

    const hasChild = () => {
      if (firstLevel) {
        return !!objectData[firstLevelChild]
      } else return !!objectData[secondLevelChild]
    }

    return (
      <Row>
        <TagContainer>
          {store.TagsStore.treeLoaded ? (
            <SingleTag
              ref={combinedRef}
              style={{
                backgroundColor: isOver ? 'lightgray' : undefined,
                zIndex: 1,
                position: 'relative',
              }}
              size={mapTableSizes(objectData.tag_size)}
              color={objectData.colour ? objectData.colour : '#505050'}
            >
              {mapIcons(objectData.tag_icon)}
              <ArcherElement id={divId} relations={relations}>
                <p style={{ width: '100%' }}>{`${objectData.name[lang]}`}</p>
              </ArcherElement>
            </SingleTag>
          ) : (
            <ArcherElement id={divId} relations={relations}>
              <SingleTag
                style={{ opacity: 0.5 }}
                size={mapTableSizes(objectData.tag_size)}
                color={objectData.colour ? objectData.colour : '#505050'}
              >
                <p>{objectData.name[lang]}</p>
              </SingleTag>
            </ArcherElement>
          )}
          <ButtonCont>
            {!firstLevel ? (
              <Tooltip title={`Edit this ${mainDesc}`} placement={'bottom'}>
                <EditButton
                  onClick={() =>
                    store.SlidingPanelState.setSelectedSlider(
                      `editTag-${objectData.id}`,
                    )
                  }
                >
                  <img src={EditIcon} />
                </EditButton>
              </Tooltip>
            ) : (
              <></>
            )}
            {!firstLevel ? (
              <Tooltip title={`Remove this ${mainDesc}`} placement={'bottom'}>
                <EditButton
                  style={{ backgroundColor: '#FF4F4B' }}
                  onClick={() =>
                    store.SlidingPanelState.setSelectedSlider(
                      `removeTag-${objectData.id}`,
                    )
                  }
                >
                  <img src={CrossWhite} />
                </EditButton>
              </Tooltip>
            ) : (
              <></>
            )}
            <Tooltip
              title={`${locales.buttons.addNew} ${mainDesc} as child`}
              placement={'bottom'}
            >
              <AddButton
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    `createNewTag-${objectData.id}`,
                  )
                }
              >
                <img src={PlusWhite} />
              </AddButton>
            </Tooltip>
          </ButtonCont>
        </TagContainer>
        {/* Separate Column for[secondLevelChild] */}
        <Column>
          {hasChild() ? (
            (firstLevel
              ? objectData[firstLevelChild]
              : objectData[secondLevelChild]
            ).map((childTag: any, i: number) => (
              <SingleTreeGenericAddComponent
                rootId={rootId}
                columns={columns}
                key={childTag.id}
                objectData={childTag}
                firstLevel={false}
                parentId={divId}
                index={i}
                firstLevelChild={firstLevelChild}
                secondLevelChild={secondLevelChild}
                firstLevelParent={firstLevelParent}
                secondLevelParent={secondLevelParent}
                lang={lang}
                mainDesc={mainDesc}
                baseUrl={baseUrl}
                setData={setData}
                searchComponent={searchComponent}
                dropFunction={dropFunction}
                dropPlusFunction={dropPlusFunction}
                addExistingLeftFunction={addExistingLeftFunction}
                addExistingRightFunction={addExistingRightFunction}
                addNewLeftFunction={addNewLeftFunction}
                addNewRightFunction={addNewRightFunction}
              />
            ))
          ) : (
            <></>
          )}
          <span style={{ marginBottom: '4px', visibility: 'hidden' }}>
            cont
          </span>
        </Column>
        <SlidingPanel
          contentId={`createNewTag-${objectData.id}`}
          title={`${locales.buttons.addNew} ${mainDesc} for parent ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <ChooseTreeGenericComponent
            id={objectData.id}
            rootId={rootId}
            parentId={''}
            columnHeaders={columnHeaders}
            columns={columns}
            copyColumns={copyHeaders}
            hideParent={true}
            view={'tag'}
            firstLevel={firstLevel}
            mainDesc={mainDesc}
            baseUrl={baseUrl}
            searchComponent={searchComponent}
            addExistingLeftFunction={addExistingLeftFunction}
            addExistingRightFunction={addExistingRightFunction}
            addNewLeftFunction={addNewLeftFunction}
            addNewRightFunction={addNewRightFunction}
          />
        </SlidingPanel>{' '}
        <SlidingPanel
          contentId={`createNewParent-${objectData.id}`}
          title={`${locales.buttons.addNew} parent for ${mainDesc} ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <ChooseTreeGenericComponent
            id={objectData.id}
            rootId={rootId}
            parentId={objectData.parent}
            columns={columns}
            columnHeaders={columnHeaders}
            copyColumns={copyHeaders}
            hideParent={true}
            left={true}
            view={'tag'}
            firstLevel={firstLevel}
            mainDesc={mainDesc}
            baseUrl={baseUrl}
            searchComponent={searchComponent}
            addExistingLeftFunction={addExistingLeftFunction}
            addExistingRightFunction={addExistingRightFunction}
            addNewLeftFunction={addNewLeftFunction}
            addNewRightFunction={addNewRightFunction}
          />
        </SlidingPanel>
        <SlidingPanel
          contentId={`addTag-${objectData.id}`}
          title={`${locales.buttons.add} ${mainDesc} to: ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <AddTreeComponent
            id={objectData.id}
            parentId={''}
            searchComponent={searchComponent}
            addExistingLeftFunction={addExistingLeftFunction}
            addExistingRightFunction={addExistingRightFunction}
          />
        </SlidingPanel>
        <SlidingPanel
          contentId={`removeTag-${objectData.id}`}
          title={`Remove ${mainDesc}: ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <RemoveTagComponent
            columns={[{ header: 'id', value: objectData.id }]}
            baseUrl={baseUrl}
            setData={setData}
            mainDesc={mainDesc}
          />
        </SlidingPanel>
        <SlidingPanel
          contentId={`editTag-${objectData.id}`}
          title={`Edit ${mainDesc}: ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <EditTree
            columnHeaders={editHeaders}
            columns={columns}
            hideParent={true}
            view={'tag'}
            baseUrl={baseUrl}
            setData={setData}
            mainDesc={mainDesc}
          />
        </SlidingPanel>
      </Row>
    )
  },
)
