import { observer } from 'mobx-react'
import {
  Container,
  DropDown,
  DropDownItem,
  Input,
  InputCont,
  TextCont,
} from './SearchCollection.styles'
import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from '../../../../../axiosconfig'
import { StoreContext } from '../../../../../App'
import ClearFilters from '../../../../../assets/icons/clear-filters.svg'
import { DeleteButton } from '../objectInput/ObjectInput.styles'

interface AddProductSearchProps {
  applySearch: (val: any) => void
  header: string
  clearInputValue?: boolean
}

function useOutsideClick(
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

/* eslint-disable */

const SearchCollectionComponent = observer(
  ({ applySearch, clearInputValue, header }: AddProductSearchProps) => {
    const store = useContext(StoreContext)
    const [data, setData] = useState<any[]>([])
    const [displayedData, setDisplayedData] = useState<any[]>([])
    const [inputValue, setInputValue] = useState<string>('')
    const [isInputFocused, setIsInputFocused] = useState(false)
    const [secondTierTags, setSecondTierTags] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(0)
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    const searchRecommendedProducts = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collections`

      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      const response = await axios.get(url, config)

      if (response.status === 200) {
        await setData((prevData) => [...prevData, ...response.data])
      }
    }

    useOutsideClick(dropdownRef, () => {
      if (!isInputFocused) return
      setSecondTierTags(false)
      setData([])
      searchRecommendedProducts()
      setIsInputFocused(false)
    })

    useEffect(() => {
      if (!isInputFocused) return
      setData([])
      setCurrentPage(0)
      searchRecommendedProducts()
    }, [isInputFocused, inputValue])

    useEffect(() => {
      setDisplayedData(() =>
        data.filter(
          (e: any) =>
            Object.keys(e.name).length &&
            e.name.EN.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      )
    }, [data])

    return (
      <Container ref={dropdownRef}>
        <InputCont>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={`Search for Collection...`}
            onFocus={() => setIsInputFocused(true)}
          />
          <DeleteButton
            active={true}
            onClick={() => {
              setSecondTierTags(false)
              setData([])
              searchRecommendedProducts()
              setIsInputFocused(false)
            }}
          >
            <img src={ClearFilters} />
          </DeleteButton>
        </InputCont>
        {isInputFocused ? (
          <DropDown>
            <>
              {displayedData.map((e: any, index: number) => (
                <DropDownItem
                  key={`search-dropdown=${index}`}
                  onClick={() => {
                    applySearch(e)
                    setInputValue(e.id)
                    if (clearInputValue) {
                      setInputValue('')
                    }
                  }}
                >
                  {/*<ImageCont>
                      <img src={e.image} alt={e.name}/>
                  </ImageCont>*/}
                  <TextCont>
                    <p>{e.name.EN}</p>
                  </TextCont>
                </DropDownItem>
              ))}{' '}
            </>
          </DropDown>
        ) : null}
      </Container>
    )
  },
)

export default SearchCollectionComponent
