import { observer } from 'mobx-react'
import { useContext, useState } from 'react'
import { StoreContext } from '../../../App'
import { ContButton } from './ExportDocuments.styles'
import axios from '../../../axiosconfig'

const ExportDocuments = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [buttonClick, setButtonClick] = useState(false)
  const [loading, setLoading] = useState(false)
  const [labelLoading, setLabelLoading] = useState(false)

  const exportDocuments = async (all: boolean) => {
    if (all) setLoading(true)
    else setLabelLoading(true)

    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/packages/documents_print`
    const data = {
      ids: store.TableStore.selectedRowId,
      documents_types: all ? ['all'] : ['labels'],
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }

    try {
      console.log(url, data, config)
      const response = await axios.post(url, data, config)
      const fileUrl = response.data.url
      const link = document.createElement('a')
      link.href = fileUrl
      link.setAttribute('download', 'download.zip')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error during file download:', error)
    }
    await setLoading(false)
    await setLabelLoading(false)
  }

  return (
    <>
      <ContButton
        onClick={() => !loading && exportDocuments(true)}
        {...Theme.copyToClipBoardStyles.contButton}
      >
        {loading ? 'Loading...' : 'Export Documents'}
      </ContButton>
      <ContButton
        onClick={() => !labelLoading && exportDocuments(false)}
        {...Theme.copyToClipBoardStyles.contButton}
      >
        {labelLoading ? 'Loading...' : 'Export Labels'}
      </ContButton>
    </>
  )
})
export default ExportDocuments
