import { rootStore, RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'

export interface ReturnType {
  id: string
  date: string
  order_id: string
  amount: number
  customer_email: string
  status: string
  products: [
    {
      code: string
      status: string
      amount: number
    },
  ]
  type: string
  reason: string
  comment: string
  mediaFiles: string[]
}

export class ReturnsStore {
  rootStore
  data: any[] = []
  detailsData: ReturnType | null = null
  detailsProducts: any[] | null = null
  change: boolean = false
  selectedProducts: { [key: string]: number } = {}
  loaded = true

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound fetchData = async (): Promise<void> => {
    const url = 'https://returnsdev.apihulk.com/returns'
    const response = await axios.get(url)
    if (response.status === 200) {
      this.data = response.data
    }
  }

  @action.bound fetchDetailsData = async (id: string): Promise<void> => {
    this.loaded = false
    const url = `https://returnsdev.apihulk.com/returns/${id}`
    const response = await axios.get(url)
    if (response.status === 200) {
      this.detailsData = response.data
      const data: any = {
        filterGroups: {
          rules: [],
          combinator: 'or',
          not: false,
        },
      }
      response.data.products.forEach((product) =>
        data.filterGroups.rules.push({
          field: 'code',
          operator: '=',
          valueSource: 'value',
          value: product.code,
        }),
      )
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/stock_view?size=${response.data.products.length}&from=0`
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
      const response2 = await axios.post(url, data, config)
      await this.rootStore.TableStore.fetchDetailsData(
        response.data.customer_order_id,
      )
      this.detailsProducts = response2.data.products
      this.loaded = true
    } else {
      toast('Error', { type: 'error' })
    }
  }

  @action.bound fetchPrintData = async (id): Promise<any> => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_orders_view?from=0&size=1`
    const data: any = {
      filterGroups: {
        rules: [
          {
            field: 'order',
            operator: '=',
            valueSource: 'value',
            value: id,
          },
        ],
        combinator: 'or',
        not: false,
      },
    }

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
        'Access-Control-Allow-Credentials': 'true',
      },
    }
    const res = await axios.post(url, data, config)
    await console.log(res)
    const orderID = await res.data.orders[0].id
    const url2 = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${orderID}`
    const res2 = await axios.get(url2, config)
    return res2.data.products
  }

  @action.bound setSelectedProducts = (productCode: string, amount: number) => {
    console.log('change', productCode, amount)

    this.selectedProducts = {
      ...this.selectedProducts,
      [productCode]: amount,
    }
    this.change = !this.change
  }

  @action.bound clearData = () => {
    this.data = []
    this.loaded = false
  }
}
