import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'
import { ProducersType } from '../types/ProducersType'

export class StatusesStore {
  rootStore
  data = []
  salesChannels = []
  currentPage = 'shippingtime'
  producers: string[] = []
  loaded = false
  avaliabilityByProducer: availabilityByProducerType[] = []
  shippingTimeStatus: shippingTimeStatusType[] = []
  avaliabilityStatus: availabilityStatusType[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.fetchSalesData()
    this.fetchProducers()
    this.fetchColumnsData()
    this.rootStore = rootStore
  }

  getUrlBasedOnView = (type?: string) => {
    switch (type ? type : this.currentPage) {
      case 'shippingtime':
        return 'shipping_time_status'
      case 'byproducer':
        return 'availability_by_producer_status'
      case 'availability':
        return 'availability_status'
      default:
        return 'availability_by_producer_status'
    }
  }

  getResponse = (type?: string) => {
    switch (type ? type : this.currentPage) {
      case 'shippingtime':
        return 'statues'
      case 'byproducer':
        return 'statuses'
      case 'availability':
        return 'statues'
      default:
        return 'availability_by_producer_status'
    }
  }

  @action.bound fetchData = async (type: string): Promise<void> => {
    this.loaded = false
    this.currentPage = type
    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/${this.getUrlBasedOnView(type)}/view`
    //console.log(url)
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const data = this.rootStore.TableStore.getData()
    const response = await axios.get(url, config)
    this.data = await response.data[this.getResponse(type)]
    //console.log(response)
    this.loaded = true
  }

  @action.bound fetchSalesData = async (): Promise<void> => {
    this.loaded = false
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/sales_channel/view`
    console.log(url)
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }
    const response = await axios.get(url, config)
    this.salesChannels = await response.data['sales channels']
    console.log(response.data['sales channels'])
    this.loaded = true
  }

  @action.bound fetchProducers = async () => {
    const url = `https://moodnook.westeurope.cloudapp.azure.com/api/experience/v1/producers_view?size=100&from=0`
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          'x-apikey': `bd6cc820-052c-4b96-9e6b-180ea6df3486`,
        },
      },
    )
    const producerNames: string[] = await Promise.all(
      response.data.producers.map((e: ProducersType) => e.name),
    )
    this.producers = [...new Set(producerNames)]
  }

  @action.bound clearData = () => {
    this.data = []
  }

  @action.bound fetchColumnsData = async () => {
    const fetchData = async (
      url: string,
      config: { headers: { [key: string]: string | undefined } },
    ) => {
      try {
        const response = await axios.get(url, config)
        return response.data
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error)
        throw error
      }
    }

    try {
      const baseUrl = process.env.REACT_APP_BASE_URL
      const apiKey = process.env.REACT_APP_API_KEY

      const config = {
        headers: {
          'x-apikey': apiKey,
        },
      }

      const urls = [
        `${baseUrl}experience/v1/shipping_time_status/view`,
        `${baseUrl}experience/v1/availability_by_producer_status/view`,
        `${baseUrl}experience/v1/availability_status/view`,
      ]

      const [data1, data2, data3] = await Promise.all(
        urls.map((url) => fetchData(url, config)),
      )

      const nullOption = { id: 'null', name: { en_GB: 'No status assigned!' } }

      this.shippingTimeStatus = [nullOption, ...data1.statues]
      this.avaliabilityByProducer = [nullOption, ...data2.statuses]
      this.avaliabilityStatus = [nullOption, ...data3.statues]
    } catch (e) {
      console.log('Error fetching column data:', e)
    }
  }

  @action.bound setCurrentPage = (currentPage: string) => {
    this.currentPage = currentPage
  }
}
