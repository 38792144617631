import { observer } from 'mobx-react'
import {
  ButtonCont,
  Label,
  Setting,
  SettingsCategory,
  StyledLink,
} from '../../../../pages/settings/settings.styles'
import { useContext } from 'react'
import { StoreContext } from '../../../../App'
import Button from '../../../button/Button.component'

export const ThemeSetterComponent = observer(() => {
  const store = useContext(StoreContext)

  return (
    <SettingsCategory>
      <h1>Theme options:</h1>

      <Setting>
        <h2>Themes List:</h2>
        {store.Theme.themeKeys &&
          store.Theme.themeKeys.map((themeKey) => (
            <h2 key={themeKey}>
              <Label>
                {themeKey.charAt(0).toUpperCase() + themeKey.slice(1)}:
              </Label>
              <ButtonCont>
                <StyledLink to={'/'}>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      store.Theme.setTheme(themeKey)
                      store.Theme.activateThemeModification(true)
                    }}
                  >
                    {'Edit'}
                  </Button>
                </StyledLink>
                <Button
                  type={'primary'}
                  onClick={() => store.Theme.setTheme(themeKey)}
                >
                  {'Select'}
                </Button>
                <Button
                  type={'primary'}
                  onClick={() => {
                    store.Theme.deleteTheme(themeKey)
                  }}
                >
                  {'Delete'}
                </Button>
              </ButtonCont>
            </h2>
          ))}
        <Button
          type={'primary'}
          onClick={() => store.Theme.activateThemeModification(true)}
          key='themecreator'
          width={'220px'}
          margin={'20px 0 0 0'}
        >
          <StyledLink to={'/'}>Create a new theme</StyledLink>
        </Button>
      </Setting>
    </SettingsCategory>
  )
})
