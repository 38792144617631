/* eslint-disable */
import React, {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Row } from '@tanstack/react-table'

import {
  Container,
  Footer,
  InnerCont,
  TagCont,
} from './AssignOrderLineTagGeneric.styles'

import { StoreContext } from '../../App'

import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import {
  SingleTag,
  SingleTagCross,
} from '../_tableGeneric/tableNewTags/TableNewTags.styles'
import Cross from '../../assets/icons/cross-white.svg'
import SearchTagNewComponent from '../_settings/tags/edits/searchTagNew/SearchTagNew.component'
import { FilterLabel } from '../_settings/tags/edits/searchTagNew/SearchTagNew.styles'
import Button from '../button/Button.component'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  row: any
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  tableName: string
  fetchData: () => void
}

const AssignTagGeneric: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps,
) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const { Theme } = store.Theme
  const [isAbleToSave, setIsAbleToSave] = useState(false)

  const [currentTagList, setCurrentTagList] = useState<Array<any>>([])
  const applySearch = (tag: any) => {
    const addedTag = [...currentTagList, tag]
    const unique = [
      ...new Map(addedTag.map((item) => [item.id, item])).values(),
    ]
    setCurrentTagList(unique)
    setIsAbleToSave(true)
  }

  const applyClearTag = (tag: any) => {
    setCurrentTagList([...currentTagList.filter((e) => e.id !== tag.id)])
    setIsAbleToSave(true)
  }

  const handleSave = async () => {
    const id = props.columns.find((e: any) => e.header === 'id')?.value
    const supOrderUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${id}`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    const response = await axios.get(supOrderUrl, config)
    const productTags: any = response.data.product_tags
    const products: any = Object.keys(response.data.products)

    const tags = [...currentTagList.map((e) => e.id)]

    const newProductTags: any[] = []
    if (products && productTags) {
      products.map((e: string, index: number) => {
        const productTag = productTags.find((f: any) => f.productID === e) || {
          productID: e,
          tags: [],
        }
        console.log(productTag)
        tags.map((tag: any) => productTag.tags.push(tag))
        productTag.tags = [...new Set(productTag.tags)]
        newProductTags.push(productTag)
      })
    }

    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/tag/update/orderline/${id}`
    await axios.post(url, { tags: newProductTags }, config)
    await toast(locales.successes.success, { type: 'success' })
    await store.SlidingPanelState.setVisibility(false)
    await props.fetchData()
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <SearchTagNewComponent
          active={true}
          view={'tag'}
          header={locales.headers.addNewTag}
          applySearch={applySearch}
          clearInputValue={true}
          customIndex={'products'}
        />
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {locales.headers.currentTags}:
        </FilterLabel>
        <TagCont>
          {currentTagList.map((e: any, index) => {
            if (e)
              return (
                <SingleTag
                  key={`new-tag-${e}`}
                  size={'12px'}
                  style={{ whiteSpace: 'nowrap' }}
                  color={e.hasOwnProperty('colour') ? e.colour : '#505050'}
                >
                  {e.name.EN}
                  <SingleTagCross onClick={() => applyClearTag(e)}>
                    <img src={Cross} />
                  </SingleTagCross>
                </SingleTag>
              )
          })}
        </TagCont>
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            if (isAbleToSave) handleSave()
          }}
          active={isAbleToSave}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}
export default AssignTagGeneric
