import { observer } from 'mobx-react'
import {
  Label,
  Setting,
  SettingsCategory,
} from '../../../pages/settings/settings.styles'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../App'

import { toJS } from 'mobx'
import { MainCont, RadioComponent } from './ReturnPolicy.styles'
import Button from '../../button/Button.component'

/* eslint-disable */
interface returnInterface {
  address: {
    companyName: string
    country: string
    zip: string
    city: string
    street: string
    email: string
    phone: string
  }
  returnPolicy: {
    clientReturnDays: number
    moneyReturnDays: number
    policyType: 'default' | 'file' | 'url'
    policyFile: File | null
    policyURL: string
  }
  extra: {
    logo: File | null
    returnRules: null | string
    addLogo: 'yes' | 'no'
    addReturnRules: 'yes' | 'no'
  }
}

const ReturnPolicyComponent = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const [loaded, setLoaded] = useState(true)
  const [data, setData] = useState<returnInterface>({
    address: {
      companyName: '',
      country: '',
      zip: '',
      city: '',
      street: '',
      email: '',
      phone: '',
    },
    returnPolicy: {
      clientReturnDays: 10,
      moneyReturnDays: 10,
      policyType: 'default',
      policyFile: null,
      policyURL: '',
    },
    extra: {
      logo: null,
      returnRules: null,
      addLogo: 'no',
      addReturnRules: 'no',
    },
  })

  const handleChange = (category: string, key: string, value: any) => {
    setData((prevData: any) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [key]: value,
      },
    }))
  }

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    const file = e.target.files && e.target.files[0]
    if (file) {
      handleChange('extra', key, file)
    }
  }

  const handleSave = async () => {
    alert(JSON.stringify(data))
  }

  /*useEffect(() => {
                                                                                                  store.StoreSettingsStore.fetchAllData()
                                                                                                  store.StoreSettingsStore.fetchData('timezones')
                                                                                                }, [])*/

  useEffect(() => {
    console.log(toJS(data))
    console.log(toJS(store.StoreSettingsStore.data))
  }, [data])

  /*useEffect(() => {
                                                                                                  const tempData: any = store.StoreSettingsStore.allData
                                                                                                  setData(tempData)
                                                                                                  setLoaded(true)
                                                                                                }, [store.StoreSettingsStore.allData])*/

  if (loaded)
    return (
      <MainCont autoComplete={'on'} spellCheck={true}>
        <SettingsCategory>
          <h1>Return Address</h1>
          <h2></h2>

          <Setting>
            <Label>Company Name:</Label>
            <input
              value={data.address.companyName}
              type='text'
              onChange={(e: any) =>
                handleChange('address', 'companyName', e.target.value)
              }
            />
          </Setting>

          <Setting>
            <Label>Country:</Label>
            <input
              value={data.address.country}
              type='text'
              onChange={(e: any) =>
                handleChange('address', 'country', e.target.value)
              }
            />
          </Setting>

          <Setting>
            <Label>ZIP Code:</Label>
            <input
              value={data.address.zip}
              type='text'
              onChange={(e: any) =>
                handleChange('address', 'zip', e.target.value)
              }
            />
          </Setting>

          <Setting>
            <Label>City:</Label>
            <input
              value={data.address.city}
              type='text'
              onChange={(e: any) =>
                handleChange('address', 'city', e.target.value)
              }
            />
          </Setting>

          <Setting>
            <Label>Street:</Label>
            <input
              value={data.address.street}
              type='text'
              onChange={(e: any) =>
                handleChange('address', 'street', e.target.value)
              }
            />
          </Setting>

          <Setting>
            <Label>Email:</Label>
            <input
              value={data.address.email}
              type='email'
              onChange={(e: any) =>
                handleChange('address', 'email', e.target.value)
              }
            />
          </Setting>

          <Setting>
            <Label>Phone:</Label>
            <input
              value={data.address.phone}
              type='text'
              onChange={(e: any) =>
                handleChange('address', 'phone', e.target.value)
              }
            />
          </Setting>
        </SettingsCategory>

        <SettingsCategory>
          <h1>Return Policy</h1>
          <h2></h2>
          <Setting>
            <Label>Client Return Days:</Label>
            <input
              value={data.returnPolicy.clientReturnDays}
              type='number'
              onChange={(e: any) =>
                handleChange('returnPolicy', 'clientReturnDays', e.target.value)
              }
            />
          </Setting>

          <Setting>
            <Label>Money Return Days:</Label>
            <input
              value={data.returnPolicy.moneyReturnDays}
              type='number'
              onChange={(e: any) =>
                handleChange('returnPolicy', 'moneyReturnDays', e.target.value)
              }
            />
          </Setting>

          {/* Policy Text Option */}
          <Setting>
            <Label>Policy Text:</Label>

            {/* Radio buttons for choosing policy type */}
            <RadioComponent>
              <div>
                <input
                  type='radio'
                  id='defaultPolicy'
                  name='policyType'
                  value='default'
                  checked={data.returnPolicy.policyType === 'default'}
                  onChange={(e) =>
                    handleChange('returnPolicy', 'policyType', e.target.value)
                  }
                />
                <Label>Use Default Policy</Label>
              </div>
              <div>
                <input
                  type='radio'
                  id='filePolicy'
                  name='policyType'
                  value='file'
                  checked={data.returnPolicy.policyType === 'file'}
                  onChange={(e) =>
                    handleChange('returnPolicy', 'policyType', e.target.value)
                  }
                />
                <Label>Upload Policy File</Label>
              </div>
              <div>
                <input
                  type='radio'
                  id='urlPolicy'
                  name='policyType'
                  value='url'
                  checked={data.returnPolicy.policyType === 'url'}
                  onChange={(e) =>
                    handleChange('returnPolicy', 'policyType', e.target.value)
                  }
                />
                <Label>Provide Policy URL</Label>
              </div>
            </RadioComponent>
          </Setting>

          {/* Conditional input rendering based on the selected policy type */}
          {data.returnPolicy.policyType === 'file' && (
            <Setting>
              <Label>Upload Policy File:</Label>
              <input
                type='file'
                onChange={(e: any) =>
                  handleChange('extra', 'returnRules', e.target.value)
                }
              />
              {data.returnPolicy.policyFile && (
                <p>Selected file: {data.returnPolicy.policyFile.name}</p>
              )}
            </Setting>
          )}

          {data.returnPolicy.policyType === 'url' && (
            <Setting>
              <Label>Policy URL:</Label>
              <input
                value={data.returnPolicy.policyURL || ''}
                type='text'
                onChange={(e: any) =>
                  handleChange('returnPolicy', 'policyURL', e.target.value)
                }
              />
            </Setting>
          )}
        </SettingsCategory>

        <SettingsCategory>
          <h1>Extra Options</h1>
          <h2></h2>
          <Setting>
            <Label>Add Logo:</Label>

            <RadioComponent>
              <div>
                <input
                  type='radio'
                  id='addLogoYes'
                  name='addLogo'
                  value='yes'
                  checked={data.extra.addLogo === 'yes'}
                  onChange={(e) =>
                    handleChange('extra', 'addLogo', e.target.value)
                  }
                />
                <Label>Add Logo</Label>
              </div>
              <div>
                <input
                  type='radio'
                  id='addLogoNo'
                  name='addLogo'
                  value='no'
                  checked={data.extra.addLogo === 'no'}
                  onChange={(e) =>
                    handleChange('extra', 'addLogo', e.target.value)
                  }
                />
                <Label>Do Not Add Logo</Label>
              </div>
            </RadioComponent>
          </Setting>

          {data.extra.addLogo === 'yes' && (
            <Setting>
              <Label>Upload Logo:</Label>
              <input
                type='file'
                onChange={(e) => handleFileChange(e, 'logo')}
              />
              {data.extra.logo && <p>Selected file: {data.extra.logo.name}</p>}
            </Setting>
          )}

          <Setting>
            <Label>Add Return Rules:</Label>
            <RadioComponent>
              <div>
                <input
                  type='radio'
                  id='addReturnRulesYes'
                  name='addReturnRules'
                  value='yes'
                  checked={data.extra.addReturnRules === 'yes'}
                  onChange={(e) =>
                    handleChange('extra', 'addReturnRules', e.target.value)
                  }
                />
                <Label>Add Return Rules</Label>
              </div>
              <div>
                <input
                  type='radio'
                  id='addReturnRulesNo'
                  name='addReturnRules'
                  value='no'
                  checked={data.extra.addReturnRules === 'no'}
                  onChange={(e) =>
                    handleChange('extra', 'addReturnRules', e.target.value)
                  }
                />
                <Label>Do Not Add Return Rules</Label>
              </div>
            </RadioComponent>
          </Setting>

          {data.extra.addReturnRules === 'yes' && (
            <Setting>
              <Label>Return Rules Text or URL:</Label>
              <input
                value={data.extra.returnRules || ''}
                type='text'
                onChange={(e: any) =>
                  handleChange('extra', 'returnRules', e.target.value)
                }
              />
            </Setting>
          )}
        </SettingsCategory>

        <Button type={'primary'} onClick={() => handleSave()}>
          {'SAVE (get object)'}
        </Button>
      </MainCont>
    )
  else return <></>
})
export default ReturnPolicyComponent
