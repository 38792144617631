import { useContext } from 'react'
import { StoreContext } from '../../App'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'

export const GetQuery = (val: string) => {
  switch (val) {
    case 'status2c':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'status',
            operator: 'beginsWith',
            valueSource: 'value',
            value: '2c',
          },
        ],
        not: false,
      }
    case 'status3':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'status',
            operator: 'beginsWith',
            valueSource: 'value',
            value: '3.',
          },
        ],
        not: false,
      }
    case 'status3a':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'status',
            operator: 'beginsWith',
            valueSource: 'value',
            value: '3a',
          },
        ],
        not: false,
      }
    case 'status3b':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'status',
            operator: 'beginsWith',
            valueSource: 'value',
            value: '3b',
          },
        ],
        not: false,
      }
    case 'status3c':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'status',
            operator: 'beginsWith',
            valueSource: 'value',
            value: '3c',
          },
        ],
        not: false,
      }
    case 'status3x':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'status',
            operator: 'beginsWith',
            valueSource: 'value',
            value: '3x',
          },
        ],
        not: false,
      }
    case 'allstatuses':
      return {
        combinator: 'or',
        rules: [
          {
            field: 'status',
            operator: 'beginsWith',
            valueSource: 'value',
            value: '3',
          },
          {
            field: 'status',
            operator: 'beginsWith',
            valueSource: 'value',
            value: '5',
          },
          {
            field: 'status',
            operator: 'beginsWith',
            valueSource: 'value',
            value: '6',
          },
        ],
        not: false,
      }
    case 'stockStates':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'stockAfterPacking',
            operator: '<',
            valueSource: 'value',
            value: '0',
          },
          {
            field: 'minimumWarnLevel',
            operator: '>=',
            valueSource: 'value',
            value: '0',
          },
          {
            field: 'producer',
            operator: '=',
            valueSource: 'value',
            value: '',
          },
        ],
        not: false,
      }
    case 'afterpacking < 0':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'stockAfterPacking',
            operator: '<',
            valueSource: 'value',
            value: '0',
          },
        ],
        not: false,
      }
    case 'afterorders < 0':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'stockAfterOrders',
            operator: '<',
            valueSource: 'value',
            value: '0',
          },
          {
            field: 'producer',
            operator: '=',
            valueSource: 'value',
            value: '',
          },
        ],
        not: false,
      }
    case 'completableMicroserviceInt=2':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'completableMicroserviceInt',
            operator: '=',
            valueSource: 'value',
            value: '2',
          },
        ],
        not: false,
      }
    case 'completable=2':
      return {
        combinator: 'and',
        rules: [
          {
            field: 'completable',
            operator: '=',
            valueSource: 'value',
            value: '2',
          },
        ],
        not: false,
      }
    case 'statuses2':
      return {
        rules: [
          {
            id: 'r-0.6137852365201113',
            field: 'status',
            operator: '=',
            valueSource: 'value',
            value: '3',
          },
          {
            id: 'r-0.8430730299032105',
            field: 'status',
            operator: '=',
            valueSource: 'value',
            value: '5',
          },
          {
            id: 'r-0.07228511612282484',
            field: 'status',
            operator: '=',
            valueSource: 'value',
            value: '6',
          },
        ],
        combinator: 'or',
        not: false,
      }
    case 'PackingStatus':
      return {
        rules: [
          {
            field: 'status',
            operator: 'contains',
            valueSource: 'value',
            value: '2c.',
          },
          {
            field: 'status',
            operator: 'contains',
            valueSource: 'value',
            value: '3.',
          },
          {
            field: 'status',
            operator: 'contains',
            valueSource: 'value',
            value: '3a.',
          },
          {
            field: 'status',
            operator: 'contains',
            valueSource: 'value',
            value: '3b.',
          },
          {
            field: 'status',
            operator: 'contains',
            valueSource: 'value',
            value: '3x.',
          },
          {
            field: 'status',
            operator: 'contains',
            valueSource: 'value',
            value: '3c.',
          },
          {
            field: 'status',
            operator: 'contains',
            valueSource: 'value',
            value: '3d.',
          },
          {
            field: 'status',
            operator: 'contains',
            valueSource: 'value',
            value: '3e.',
          },
          {
            field: 'status',
            operator: 'contains',
            valueSource: 'value',
            value: '3f.',
          },
        ],
        combinator: 'or',
        not: false,
      }
    default:
      return {
        combinator: 'and',
        rules: [],
        not: false,
      }
  }
}

/*export const QueryPredefinedFilters = () => {
  const store = useContext(StoreContext)
  switch (store.RouteStore.currentPage) {
    case 'customerorders':
    case '':
      return [
        { value: '', label: '' },
        { value: 'allstatuses', label: 'Show status in progress' },
        { value: 'status2c', label: 'status = 2c' },
        { value: 'status3', label: 'status = 3' },
        { value: 'status3a', label: 'status = 3a' },
        { value: 'status3b', label: 'status = 3b' },
        { value: 'status3c', label: 'status = 3c' },
        { value: 'status3x', label: 'status = 3x' },
        { value: 'PackingStatus', label: 'Statusy z listy Packing queue' },
      ]
    case 'stock':
      return [
        { value: '', label: '' },
        {
          value: 'afterorders < 0',
          label: 'Create suplier order',
        },
        {
          value: 'stockStates',
          label: 'Equalize minimum stock states',
        },
      ]
    case 'supplierorders':
      return [
        { value: '', label: '' },
        { value: 'statuses2', label: 'In progress (all)' },
      ]
    case 'packing':
      return [
        { value: '', label: '' },
        {
          value: 'completableMicroserviceInt=2',
          label: 'Batch picking',
        },
        {
          value: 'completable=2',
          label: 'Single Order Picking - Pick and pack',
        },
      ]
    default:
      return []
  }
}*/
export const QueryPredefinedFilters = () => {
  const store = useContext(StoreContext)
  return [{ value: '', label: `` }, ...store.FiltersStore.currentFilters]
}
export const SaveQuery = async (
  query: {
    combinator: string
    rules: any[]
    not: boolean
    id?: string
  },
  page: string,
  value: string,
  privateMode: boolean,
) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const url = `${process.env.REACT_APP_BASE_URL}experience/v1/filter/_create`
  const data = {
    filter: {
      query: query,
      label: value,
    },
    access_group: privateMode ? 'private' : 'public',
    created_by: store.UserStore.userID,
    view: store.TableStore.getApiRequestFromUrl(),
  }
  console.log(data)
  const config = {
    headers: {
      'x-apikey': `${process.env.REACT_APP_API_KEY}`,
    },
  }
  await toast(locales.successes.success, {
    type: 'success',
    autoClose: 5000,
  })
  await store.FiltersStore.fetchAllFilters()
}
