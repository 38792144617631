import React, { useState, useEffect, useContext } from 'react'
import { ScrollToTopButtonWrapper } from './SwitchToTopButton.styles'
import { StoreContext } from '../../App'

const ScrollToTopButton = () => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setIsVisible(scrollTop > 0)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  if (store.SlidingPanelState.selectedSlider === '')
    return (
      <ScrollToTopButtonWrapper isVisible={isVisible} onClick={scrollToTop}>
        {locales.buttons.backToTop}
      </ScrollToTopButtonWrapper>
    )
  else return <></>
}
export default ScrollToTopButton
