import styled from 'styled-components'
import Modal from '@mui/material/Modal'
import { darken } from 'polished'
/* eslint-disable */

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

interface ActiveButtonProps {
  color?: string
  bgColor?: string
  borderRadius?: string
  border?: string
  padding?: string
  active: boolean
}

export const ActiveButton = styled.div<ActiveButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '4px 12px')};
  margin-bottom: 4px;
  border: ${(props) =>
    props.active
      ? props.border
        ? props.border
        : '1px solid #20c33a'
      : '1px solid #C21F40'};
  background-color: ${(props) =>
    props.active ? (props.bgColor ? props.bgColor : '#f6fef7') : '#FFF7FA'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  color: ${(props) =>
    props.active ? (props.color ? props.color : '#20c33a') : '#C21F40'};
  cursor: pointer;
`

interface TextProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const SeparatorCont = styled.div`
  display: flex;
`
export const Label = styled.label`
  display: flex;
  align-items: center;
`

export const Text = styled.p<TextProps>`
  color: ${(props) => (props.color ? props.color : '#575757')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: 12px;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.35px'};
  font-feature-settings:
    'tnum' on,
    'lnum' on;
`
export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ModalCont = styled.div`
  position: absolute;
  max-width: 500px;
  background-color: white;

  p {
    padding: 16px;
  }
`

export const Header = styled.div`
  width: 500px;
  display: flex;
  justify-content: center;
  padding: 8px;
  padding-top: 16px;
  color: white;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`

export const InnerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface StyledInputInterface {
  activeBgColor?: string
  inactiveBgColor?: string
  boxShadow?: string
  borderRadius?: string
  padding?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
}

export const TextArea = styled.textarea<StyledInputInterface>`
  background-color: #ffffff;
  width: 400px;
  border: ${(props) => props.activeBgColor || '1px solid #D6D6D6'};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  padding: 2px 2px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

export const SelectCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SelectInput = styled.select`
  height: 32px;
  width: 120px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
`

interface ViewButtonInterface {
  selected: boolean
  view?: 'left' | 'right'
}

/* eslint-disable */
export const ViewButton = styled.button<ViewButtonInterface>`
  font-weight: 600;
  font-size: 14px;
  width: 200px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #303030;
  padding: 8px 20px;
  margin-top: 16px;
  border-radius: ${(props) =>
    props.view === 'left'
      ? '6px 0 0 6px'
      : props.view === 'right'
        ? '0 6px 6px 0'
        : '0'};
  border: 1px solid ${(props) => (!props.selected ? '#575757' : '#D6D6D6')};
  background-color: ${(props) => (!props.selected ? '#F5F5F5' : '#FFFFFF')};
`

interface FooterProps {
  bgColor?: string
  borderRadius?: string
}

export const Footer = styled.div<FooterProps>`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0 0 4px 4px'};
`

export const ButtonCont = styled.div`
  display: flex;
  width: 90%;
  justify-content: flex-end;
`

interface SecondaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  margin?: string
  color?: string
}

export const SecondaryButton = styled.button<SecondaryButtonProps>`
  font-weight: 600;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#424242')};
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 16px;
  white-space: nowrap;
  transition:
    color 0.3s ease,
    transform 0.2s ease;

  &:hover {
    color: ${(props) => darken(0.1, props.color ? props.color : '#424242')};
    transform: scale(1.05);
  }

  &:active {
    color: ${(props) => darken(0.2, props.color ? props.color : '#424242')};
    transform: scale(0.98);
  }
`

interface ContDropProps {
  bgColor?: string
}

export const ContDrop = styled.div<ContDropProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgba(0, 0, 0, 0.3)'};
`

interface InnerContDropProps {
  padding?: string
  border?: string
  bgColor?: string
}

export const InnerContDrop = styled.div<InnerContDropProps>`
  padding: ${(props) => (props.padding ? props.padding : '32px')};
  border: ${(props) => (props.border ? props.border : '1px solid black')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface DropAreaProps {
  padding?: string
  border?: string
  bgColor?: string
}

export const DropArea = styled.div<DropAreaProps>`
  border: 1.5px dashed #707070;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${(props) => (props.padding ? props.padding : '32px')};
  margin-bottom: 8px;
`

interface SelectedFileProps {
  padding?: string
  border?: string
  bgColor?: string
  borderRadius?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const SelectedFile = styled.div<SelectedFileProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  border: ${(props) => (props.border ? props.border : '2px solid #909090')};
  position: relative;
  width: 110%;
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  margin-bottom: 16px;

  p {
    color: ${(props) => (props.color ? props.color : '#303030')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  }
`
