import { observer } from 'mobx-react'
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Row } from '@tanstack/react-table'

import { StoreContext } from '../../../App'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'
import { NoAction } from '../tableNoAction/TableNoAction.component'

import {
  ActionEdit,
  ActionList,
  Container,
  SingleAction,
  SvgCont,
} from './TableMoreActions.styles'
import { IconMenu2 } from '@tabler/icons-react'
import { Tooltip } from '@mui/material'
import useOutsideClick from '../../../methods/methods/useOutSideClick'

interface Props extends PropsWithChildren {
  actions: Array<{
    title?: string
    component: (props: any) => JSX.Element
  }> | null
  columns: Array<string>
  row: any
  rendered?: boolean
  tableRow?: string
  loaded: boolean
}

interface InnerProps extends PropsWithChildren {
  e: { title?: string; component: (props: object) => JSX.Element }
  row: any
  index: number
  columns: Array<{ header: string; value: string }>
  showActions: boolean
  setShowActions: Dispatch<SetStateAction<boolean>>
  rendered?: boolean
  tableRow?: string
  loaded: boolean
}

const TableMoreSingleOption: FC<InnerProps> = observer(
  ({
    e,
    index,
    row,
    columns,
    showActions,
    setShowActions,
    rendered,
    tableRow,
    loaded,
  }: InnerProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const [active, setActive] = useState(false)

    return (
      <>
        {showActions && (
          <SingleAction
            key={index}
            onClick={() => {
              store.SlidingPanelState.setSelectedSlider(
                `sliding-${e.title}-${index}`
              )
              setShowActions(false)
            }}
            {...Theme.tableMoreActionsStyles.singleAction}
          >
            <p>{e.title}</p>
          </SingleAction>
        )}
      </>
    )
  }
)

const TableMoreSlidingPanel: FC<InnerProps> = observer(
  ({
    e,
    index,
    row,
    columns,
    showActions,
    setShowActions,
    rendered,
    tableRow,
    loaded,
  }: InnerProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const [active, setActive] = useState(false)

    const toggleClick = () => {
      setActive(!active)
    }

    const getTitle = () => {
      if (
        !row.getAllCells().filter((e: any) => e.column.id === 'id') &&
        !row
          .getAllCells()
          .filter((e: any) => e.column.id === 'id')[0]
          .getValue()
      )
        return ''
      if (rendered)
        return (
          <p>{`${e.title}: ${row
            .getAllCells()
            .filter((e: any) => e.column.id === 'ProductCode')[0]
            .getValue()
            .slice(0, 10)}...`}</p>
        )
      if (
        row.getAllCells().filter((e: any) => e.column.id === 'id') &&
        row
          .getAllCells()
          .filter((e: any) => e.column.id === 'id')[0]
          .getValue().length > 10
      ) {
        return (
          <Tooltip
            title={row
              .getAllCells()
              .filter((e: any) => e.column.id === 'id')[0]
              .getValue()}
          >
            <p>{`${e.title}: ${row
              .getAllCells()
              .filter((e: any) => e.column.id === 'id')[0]
              .getValue()
              .slice(0, 10)}...`}</p>
          </Tooltip>
        )
      } else
        return `${e.title}: ${row
          .getAllCells()
          .filter((e: any) => e.column.id === 'id')[0]
          .getValue()}`
    }

    const component = e.component({
      id: index,
      columns: columns,
      toggleClick: toggleClick,
      row: row,
      name: `sliding-${e.title}-${index}`,
      tableRow: tableRow,
    })

    return (
      <>
        <SlidingPanel
          contentId={`sliding-${e.title}-${index}`}
          title={loaded ? getTitle() : ''}
        >
          {component}
        </SlidingPanel>
      </>
    )
  }
)

const TableMoreActions: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const keys = props.columns
  const values = [
    ...keys.map((e, index) => ({
      header: keys[index],
      value: props.row
        .getAllCells()
        .find((cell: any) => cell.column.id === e)
        ?.getValue(),
    })),
  ]
  const [showActions, setShowActions] = useState(false)
  const containerRef = useRef<any>(null)

  useOutsideClick(containerRef, () => setShowActions(false))

  if (props.actions)
    return (
      <>
        <Container ref={containerRef}>
          <SvgCont>
            <IconMenu2
              size='16px'
              onClick={() => setShowActions(!showActions)}
            />
          </SvgCont>
          <ActionList
            sliding={store.SlidingPanelState.visibility}
            showActions={showActions}
            {...Theme.tableMoreActionsStyles.actionList}
          >
            {props.actions?.map((e, index) => (
              <TableMoreSingleOption
                e={e}
                loaded={props.loaded}
                columns={values}
                key={index}
                row={props.row}
                index={parseInt(props.row.id)}
                showActions={showActions}
                setShowActions={setShowActions}
                rendered={props.rendered}
                tableRow={props.tableRow}
              />
            ))}
          </ActionList>
        </Container>
        {props.actions?.map((e, index) => (
          <TableMoreSlidingPanel
            e={e}
            loaded={props.loaded}
            columns={values}
            key={index}
            row={props.row}
            index={parseInt(props.row.id)}
            showActions={showActions}
            setShowActions={setShowActions}
            rendered={props.rendered}
            tableRow={props.tableRow}
          />
        ))}
      </>
    )
  else return <></>
})

export default TableMoreActions
