import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'
import Table from '../../_tableGeneric/table/Table.component'
import ParcelsComponent from './parcels/Parcels.component'
import SenderComponent from './sender/Sender.component'
import RecevierComponent from './receiver/Recevier.component'
import URL from '../../../assets/icons/url.png'
import Edit from '../../../assets/icons/edit.svg'
import TableDate from '../../_tableGeneric/tableDate/TableDate.component'
import { ActiveButton } from '../../_tableGeneric/tableLock/TableLock.styles'
import { NavLink } from 'react-router-dom'
import { Container } from '../../../styles/LayoutStyles'
/* eslint-disable */
export const PackagesTableComponent = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const serviceList = [
    'ambroexpress',
    'deligoo',
    'dhl',
    'dpd',
    'fedex',
    'furgonetka',
    'gls',
    'inpost',
    'meest',
    'orlen',
    'poczta_kurier48',
    'poczta_ekspres24',
    'ups',
    'xpressdelivery',
    'pocztex',
    'pallex',
    'swiatprzesylek',
    'wza',
    'postivo',
  ]

  const stateList = [
    'waiting',
    'ordered',
    'collect-problem',
    'collected',
    'transit',
    'delivery',
    'delivery-problem',
    'delivered',
    'unexpected-situation',
    'canceled',
    'returned',
    'ready-for-transport',
    'waiting-for-receiver',
  ]

  const columns = {
    id: {
      canFilter: false,
      canSort: false,
    },
    user_reference_number: {
      Cell: (cell: any) => (
        <NavLink to={`/customerorders/sklep440800_${cell.getValue()}`}>
          <ActiveButton active={true} {...Theme.tableLockStyles.activeButton}>
            {cell.getValue()}
          </ActiveButton>
        </NavLink>
      ),
      canFilter: true,
      canSort: false,
      editType: 'number',
      canBeNull: false,
    },
    service: {
      canFilter: true,
      canSort: false,
      editType: 'select',
      selectValues: serviceList.map((service) => ({
        value: service.toString(),
        label: service.toString(),
      })),
    },
    state: {
      canFilter: true,
      canSort: false,
      editType: 'select',
      selectValues: stateList.map((state) => ({
        value: state.toString(),
        label: state.toString(),
      })),
    },
    name: {
      canFilter: false,
      canSort: false,
    },
    package_id: {
      canFilter: false,
      canSort: false,
    },
    sender: {
      Cell: (cell: any) => (
        <SenderComponent data={cell.getValue()} secondary={true} />
      ),
      canFilter: false,
      canSort: false,
    },
    pickup: {
      Cell: (cell: any) => (
        <RecevierComponent data={cell.getValue()} secondary={true} />
      ),
      canFilter: false,
      canSort: false,
    },
    receiver: {
      Cell: (cell: any) => (
        <RecevierComponent data={cell.getValue()} secondary={true} />
      ),
      canFilter: false,
      canSort: false,
    },
    parcels: {
      Cell: (cell: any) => (
        <div>
          {cell.getValue() &&
            cell.getValue().map((f: any, i: number) => {
              return <ParcelsComponent data={f} key={i} secondary={true} />
            })}
        </div>
      ),
      canFilter: false,
      canSort: false,
    },
    documents_url: {
      Cell: (cell: any) => (
        <a href={cell.getValue()} target={'_blank'} rel='noreferrer'>
          <img style={{ width: '24px' }} src={URL} />
        </a>
      ),
      canFilter: false,
      canSort: false,
    },
    add_similar_url: {
      Cell: (cell: any) => (
        <a href={cell.getValue()} target={'_blank'} rel='noreferrer'>
          <img style={{ width: '24px' }} src={URL} />
        </a>
      ),
      canFilter: false,
      canSort: false,
    },
    edit_url: {
      Cell: (cell: any) => (
        <a href={cell.getValue()} target={'_blank'} rel='noreferrer'>
          {cell.getValue() ? (
            <img style={{ width: '24px' }} src={Edit} />
          ) : (
            <></>
          )}
        </a>
      ),
      canFilter: false,
      canSort: false,
    },
    datetime_order: {
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: true,
      editType: 'date',
      canSort: false,
    },
    datetime_add: {
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: false,
    },
    datetime_delivery: {
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: false,
      canSort: false,
    },
    delivery_time: {
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: false,
      canSort: false,
    },
  }

  useEffect(() => {
    store.PackagesStore.fetchData()
    return () => store.PackagesStore.clearData()
  }, [])
  return (
    <>
      <Container>
        <Table
          config={{
            tableName: 'packages',
            data: store.PackagesStore.data || [],
            fetchData: () => store.PackagesStore.fetchData(),
            columnHeaders: columns,
            displayColumnVisibility: true,
            allowColumnResize: true,
            displaySearch: true,
            displaySelect: true,
            selectAllFunction: () => store.PackagesStore.selectAllFunction(),
            displayExpand: false,
            displayTooltip: false,
            displayEntry: false,
            displayPaginator: true,
            displayInfinitySwitch: false,
            displayOrdinalList: true,
            allowSort: true,
            bank: false,
            actions: {
              display: true,
              label: locales.tableLabels.packages,
              edit: null,
              create: null,
              remove: null,
              assignTag: null,
              custom: ['getPackageDocuments'],
            },
            actionsMultiple: {
              display: true,
              label: locales.tableLabelsMultiple.packages,
              edit: null,
              create: null,
              remove: null,
              assignTag: false,
              custom: ['exportDocuments', 'copyToClipboard', 'downloadFile'],
            },
            headline: {
              visible: store.Theme.Navigation === 'side',
              title: 'Packages List',
              subTitle: 'Packages List',
            },
            breadcrumb: [],
            loaded: store.PackagesStore.loaded,
          }}
        ></Table>
      </Container>
    </>
  )
})
