import styled, { css } from 'styled-components'
import { darken } from 'polished'
/* eslint-disable */

export const QueryFilterWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
`

export const QueryBuilderWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .queryBuilder {
    width: 100%;
    box-sizing: border-box;
    margin: 0px;
    padding: 20px 16px 20px;
    background: transparent;
  }
`

export const FooterCont = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-left: 52px;
  max-width: 100%;
  flex-wrap: wrap;
`

interface SelectFilterProps {
  selected?: boolean
}

export const SelectButtonCont = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.06));
  max-width: 100%;
  flex-wrap: wrap;
`

export const LeftFilter = styled.button<SelectFilterProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #303030;
  padding: 8px 20px;
  border-radius: 6px 0 0 6px;
  border: 1px solid ${(props) => (!props.selected ? '#575757' : '#D6D6D6')};
  background-color: ${(props) => (!props.selected ? '#F5F5F5' : '#FFFFFF')};
`

export const RightFilter = styled.button<SelectFilterProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #303030;
  padding: 8px 20px;
  border-radius: 0 6px 6px 0;
  border: 1px solid ${(props) => (props.selected ? '#575757' : '#D6D6D6')};
  background-color: ${(props) => (props.selected ? '#F5F5F5' : '#FFFFFF')};
`

export const SelectCont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 50px 30px;
`

// write css with params of styledlabel
const commonStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424242;
  letter-spacing: 0.5px;
`

export const SelectLabel = styled.label`
  margin-top: 24px;

  ${commonStyles};
`

export const StyledParagraph = styled.p`
  ${commonStyles};
`

export const SelectInput = styled.select`
  height: 40px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
`
export const SaveInput = styled.input`
  height: 40px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
`
export const CheckBoxCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  input {
    width: 13px;
    height: 13px;
  }
`
