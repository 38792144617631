import styled from 'styled-components'

export const Container = styled.a`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

interface StyledDotInterface {
  color: string
}

export const StyledDot = styled.div<StyledDotInterface>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 8px;
  margin-top: 4px;
`
export const StyledText = styled.div`
  font-weight: 700;
  text-transform: capitalize;
`
