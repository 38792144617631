import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { darken } from 'polished'

export const Container = styled.div`
  position: absolute;
  top: 53px;
  right: 0;
  background-color: #f5f5f5;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;

  * {
    color: black;
  }

  p,
  a {
    margin-bottom: 4px;
  }
`
export const Image = styled.img`
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-bottom: 12px;
`
export const TitleText = styled.p`
  font-size: 16px;
  font-weight: 700;
`
export const SubTitleText = styled.p``
export const OptionCont = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #909090;
`
export const Option = styled.a`
  color: #909090;
  margin: 0 36px;
`
