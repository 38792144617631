import { ButtonText, Wrap } from './SearchFieldButton.styles'
import { observer } from 'mobx-react'
import Button from '../../button/Button.component'

interface Props {
  text: string
  onClick: () => void
  icon?: JSX.Element
  absoluteWrap?: boolean
}

const SearchFieldButton = observer((props: Props) => {
  if (props.absoluteWrap)
    return (
      <Wrap onClick={props.onClick}>
        <Button
          type={'primary'}
          size={'small'}
          margin={'0 8px 0 0'}
          width={'max-content'}
        >
          {props.icon}
          {props.text}
        </Button>
      </Wrap>
    )
  return (
    <Button
      type={'primary'}
      size={'small'}
      margin={'0 8px 0 0'}
      width={'max-content'}
      onClick={props.onClick}
    >
      {props.icon}
      {props.text}
    </Button>
  )
})
export default SearchFieldButton
