import styled from 'styled-components'

interface LayoutProps {
  type: string | 'top' | 'side'
  active: boolean
  modifyMode: boolean
}

export const OutSideUnauthenticated = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const OutSideLayOut = styled.div`
  display: flex;
`
export const Layout = styled.div<LayoutProps>`
  display: flex;
  flex-direction: ${(props) => (props.type === 'top' ? 'column' : 'row')};
  // set the width of the side bar if modifyMode is active
  width: ${(props) => (props.modifyMode ? 'calc(100% - 300px)' : '100%')};
`

interface ContentProps {
  sidebar: boolean
  expanded: boolean
  modifyMode: boolean
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: clip;
  width: ${(props) =>
    props.modifyMode && props.sidebar ? 'calc(100% - 300px)' : '100%'};

  max-width: ${(props) =>
    props.sidebar
      ? props.expanded
        ? 'calc(100vw - 148px)'
        : 'calc(100vw - 82px)'
      : '100vw'};
`

interface ContainerInterface {
  sideType?: boolean
}

export const Container = styled.main<ContainerInterface>`
  display: flex;
  position: relative;
  width: 100%;
  padding: 1rem;
  flex-direction: column;
  min-height: calc(100vh - 54px);
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: clip;
`
