import { ContainerLayout, PanelLayout } from './ThemeVerify.styles'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef } from 'react'
import { StoreContext } from '../../App'
import {
  ChangesBottom,
  ChangesBottomText,
  ChangesCircle,
  ChangesCont,
  ChangesInner,
  ChangesLeft,
  ChangesLine,
  ChangesRight,
  ChangesTopText,
  Container,
  HeaderTitle,
} from '../../components/actionsHistory/ActionsHistory.styles'
import Change from '../../assets/icons/change.svg'
import Admin from '../admin/admin.component'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'
import {
  ComponentList,
  ComponentWrap,
} from '../../components/reports/calendarComponents/calendarMonth/CalendarMonth.styles'
import CalendarEntry from '../../components/reports/calendarComponents/calendarEntry/CalendarEntry.component'
import DelayedProductEntry from '../../components/reports/calendarComponents/delayedProducyEntry/DelayedProductEntry.component'
import SearchFieldButton from '../../components/_tableGeneric/searchFieldButton/SearchFieldButton.component'
import CopyToClipboard from '../../components/_tableGeneric/copyToClipboard/CopyToClipboard.component'
import { Container as LogoContainer } from '../../components/moodNookLogo/MoodNookLogo.styles'
import {
  Body,
  Header,
  HeaderIcon,
  HeaderIconCont,
  NotesInput,
  Row,
  SecondRowLeft,
  SecondRowRight,
  StyledSelect,
  TableCell,
  TableCont,
  TableHeaderCell,
  TableInputCell,
  TableSecondRow,
  Container as CreateOrderContainer,
} from '../../components/_tableGeneric/createOrderPopup/CreateOrderPopup.styles'
import Cross from '../../assets/icons/cross-white.svg'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import { Tooltip } from '@mui/material'
import {
  DropDownItem,
  HeaderDropDown,
} from '../../components/detailsDropDown/DetailsDropDown.styles'
import {
  Footer,
  InnerCont,
  Container as EditProductContainer,
} from '../../components/editGeneric/EditGeneric.styles'
import {
  ContainerHeader,
  ExitIcon,
  HeaderImg,
  HeaderLeft,
} from '../../components/slidingContainer/SlidingContainer.styles'
import FilterIcon from '../../assets/icons/filter-icon.svg'
import ClearFilters from '../../assets/icons/clear-filters.svg'
import QueryFilter from '../../components/queryFilter/QueryFilter.component'
import { MainTitle } from '../../components/headline/Headline.styles'
import { Button as Navbutton } from '../../components/navButton/NavButton.styles'
import {
  CreateOrderCont,
  LeftInnerPagination,
  LeftInnerPaginationBot,
  LeftInnerPaginationTop,
  OptionsSelect,
  PaginationWrapper,
  RightInnerPagination,
  RowsCount,
  RowsPerPage,
  SelectedCount,
  SelectedParagraph,
  SelectedWrapper,
  StyledCross,
  StyledPaginator,
  StyledPlus,
} from '../../components/_tableGeneric/paginator/Paginator.styles'
import { ReactComponent as CrossWhite } from '../../assets/icons/cross-white.svg'
import PlusWhite from '../../assets/icons/plus-white.svg'
import {
  AddressBody,
  AddressCont,
  HeaderButton,
  HeaderCont,
  HeaderInner,
  HeaderInnerRight,
  HeaderList,
  LeftBody,
  Body as ProformsBody,
  RightBody,
  Container as ProformsDetailsContainer,
  ButtonCont,
} from '../proformsDetails/ProformsDetails.styles'
import { PrintSelectedButton } from '../packingDetails/packingDetails.styles'
import PrintIcon from '../../assets/icons/print-icon.svg'
import DetailsTable from '../../components/detailsTable/DetailsTable.component'
import {
  DropArea,
  ExitCross,
  ExitCrossCont,
  FileNameHeader,
  InnerContDrop,
  SelectedFile,
  StyledCloseCross,
} from '../../components/_tableGeneric/tableBankExports/TableBankExports.styles'
import { FileDrop } from 'react-file-drop'
import { ContButton } from '../../components/_tableGeneric/tableCSV/TableCSV.styles'
import { Entry } from '../../components/_tableGeneric/tableEntry/TableEntry.styles'
import { IconAlertCircle } from '@tabler/icons-react'
import { Displayed } from '../../components/_tableGeneric/tableImage/TableImage.styles'
import { Text } from '../../components/_tableGeneric/tableLock/TableLock.styles'
import {
  ActionList,
  SingleAction,
} from '../../components/_tableGeneric/tableMoreActions/TableMoreActions.styles'
import {
  DataCont,
  DataValue,
} from '../../components/_tableGeneric/tableNotes/TableNotes.styles'
import {
  Bottom,
  Top,
  Container as TableStockContainer,
} from '../../components/_tableGeneric/tableStockInProgress/TableStockInProfress.styles'
import SuppliersTable from '../../components/suppliersTable/SuppliersTable.component'
import { ActiveButton } from '../../components/importSelect/ImportSelect.styles'
import Button from '../../components/button/Button.component'

/* eslint-disable */
export const ThemeVerify = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const pagination = {
    tableSize: 10,
    pageSize: 1,
    pageIndex: 1,
    totalCount: 10,
  }
  const allColumns = [{ id: 'id' }, { id: 'name' }, { id: 'code' }]
  const container = useRef()

  useEffect(() => {
    store.MockStore.fetchAllMockData()
    store.CalendarStore.loadData()
    return () => {
      store.CalendarStore.clearData()
    }
  }, [])

  return (
    <ContainerLayout>
      <PanelLayout id={'buttons'}>
        <Button
          type={'primary'}
          onClick={() => console.log('123')}
          {...Theme.buttons.primaryButton}
        >
          {'Primary'}
        </Button>
        <Button type={'secondary'} {...Theme.buttons.secondaryButton}>
          {'Secondary'}
        </Button>
      </PanelLayout>
      <PanelLayout id={'actionsHistoryStyles'}>
        <Container {...Theme.actionsHistoryStyles.container}>
          <HeaderTitle {...Theme.actionsHistoryStyles.headerTitle}>
            {'Actions History'}
          </HeaderTitle>
          <ChangesCont {...Theme.actionsHistoryStyles.changesCont}>
            {Array(10)
              .fill(0)
              .map((_, index) => (
                <ChangesInner key={index}>
                  <ChangesLeft>
                    <ChangesCircle
                      {...Theme.actionsHistoryStyles.changesCircle}
                    >
                      <img src={Change} alt={'<>'} />
                    </ChangesCircle>
                    <ChangesLine {...Theme.actionsHistoryStyles.changesLine} />
                  </ChangesLeft>
                  <ChangesRight {...Theme.actionsHistoryStyles.changesRight}>
                    <ChangesTopText
                      {...Theme.actionsHistoryStyles.changesTopText}
                    >
                      {'Label'}
                    </ChangesTopText>
                    <ChangesBottom
                      {...Theme.actionsHistoryStyles.changesBottom}
                    >
                      <ChangesBottomText
                        {...Theme.actionsHistoryStyles.changesBottomText}
                      >
                        {new Date().toLocaleString()}
                      </ChangesBottomText>
                      <ChangesBottomText
                        {...Theme.actionsHistoryStyles.changesBottomText}
                      >
                        {'Jan Kowalski'}
                      </ChangesBottomText>
                    </ChangesBottom>
                  </ChangesRight>
                </ChangesInner>
              ))}
          </ChangesCont>
        </Container>
      </PanelLayout>
      <PanelLayout id={'adminStyles'}>
        <Admin />
      </PanelLayout>
      <PanelLayout id={'breadcrumbStyles'}>
        <Breadcrumb treeArray={['themeVerify, test']} />
      </PanelLayout>
      <PanelLayout id={'calendarStyles'}>
        <ComponentWrap bgColor={Theme.calendarStyles.popUpColors.createdOrders}>
          <p>{'Created Orders'}</p>
          <ComponentList>
            <CalendarEntry
              deliveredHover={true}
              color={Theme.calendarStyles.popUpColors.createdOrders}
              entryData={{
                id: 'test',
                supplierOrderId: 'test',
                supplier: 'test',
                status: 'test',
                estimateDate: 'test',
                creationDate: 'test',
                products: {
                  test: {
                    ProductID: 'test',
                    Name: 'test',
                    OrderedQuantity: 'test',
                    DeliveredQuantity: 'test',
                    ProductCode: 'test',
                  },
                },
              }}
            />
          </ComponentList>
        </ComponentWrap>
        <ComponentWrap
          bgColor={Theme.calendarStyles.popUpColors.estimatedDeliveryOrders}
        >
          <p>{'Estimated Delivery Orders'}</p>
          <ComponentList>
            <CalendarEntry
              deliveredHover={true}
              color={Theme.calendarStyles.popUpColors.estimatedDeliveryOrders}
              entryData={{
                id: 'test',
                supplierOrderId: 'test',
                supplier: 'test',
                status: 'test',
                estimateDate: 'test',
                creationDate: 'test',
                products: {
                  test: {
                    ProductID: 'test',
                    Name: 'test',
                    OrderedQuantity: 'test',
                    DeliveredQuantity: 'test',
                    ProductCode: 'test',
                  },
                },
              }}
            />
          </ComponentList>
        </ComponentWrap>
        <ComponentWrap
          bgColor={Theme.calendarStyles.popUpColors.firstProductDelivered}
        >
          <p>{'First Product Delivered from Order'}</p>
          <ComponentList>
            <CalendarEntry
              deliveredHover={true}
              color={Theme.calendarStyles.popUpColors.firstProductDelivered}
              entryData={{
                id: 'test',
                supplierOrderId: 'test',
                supplier: 'test',
                status: 'test',
                estimateDate: 'test',
                creationDate: 'test',
                products: {
                  test: {
                    ProductID: 'test',
                    Name: 'test',
                    OrderedQuantity: 'test',
                    DeliveredQuantity: 'test',
                    ProductCode: 'test',
                  },
                },
              }}
            />
          </ComponentList>
        </ComponentWrap>
        <ComponentWrap
          bgColor={Theme.calendarStyles.popUpColors.lastProductDelivered}
        >
          <p>{'Last Product Delivered from Order'}</p>
          <ComponentList>
            <CalendarEntry
              deliveredHover={true}
              color={Theme.calendarStyles.popUpColors.lastProductDelivered}
              entryData={{
                id: 'test',
                supplierOrderId: 'test',
                supplier: 'test',
                status: 'test',
                estimateDate: 'test',
                creationDate: 'test',
                products: {
                  test: {
                    ProductID: 'test',
                    Name: 'test',
                    OrderedQuantity: 'test',
                    DeliveredQuantity: 'test',
                    ProductCode: 'test',
                  },
                },
              }}
            />
          </ComponentList>
        </ComponentWrap>
        <ComponentWrap
          bgColor={Theme.calendarStyles.popUpColors.deliveredProducts}
        >
          <p>{'Delivered Products'}</p>
          <ComponentList>
            <DelayedProductEntry
              color={Theme.calendarStyles.popUpColors.deliveredProducts}
              delayedProductData={{
                ProductID: 'test',
                Name: 'test',
                OrderedQuantity: 'test',
                DeliveredQuantity: 'test',
              }}
              displayOrder={true}
            />
          </ComponentList>
        </ComponentWrap>
        <ComponentWrap
          bgColor={Theme.calendarStyles.popUpColors.estimatedDeliveryProducts}
        >
          <p>{'Estimated deliveries'}</p>
          <ComponentList>
            <DelayedProductEntry
              color={Theme.calendarStyles.popUpColors.estimatedDeliveryProducts}
              delayedProductData={{
                ProductID: 'test',
                Name: 'test',
                OrderedQuantity: 'test',
                DeliveredQuantity: 'test',
              }}
              displayOrder={true}
            />
          </ComponentList>
        </ComponentWrap>
        <ComponentWrap
          bgColor={Theme.calendarStyles.popUpColors.delayedProducts}
        >
          <p>{'Delayed Products'}</p>
          <ComponentList>
            <DelayedProductEntry
              color={Theme.calendarStyles.popUpColors.delayedProducts}
              delayedProductData={{
                ProductID: 'test',
                Name: 'test',
                OrderedQuantity: 'test',
                DeliveredQuantity: 'test',
              }}
              displayOrder={true}
            />
          </ComponentList>
        </ComponentWrap>
      </PanelLayout>
      <PanelLayout id={'columnVisibilityStyles'}>
        {/*<ColumnVisibility
          getToggleHideAllColumnsProps={(props = {}) => {
            return props
          }}
          allColumns={[
            {
              id: 'example',
              isVisible: true,
              render: () => <div>Example</div>,
              totalLeft: 0,
              totalWidth: 100,
              getToggleHiddenProps: () => {
                return {}
              },
            },
            {
              id: 'example2',
              isVisible: true,
              render: () => <div>Example</div>,
              totalLeft: 0,
              totalWidth: 100,
              getToggleHiddenProps: () => {
                console.log('123')
                return {}
              },
            },
            {
              id: 'example3',
              isVisible: true,
              render: () => <div>Example</div>,
              totalLeft: 0,
              totalWidth: 100,
              getToggleHiddenProps: () => {
                console.log('123')
                return {}
              },
            },
          ]}
        />*/}
      </PanelLayout>
      <PanelLayout id={'contButton'}>
        <SearchFieldButton
          text={'example'}
          onClick={() => console.log('test')}
        />
      </PanelLayout>
      <PanelLayout id={'copyToClipboard'}>
        <CopyToClipboard />
      </PanelLayout>
      <PanelLayout id={'createOrderPopupStyles'}>
        <ScrollSync>
          <CreateOrderContainer {...Theme.createOrderPopupStyles.container}>
            <TableCont>
              <HeaderIconCont>
                <HeaderTitle>{locales.buttons.createPurchaseOrder}</HeaderTitle>
                <HeaderIcon src={Cross} alt={'X'} />
              </HeaderIconCont>
              <ScrollSyncPane>
                <Header>
                  <Tooltip title={'No:'} placement='bottom' arrow>
                    <TableHeaderCell>{'No:'}</TableHeaderCell>
                  </Tooltip>
                  <Tooltip title={'Title'} placement='bottom' arrow>
                    <TableHeaderCell>{'Cell value'}</TableHeaderCell>
                  </Tooltip>
                  <Tooltip title={'Notes'} placement='bottom' arrow>
                    <TableHeaderCell>{'Notes'}</TableHeaderCell>
                  </Tooltip>
                  <Tooltip title={'Purchase Price'} placement='bottom' arrow>
                    <TableHeaderCell>{'Purchase Price'}</TableHeaderCell>
                  </Tooltip>
                  <Tooltip title={'Order'} placement='bottom' arrow>
                    <TableHeaderCell>{'Order'}</TableHeaderCell>
                  </Tooltip>
                </Header>
              </ScrollSyncPane>
              <Body>
                <ScrollSyncPane key={`tablerow-1`}>
                  <Row
                    odd={false}
                    lastChild={false}
                    {...Theme.createOrderPopupStyles.row}
                  >
                    <TableCell>{'1'}</TableCell>
                    <Tooltip key={`tablecell`} title={'Column'}>
                      <TableCell>{'test'}</TableCell>
                    </Tooltip>
                    <TableCell>
                      <input type='text' value={'test'} />
                    </TableCell>
                    <TableInputCell active={true}>
                      <input type='number' step='0.01' value={2} />
                    </TableInputCell>
                    <TableInputCell active={true}>
                      <input type='number' value={3} />
                    </TableInputCell>
                  </Row>
                </ScrollSyncPane>
                <ScrollSyncPane key={`tablerow-2`}>
                  <Row
                    odd={true}
                    lastChild={true}
                    {...Theme.createOrderPopupStyles.row}
                  >
                    <TableCell>{'2'}</TableCell>
                    <Tooltip key={`tablecell`} title={'Column'}>
                      <TableCell>{'test'}</TableCell>
                    </Tooltip>
                    <TableCell>
                      <input type='text' value={'test'} />
                    </TableCell>
                    <TableInputCell active={true}>
                      <input type='number' step='0.01' value={2} />
                    </TableInputCell>
                    <TableInputCell active={true}>
                      <input type='number' value={3} />
                    </TableInputCell>
                  </Row>
                </ScrollSyncPane>
              </Body>
            </TableCont>
            <TableSecondRow>
              <SecondRowLeft>
                <div>
                  <b>Products count: 2 Orders count: 3</b>
                </div>
                <div>
                  <p>{'Notes: '}</p>
                  <NotesInput value={'test'} />
                </div>
              </SecondRowLeft>
              <SecondRowRight>
                <div>
                  <p>{'Currency:'}</p>
                  <StyledSelect active={true}>
                    <option value={'Click to select!'}>
                      {'Click to select!'}
                    </option>
                    <option key={`option-currency-pln`} value={'PLN'}>
                      {'PLN'}
                    </option>
                  </StyledSelect>
                </div>
                <div>
                  <p>{'Supplier:'}</p>
                  <StyledSelect active={true}>
                    <option value={'Click to select!'}>
                      {'Click to select!'}
                    </option>
                  </StyledSelect>
                </div>
                <Button type={'primary'} {...Theme.buttons.primaryButton}>
                  {locales.buttons.createPurchaseOrder}
                </Button>
              </SecondRowRight>
            </TableSecondRow>
          </CreateOrderContainer>
        </ScrollSync>
      </PanelLayout>
      <PanelLayout id={'detailsDropDownStyles'}>
        <HeaderDropDown {...Theme.proformsDetailsStyles.headerDropDown}>
          <DropDownItem {...Theme.proformsDetailsStyles.dropDownItem}>
            {'Test Message'}
          </DropDownItem>
          <DropDownItem {...Theme.proformsDetailsStyles.dropDownItem}>
            {'Test Message 2'}
          </DropDownItem>
          <DropDownItem {...Theme.proformsDetailsStyles.dropDownItem}>
            {'Test Message 3'}
          </DropDownItem>
        </HeaderDropDown>
      </PanelLayout>
      <PanelLayout id={'editProductsStyles'} style={{ marginTop: '200px' }}>
        <div>
          <ContainerHeader>
            <HeaderLeft>
              <HeaderImg src={FilterIcon} />
              <HeaderTitle>{'Header Title'}</HeaderTitle>
            </HeaderLeft>
            <button>
              <ExitIcon src={ClearFilters} alt='close' />
            </button>
          </ContainerHeader>
          <EditProductContainer
            style={{ marginTop: '20px', height: '600px' }}
            {...Theme.editProductsStyles.container}
          >
            <InnerCont cols={1}></InnerCont>
            <Footer {...Theme.editProductsStyles.styledFooter}>
              <Button type={'secondary'}>{'CANCEL'}</Button>
              <Button type={'primary'}>{'SAVE'}</Button>
            </Footer>
          </EditProductContainer>
        </div>
      </PanelLayout>
      <PanelLayout id={'filtersStyles'}>
        <QueryFilter
          tableName={'test'}
          table={[]}
          setLoaded={() => {}}
          allColumns={[]}
          fetchData={() => console.log('data')}
        />
      </PanelLayout>
      <PanelLayout id={'headlineStyles'}>
        <MainTitle {...Theme.headlineStyles.mainTitle}>
          {'Example Main Title'}
        </MainTitle>
      </PanelLayout>
      <PanelLayout id={'moodNookLogo'}>
        <LogoContainer
          style={{ width: '160px' }}
          {...Theme.moodNookLogo.container}
        >
          <svg
            version='1.1'
            id='Warstwa_1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 246.6 177.9'
          >
            <g>
              <path
                className='st0'
                fill='#E0FBFC'
                d='M246.6,177.9H0V0h246.6V177.9z M14.2,163.8h218.2V14.2H14.2V163.8z'
              />
            </g>
            <g>
              <path
                className='st0'
                fill='#E0FBFC'
                d='M29.8,120.4V79.9h10.5l19.3,24.8h0.1V79.9h10.5v40.5H59.7L40.4,95.6h-0.1v24.8H29.8z'
              />
              <path
                className='st0'
                fill='#E0FBFC'
                d='M88.2,81.1c3.4-1.7,7.1-2.6,11-2.6c3.9,0,7.6,0.9,11,2.6c3.4,1.7,6.2,4.2,8.3,7.4c2.1,3.2,3.2,6.8,3.2,10.8
		c0,6.6-2.1,11.9-6.3,16.1c-4.2,4.2-9.6,6.3-16.1,6.3c-6.5,0-11.9-2.1-16.1-6.3c-4.2-4.2-6.3-9.5-6.3-16.1c0-4,1.1-7.6,3.2-10.8
		C82,85.4,84.8,82.9,88.2,81.1z M107.2,92c-2.3-2.1-4.9-3.2-8.1-3.2s-5.8,1.1-8.1,3.2c-2.3,2.1-3.4,4.6-3.4,7.5
		c0,3.4,1.1,6.3,3.3,8.6c2.2,2.3,4.9,3.5,8.1,3.5c3.2,0,5.9-1.2,8.1-3.5c2.2-2.3,3.3-5.2,3.3-8.6C110.6,96.6,109.5,94.1,107.2,92z'
              />
              <path
                className='st0'
                fill='#E0FBFC'
                d='M137.5,81.1c3.4-1.7,7.1-2.6,11-2.6s7.6,0.9,11,2.6c3.4,1.7,6.2,4.2,8.3,7.4c2.1,3.2,3.2,6.8,3.2,10.8
		c0,6.6-2.1,11.9-6.3,16.1c-4.2,4.2-9.6,6.3-16.1,6.3c-6.5,0-11.9-2.1-16.1-6.3c-4.2-4.2-6.3-9.5-6.3-16.1c0-4,1.1-7.6,3.2-10.8
		C131.3,85.4,134.1,82.9,137.5,81.1z M156.5,92c-2.3-2.1-4.9-3.2-8.1-3.2s-5.8,1.1-8.1,3.2c-2.3,2.1-3.4,4.6-3.4,7.5
		c0,3.4,1.1,6.3,3.3,8.6c2.2,2.3,4.9,3.5,8.1,3.5c3.2,0,5.9-1.2,8.1-3.5c2.2-2.3,3.3-5.2,3.3-8.6C159.9,96.6,158.8,94.1,156.5,92z'
              />
              <path
                className='st0'
                fill='#E0FBFC'
                d='M188.1,96.6l12.7-16.7h13.1l-16.1,19.2l17.6,21.3h-13.6l-13.6-17.6H188v17.6h-10.5V79.9H188v16.7H188.1z'
              />
            </g>
            <g>
              <path
                className='st0'
                fill='#E0FBFC'
                d='M30,70.1l6.9-40.5h10.4l8.2,21.7l8.7-21.7h10.5l6.1,40.5H70.3l-3-23.3h-0.1l-9.7,23.3h-4.2L44,46.7h-0.1
		l-3.4,23.3H30z'
              />
              <path
                className='st0'
                fill='#E0FBFC'
                d='M94.7,30.8c3.4-1.7,7.1-2.6,11-2.6c3.9,0,7.6,0.9,11,2.6c3.4,1.7,6.2,4.2,8.3,7.4c2.1,3.2,3.2,6.8,3.2,10.8
		c0,6.6-2.1,11.9-6.3,16.1c-4.2,4.2-9.6,6.3-16.1,6.3c-6.5,0-11.9-2.1-16.1-6.3c-4.2-4.2-6.3-9.5-6.3-16.1c0-4,1.1-7.6,3.2-10.8
		C88.5,35,91.3,32.6,94.7,30.8z M113.7,41.6c-2.3-2.1-4.9-3.2-8.1-3.2s-5.8,1.1-8.1,3.2c-2.3,2.1-3.4,4.6-3.4,7.5
		c0,3.4,1.1,6.3,3.3,8.6c2.2,2.3,4.9,3.5,8.1,3.5c3.2,0,5.9-1.2,8.1-3.5c2.2-2.3,3.3-5.2,3.3-8.6C117.1,46.3,115.9,43.8,113.7,41.6z
		'
              />
              <path
                className='st0'
                fill='#E0FBFC'
                d='M142.3,30.8c3.4-1.7,7.1-2.6,11-2.6s7.6,0.9,11,2.6c3.4,1.7,6.2,4.2,8.3,7.4c2.1,3.2,3.2,6.8,3.2,10.8
		c0,6.6-2.1,11.9-6.3,16.1c-4.2,4.2-9.6,6.3-16.1,6.3c-6.5,0-11.9-2.1-16.1-6.3c-4.2-4.2-6.3-9.5-6.3-16.1c0-4,1.1-7.6,3.2-10.8
		C136.2,35,138.9,32.6,142.3,30.8z M161.3,41.6c-2.3-2.1-4.9-3.2-8.1-3.2s-5.8,1.1-8.1,3.2c-2.3,2.1-3.4,4.6-3.4,7.5
		c0,3.4,1.1,6.3,3.3,8.6c2.2,2.3,4.9,3.5,8.1,3.5c3.2,0,5.9-1.2,8.1-3.5c2.2-2.3,3.3-5.2,3.3-8.6C164.7,46.3,163.6,43.8,161.3,41.6z
		'
              />
              <path
                className='st0'
                fill='#E0FBFC'
                d='M180.6,70.1V29.6h14.9c5.7,0,10.5,2,14.5,5.9c4,3.9,6,8.7,6,14.4c0,5.7-2,10.5-6,14.4c-4,3.9-8.9,5.9-14.5,5.9
		H180.6z M191.1,38.5v22.7h2.4c3.4,0,6.2-0.9,8.4-2.8c2.2-1.9,3.3-4.7,3.3-8.5c0-3.5-1.1-6.3-3.2-8.3c-2.1-2-4.9-3-8.4-3H191.1z'
              />
            </g>
          </svg>
        </LogoContainer>
      </PanelLayout>
      <PanelLayout id={'navButtonStyles'}>
        <Navbutton {...Theme.navButtonStyles.button}>{'Button'}</Navbutton>
      </PanelLayout>
      <PanelLayout id={'paginatorStyles'} style={{ flexDirection: 'column' }}>
        <PaginationWrapper {...Theme.paginatorStyles.paginationWrapper}>
          <LeftInnerPagination>
            <LeftInnerPaginationTop>
              <StyledPaginator
                count={Math.ceil(pagination.tableSize / pagination.pageSize)}
                page={pagination.pageIndex}
                showFirstButton
                showLastButton
                size={'medium'}
                {...Theme.paginatorStyles.styledPaginator}
              />
            </LeftInnerPaginationTop>
            <LeftInnerPaginationBot>
              <SelectedCount>
                {(pagination.pageIndex - 1) * pagination.pageSize + 1}-
                {pagination.pageIndex * pagination.pageSize}{' '}
                {locales.pagination.of} {pagination.tableSize}{' '}
                {locales.pagination.entries}
                {store.TableStore.appliedQuery.rules.length
                  ? ` (Filtered from ${pagination.totalCount})`
                  : ''}
              </SelectedCount>
            </LeftInnerPaginationBot>
          </LeftInnerPagination>
          <RightInnerPagination>
            <RowsPerPage {...Theme.paginatorStyles.rowsPerPage}>
              {locales.pagination.showing}
            </RowsPerPage>
            <OptionsSelect value={10} {...Theme.paginatorStyles.optionsSelect}>
              {[8, 10, 20, 50, 100, 200, 500, 1000].map((pageSize) => (
                <option
                  key={pageSize}
                  id={pageSize.toString()}
                  value={pageSize}
                >
                  {pageSize}
                </option>
              ))}
            </OptionsSelect>
          </RightInnerPagination>
        </PaginationWrapper>
        <SelectedWrapper
          style={{ position: 'relative' }}
          {...Theme.paginatorStyles.selectedWrapper}
        >
          <StyledCross>
            <CrossWhite />
          </StyledCross>
          <SelectedParagraph {...Theme.paginatorStyles.selectedParagraph}>
            {'Selected: '}
            <RowsCount>{store.TableStore.selectedRowId.length}</RowsCount>
          </SelectedParagraph>
          <SelectedParagraph {...Theme.paginatorStyles.selectedParagraph}>
            {'Selected subrows: '}
            <RowsCount>{store.TableStore.selectedExpanded.length}</RowsCount>
          </SelectedParagraph>
          <CreateOrderCont {...Theme.paginatorStyles.selectedParagraph}>
            <StyledPlus src={PlusWhite} /> {locales.buttons.createPurchaseOrder}
          </CreateOrderCont>
          <CreateOrderCont {...Theme.paginatorStyles.selectedParagraph}>
            Export Bank Transaction
          </CreateOrderCont>

          <CreateOrderCont {...Theme.paginatorStyles.selectedParagraph}>
            {`${locales.buttons.editSelected} ${locales.buttons.supplierProducts}`}
          </CreateOrderCont>

          <CreateOrderCont {...Theme.paginatorStyles.selectedParagraph}>
            {`${locales.buttons.editSelected} ${
              locales.tableNames[store.RouteStore.currentPage]
            }`}
          </CreateOrderCont>
        </SelectedWrapper>
      </PanelLayout>
      <PanelLayout
        id={'proformsDetailsStyles'}
        style={{ flexDirection: 'column' }}
      >
        <ProformsDetailsContainer
          loading={true}
          {...Theme.proformsDetailsStyles.container}
        >
          <HeaderCont>
            <HeaderList>
              <HeaderInner>
                <HeaderTitle {...Theme.proformsDetailsStyles.headerTitle}>
                  {'Order: 1234'}
                </HeaderTitle>
                <ButtonCont>
                  <HeaderButton {...Theme.proformsDetailsStyles.headerButton}>
                    <img src={PrintIcon} alt={'Print'} />
                    {locales.buttons.print}
                  </HeaderButton>
                  <PrintSelectedButton
                    {...Theme.proformsDetailsStyles.headerButton}
                  >
                    <img src={PrintIcon} alt={'Print'} />
                    {locales.buttons.printWithoutData}
                  </PrintSelectedButton>
                </ButtonCont>
              </HeaderInner>
              <HeaderInnerRight>
                {/*<SecondaryButton
                      bgColor={'green'}
                      {...Theme.proformsDetailsStyles.headerButtonProps}
                      {...Theme.proformsDetailsStyles.secondaryButton}
                    >
                      <p>
                        locales.buttons.setPriority
                      </p>
                      <StyledImg src={ArrowExpanded} alt={'->'} />
                        <DetailsDropDown active={true} table={PriorityList} />
                    </SecondaryButton>*/}
              </HeaderInnerRight>
            </HeaderList>
          </HeaderCont>
          <ProformsBody className={'hide-print'}>
            <LeftBody>
              <AddressCont>
                <AddressBody>
                  <DetailsTable
                    title={locales.headers.deliveryAddress}
                    columns={[
                      { header: locales.address.firstname, value: 'firstname' },
                      { header: locales.address.lastname, value: 'lastname' },
                      { header: locales.address.company, value: 'company' },
                      { header: locales.address.city, value: 'city' },
                      { header: locales.address.postcode, value: 'postcode' },
                      { header: locales.address.street1, value: 'street1' },
                      { header: locales.address.street2, value: 'street2' },
                      { header: locales.address.country, value: 'country' },
                    ]}
                    data={store.TableStore.detailsData.customer || {}}
                    showArrow={true}
                  />
                  <DetailsTable
                    title={'System'}
                    columns={[
                      { header: 'source system order number', value: 'pageId' },
                      { header: 'source system', value: 'type' },
                      { header: 'source system instance', value: 'url' },
                      { header: 'domain', value: 'domain' },
                    ]}
                    data={store.TableStore.detailsData.system || {}}
                    showArrow={true}
                  />
                </AddressBody>
              </AddressCont>
            </LeftBody>
            <RightBody></RightBody>
          </ProformsBody>
        </ProformsDetailsContainer>
      </PanelLayout>
      <PanelLayout
        id={'tableBankExportsStyles'}
        style={{ flexDirection: 'column', width: '500px' }}
      >
        <InnerContDrop {...Theme.tableBankExportsStyles.innerContDrop}>
          <SelectedFile {...Theme.tableBankExportsStyles.selectedFile}>
            <FileNameHeader>
              <p>{'File name'}</p>
              <StyledCloseCross src={Cross} alt={'X'} />
            </FileNameHeader>
            <p>{'Wybierz typ pliku:'}</p>
          </SelectedFile>
          <ExitCrossCont>
            <ExitCross src={Cross} alt={'X'} />
          </ExitCrossCont>
          <FileDrop>
            <DropArea {...Theme.tableBankExportsStyles.dropArea}>
              DRAG FILE HERE OR BROWSE
            </DropArea>
          </FileDrop>
          <Button type={'primary'}>{'Prześlij'}</Button>
        </InnerContDrop>
      </PanelLayout>
      <PanelLayout id={'tableCSVStyles'}>
        <ContButton {...Theme.tableCSVStyles.contButton}>
          {locales.buttons.downloadFile}
        </ContButton>
      </PanelLayout>
      <PanelLayout id={'tableDocumentsStyles'}>
        <ContButton {...Theme.tableCSVStyles.contButton}>
          {locales.buttons.downloadFile}
        </ContButton>
      </PanelLayout>
      <PanelLayout id={'tableEntryStyles'}>
        <div style={{ display: 'flex' }}>
          <IconAlertCircle size='18px' color={'low'} />
          <Entry {...Theme.tableEntryStyles.entry}>{'entry'}</Entry>
        </div>
      </PanelLayout>
      <PanelLayout id={'tableImageStyles'}>
        <Displayed
          style={{ position: 'relative' }}
          {...Theme.tableImageStyles.displayed}
        >
          <img
            src={
              'https://sklep440800.shoparena.pl/userdata/public/gfx/34889.jpg'
            }
            //src={props.url[Math.abs(displayedIndex % props.url.length)]}
            alt={'image'}
            loading='lazy'
          />
        </Displayed>
      </PanelLayout>
      <PanelLayout id={'tableLockStyles'}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ActiveButton
            style={{ width: '90px' }}
            active={true}
            {...Theme.tableLockStyles.activeButton}
          >
            {locales.buttons.view}
          </ActiveButton>
          <Text {...Theme.tableLockStyles.text}>
            {locales.buttons.lockedBy}
          </Text>
          <Text {...Theme.tableLockStyles.text}>{'Jan Kowalski'}</Text>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '250px' }}
        >
          <ActiveButton
            style={{ width: '90px' }}
            active={false}
            {...Theme.tableLockStyles.activeButton}
          >
            {locales.buttons.locked}
          </ActiveButton>
          <Text {...Theme.tableLockStyles.text}>
            {locales.buttons.lockedBy}
          </Text>
          <Text {...Theme.tableLockStyles.text}>{'Jan Kowalski'}</Text>
        </div>
      </PanelLayout>
      <PanelLayout id={'tableMoreActionsStyles'}>
        <ActionList
          style={{ position: 'relative' }}
          sliding={store.SlidingPanelState.visibility}
          showActions={true}
          {...Theme.tableMoreActionsStyles.actionList}
        >
          <SingleAction {...Theme.tableMoreActionsStyles.singleAction}>
            <p>{'Action 1'}</p>
          </SingleAction>
          <SingleAction {...Theme.tableMoreActionsStyles.singleAction}>
            <p>{'Action 2'}</p>
          </SingleAction>
          <SingleAction {...Theme.tableMoreActionsStyles.singleAction}>
            <p>{'Action 3'}</p>
          </SingleAction>
        </ActionList>
      </PanelLayout>
      <PanelLayout id={'tableNotesStyles'}>
        <DataCont>
          <DataValue {...Theme.tableNotesStyles.dataValue}>
            {'Estimated: 4'}
          </DataValue>
          <DataValue {...Theme.tableNotesStyles.dataValue}>
            {'Delieveried: 4'}
          </DataValue>
          <DataValue {...Theme.tableNotesStyles.dataValue}>
            {'Notes: Notes'}
          </DataValue>
        </DataCont>
      </PanelLayout>
      <PanelLayout id={'tableStockInProgressStyles'}>
        <TableStockContainer
          style={{ width: '250px' }}
          {...Theme.tableStockInProgressStyles.container}
        >
          <Top {...Theme.tableStockInProgressStyles.top}>
            <p>0</p> from 1223
          </Top>
          <Bottom>{'11-12-2023'}</Bottom>
          <Bottom>{'Sent to supplier (3)'}</Bottom>
        </TableStockContainer>
      </PanelLayout>
      <PanelLayout id={'tableStyles'}>
        <SuppliersTable mockData={true} />s
      </PanelLayout>
    </ContainerLayout>
  )
})
export default ThemeVerify
