import React, { useContext } from 'react'
import { StoreContext } from '../../../../App'
import { observer } from 'mobx-react'
import { Container, DayContainer } from './DelayedProductEntry.styles'
import { Tooltip } from '@mui/material'

/* eslint-disable */
interface DelayedProduct {
  ProductID?: string
  Name?: string
  SupplierDeliveryDate?: string
  SupplierOrderId?: string
  OrderedQuantity?: string
  DeliveredQuantity?: string
}

interface DelayedProductEntryProps {
  delayedProductData: DelayedProduct
  displayOrder?: boolean
  color: string
}

const DelayedProductEntry = observer(
  ({ delayedProductData, color, displayOrder }: DelayedProductEntryProps) => {
    const store = useContext(StoreContext)
    const locales = store.TranslationsState.translations
    const view = store.CalendarStore.view

    const {
      Name,
      SupplierOrderId,
      OrderedQuantity,
      DeliveredQuantity,
      ProductID,
      SupplierDeliveryDate,
    } = delayedProductData

    switch (view) {
      case 'month':
      case 'week':
      case 'day':
        return (
          <Tooltip title={`${SupplierOrderId}: ${ProductID}, ${Name}`}>
            <Container color={color}>
              {`${OrderedQuantity}x: ${ProductID ? ProductID : 'Unnamed'} ${
                displayOrder
                  ? `, ${OrderedQuantity} from ${SupplierOrderId}`
                  : ''
              }`}
            </Container>
          </Tooltip>
        )
      /*case 'week':
              return (
                <Container color={color}>
                  <div>{`${ProductID ? ProductID : 'Unnamed'}`}</div>
                  <div>Delayed Date: {SupplierDeliveryDate}</div>
                </Container>
              )
            case 'day':
              return (
                <DayContainer color={color}>
                  <div>{ProductID ? ProductID : 'Unnamed'}</div>
                  <div>Product: {Name}</div>
                  <div>Delayed Date: {SupplierDeliveryDate}</div>
                </DayContainer>
              )*/
      default:
        return <></>
    }
  },
)

export default DelayedProductEntry
