import { action, makeAutoObservable, toJS } from 'mobx'
import { mainTheme } from '../static/themes/main.theme'
import { RootStore } from './Root.store'
import axios from '../axiosconfig'

/* eslint-disable */
export class StorybookStore {
  rootStore
  Theme: any = {}

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound UpdateTheme = (string1: string, string2: string, object) => {
    this.Theme[string1] = {
      ...(this.Theme[string1] || {}),
      [string2]: object,
    }

    this.Theme = {
      ...this.Theme,
    }

    this.rootStore.Theme.Theme = {
      ...this.rootStore.Theme.Theme,
      ...this.Theme,
    }
  }
}
