import { observer } from 'mobx-react'
import { FC } from 'react'
import { Container } from './TableObjectDisplay.styles'

interface TableObjectDisplayProps {
  data: Record<string, any>
}

const TableObjectDisplay: FC<TableObjectDisplayProps> = observer(({ data }) => {
  const renderValue = (value: any) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No'
    }
    if (typeof value === 'object' && value !== null) {
      return (
        <div>
          {Object.entries(value).map(([subKey, subValue]) => (
            <div key={subKey}>
              <strong>{subKey}:</strong> {String(subValue)}
            </div>
          ))}
        </div>
      )
    }
    return value !== null ? String(value) : 'N/A'
  }

  return (
    <Container>
      {Object.entries(data).map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong> {renderValue(value)}
        </div>
      ))}
    </Container>
  )
})

export default TableObjectDisplay
