import { GenericTreeComponent } from './genericTree/GenericTree.component'
import SearchTagNewComponent from './tags/edits/searchTagNew/SearchTagNew.component'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import { StoreContext } from '../../App'
import { LanguageBasedCell } from './tags/edits/languageBasedCell/LanguageBasedCell.component'
import { ObjectDisplayCont } from './tags/Tags.styles'
import { observer } from 'mobx-react'

/* eslint-disable */
const TagTreeGeneric = observer(() => {
  const store = useContext(StoreContext)

  const columns = {
    id: {
      canFilter: false,
      canSort: false,
      editable: false,
    },
    name: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    root_id: {
      canFilter: false,
      canSort: false,
      editable: false,
      mandatory: false,
      editType: 'rootSearch',
    },
    colour: {
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'color',
    },
    priority: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'number',
      canBeNull: false,
    },
    parent: {
      canFilter: false,
      canSort: false,
      editable: false,
      mandatory: false,
      editType: 'tagSearch',
    },
    tag_size: {
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'select',
      selectValues: [
        { value: 'default', label: 'default' },
        { value: 'big', label: 'big' },
        { value: 'small', label: 'small' },
      ],
    },
    tag_icon: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'select',
      selectValues: store.TagsStore.avaliableIcons.map((icon) => ({
        value: icon,
        label: icon,
      })),
    },
    description: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    path: {
      canFilter: false,
      canSort: false,
      editable: false,
      Cell: (cell: any) => (
        <ObjectDisplayCont>
          {cell.getValue() &&
            Object.entries(cell.getValue()).map(([header, value], index) => (
              <div key={`${index}-value-name`}>{`${header}: ${value}`}</div>
            ))}
        </ObjectDisplayCont>
      ),
    },
  }

  return (
    <GenericTreeComponent
      columns={columns}
      data={store.TagsStore.data}
      setData={() => {
        store.TagsStore.setView('root')
        store.TagsStore.fetchData()
      }}
      mainDesc={'Tag'}
      lang={'EN'}
      firstLevelChild={'tags'}
      secondLevelChild={'children'}
      firstLevelParent={'root_id'}
      secondLevelParent={'parent'}
      baseUrl={`${process.env.REACT_APP_BASE_URL}experience/v1/tag/`}
      searchComponent={(applySearch: any) => (
        <SearchTagNewComponent
          active={true}
          applySearch={applySearch}
          view={'tag'}
          header={'Search Tag'}
        />
      )}
      dropFunction={async (
        currentTagData: any,
        draggedTagData: any,
        firstLevel: boolean,
      ) => {
        await store.TagsStore.setTreeLoaded(false)
        const data = {
          parent: !firstLevel ? currentTagData.id : '',
        }
        toast('Tag moved successfully.')
        await store.TagsStore.editTag(data, draggedTagData.id, 'tag')
        await store.TagsStore.fetchData()
        await store.TagsStore.setTreeLoaded(true)
      }}
      dropPlusFunction={async (currentTagData: any, draggedTagData: any) => {
        await store.TagsStore.setTreeLoaded(false)
        await store.TagsStore.insertTag(
          draggedTagData.id,
          currentTagData.parent,
          currentTagData.id,
          {},
        )
        await store.TagsStore.fetchData()
        await store.TagsStore.setTreeLoaded(true)
      }}
      addExistingLeftFunction={async (
        currentId: string,
        newId: string,
        parentId: string,
      ) => {
        await store.TagsStore.insertTag(newId, parentId, currentId, {})
      }}
      addExistingRightFunction={async (currentId: string, newId: string) => {
        const data = {
          parent: currentId,
        }
        await store.TagsStore.editTag(data, newId, 'tag')
      }}
      addNewLeftFunction={async (
        formData: object,
        rootId: string,
        parentId: string,
        id: string,
      ) => {
        formData = {
          ...formData,
          parent: parentId ? parentId : '',
          root_id: rootId,
        }
        await store.TagsStore.insertTag(
          '_create',
          parentId ? parentId : '',
          id ? id : '',
          formData,
        )
      }}
      addNewRightFunction={async (
        formData: object,
        id: string,
        rootId: string,
      ) => {
        formData = {
          ...formData,
          parent: id,
          root_id: rootId,
        }
        await store.TagsStore.addTag(formData, 'tag')
      }}
    />
  )
})
export default TagTreeGeneric
