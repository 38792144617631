import React from 'react'
import styled from 'styled-components'

interface ContainerInterface {
  active: boolean
}

const Container = styled.div<ContainerInterface>`
  display: flex;
  flex-wrap: wrap;
  width: 230px;
  margin-left: -8px;
  margin-top: 4px;
  opacity: ${(props) => (props.active ? 1 : 0)};
`
const ColorField = styled.div`
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 2px 4px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`

interface PredefinedColorProps {
  onSelectColor: (color: string) => void
  active: boolean
}

export const PredefinedColors: React.FC<PredefinedColorProps> = ({
  onSelectColor,
  active,
}) => {
  const colors = [
    '#FFFFFF', // White
    '#000000', // Black
    '#FF0000', // Red
    '#00FF00', // Lime
    '#0000FF', // Blue
    '#FFFF00', // Yellow
    '#FFA500', // Orange
    '#800080', // Purple
    '#00FFFF', // Aqua/Cyan
    '#FFC0CB', // Pink
    '#808080', // Gray
    '#008000', // Green
    '#000080', // Navy
    '#800000', // Maroon
    '#FF00FF', // Fuchsia/Magenta
    '#C0C0C0', // Silver
  ]

  return (
    <Container active={active}>
      {colors.map((color) => (
        <ColorField
          key={color}
          style={{ backgroundColor: color }}
          onClick={() => onSelectColor(color)}
        />
      ))}
    </Container>
  )
}
