import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../../App'
import { Container, Day } from './CalendarWeek.styles'
import CalendarEntry from '../calendarEntry/CalendarEntry.component'
import { CreateOrders, CreateProducts } from '../calendar/Calendar.methods'
import { HeaderDay, HeaderWeek } from '../calendarMonth/CalendarMonth.styles'

const CalendarWeek = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  const currentDate = new Date(store.CalendarStore.currentDate)
  currentDate.setHours(0, 0, 0, 0) // ensures we're at the start of the day

  const startOfWeekDate = new Date(currentDate)
  startOfWeekDate.setDate(startOfWeekDate.getDate() - startOfWeekDate.getDay())

  const weekDaysArr = Array.from({ length: 7 }, (_, i) => {
    const day = new Date(startOfWeekDate)
    day.setDate(day.getDate() + i)
    return day
  })
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wedesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  return (
    <>
      <HeaderWeek>
        {days.map((day) => (
          <HeaderDay key={day} today={false}>
            {day}
          </HeaderDay>
        ))}
      </HeaderWeek>
      <Container>
        {weekDaysArr.map((day, index) => (
          <Day key={index}>
            {day.getDate() ? (
              <>
                <p>{day.getDate()}</p>
                {store.CalendarStore.visibility.createdOrders ? (
                  <CreateOrders
                    title={'Created Orders:'}
                    color={'#cdaa6e'}
                    dataGetter={() =>
                      store.CalendarStore.getOrderedDataForDate(
                        new Date(
                          currentDate.getFullYear(),
                          currentDate.getMonth(),
                          day.getDate() as number,
                        ),
                      )
                    }
                  />
                ) : (
                  <></>
                )}
                {store.CalendarStore.visibility.estimatedOrders ? (
                  <CreateOrders
                    title={'Estimated Delivery Orders:'}
                    color={'blue'}
                    dataGetter={() =>
                      store.CalendarStore.getDataForDate(
                        new Date(
                          currentDate.getFullYear(),
                          currentDate.getMonth(),
                          day.getDate() as number,
                        ),
                      )
                    }
                  />
                ) : (
                  <></>
                )}
                {store.CalendarStore.visibility.deliveredOrders ? (
                  <CreateOrders
                    title={'Delivered Orders:'}
                    color={'#234F1e'}
                    deliveredHover={true}
                    dataGetter={() =>
                      store.CalendarStore.getDeliveredDataForDate(
                        new Date(
                          currentDate.getFullYear(),
                          currentDate.getMonth(),
                          day.getDate() as number,
                        ),
                      )
                    }
                  />
                ) : (
                  <></>
                )}
                {store.CalendarStore.visibility.deliveredProducts ? (
                  <CreateProducts
                    title={'Delivered Products:'}
                    color={'green'}
                    dataGetter={() =>
                      store.CalendarStore.getDeliveredProductsForDate(
                        new Date(
                          currentDate.getFullYear(),
                          currentDate.getMonth(),
                          day.getDate() as number,
                        ),
                      )
                    }
                  />
                ) : (
                  <></>
                )}
                {store.CalendarStore.visibility.estimatedProducts ? (
                  <CreateProducts
                    title={'Estimated deliveries:'}
                    color={'blue'}
                    displayOrder={true}
                    dataGetter={() =>
                      store.CalendarStore.getEstimatedProductsForDate(
                        new Date(
                          currentDate.getFullYear(),
                          currentDate.getMonth(),
                          day.getDate() as number,
                        ),
                      )
                    }
                  />
                ) : (
                  <></>
                )}
                {store.CalendarStore.visibility.delayedProducts ? (
                  <CreateProducts
                    title={'Delayed Products:'}
                    color={'red'}
                    displayOrder={true}
                    dataGetter={() =>
                      store.CalendarStore.getDelayedProductsForDate(
                        new Date(
                          currentDate.getFullYear(),
                          currentDate.getMonth(),
                          day.getDate() as number,
                        ),
                      )
                    }
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Day>
        ))}
      </Container>
    </>
  )
})

export default CalendarWeek
