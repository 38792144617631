import { Container, StyledDot, StyledText } from './TableReturnStatus.styles'
import { observer } from 'mobx-react'

interface TableCompleteableInterface {
  cell: any
}

const TableReturnStatus = observer(({ cell }: TableCompleteableInterface) => {
  const value = cell.getValue()
  const id = cell.row
    .getAllCells()
    .find((e: any) => e.column.id === 'id')
    .getValue()

  const getColor = () => {
    switch (value) {
      case 'rejected':
        return 'rgba(0,255,0,1)'
      case 'pending':
        return 'rgba(242,242,0,1)'
      case 'accepted':
        return 'rgba(255,0,0,1)'
      default:
        return 'transparent'
    }
  }
  return (
    <Container href={`/returns/${id}`}>
      <StyledDot color={getColor()}></StyledDot>
      <StyledText>{value}</StyledText>
    </Container>
  )
})
export default TableReturnStatus
