import { StoreContext } from '../../../App'

import { mainTheme as theme } from '../../../static/themes/main.theme'

import {
  CopyRightMark,
  LeftInnerPagination,
  LeftInnerPaginationBot,
  LeftInnerPaginationTop,
  OptionsSelect,
  PaginationWrapper,
  RightInnerPagination,
  RowsPerPage,
  SelectedCount,
  StyledPaginator,
} from './Paginator.styles'

import { observer } from 'mobx-react'
import { ChangeEvent, useContext } from 'react'
import useWindowDimensions from '../../../methods/hooks/useWindowDimensions'

/* eslint-disable */
interface PaginatorProps {
  tableName: string
  loaded: boolean
  infinity: boolean
  fetchData: () => void
  setLoaded?: (val: boolean) => void
}

const Paginator = observer(
  ({ tableName, loaded, setLoaded, infinity, fetchData }: PaginatorProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations
    const pagination = store.PaginationStore.pagination[tableName]
    const { width } = useWindowDimensions()
    const handlePageChange = async (val: number) => {
      if (setLoaded) await setLoaded(false)
      await store.PaginationStore.setPagination(
        tableName,
        pagination.tableSize,
        pagination.pageSize,
        val,
        pagination.totalCount,
      )
      await fetchData()
    }

    const handlePageSize = async (e: ChangeEvent<HTMLSelectElement>) => {
      if (setLoaded) await setLoaded(false)
      if (e.target.value === locales.buttons.selectAll) {
        await store.PaginationStore.setPagination(
          tableName,
          pagination.tableSize,
          pagination.tableSize,
          1,
          pagination.totalCount,
        )
      }
      await store.PaginationStore.setPagination(
        tableName,
        pagination.tableSize,
        parseInt(e.target.value),
        1,
        pagination.totalCount,
      )
      await fetchData()
    }

    return (
      <>
        {!infinity && (
          <PaginationWrapper {...Theme.paginatorStyles.paginationWrapper}>
            <LeftInnerPagination>
              <LeftInnerPaginationTop>
                {loaded && pagination ? (
                  <StyledPaginator
                    count={Math.ceil(
                      pagination.tableSize / pagination.pageSize,
                    )}
                    page={pagination.pageIndex}
                    onChange={(e, val) => handlePageChange(val)}
                    showFirstButton
                    showLastButton
                    size={width < 600 ? 'small' : 'medium'}
                    {...Theme.paginatorStyles.styledPaginator}
                  />
                ) : (
                  <></>
                )}
              </LeftInnerPaginationTop>
              <LeftInnerPaginationBot>
                {loaded && pagination ? (
                  <SelectedCount>
                    {(pagination.pageIndex - 1) * pagination.pageSize + 1}-
                    {pagination.pageIndex * pagination.pageSize}{' '}
                    {locales.pagination.of} {pagination.tableSize}{' '}
                    {locales.pagination.entries}
                    {store.TableStore.appliedQuery.rules.length
                      ? ` (Filtered from ${pagination.totalCount})`
                      : ''}
                  </SelectedCount>
                ) : (
                  <></>
                )}
              </LeftInnerPaginationBot>
            </LeftInnerPagination>
            <RightInnerPagination>
              <RowsPerPage {...theme.paginatorStyles.rowsPerPage}>
                {locales.pagination.showing}
              </RowsPerPage>
              {loaded && pagination ? (
                <OptionsSelect
                  value={pagination.pageSize}
                  onChange={(e) => handlePageSize(e)}
                  {...theme.paginatorStyles.optionsSelect}
                >
                  {[8, 10, 20, 50, 100, 200, 500, 1000].map((pageSize) => (
                    <option
                      key={pageSize}
                      id={pageSize.toString()}
                      value={pageSize}
                    >
                      {pageSize}
                    </option>
                  ))}
                </OptionsSelect>
              ) : (
                <></>
              )}
            </RightInnerPagination>
          </PaginationWrapper>
        )}
        <CopyRightMark>
          © {new Date().getFullYear()} - MoodNook Packing App
        </CopyRightMark>
      </>
    )
  },
)

export default Paginator
