import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../App'
import {
  DeleteButton,
  Input,
  ArrayRow,
  ArrayContainer,
  MainContainer,
  SingleInputCont,
} from './TimeZone.styles'
import { InputLabel } from '../../dataInputGeneric/DataInputGeneric.styles'
import ClearFilters from '../../../assets/icons/clear-filters.svg'
import Button from '../../button/Button.component'

interface StringArrayInputProps {
  data: { id: string; offset: string; abbr: string }[]
  handleChange: (
    form: Array<{ id: string; offset: string; abbr: string }>,
  ) => void
}

const ArrayObjectInput = ({ data, handleChange }: StringArrayInputProps) => {
  const store = useContext(StoreContext)
  const [inputData, setInputData] = useState(data || [])

  const handleInputChange = (i: number, key: string, value: string) => {
    const newData = inputData.map((item, idx) =>
      idx === i ? { ...item, [key]: value } : item,
    )
    setInputData(newData)
  }

  const deleteField = (i: number) =>
    setInputData(inputData.filter((_, idx) => idx !== i))

  const addNewField = () =>
    setInputData([...inputData, { id: '', offset: '', abbr: '' }])

  useEffect(() => {
    handleChange(inputData)
  }, [inputData])

  return (
    <MainContainer>
      <ArrayContainer>
        {inputData.map((item, i) => (
          <ArrayRow key={i}>
            <SingleInputCont>
              <InputLabel>id</InputLabel>
              <Input
                type={'text'}
                value={item.id}
                activeStyle={true}
                onChange={(e: any) =>
                  handleInputChange(i, 'id', e.target.value)
                }
              />
            </SingleInputCont>
            <SingleInputCont>
              <InputLabel>offset</InputLabel>
              <Input
                type='text'
                activeStyle={true}
                value={item.offset}
                onChange={(e) => handleInputChange(i, 'offset', e.target.value)}
              />
            </SingleInputCont>
            <SingleInputCont>
              <InputLabel>Abbr</InputLabel>
              <Input
                type='text'
                activeStyle={true}
                value={item.abbr}
                onChange={(e) => handleInputChange(i, 'abbr', e.target.value)}
              />
            </SingleInputCont>
            <DeleteButton onClick={() => deleteField(i)}>
              <img src={ClearFilters} alt='Clear' />
            </DeleteButton>
          </ArrayRow>
        ))}
      </ArrayContainer>
      <Button type={'primary'} onClick={addNewField} margin={'16px'}>
        + Add new
      </Button>
    </MainContainer>
  )
}

export default ArrayObjectInput
