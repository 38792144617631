import styled from 'styled-components'
import { breakpoints } from '../../../static/breakpoints'

/* eslint-disable */

export const Wrap = styled.div`
  position: absolute;
  z-index: 1;
  right: 90px;

  @media screen and (max-width: ${breakpoints.tablet}) {
    right: 20px;
    transform: translateY(-30px);
  }
`
export const ButtonText = styled.p`
  margin-top: 4px;
  margin-bottom: 4px;
  white-space: nowrap;
`
