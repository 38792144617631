import Switch from 'react-switch'
import styled from 'styled-components'

export const MainCont = styled.section`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

interface TableContProps {
  fontSize?: string
  lineHeight?: string
  trackBgColor?: string
  trackBgRadius?: string
  thumbBgColor?: string
  thumbBgRadius?: string
  thumbBorder?: string
}

/* eslint-disable */
export const TableCont = styled.div<TableContProps>`
  color: ${(props) => (props.color ? props.color : '#111827')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.75rem')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1rem')};
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  @media print {
    margin-top: 40px;
    page-break-before: always;
    height: max-content !important;
  }

  &.no-print {
    @media print {
      margin-top: 40px;
      page-break-before: avoid;
      min-height: 500px;
      height: max-content;
    }
  }
`

/* eslint-enable */

interface SwitchStyledProps {
  fontSize?: string
  lineHeight?: string
}

export const SwitchStyled = styled.div<SwitchStyledProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.875rem')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.25rem')};
  margin-top: 4px;
  margin-left: 12px;

  div {
    margin-bottom: 4px;
  }
`
export const UpperCont = styled.div`
  display: flex;

  @media screen and (max-width: 520px) {
    margin-top: 16px;
    margin-left: auto;
  }
`
export const TableOptionsCont = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding-right: 6px;
  cursor: pointer;

  img {
    height: 25px;
    width: 25px;
  }
`

export const SwitchCont = styled.div`
  display: flex;
  margin-bottom: 15px !important;
  margin-left: 4px;
  margin-right: 4px;
`
export const SwitchButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > * {
    margin: 2px;
  }
`
export const SwitchInput = styled(Switch)`
  margin-right: 4px;
  margin-left: 4px;
`
export const SwitchInputText = styled.p`
  margin-right: 8px;
  margin-left: 4px;
`

interface MobileSwitchStyledProps {
  padding?: string
  borderRadius?: string
  border?: string
  margin?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
}

export const MobileSwitchStyled = styled.button<MobileSwitchStyledProps>`
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  margin: 0 4px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '20px')};
  border: ${(props) => (props.border ? props.border : '1px solid #303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '12px'};
  height: 28px;
  white-space: nowrap;
  align-items: center;

  svg {
    margin-right: 4px;
  }

  p {
    color: ${(props) => (props.color ? props.color : '#303030')};
  }

  @media screen and (min-width: 520px) {
    display: none !important;
  }
`
export const MobileBreakLine = styled.div`
  position: relative;
  width: 90%;
  height: 2px;
  background-color: #707070;
  border-radius: 1px;
`

interface RowWrapperInterface {
  expanded: boolean
}

export const TableRowWrapper = styled.div<RowWrapperInterface>`
  border: ${(props) => (props.expanded ? '2px solid black' : 'none')};

  > :hover {
    background-color: #e8e8e8;
  }
`

/*eslint-disable */

interface RowInnerPropsInterface {
  select?: boolean
  odd?: boolean
  oddBg?: string
  paddingTop?: string
  paddingBottom?: string
  selectBgHover?: string
  oddBgHover?: string
  bgColorHover?: string
  lastChild?: boolean
  trackBgColor?: string
  trackBgRadius?: string
  thumbBgColor?: string
  thumbBgRadius?: string
  thumbBorder?: string
}

export const TableRow = styled.div<RowInnerPropsInterface>`
  border-bottom-width: 1px;
  display: flex;
  width: 100%;
  overflow-x: auto;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 46px;
  background-clip: padding-box;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '4px')};
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : '4px'};
  background-color: ${(props) =>
    props.select
      ? 'rgba(221,175,77,0.4)'
      : props.odd
        ? props.oddBg // eslint-disable-line no-use-before-define
        : 'transparent'}; // eslint-disable-line no-use-before-define

  scrollbar-width: ${(props) => (props.lastChild ? 'auto' : 'none')};

  @media print {
    overflow-x: visible;
  }

  ::-webkit-scrollbar {
    display: ${(props) => (props.lastChild ? 'block' : 'none')};
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.trackBgColor ? props.trackBgColor : '#aaaaaa'};
    border-radius: ${(props) =>
      props.trackBgRadius ? props.trackBgRadius : '3px'};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.thumbBgColor ? props.thumbBgColor : '#303030'};
    border-radius: ${(props) =>
      props.thumbBgRadius ? props.thumbBgRadius : '6px'};
    border: ${(props) =>
      props.thumbBorder ? props.thumbBorder : '3px solid #aaaaaa'};
  }
`
/*eslint-enable */
export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  flex-grow: 1;
  position: relative;
  width: 100%;
`

interface TableFooterWrapInterface {
  selected: boolean
}

export const TableFooterWrap = styled.div<TableFooterWrapInterface>`
  display: flex;
  flex-direction: column;
  bottom: ${(props) => (props.selected ? '45px' : '0px')};
  flex-grow: 1;
  z-index: 1;
  position: sticky !important;
  width: 100%;
  overflow: hidden;
`
export const TableFooter = styled.div`
  background-clip: padding-box;
  border-bottom-width: 1px;
  position: relative;
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 4px;
  padding-bottom: 4px;
  background-repeat: repeat;

  div {
    min-width: 60px;
    flex-shrink: 0;
  }

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: #aaaaaa;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #303030;
    border-radius: 6px;
    border: 3px solid #aaaaaa;
  }
`
export const SearchSwitchWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 1 auto;
  @media print {
    display: none;
  }

  @media screen and (max-width: 520px) {
    flex-direction: column;
  }
`
export const QueryRulesCont = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    line-height: 12px;
    color: #303030;
  }
`
