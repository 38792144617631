/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { hiddenColumns } from '../../columnsConfig'

import { Container, Footer, InnerCont } from './EditRenderedProduct.styles'

import { StoreContext } from '../../App'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import { socket } from '../../socket/socket'
import Button from '../button/Button.component'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  row: any
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  name: string
  tableRow: string
}

const EditProducts: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps,
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const { width, height } = useWindowDimensions()
  const [isAbleToSave, setIsAbleToSave] = useState(false)
  const [triggerChange, setTriggerChange] = useState(false)
  const divRef = useRef<any>(null)
  const columns = props.columns.filter((e) => !hiddenColumns.includes(e.header))
  const initialState = [
    ...columns.map((e) => {
      return {
        header: e.header,
        value: e.value,
        error: '',
        rules: ['required', 'length'],
        isActive: false,
      }
    }),
  ]
  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => {
    setForm([...tempForm])
  }

  const handleSave = async () => {
    let fetchObject: { [key: string]: string | object } = {
      id: props.tableRow,
    }

    let singleProduct = {}

    form.map((e) => {
      if (e.isActive)
        singleProduct = {
          [e.header]: e.value,
          ...singleProduct,
        }
    })
    const code = props.row
      .getAllCells()
      .find((e: any) => e.column.id === 'ProductCode')
      ?.getValue()

    fetchObject = {
      products: { [code]: singleProduct },
      ...fetchObject,
    }

    store.SlidingPanelState.setVisibility(false)
    const formattedProduct = store.TableStore.formatRequestData(fetchObject)
    await store.TableStore.updateData(formattedProduct)

    socket.emit('editOrder', {
      id: props.tableRow,
      user: store.UserStore.user,
      page: store.TableStore.getApiRequestFromUrl(),
    })

    store.TableStore.setSelectedExpanded([
      { rowId: props.tableRow, values: singleProduct },
    ])
    setTimeout(async () => {
      await store.TableStore.fetchData()
    }, 3000)
  }

  useEffect(() => {
    setInputColumns(Math.ceil((90 * columns.length) / (height * 0.8)))
  }, [height])

  useEffect(() => {
    setIsAbleToSave(form.some((item) => item.isActive))
  }, [form])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont ref={divRef} cols={inputColumns}>
        {columns
          ? columns.map((e, index) => {
              const customOptions = props.row.getAllCells().find(
                (f: any) => f.column.id === e.header,
                //@ts-ignore
              )?.column.columnDef.editCustomOptions
              if (customOptions.editable && e.header !== 'equal')
                return (
                  <DataInputGeneric
                    type={'edit'}
                    vals={e}
                    editOptions={customOptions}
                    key={`data-input--${index}`}
                    index={index}
                    form={form}
                    setForm={handleSetForm}
                    triggerChange={() => setTriggerChange(!triggerChange)}
                  />
                )
            })
          : null}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={async () => {
            if (isAbleToSave) await handleSave()
          }}
          active={isAbleToSave}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}
export default EditProducts
