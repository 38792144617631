import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext } from 'react'
import { StoreContext } from '../../../App'
import { Container, Text } from './TableCompareValues.styles'

interface Props extends PropsWithChildren {
  value: string
  fromValue: string
}

const TableDifferentValues: FC<Props> = observer(
  ({ value, fromValue }: Props) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme

    return (
      <Container active={parseInt(value) > parseInt(fromValue)}>
        <Text {...Theme.tableLabelStyles.text}>{value}</Text>
      </Container>
    )
  },
)
export default TableDifferentValues
