import styled, { css } from 'styled-components'
import { darken } from 'polished'

interface ButtonBaseProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  color?: string
  margin?: string
  width?: string
  size?: 'small' | 'medium' | 'large'
  isLoading?: boolean
  active?: boolean
}

const getSizeStyles = (size: string) => {
  switch (size) {
    case 'small':
      return `
        padding: 4px 8px;
        font-size: 10px;
        p {
          font-size: 10px;
        }
      `
    case 'large':
      return `
        padding: 10px 20px;
        font-size: 16px;
        p {
          font-size: 16px;
        }
      `
    default: // medium
      return `
        padding: 8px 16px;
        font-size: 14px;
        p {
          font-size: 14px;
        }
      `
  }
}

const loadingStyles = css<ButtonBaseProps>`
  opacity: ${(props) => (props.isLoading || !props.active ? 0.5 : 1)};
  pointer-events: ${(props) =>
    props.isLoading || !props.active ? 'none' : 'auto'};

  img {
    margin-right: 8px;
  }
`

export const PrimaryButton = styled.button<ButtonBaseProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  ${(props) => getSizeStyles(props.size || 'medium')}
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => props.width || 'auto'};
  background-color: ${(props) => props.bgColor || '#303030'};
  color: ${(props) => props.color || '#fafafa'} !important;

  border-radius: ${(props) => props.borderRadius || '6px'};
  font-weight: ${(props) => props.fontWeight || 600};
  line-height: ${(props) => props.lineHeight || '16px'};
  letter-spacing: ${(props) => props.letterSpacing || '0.5px'};
  text-transform: uppercase;
  transition:
    background-color 0.3s,
    transform 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => darken(0.1, props.bgColor || '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }

  ${loadingStyles}
`

export const SecondaryButton = styled(PrimaryButton)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`

export const TertiaryButton = styled(PrimaryButton)`
  background-color: ${(props) => props.bgColor || '#926f34'};
  color: ${(props) => props.color || '#ffffff'};

  &:hover {
    background-color: #6d5731;
    transform: scale(1.02);
  }
`
