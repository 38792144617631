interface tableConfigType {
  [key: string]: {
    ColumnVisibility: boolean
    AllowColumnResize: boolean
    DisplaySearch: boolean
    DisplaySelect: boolean
    DisplayExpand: boolean
    DisplayTooltip: boolean
    DisplayEntry: boolean
    DisplayPaginator: boolean
    AllowSort: boolean
    BankFeatures: boolean
    headLine: {
      visible: boolean
      title: string
      subTitle: string
    }
  }
}

export const tableConfig: tableConfigType = {
  customerorders: {
    ColumnVisibility: true,
    AllowColumnResize: true,
    DisplaySearch: true,
    DisplaySelect: true,
    DisplayExpand: false,
    DisplayTooltip: true,
    DisplayEntry: true,
    DisplayPaginator: true,
    AllowSort: true,
    BankFeatures: false,
    headLine: {
      visible: true,
      title: 'Customer Orders',
      subTitle: 'List of Customer Orders',
    },
  },
  stock: {
    ColumnVisibility: true,
    AllowColumnResize: true,
    DisplaySearch: true,
    DisplaySelect: true,
    DisplayExpand: false,
    DisplayTooltip: false,
    DisplayEntry: false,
    DisplayPaginator: true,
    AllowSort: true,
    BankFeatures: false,
    headLine: {
      visible: true,
      title: 'Stock',
      subTitle: 'List of Stocked Products',
    },
  },
  supplierorders: {
    ColumnVisibility: true,
    AllowColumnResize: true,
    DisplaySearch: true,
    DisplaySelect: true,
    DisplayExpand: true,
    DisplayTooltip: false,
    DisplayEntry: false,
    DisplayPaginator: true,
    AllowSort: true,
    BankFeatures: false,
    headLine: {
      visible: true,
      title: 'Supplier Orders',
      subTitle: 'List of supplier Orders',
    },
  },
  bank: {
    ColumnVisibility: true,
    AllowColumnResize: true,
    DisplaySearch: true,
    DisplaySelect: true,
    DisplayExpand: false,
    DisplayTooltip: false,
    DisplayEntry: false,
    DisplayPaginator: true,
    AllowSort: true,
    BankFeatures: true,
    headLine: {
      visible: true,
      title: 'Bank Transactions',
      subTitle: 'List of bank transactions',
    },
  },
  packing: {
    ColumnVisibility: true,
    AllowColumnResize: true,
    DisplaySearch: true,
    DisplaySelect: true,
    DisplayExpand: false,
    DisplayTooltip: true,
    DisplayEntry: true,
    DisplayPaginator: true,
    AllowSort: true,
    BankFeatures: false,
    headLine: {
      visible: true,
      title: 'Packing',
      subTitle: 'List of packing orders in queue',
    },
  },
}
