import styled from 'styled-components'
import { darken } from 'polished'
import { breakpoints } from '../../static/breakpoints'

/* eslint-disable */
interface InnerContInterface {
  cols: number
}

export const InnerCont = styled.div<InnerContInterface>`
  height: 80vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  column-count: ${(props) => props.cols};
  width: max-content;
  max-width: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: #aaaaaa;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #303030;
    border-radius: 6px;
    border: 3px solid #aaaaaa;
  }

  @media screen and (max-width: ${breakpoints.vertical}) {
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    column-count: 1;
    overflow: auto;
    scrollbar-width: thin;
  }
`

interface FooterProps {
  bgColor?: string
  borderRadius?: string
}

export const Footer = styled.div<FooterProps>`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0 0 4px 4px'};
`
