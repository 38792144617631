/* eslint-disable */
import React, {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Container, InnerCont } from './actionButton.styles'

import { StoreContext } from '../../App'
import { observer } from 'mobx-react'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import Button from '../button/Button.component'

export interface ActionButtonProps extends PropsWithChildren {}

const ActionButton: (props: ActionButtonProps) => JSX.Element = observer(
  ({}: ActionButtonProps) => {
    const store = useContext(StoreContext)
    const locales = store.TranslationsState.translations

    const { Theme } = store.Theme
    const [selected, setSelected] = useState<null | string>(null)

    const addNewPackage = async () => {
      console.log(store.RouteStore.currentDataId)
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/package/${store.RouteStore.currentDataId}`
      const data = {}
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      await axios.post(url, data, config)
      await toast('Package Added', { type: 'success' })
      await store.TableStore.fetchDetailsData(store.RouteStore.currentDataId)
      await store.SlidingPanelState.setVisibility(false)
      await store.TableStore.setLoaded(true)
    }

    useEffect(() => {
      setSelected(null)
    }, [store.SlidingPanelState.visibility])

    const renderContent = () => {
      switch (selected) {
        case 'newPackage':
          return <div></div>
        case 'existing':
          return <div></div>
        case 'copy':
          return <div></div>
        default:
          return (
            <InnerCont cols={1}>
              <p> Select action: </p>
              <Button
                type={'primary'}
                onClick={() => {
                  addNewPackage()
                }}
              >
                {`${locales.buttons.addNew} ${locales.tableNames.packages}`}
              </Button>
            </InnerCont>
          )
      }
    }

    return (
      <Container {...Theme.editProductsStyles.container}>
        {renderContent()}
      </Container>
    )
  },
)

export default ActionButton
