import React, { PropsWithChildren, FC, useContext, useEffect } from 'react'
/* eslint-disable */
import {
  Container,
  StyledButton,
  StyledImage,
  StyledInput,
} from './TableReturnsCounter.styles'
import { StoreContext } from '../../../App'
import { observer } from 'mobx-react'
import Minus from '../../../assets/icons/minus.svg'
import Plus from '../../../assets/icons/plus-return.svg'
import { toJS } from 'mobx'

interface Props extends PropsWithChildren {
  cell: any
}

const TableReturnsCounter: FC<Props> = observer(({ cell }: Props) => {
  const store = useContext(StoreContext)
  const code = cell.row
    .getAllCells()
    .find((e: any) => e.column.id === 'code')
    ?.getValue()

  const product: any = store.ReturnsStore.detailsData?.products?.find(
    (e) => e.code === code,
  )

  const selected = store.TableStore.selectedRowId.includes(code)

  useEffect(() => {
    console.log(toJS(store.ReturnsStore.selectedProducts))
  }, [store.ReturnsStore.selectedProducts, store.TableStore.selectedRowId])

  return (
    <Container selected={selected}>
      <StyledButton
        onClick={() => {
          console.log(toJS(store.ReturnsStore.selectedProducts))
          if (selected)
            store.ReturnsStore.setSelectedProducts(
              product.code,
              Math.max(
                0,
                store.ReturnsStore.selectedProducts[product.code] - 1,
              ),
            )
        }}
      >
        <StyledImage src={Minus} alt={'-'} />
      </StyledButton>

      <StyledInput
        type='number'
        value={
          store.ReturnsStore.selectedProducts[product.code] !== undefined
            ? store.ReturnsStore.selectedProducts[product.code]
            : product.amount
        }
        min={0}
        max={product.amount}
        onChange={(e) =>
          selected &&
          store.ReturnsStore.setSelectedProducts(
            product.code,
            Math.min(product.amount, +e.target.value),
          )
        }
        disabledSelected={false}
      />

      <StyledButton
        onClick={() =>
          selected &&
          store.ReturnsStore.setSelectedProducts(
            product.code,
            Math.min(
              product.amount,
              store.ReturnsStore.selectedProducts[product.code] + 1,
            ),
          )
        }
      >
        <StyledImage src={Plus} alt={'+'} />
      </StyledButton>
    </Container>
  )
})
export default TableReturnsCounter
