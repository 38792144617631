import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useEffect } from 'react'

import { StoreContext } from '../../../App'
import {
  AllSelectCheckbox,
  IndeterminateCheckbox,
  PageSelectCheckbox,
} from '../../checkboxSelect/CheckboxSelect.component'

import {
  HeaderCheckboxCont,
  HeaderCheckboxes,
  HeaderCheckboxParagraph,
  HeaderInnerCheckBox,
} from './TableHeaderCheckboxes.styles'
import All from '../../../assets/icons/all.svg'
import Page from '../../../assets/icons/page.svg'
import { toast } from 'react-toastify'

interface Props extends PropsWithChildren {
  data: any[]
  actions?: {
    display: boolean
    label: string
    edit: string | null
    create: string | null
    remove: string | null
    assignTag: string | null
    custom: any[]
  } | null
  selectAllFunction?: () => Promise<void>
}

const TableHeaderCheckboxes: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  return (
    <HeaderCheckboxes {...Theme.tableStyles.headerCheckboxes}>
      <HeaderInnerCheckBox>
        {props.selectAllFunction ? (
          <HeaderCheckboxCont>
            <AllSelectCheckbox
              selectAllFunction={props.selectAllFunction}
              allSelected={store.TableStore.allSelectedRows}
            />
            <HeaderCheckboxParagraph
              {...Theme.tableStyles.headerCheckboxParagraph}
            >
              <img src={All} />
            </HeaderCheckboxParagraph>
          </HeaderCheckboxCont>
        ) : (
          <></>
        )}
        <HeaderCheckboxCont>
          <PageSelectCheckbox data={props.data} />
          <HeaderCheckboxParagraph
            {...Theme.tableStyles.headerCheckboxParagraph}
          >
            <img src={Page} />
          </HeaderCheckboxParagraph>
        </HeaderCheckboxCont>
      </HeaderInnerCheckBox>
    </HeaderCheckboxes>
  )
})
export default TableHeaderCheckboxes
