import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { StoreContext } from '../../../../App'
import { Bottom, Container, Top } from './Sender.styles'

/* eslint-disable */
interface Props {
  data: {
    name?: string
    company?: string
    street?: string
    postcode?: string
    city?: string
    email?: string
    phone?: string
  }
  secondary?: boolean
}

const SenderComponent: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  return (
    <Container /*{...Theme.tableStockInProgressStyles.container}*/>
      <Top {...Theme.tableStockInProgressStyles.top}>
        <p>{props.data.name}</p>
      </Top>
      <Bottom>
        <p>{props.data.company}</p>
        <p>{props.data.street}</p>
        <p>{`${props.data.postcode} ${props.data.city}`}</p>
        <p>{props.data.email}</p>
        <p>{props.data.phone}</p>
      </Bottom>
    </Container>
  )
})
export default SenderComponent
