/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { hiddenColumns, ValidateArray } from '../../columnsConfig'

import { Container, Footer, InnerCont } from './EditGeneric.styles'

import { StoreContext } from '../../App'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import axios from '../../axiosconfig'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'
import { toJS } from 'mobx'
import Button from '../button/Button.component'

export interface EditProductsProps extends PropsWithChildren {
  tableName: string
  row: any
  url: string
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  name: string
  setLoaded?: (loaded: boolean) => void
  fetchData: () => void
}

export const formatEntryValue = (type: string, value: any) => {
  switch (type) {
    case 'number':
      return parseInt(value)
    case 'date': {
      if (value === null) {
        return null
      }
      // Attempt to parse the value as a date, considering different formats
      if (/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
        const [day, month, year] = value.split('.')
        return `${year}-${month}-${day}`
      }
      const parsedDate = new Date(value)

      if (!isNaN(parsedDate.getTime())) {
        // Ensure the output is in the format 'YYYY-MM-DD' for 'date' input fields
        const year = parsedDate.getFullYear()
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
        const day = String(parsedDate.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
      }

      // If the value is not a valid date, return as-is
      return value
    }

    case 'datetime-local': {
      if (value === null) {
        return null
      }
      // Attempt to parse the value as a date-time
      const parsedDateTime = new Date(value)

      if (!isNaN(parsedDateTime.getTime())) {
        const year = parsedDateTime.getFullYear()
        const month = String(parsedDateTime.getMonth() + 1).padStart(2, '0')
        const day = String(parsedDateTime.getDate()).padStart(2, '0')
        const hours = String(parsedDateTime.getHours()).padStart(2, '0')
        const minutes = String(parsedDateTime.getMinutes()).padStart(2, '0')

        // Return in 'YYYY-MM-DDTHH:MM' format for 'datetime-local' fields
        return `${year}-${month}-${day}T${hours}:${minutes}`
      }

      return value
    }

    default:
      return value
  }
}

const EditGeneric: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps,
) => {
  const store = useContext(StoreContext)
  const { SlidingPanelState } = store
  const locales = store.TranslationsState.translations
  const { Theme } = store.Theme
  const { width, height } = useWindowDimensions()
  const [lockChecked, setLockChecked] = useState(true)
  const [locked, setLocked] = useState(false)
  const [lockedUser, setLockedUser] = useState('')
  const [triggerChange, setTriggerChange] = useState(false)
  const [isAbleToSave, setIsAbleToSave] = useState(false)

  const divRef = useRef<any>(null)
  const initialState = [
    ...props.columns.map((e) => {
      const customOptions = props.row.getAllCells().find(
        (f: any) => f.column.id === e.header,
        //@ts-ignore
      )?.column.columnDef.editCustomOptions
      return {
        header: e.header,
        value: formatEntryValue(customOptions.editType, e.value),
        error: '',
        rules: ValidateArray[e.header] ? ValidateArray[e.header] : [],
        isActive: false,
      }
    }),
  ]

  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => {
    setForm([...tempForm])
  }

  const getUrl = (id: string | undefined) => {
    switch (props.tableName) {
      case 'currencies':
        return `${props.url}${id}`
      default:
        return `${props.url}/${id}`
    }
  }
  const handleSave = async () => {
    const id = props.columns.find((e: any) => e.header === 'id')?.value
    let fetchObject: any = {}
    form.map((e, index) => {
      const customOptions = props.row
        .getAllCells()
        .find((f: any) => f.column.id === e.header)?.column
        .columnDef.editCustomOptions

      if (
        JSON.stringify(e.value) !== JSON.stringify(initialState[index].value) ||
        props.tableName === 'supplierorders' ||
        customOptions.editType === 'filter'
      )
        fetchObject = {
          [e.header]: toJS(e.value),
          ...fetchObject,
        }
    })
    if (props.tableName === 'supplierorders') {
      fetchObject = {
        ...fetchObject,
        lastEditAuthor: store.UserStore.user,
        lastEditDate: new Date().toISOString(),
      }
    }

    let data = store.TableStore.formatRequestData(fetchObject)
    if (props.tableName === 'predefinedfilters') {
      //@ts-ignore
      data = { filter: data.action }
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }

    try {
      await axios.post(getUrl(id), data, config)
      await store.SlidingPanelState.setVisibility(false)
      if (props.setLoaded) props.setLoaded(false)
      await props.fetchData()
      if (props.setLoaded) props.setLoaded(true)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 *
          props.columns.filter((e) => !hiddenColumns.includes(e.header))
            .length) /
          (height * 0.8),
      ),
    )
  }, [height])

  useEffect(() => {
    setIsAbleToSave(form.some((item) => item.isActive))
  }, [triggerChange])

  return (
    <Container {...Theme.editProductsStyles.container}>
      {lockChecked && !locked ? (
        <>
          <InnerCont ref={divRef} cols={inputColumns}>
            {props.columns
              ? props.columns.map((e, index) => {
                  const customOptions = props.row.getAllCells().find(
                    (f: any) => f.column.id === e.header,
                    //@ts-ignore
                  )?.column.columnDef.editCustomOptions
                  if (customOptions.editable && e.header !== 'equal')
                    return (
                      <DataInputGeneric
                        type={'edit'}
                        vals={e}
                        editOptions={customOptions}
                        key={`data-input--${index}`}
                        index={index}
                        form={form}
                        setForm={handleSetForm}
                        triggerChange={() => setTriggerChange(!triggerChange)}
                      />
                    )
                })
              : null}
          </InnerCont>
          <Footer {...Theme.editProductsStyles.styledFooter}>
            <Button
              type={'secondary'}
              onClick={() => store.SlidingPanelState.setVisibility(false)}
              {...Theme.buttons.secondaryButton}
            >
              {locales.buttons.cancel}
            </Button>
            <Button
              type={'primary'}
              onClick={async () => {
                if (isAbleToSave) await handleSave()
              }}
              //onClick={() => handleSave()}
              active={isAbleToSave}
              {...Theme.buttons.primaryButton}
            >
              {locales.buttons.save}
            </Button>
          </Footer>
        </>
      ) : (
        <>
          {locked ? (
            <>This order is currently edited by {lockedUser}</>
          ) : (
            <>Checking order status</>
          )}
        </>
      )}
    </Container>
  )
}
export default EditGeneric
