import React, { forwardRef, useContext, useEffect, useMemo } from 'react'
import { Container } from './CheckboxSelect.styles'
import { StoreContext } from '../../App'
import { toJS, values } from 'mobx'

interface Props {
  indeterminate?: boolean
  allSelected?: boolean
  row: any
  rowId?: string
  name?: string
  selected?: boolean
}

export const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ row, rowId, selected, ...rest }, ref: any) => {
    const store = useContext(StoreContext)
    const tableStore = store.TableStore
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
    const rowVal =
      row
        ?.getAllCells()
        .find((e: any) => e.column.id === 'id')
        ?.getValue() ||
      row
        ?.getAllCells()
        .find((e: any) => e.column.id === 'code')
        ?.getValue() ||
      null

    useEffect(() => {
      if (rowVal) {
        resolvedRef.current.checked =
          store.TableStore.selectedRowId.includes(rowVal)
      }
    }, [store.TableStore.selectedRowId])

    useEffect(() => {
      if (store.TableStore.selectedExpanded.length > 0) {
        resolvedRef.current.checked = false
        selected = false
      }
    }, [store.TableStore.selectedExpanded])

    const addRowData = () => {
      const isSelected = !selected

      if (isSelected && rowId && !store.TableStore.allSelectedRows) {
        const tempData = [rowId, ...tableStore.selectedRowId]
        const uniqueData = [...new Set(tempData)]
        tableStore.setSelectedRows(uniqueData)
      } else if (!isSelected || store.TableStore.allSelectedRows) {
        if (rowId) {
          const index = tableStore.selectedRowId.indexOf(rowId)
          const newArray = tableStore.selectedRowId
          newArray.splice(index, 1)
          tableStore.setSelectedRows(newArray)
        }
      }
      store.TableStore.setAllSelectedRows(false)
    }

    return (
      <Container>
        <input
          onClick={() => addRowData()}
          readOnly={true}
          type='checkbox'
          ref={resolvedRef}
          {...rest}
        />
      </Container>
    )
  },
)
IndeterminateCheckbox.displayName = 'IndeterminateSelectCheckbox'

interface PageProps {
  indeterminate?: boolean
  allSelected?: boolean
  name?: string
  selected?: boolean
  data: any[]
}

export const PageSelectCheckbox = forwardRef<HTMLInputElement, PageProps>(
  ({ indeterminate, allSelected, data, ...rest }, ref: any) => {
    const store = useContext(StoreContext)
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    const updatePageData = () => {
      const MapId: Array<string> = [...data.map((e: any) => `${e.id}`)]
      const checked = resolvedRef.current.checked
      if (checked) {
        const combinedArray = [...MapId, ...store.TableStore.selectedRowId]
        const uniqueArray = [...new Set(combinedArray)]
        store.TableStore.setSelectedRows(uniqueArray)
      } else {
        const reducedArray = store.TableStore.selectedRowId.filter(
          (a) => !MapId.includes(a),
        )
        store.TableStore.setAllSelectedRows(false)
        store.TableStore.setSelectedRows(reducedArray)
      }
    }

    const setChecked = () => {
      const MapId: Array<string> = [...data.map((e: any) => `${e.id}`)]
      const selectedIds = store.TableStore.selectedRowId

      const allSelected = MapId.every((id) => selectedIds.includes(id))
      const someSelected = MapId.some((id) => selectedIds.includes(id))

      resolvedRef.current.checked = allSelected
      resolvedRef.current.indeterminate = someSelected && !allSelected
    }

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    useEffect(() => {
      setChecked()
    }, [store.TableStore.selectedRowId])

    return (
      <Container>
        <input
          onClick={() => updatePageData()}
          onChange={() => console.log('change')}
          type='checkbox'
          readOnly={true}
          ref={resolvedRef}
          {...rest}
        />
      </Container>
    )
  },
)
PageSelectCheckbox.displayName = 'AllSelectCheckbox'

interface PageRenderedInterface {
  indeterminate?: boolean
  allSelected?: boolean
  name?: string
  selected?: boolean
  data: object[]
  id: string
}

export const PageRenderedCheckbox = forwardRef<
  HTMLInputElement,
  PageRenderedInterface
>(({ indeterminate, allSelected, data, id, ...rest }, ref: any) => {
  const store = useContext(StoreContext)
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  const updatePageData = () => {
    const MapId: Array<any> = [
      ...data.map((e: any) => {
        return {
          rowId: id,
          values: e,
        }
      }),
    ]

    const checked = resolvedRef.current.checked
    if (checked) {
      const combinedArray = [
        ...MapId,
        ...store.TableStore.selectedExpanded.filter((a) => !(a.rowId === id)),
      ]
      const uniqueArray = [...new Set(combinedArray)]
      store.TableStore.setSelectedExpanded(uniqueArray)
      store.TableStore.setSelectedRows([])
    } else {
      const reducedArray = store.TableStore.selectedExpanded.filter(
        (a) => !(a.rowId === id),
      )
      store.TableStore.setAllSelectedRows(false)
      store.TableStore.setSelectedExpanded(reducedArray)
    }
  }

  const setChecked = () => {
    const MapId: Array<any> = [
      ...data.map((e: any) => {
        return {
          rowId: id,
          values: e,
        }
      }),
    ]
    const selectedExpanded = store.TableStore.selectedExpanded.filter(
      (a) => a.rowId === id,
    )

    const allSelected =
      selectedExpanded.length === data.length && selectedExpanded.length > 0
    const someSelected = selectedExpanded.length > 0

    resolvedRef.current.checked = allSelected
    resolvedRef.current.indeterminate = someSelected && !allSelected
  }

  useEffect(() => {
    setChecked()
  }, [store.TableStore.selectedRowId])

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <Container>
      <input
        onClick={() => updatePageData()}
        type='checkbox'
        readOnly={true}
        ref={resolvedRef}
        {...rest}
      />
    </Container>
  )
})
PageRenderedCheckbox.displayName = 'AllSelectCheckbox'

interface AllProps {
  indeterminate?: boolean
  allSelected?: boolean
  selectAllFunction: () => Promise<void>
}

export const AllSelectCheckbox = forwardRef<HTMLInputElement, AllProps>(
  ({ indeterminate, allSelected, selectAllFunction, ...rest }, ref: any) => {
    const store = useContext(StoreContext)
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.checked =
        store.TableStore.selectedRowId.length ===
          store.TableStore.pagination[store.RouteStore.currentPage].tableSize &&
        store.TableStore.selectedRowId.length > 0
    }, [store.TableStore.allSelectedRows, indeterminate])

    return (
      <Container>
        <input
          onClick={() => {
            selectAllFunction()
          }}
          onChange={() => console.log('change')}
          type='checkbox'
          ref={resolvedRef}
          readOnly={true}
          checked={store.TableStore.allSelectedRows}
          {...rest}
        />
      </Container>
    )
  },
)
AllSelectCheckbox.displayName = 'AllSelectCheckbox'

interface RenderedProps {
  indeterminate?: boolean
  allSelected?: boolean
  name?: string
  handleClick?: any
  selectedMainRows: number
}

export const TableRenderedCheckbox = forwardRef<
  HTMLInputElement,
  RenderedProps
>(({ indeterminate, handleClick, selectedMainRows, ...rest }, ref: any) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate, selectedMainRows])

  return (
    <Container>
      <input
        type='checkbox'
        onClick={(e) => {
          handleClick(e)
        }}
        ref={resolvedRef}
        readOnly={true}
        {...rest}
      />
    </Container>
  )
})
TableRenderedCheckbox.displayName = 'TableRenderedCheckbox'
