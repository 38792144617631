/* eslint-disable */
import {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Row } from '@tanstack/react-table'

import {
  Container,
  Footer,
  InnerCont,
  TagCont,
} from './AssignCollectiolnGeneric.styles'

import { StoreContext } from '../../App'

import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import {
  SingleTag,
  SingleTagCross,
} from '../_tableGeneric/tableNewTags/TableNewTags.styles'
import Cross from '../../assets/icons/cross-white.svg'
import SearchCollectionComponent from '../_settings/tags/edits/searchCollection/SearchCollection.component'
import Button from '../button/Button.component'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  row: any
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  url: string
  tableName: string
  fetchData: () => void
}

const AssignTagGeneric: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps,
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [isAbleToSave, setIsAbleToSave] = useState(false)

  const [currentCollectionList, setCurrentCollectionList] = useState<
    Array<any>
  >([])
  const applySearch = (tag: any) => {
    const addedTag = [tag]
    const unique = [
      ...new Map(addedTag.map((item) => [item.id, item])).values(),
    ]
    setCurrentCollectionList(unique)
    setIsAbleToSave(true)
  }

  const applyClearTag = (tag: any) => {
    setCurrentCollectionList([
      ...currentCollectionList.filter((e) => e.id !== tag.id),
    ])
    setIsAbleToSave(true)
  }

  const handleSave = async () => {
    let id = props.columns.find((e: any) => e.header === 'id')?.value

    const tags = [...currentCollectionList.map((e) => e.id)]
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collection/${tags[0]}/assignto?operator=assign`

    const data = {
      //objects: [...currentCollectionList.map((e) => e.id)],
      objects: [id],
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    console.log(url, data, config)
    await axios.post(url, data, config)

    await toast(locales.successes.success, { type: 'success' })
    await store.SlidingPanelState.setVisibility(false)
    await props.fetchData()
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <TagCont>
          {locales.headers.currentCollections}{' '}
          {currentCollectionList.map((e: any, index) => {
            if (e)
              return (
                <SingleTag
                  key={`new-tag-${e}`}
                  size={'12px'}
                  style={{ whiteSpace: 'nowrap' }}
                  color={e.hasOwnProperty('colour') ? e.colour : '#505050'}
                >
                  {e.name.EN}
                  <SingleTagCross onClick={() => applyClearTag(e)}>
                    <img src={Cross} />
                  </SingleTagCross>
                </SingleTag>
              )
          })}
        </TagCont>
        <SearchCollectionComponent
          header={locales.headers.addNewTag}
          applySearch={applySearch}
          clearInputValue={true}
        />
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            if (isAbleToSave) handleSave()
          }}
          active={isAbleToSave}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}
export default AssignTagGeneric
