import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useCallback } from 'react'
import { StoreContext } from '../../App'
import { DropDownItem, HeaderDropDown } from './DetailsDropDown.styles'
import axios from '../../axiosconfig'
import { useParams } from 'react-router-dom'
import { IconAlertCircle } from '@tabler/icons-react'
import { toast } from 'react-toastify'

interface Props extends PropsWithChildren {
  table: Array<string>
  active?: boolean
  setLoading: any
}

const priorityView: Record<string, string> = {
  default: 'Default',
  low: 'High',
  medium: 'Very High',
  high: 'Extremely High',
}

const getViewInfo = (e: string, active?: boolean) =>
  active ? priorityView[e] : e

const DetailsDropDown: FC<Props> = observer(
  ({ table, active, setLoading }: Props) => {
    const store = useContext(StoreContext)
    const locales = store.TranslationsState.translations

    const params = useParams<{ id: string }>()
    const { Theme } = store.Theme

    const handleChange = useCallback(
      async (priority: string) => {
        const id = store.RouteStore.currentDataId
        try {
          const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${id}`
          const data = { priority: priority }
          const config = {
            headers: {
              'x-apikey': process.env.REACT_APP_API_KEY || '',
            },
          }
          await axios.post(url, data, config)
          await new Promise((resolve) => setTimeout(resolve, 2000))
          await store.TableStore.fetchDetailsData(params.id)
          await toast('Priority changed!', {
            type: 'success',
          })
        } catch (error) {
          toast('Error changing Priorit!', {
            type: 'error',
          })
        }
        setLoading(false)
      },
      [store, params.id],
    )

    return (
      <HeaderDropDown {...Theme.proformsDetailsStyles.headerDropDown}>
        {table.map((priority, index) => {
          const shouldRenderItem =
            store.TableStore.detailsData.priority !== 'default' ||
            priority !== 'default'

          return shouldRenderItem ? (
            <DropDownItem
              key={index}
              onClick={async () => {
                setLoading(true)
                await handleChange(priority)
              }}
              {...Theme.proformsDetailsStyles.dropDownItem}
            >
              {getViewInfo(priority, active)}
            </DropDownItem>
          ) : null
        })}
      </HeaderDropDown>
    )
  },
)

export default DetailsDropDown
