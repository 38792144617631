import { RootStore } from './Root.store'
import { action, makeAutoObservable } from 'mobx'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'
import { Address, Company, Contact, Identity } from '../types/ContactTypes'

export class ContactsStore {
  rootStore
  addresses: any[] = []
  contacts: any[] = []
  identities: any[] = []
  companies: any[] = []

  addressDetails: Address | null = null
  contactDetails: Contact | null = null
  identityDetails: Identity | null = null
  companyDetails: Company | null = null

  loaded = {
    addresses: true,
    contacts: true,
    identities: true,
    companies: true,
    details: true,
  }

  pagination = {
    addresses: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    contacts: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    identities: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    companies: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  private async fetchData(
    url: string,
    setData: (data: any[]) => void,
    setLoaded: (state: boolean) => void,
  ) {
    setLoaded(false)
    try {
      const config = {
        headers: {
          'x-apikey': `17100cd0-1a08-4962-b850-7834221d4dd8`,
          username: this.rootStore.UserStore.user,
        },
      }
      const response = await axios.get(url, config)
      setData(response.data)
    } catch (error) {
      this.handleError(error)
    } finally {
      setLoaded(true)
    }
  }

  // Fetch functions for each data type
  @action.bound fetchAddresses = async () => {
    const url = `https://moodnook-dev.frendsapp.com/api/crm/v1/addresses`
    await this.fetchData(
      url,
      (data) => (this.addresses = data),
      (state) => (this.loaded.addresses = state),
    )
  }

  @action.bound fetchContacts = async () => {
    const url = `https://moodnook-dev.frendsapp.com/api/crm/v1/contacts`
    await this.fetchData(
      url,
      (data) => (this.contacts = data),
      (state) => (this.loaded.contacts = state),
    )
  }

  @action.bound fetchIdentities = async () => {
    const url = `https://moodnook-dev.frendsapp.com/api/crm/v1/identities`
    await this.fetchData(
      url,
      (data) => (this.identities = data),
      (state) => (this.loaded.identities = state),
    )
  }

  @action.bound fetchCompanies = async () => {
    const url = `https://moodnook-dev.frendsapp.com/api/crm/v1/companies`
    await this.fetchData(
      url,
      (data) => (this.companies = data),
      (state) => (this.loaded.companies = state),
    )
  }

  // Setters for pagination per data type
  @action.bound setAddressPagination = (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number,
  ) => {
    this.pagination.addresses = { tableSize, pageSize, pageIndex, totalCount }
    this.fetchAddresses()
  }

  @action.bound setContactPagination = (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number,
  ) => {
    this.pagination.contacts = { tableSize, pageSize, pageIndex, totalCount }
    this.fetchContacts()
  }

  @action.bound setIdentityPagination = (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number,
  ) => {
    this.pagination.identities = { tableSize, pageSize, pageIndex, totalCount }
    this.fetchIdentities()
  }

  @action.bound setCompanyPagination = (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number,
  ) => {
    this.pagination.companies = { tableSize, pageSize, pageIndex, totalCount }
    this.fetchCompanies()
  }

  // Generic fetch details function
  private async fetchDetailsData<T>(
    url: string,
    setDetails: (data: T) => void,
  ) {
    try {
      const response = await axios.get(url)
      setDetails(response.data as T)
    } catch (error) {
      this.handleError(error)
    }
  }

  // Fetch details functions for each data type
  @action.bound fetchAddressDetails = async (id: string) => {
    const url = `https://moodnook-dev.frendsapp.com/api/crm/v1/addresses/${id}`
    await this.fetchDetailsData<Address | null>(
      url,
      (data) => (this.addressDetails = data),
    )
  }

  @action.bound fetchContactDetails = async (id: string) => {
    const url = `https://moodnook-dev.frendsapp.com/api/crm/v1/contacts/${id}`
    await this.fetchDetailsData<Contact | null>(
      url,
      (data) => (this.contactDetails = data),
    )
  }

  @action.bound fetchIdentityDetails = async (id: string) => {
    const url = `https://moodnook-dev.frendsapp.com/api/crm/v1/identities/${id}`
    await this.fetchDetailsData<Identity | null>(
      url,
      (data) => (this.identityDetails = data),
    )
  }

  @action.bound fetchCompanyDetails = async (id: string) => {
    const url = `https://moodnook-dev.frendsapp.com/api/crm/v1/companies/${id}`
    await this.fetchDetailsData<Company | null>(
      url,
      (data) => (this.companyDetails = data),
    )
  }

  // Clear functions for each data type
  @action.bound clearAddresses = () => {
    this.addresses = []
  }
  @action.bound clearContacts = () => {
    this.contacts = []
  }
  @action.bound clearIdentities = () => {
    this.identities = []
  }
  @action.bound clearCompanies = () => {
    this.companies = []
  }

  @action.bound clearAddressDetails = () => {
    this.addressDetails = null
  }
  @action.bound clearContactDetails = () => {
    this.contactDetails = null
  }
  @action.bound clearIdentityDetails = () => {
    this.identityDetails = null
  }
  @action.bound clearCompanyDetails = () => {
    this.companyDetails = null
  }

  // Error handling
  private handleError(error: unknown) {
    if (error instanceof Error) {
      toast(error.message, { type: 'error' })
    } else {
      toast('An unknown error occurred', { type: 'error' })
    }
  }

  @action.bound
  async mockSettingUpData() {
    await this.fetchAddresses()
    await this.fetchContacts()
    await this.fetchIdentities()
    await this.fetchCompanies()

    this.addressDetails = this.addresses[0]
    this.contactDetails = this.contacts[0]
    this.identityDetails = this.identities[0]
    this.companyDetails = this.companies[0]

    this.loaded.details = true
  }
}
