import React, { useContext, useEffect, useRef, useState } from 'react'
import { StoreContext } from '../../App'
import { observer } from 'mobx-react'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import { InnerCont, Footer } from './EditSupplierProduct.styles'
import { toJS } from 'mobx'
import axios from '../../axiosconfig'
import { Container } from '../editGeneric/EditGeneric.styles'
import Button from '../button/Button.component'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'

const EditSupplierProduct = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const columns = {
    ProductID: {
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    ProductCode: {
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    Name: {
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    ProducerName: {
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    SupplierDeliveryCode: {
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    OrderLink: {
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    ean: {
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    PurchasePrice: {
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    Tags: {
      canFilter: false,
      editable: false,
    },
    OrderLineTags: {
      canFilter: false,
      editable: false,
    },
    SupplierDeliveryDate: {
      canFilter: true,
      editable: true,
      editType: 'date',
      dateFormat: 'DD.MM.YYYY',
      Header: 'Estimated Delievery Date',
    },
    OrderedQuantity: {
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    DeliveredQuantity: {
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    DeliveredDate: {
      canFilter: true,
      editable: true,
      editType: 'date',
      dateFormat: 'DD.MM.YYYY',
    },
    Notes: {
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    CreatedBy: {
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    LastEditAuthor: {
      canFilter: true,
      editable: false,
      editType: 'text',
    },
  }
  const [inputColumns, setInputColumns] = useState(1)
  const { width, height } = useWindowDimensions()
  const divRef = useRef<any>(null)

  const columnHeaders = Object.keys(columns)

  const initialState = [
    ...columnHeaders.map((e) => {
      return {
        header: e,
        value: '',
        error: '',
        rules: ['required', 'length'],
        isActive: false,
      }
    }),
  ]

  const [isAbleToSave, setIsAbleToSave] = useState(false)
  const [form, setForm] = useState(initialState)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => {
    setForm([...tempForm])
  }
  const handleSave = async () => {
    const uniqueProducts = [
      ...new Set(store.TableStore.selectedExpanded.map((item) => item.rowId)),
    ]
    const fetchPromises = uniqueProducts.map((productId) => {
      const idProducts = store.TableStore.selectedExpanded.filter(
        (e) => e.rowId === productId,
      )
      let productsData = {}
      idProducts.map((e: any) => {
        let formData = e.values
        form.map((f: any) => {
          if (f.isActive) {
            formData = { ...formData, [f.header]: f.value }
          }
        })
        productsData = {
          ...productsData,
          [e.values.ProductCode]: toJS(formData),
        }
      })
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${productId}`
      const data = {
        id: productId,
        products: productsData,
      }
      const formattedData = store.TableStore.formatRequestData(data)
      console.log(formattedData)
      return axios.post(url, formattedData, {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      })
    })
    await Promise.all(fetchPromises)
    await store.SlidingPanelState.setVisibility(false)
    await setTimeout(async () => await store.TableStore.fetchData(), 2000)
  }

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
    setIsAbleToSave(changed)
  }, [form])

  return (
    <Container>
      <InnerCont ref={divRef} cols={inputColumns}>
        {columnHeaders.map((e, index) => {
          const editOptions = columns[e]
          if (editOptions.editable)
            return (
              <DataInputGeneric
                type={'edit'}
                vals={{ header: e, value: '' }}
                form={form}
                index={index}
                setForm={handleSetForm}
                editOptions={columns[e]}
                key={`edit-supplier-${index}`}
              />
            )
        })}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            if (isAbleToSave) handleSave()
          }}
          active={isAbleToSave}
          {...Theme.buttons.primaryButton}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
})

export default EditSupplierProduct
