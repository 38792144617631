import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

interface FilterLabelProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
  marginTop?: string
}

export const InputCont = styled.div`
  display: flex;
  margin-bottom: 20px;
`
export const FilterLabel = styled.label<FilterLabelProps>`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '20px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '20px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  color: ${(props) => (props.color ? props.color : '#424242')};
  max-width: 180px;
  word-break: break-all;
  display: inline-block;
`

interface StyledInputInterface {
  activeStyle: boolean
  activeBgColor?: string
  inactiveBgColor?: string
  boxShadow?: string
  borderRadius?: string
  padding?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
}

export const Input = styled.input<StyledInputInterface>`
  height: 40px;
  width: 180px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '16px')};
  background-color: ${(props) => (props.activeStyle ? '#ffffff' : '#f5f5f5')};
  border: ${(props) =>
    props.activeStyle
      ? props.activeBgColor || '1px solid #D6D6D6'
      : props.inactiveBgColor || '1px solid #575757'};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

interface DropDownProps {
  color?: string
  bgColor?: string
  borderRadius?: string
  boxShadow?: string
}

export const DropDown = styled.div<DropDownProps>`
  position: absolute;
  width: 300px;
  top: 90px;
  min-width: 140px;
  z-index: 50;
  color: ${(props) => (props.color ? props.color : '#000000')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  max-height: 300px;
  overflow-y: scroll;
`

export const DropdownHeader = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  padding-left: 4px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #606060;
  font-weight: bold;
  color: white;

  * img {
    height: 24px;
    position: relative;
    filter: invert(1);
  }
`

export const DropDownGroupFilter = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e8e8e8;
  font-weight: 500;
`
export const GroupCheckbox = styled.div`
  display: flex;

  p {
    margin: 4px;
  }
`
export const DropDownTier = styled.div`
  position: relative;
  width: 100%;
`

export const DropDownTierLabel = styled.div`
  position: relative;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface DropDownItemProps {
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  color?: string
  hoverBgColor?: string
}

/* eslint-disable */
export const DropDownItem = styled.div<DropDownItemProps>`
  position: relative;
  width: 100%;
  height: 60px;
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '40px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;

  svg {
    margin-right: 4px;
  }

  :hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : '#f8f8f8'};
  }
`
export const ImageCont = styled.div`
  height: 48px;
  width: 48px;

  img {
    height: 100%;
    width: 100%;
  }
`
export const TextCont = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  font-size: 12px;
  line-height: 16px;
  height: 100%;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const PathCont = styled.p`
  color: #707070;
  font-size: 10px;
`
export const ShowGroupHeader = styled(DropDownItem)`
  height: 24px;
  justify-content: center;
  color: white;
  font-size: 12px;
  font-weight: 600;
  background-color: #808080;

  :hover {
    background-color: #707070;
  }
`
