import { FC, PropsWithChildren, useContext } from 'react'
import { observer } from 'mobx-react'

import { StoreContext } from '../../../App'

import {
  AllColumnRow,
  ColumnHead,
  ColumnHeadText,
  ColumnRow,
  ColumnText,
  SelectWrap,
  StyledInput,
} from './ColumnVisibility.styles'

/* eslint-disable */
interface Props extends PropsWithChildren {
  mobile?: boolean
  table: any
  change?: any
  toggleChange?: () => void
}

const ColumnVisibility: FC<Props> = observer(
  ({ mobile, table, change, toggleChange }: Props) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations

    return (
      <SelectWrap mobile={mobile || false}>
        <div>
          <ColumnHead {...Theme.columnVisibilityStyles.columnHead}>
            <ColumnHeadText {...Theme.columnVisibilityStyles.columnHeadText}>
              {locales.actions.columnName}
            </ColumnHeadText>
            <ColumnHeadText {...Theme.columnVisibilityStyles.columnHeadText}>
              {locales.actions.show}
            </ColumnHeadText>
          </ColumnHead>
          <AllColumnRow
            active={true}
            {...Theme.columnVisibilityStyles.columnRow}
          >
            <ColumnText {...Theme.columnVisibilityStyles.columnText}>
              Toggle All
            </ColumnText>
            <div>
              <StyledInput
                type='checkbox'
                checked={table.getIsAllColumnsVisible()}
                onClick={() => toggleChange && toggleChange()}
                onChange={table.getToggleAllColumnsVisibilityHandler()}
                {...Theme.columnVisibilityStyles.styledInput}
              />
            </div>
          </AllColumnRow>
          {table.getAllLeafColumns().map((column: any) => {
            return (
              <ColumnRow
                key={column.id}
                active={false}
                {...Theme.columnVisibilityStyles.columnRow}
              >
                <ColumnText {...Theme.columnVisibilityStyles.columnText}>
                  {column &&
                  column.id &&
                  store &&
                  store.RouteStore &&
                  store.RouteStore.currentPage &&
                  locales &&
                  locales[`${store.RouteStore.currentPage}Columns`] &&
                  locales[
                    `${store.RouteStore.currentPage}Columns`
                  ].hasOwnProperty(column.id)
                    ? locales[`${store.RouteStore.currentPage}Columns`][
                        column.id
                      ]
                    : column && column.id}
                </ColumnText>
                <ColumnText {...Theme.columnVisibilityStyles.columnText}>
                  <StyledInput
                    type='checkbox'
                    checked={column.getIsVisible()}
                    onClick={() => toggleChange && toggleChange()}
                    onChange={column.getToggleVisibilityHandler()}
                    {...Theme.columnVisibilityStyles.styledInput}
                  />
                </ColumnText>
              </ColumnRow>
            )
          })}
        </div>
      </SelectWrap>
    )
  },
)
export default ColumnVisibility
