import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  input {
    &.intermediate {
      background-color: black !important;
      color: white; /* Ensures text is visible on the black background */
    }
  }
`
