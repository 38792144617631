import { observer } from 'mobx-react'
import { Container, Input, TextArea, Select } from './EditInput.styles'
import { useContext, useEffect, useState } from 'react'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import { socket } from '../../socket/socket'
import { StoreContext } from '../../App'

/* eslint-disable */
interface TableQuickEditComponentInterface {
  defaultValue: string
  orderId: string
  object: any
  columnName: string
  type?: string
}

export const EditInput = observer(
  ({
    defaultValue,
    orderId,
    columnName,
    object,
  }: TableQuickEditComponentInterface) => {
    const store = useContext(StoreContext)
    const locales = store.TranslationsState.translations

    const [value, setValue] = useState(defaultValue)

    const handleKeyDown = async (event: any) => {
      if (event.key === 'Enter') {
        const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${orderId}`
        const data = object.column
          ? {
              [object.column]: { [columnName]: value },
              lastEdit: new Date().toISOString(),
              lastEditedBy: store.UserStore.user,
            }
          : {
              [columnName]: value,
              lastEdit: new Date().toISOString(),
              lastEditedBy: store.UserStore.user,
            }
        const config = {
          headers: {
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          },
        }
        const response = await axios.post(url, data, config)
        if (response.status === 200) {
          await toast(locales.successes.saved, {
            type: 'success',
          })
          store.TableStore.fetchDetailsData(store.RouteStore.currentDataId)
        }
      }
    }

    const sendData = async (value: string | number) => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${orderId}`
      const data = {
        [columnName]: value,
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      console.log(url, data, config)
      const response = await axios.post(url, data, config)
      if (response.status === 200) {
        toast(locales.successes.saved, {
          type: 'success',
        })
      }
      await store.TableStore.fetchDetailsData(orderId)
    }

    const getSelectOptions = (header: string) => {
      switch (header) {
        case 'completable':
          return (
            <>
              <option value={''}>{''}</option>
              <option value={0} selected={value === '0'}>
                {`${locales.packable.allMissing} (0)`}
              </option>
              <option value={1} selected={value === '1'}>
                {`${locales.packable.someMissing} (1)`}
              </option>
              <option value={2} selected={value === '2'}>
                {`${locales.packable.packable} (2)`}
              </option>
            </>
          )
      }
    }

    const getProperInput = (type: string, header: string) => {
      switch (type) {
        case 'textarea':
          return (
            <TextArea
              value={value}
              onKeyDown={handleKeyDown}
              onChange={(e) => setValue(e.target.value)}
            />
          )
        case 'select':
          return (
            <Select onChange={(e) => sendData(parseInt(e.target.value))}>
              {getSelectOptions(header)}
            </Select>
          )
        default:
          return (
            <Input
              value={value}
              type={object.editable ? object.editable : 'text'}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                setValue(e.target.value)
                if (object.editable === 'date') {
                  sendData(e.target.value)
                }
              }}
            />
          )
      }
    }

    useEffect(() => {
      setValue(defaultValue)
    }, [defaultValue])

    return (
      <Container>{getProperInput(object.editable, object.value)}</Container>
    )
  },
)
