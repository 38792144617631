/* eslint-disable */
import { PropsWithChildren, useContext, useEffect, useState } from 'react'

import { Container, Footer, InnerCont } from './AddSupplierProduct.styles'

import { StoreContext } from '../../App'

import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import AddProductSearchComponent from '../addProductSearch/AddProductSearch.component'
import { FilterLabel, Input } from '../dataInputGeneric/DataInputGeneric.styles'
import { validate } from '../../static/validate'
import { socket } from '../../socket/socket'
import Button from '../button/Button.component'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  row: any
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  tableName: string
  fetchData: () => void
}

const AddSupplierProduct: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps,
) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  const { Theme } = store.Theme
  const [isAbleToSave, setIsAbleToSave] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const initialState = [
    {
      header: 'quantity',
      value: '1',
      error: '',
      rules: ['positive'],
      isActive: true,
    },
    {
      header: 'productID',
      value: '',
      error: '',
      rules: ['required', 'length'],
      isActive: true,
    },
  ]
  const [form, setForm] = useState(initialState)

  const applySearch = (product: any) => {
    const tempForm = [...form]
    tempForm[1].value = product.id
    setForm([...tempForm])
  }
  const handleSave = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/stock_view?size=1&from=0`
    const data = {
      filterGroups: {
        rules: [
          {
            field: 'id',
            operator: '=',
            valueSource: 'value',
            value: form[1].value,
          },
        ],
        combinator: 'and',
        not: false,
      },
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    const response = await axios.post(url, data, config)
    if (response.data.count) {
      const product = response.data.products[0]
      const supplierId = props.row
        .getAllCells()
        .find((e: any) => e.column.id === 'id')
        ?.getValue()
      const postUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${supplierId}`
      const postData = {
        id: supplierId,
        products: {
          [product.code]: {
            ProductID: product.code,
            ProductCode: product.code,
            Name: product.name,
            SupplierName: product.producer,
            SupplierDeliveryCode: '',
            SupplierOrderId: '',
            OrderLink: '',
            Notes: '',
            ProducerName: product.producer,
            OrderedQuantity: form[0].value,
            SupplierDeliveryDate: '',
            DeliveredQuantity: '0',
            DeliveredDate: '',
            PurchasePrice: '0',
            Unit: '',
            LastEditAuthor: store.UserStore.user,
            CreatedBy: store.UserStore.user,
          },
        },
      }
      const formattedData: any = store.TableStore.formatRequestData(postData)

      await axios.post(postUrl, formattedData, config)
      await toast(locales.successes.success, { type: 'success' })
      await store.SlidingPanelState.setVisibility(false)
      await socket.emit('editOrder', {
        id: supplierId,
        user: store.UserStore.user,
        page: store.TableStore.getApiRequestFromUrl(),
      })
      await setLoading(false)
      await setTimeout(async () => await store.TableStore.fetchData(), 3000)
    } else {
      toast(locales.errors.noOrderId, { type: 'error' })
    }
  }

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
    setIsAbleToSave(changed)
  }, [form])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <AddProductSearchComponent applySearch={applySearch} />
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {locales.headers.quantity}
        </FilterLabel>
        <Input
          type={'text'}
          value={form[0].value}
          onChange={(e) => {
            const updatedForm = [...form]
            const error = validate(form[0].rules, e.target.value)
            updatedForm[0] = { ...form[0], value: e.target.value, error: error }

            setForm(updatedForm)
          }}
          activeStyle={true}
          {...Theme.editProductsStyles.styledInput}
        />
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            if (isAbleToSave && !loading) handleSave()
          }}
          active={isAbleToSave && !loading}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}
export default AddSupplierProduct
