import styled from 'styled-components'

interface StyledCheckbox {
  bgColor?: string
  border?: string
  boxShadow?: string
  borderRadius?: string
  checkedColor?: string
  marginBottom?: string
  marginRight?: string
}

export const HeaderCheckboxCont = styled.div<StyledCheckbox>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '5px'};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '2px'};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '2px')};
  position: relative;
  height: 24px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

interface HeaderCheckboxesProps {
  fontSize?: string
  color?: string
}

export const HeaderCheckboxes = styled.div<HeaderCheckboxesProps>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  line-break: loose;
  flex-wrap: wrap;
  margin-top: -8px;

  input {
    margin: 4px;
    margin-bottom: 12px;

    :indeterminate {
      color: ${(props) => (props.color ? props.color : 'white')};
    }
  }
`

interface HeaderCheckboxParagraphProps {
  fontSize?: string
  fontWeight?: number
  color?: string
}

export const HeaderCheckboxParagraph = styled.p<HeaderCheckboxParagraphProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10.5')};
  margin-top: 1px;
  margin-right: 5px;
  color: ${(props) => (props.color ? props.color : '#ffffff')};

  img {
    height: 12px;
    width: 12px;
    margin-bottom: 6px;
  }
`

export const HeaderInnerCheckBox = styled.div`
  height: 36px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  word-break: normal;
  position: relative;
  justify-content: center;
`
