import { Row } from '@tanstack/react-table'
import React from 'react'

import { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'

import {
  GlobalFilterInput,
  StyledForm,
  StyledClearCont,
} from './Filters.styles'
import { mainTheme as theme } from '../../static/themes/main.theme'
import { toast } from 'react-toastify'
import ClearFilters from '../../assets/icons/clear-filters.svg'
import SearchFieldButton from '../_tableGeneric/searchFieldButton/SearchFieldButton.component'

/* eslint-disable */
export const GlobalFilter = observer(({ tableName, fetchData }: any) => {
  const store = useContext(StoreContext)
  const { globalSearch, setGlobalSearch } = store.TableStore
  const locales = store.TranslationsState.translations
  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (globalSearch.length >= 3 || globalSearch.length == 0) {
        store.TableStore.applyQuery()
        fetchData()
      } else
        toast('Search query must be at least 3 characters long', {
          type: 'error',
        })
    }
  }

  return (
    <StyledForm>
      {locales.buttons.searchAll}:{' '}
      <GlobalFilterInput
        value={globalSearch}
        onChange={(e) => setGlobalSearch(e.target.value)}
        placeholder={locales.buttons.setGlobalSearch}
        onKeyDown={handleKeyDown}
        {...theme.filtersStyles.globalFilterInput}
      />
      <StyledClearCont>
        {store.TableStore.appliedGlobalSearch.length ? (
          <SearchFieldButton
            text={locales.buttons.clearGlobalFilter}
            onClick={() => {
              store.TableStore.setGlobalSearch('')
              store.TableStore.applyQuery()
              fetchData()
            }}
            icon={<img src={ClearFilters} />}
          />
        ) : (
          <></>
        )}
      </StyledClearCont>
    </StyledForm>
  )
})
