import { Column } from '@tanstack/react-table'
import axios from '../../../axiosconfig'
import { StoreContext } from '../../../App'
import CreateOrderPopUp from '../createOrderPopup/CreateOrderPopup.component'
import CopyToClipboard from '../copyToClipboard/CopyToClipboard.component'

import {
  RowsCount,
  SelectedParagraph,
  SelectedWrapper,
  StyledCross,
  StyledPlus,
} from './Footer.styles'

import { observer } from 'mobx-react'
import { useContext, useState } from 'react'
import { ReactComponent as Cross } from '../../../assets/icons/clear-filters.svg'
import PlusWhite from '../../../assets/icons/plus-white.svg'
import TableCSV from '../tableCSV/TableCSV.component'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'
import EditSupplierProduct from '../../editSupplierProduct/EditSupplierProduct.component'
import EditMultipleProducts from '../../editMultipleProducts/EditMultipleProducts.component'
import { Tooltip } from '@mui/material'
import AddMultipleSupplierProductComponent from '../../addMultipleSupplierProduct/AddMultipleSupplierProduct.component'
import EditMultipleProductsByQueryComponent from '../../editMultipleProductsByQuery/EditMultipleProductsByQuery.component'
import AssignMultipleTagsComponent from '../../_settings/tags/edits/assignMultipleTags/AssignMultipleTags.component'
import AssignSupplierProductsTagComponent from '../../_settings/tags/edits/assignSupplierProductsTag/AssignSupplierProductsTags.component'
import RemoveMultipleTagsComponent from '../../_settings/tags/edits/removeMultipleTags/RemoveMultipleTags.component'
import AssignCollectionGenericMultipleComponent from '../../assignCollectionGenericMultiple/AssignCollectionGenericMultiple.component'
import EditMultipleStatusesComponent from '../../editMultipleStatuses/EditMultipleStatuses.component'
import RemoveMultipleTransactionsComponent from '../../removeMultipleTransactions/RemoveMultipleTransactions.component'
import ExportBankTransaction from '../exportBankTransaction/ExportBankTransaction.component'
import ExportDocumentsComponent from '../exportDocuments/ExportDocuments.component'
import Button from '../../button/Button.component'
import { IconCross, IconX } from '@tabler/icons-react'

/* eslint-disable */
interface PaginatorProps {
  columns: Array<any>
  fetchData: () => void
  tableName: string
  actions?: {
    display: boolean
    label: string
    edit: string | null
    create: string | null
    remove: string | null
    assignTag: string | null
    custom: any[]
  } | null
  actionsMultiple: {
    display: boolean
    label: string
    edit: string | null
    create: string | null
    remove: string | null
    assignTag: boolean
    custom: any[]
  }
}

const Footer = observer(
  ({
    tableName,
    columns,
    fetchData,
    actions,
    actionsMultiple,
  }: PaginatorProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations
    const [createOrderPopup, setCreateOrderPopup] = useState<boolean>(false)

    const HandleSlidingName = () => {
      const array = store.TableStore.selectedRowId.join(', ')
      if (array.length > 10)
        return (
          <Tooltip
            title={`${locales.buttons.editSelected} ${
              actions
                ? actions.label
                : locales.tableNames[store.RouteStore.currentPage]
            }: ${array}`}
          >
            <>{`${locales.buttons.editSelected} ${
              actions
                ? actions.label
                : locales.tableNames[store.RouteStore.currentPage]
            }: ${array.substring(0, 10)}...`}</>
          </Tooltip>
        )
      return `${locales.buttons.editSelected} ${
        actions
          ? actions.label
          : locales.tableNames[store.RouteStore.currentPage]
      }: ${array}`
    }

    const getMutipleEdit = () => {
      switch (tableName) {
        case 'customerorders':
        case 'stock':
          if (actionsMultiple.edit)
            return (
              <EditMultipleProductsByQueryComponent
                url={actionsMultiple.edit}
                columns={columns}
              />
            )
          else return <></>
        case 'statuses':
        case 'shippingtime':
        case 'availability':
          if (actionsMultiple.edit)
            return (
              <EditMultipleStatusesComponent
                url={actionsMultiple.edit}
                columns={columns}
              />
            )
          else return <></>
        default:
          return <EditMultipleProducts columns={columns} />
      }
    }

    const displaySelected = () => {
      const data: any = {
        rules: [],
        combinator: 'or',
        not: false,
      }

      store.TableStore.selectedRowId.map((e: string, index: number) =>
        data.rules.push({
          field: 'id',
          operator: '=',
          valueSource: 'value',
          value: e,
        }),
      )

      store.TableStore.setQuery(data)
      store.TableStore.applyQuery()
      fetchData()
    }

    return (
      <>
        {createOrderPopup && (
          <CreateOrderPopUp
            columns={columns}
            setActive={setCreateOrderPopup}
            setCreateOrderPopup={() => setCreateOrderPopup(false)}
          />
        )}
        {store.TableStore.selectedRowId.length > 0 ||
        store.TableStore.selectedExpanded.length > 0 ||
        store.TableStore.allSelectedRows ? (
          <SelectedWrapper {...Theme.paginatorStyles.selectedWrapper}>
            <StyledCross
              onClick={() => {
                //toggleAllRowsSelected(false)
                store.TableStore.setAllSelectedRows(false)
                store.TableStore.setSelectedRows([])
                store.TableStore.setSelectedExpanded([])
              }}
            >
              <IconX size={'16px'} />
            </StyledCross>
            <SelectedParagraph
              onClick={() => displaySelected()}
              {...Theme.paginatorStyles.selectedParagraph}
            >
              {'Selected: '}
              <RowsCount>{store.TableStore.selectedRowId.length}</RowsCount>
            </SelectedParagraph>
            {store.TableStore.selectedExpanded.length ? (
              <SelectedParagraph {...Theme.paginatorStyles.selectedParagraph}>
                {'Selected subrows: '}
                <RowsCount>
                  {store.TableStore.selectedExpanded.length}
                </RowsCount>
              </SelectedParagraph>
            ) : (
              <></>
            )}
            {actionsMultiple.custom.includes('createOrder') ? (
              <Button
                type={'primary'}
                size={'small'}
                onClick={() => setCreateOrderPopup(true)}
                margin={'2px 0 2px 8px'}
              >
                <StyledPlus src={PlusWhite} />{' '}
                {locales.buttons.createPurchaseOrder}
              </Button>
            ) : (
              <></>
            )}
            {actionsMultiple.custom.includes('exportBankTransaction') ? (
              <ExportBankTransaction />
            ) : (
              <></>
            )}
            {actionsMultiple.custom.includes('exportDocuments') ? (
              <ExportDocumentsComponent />
            ) : (
              <></>
            )}

            {actionsMultiple.custom.includes('editOrderline') &&
            store.TableStore.selectedExpanded.length ? (
              <Button
                type={'primary'}
                size={'small'}
                margin={'2px 0 2px 8px'}
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    'EditSupplierProduct',
                  )
                }
              >
                {`${locales.buttons.editSelected} ${locales.buttons.supplierProducts}`}
              </Button>
            ) : (
              <></>
            )}
            {actionsMultiple.custom.includes('assignOrderLineTags') &&
            store.TableStore.selectedExpanded.length ? (
              <Button
                type={'primary'}
                size={'small'}
                margin={'2px 0 2px 8px'}
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    'AddTagToSupplierProduct',
                  )
                }
              >
                {`${locales.buttons.addTags} selected supplier products`}
              </Button>
            ) : (
              <></>
            )}
            {store.TableStore.selectedRowId.length ? (
              <>
                {actionsMultiple.edit ? (
                  <Button
                    type={'primary'}
                    size={'small'}
                    margin={'2px 0 2px 8px'}
                    onClick={() =>
                      store.SlidingPanelState.setSelectedSlider(
                        'EditMultipleProducts',
                      )
                    }
                    {...Theme.paginatorStyles.selectedParagraph}
                  >
                    {`${locales.buttons.editSelected} ${
                      actions
                        ? actions.label
                        : locales.tableNames[store.RouteStore.currentPage]
                    }`}
                  </Button>
                ) : (
                  <></>
                )}
                {actionsMultiple?.assignTag ? (
                  <Button
                    type={'primary'}
                    size={'small'}
                    margin={'2px 0 2px 8px'}
                    onClick={() =>
                      store.SlidingPanelState.setSelectedSlider(
                        'AddMultipleTags',
                      )
                    }
                    {...Theme.paginatorStyles.selectedParagraph}
                  >
                    {`${locales.buttons.addTags} ${
                      actions
                        ? actions.label
                        : locales.tableNames[store.RouteStore.currentPage]
                    }`}
                  </Button>
                ) : (
                  <></>
                )}

                {actionsMultiple.custom.includes('assignCollection') ? (
                  <Button
                    type={'primary'}
                    size={'small'}
                    margin={'2px 0 2px 8px'}
                    onClick={() =>
                      store.SlidingPanelState.setSelectedSlider(
                        'AddMultipleCollections',
                      )
                    }
                  >
                    {`${locales.buttons.addCollections} ${
                      actions
                        ? actions.label
                        : locales.tableNames[store.RouteStore.currentPage]
                    }`}
                  </Button>
                ) : (
                  <></>
                )}

                {actionsMultiple.custom.includes('addProducts') ? (
                  <Button
                    type={'primary'}
                    size={'small'}
                    margin={'2px 0 2px 8px'}
                    onClick={() =>
                      store.SlidingPanelState.setSelectedSlider(
                        'AddMultipleSupplierProducts',
                      )
                    }
                  >
                    <StyledPlus src={PlusWhite} />{' '}
                    {locales.buttons.addToSelected}
                  </Button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {actionsMultiple.custom.includes('exportBankTransaction') ? (
              <Button
                type={'primary'}
                size={'small'}
                margin={'2px 0 2px 8px'}
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    'RemoveMultipleTransactions',
                  )
                }
              >
                {`Remove selected transactions`}
              </Button>
            ) : (
              <></>
            )}
            {actionsMultiple.assignTag &&
            store.TableStore.selectedRowId.length ? (
              <Button
                type={'primary'}
                size={'small'}
                margin={'2px 0 2px 8px'}
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    'RemoveMultipleTags',
                  )
                }
                {...Theme.paginatorStyles.selectedParagraph}
              >
                {`Remove tags from selected ${
                  actions
                    ? actions.label
                    : locales.tableNames[store.RouteStore.currentPage]
                }`}
              </Button>
            ) : (
              <></>
            )}
            {actionsMultiple.custom.includes('copyToClipboard') ? (
              <CopyToClipboard />
            ) : (
              <></>
            )}
            {actionsMultiple.custom.includes('downloadFile') ? (
              <TableCSV data={store.TableStore.data} tableName={tableName} />
            ) : (
              <></>
            )}
          </SelectedWrapper>
        ) : (
          <></>
        )}
        <SlidingPanel
          contentId={'EditSupplierProduct'}
          title={
            store.TableStore.selectedExpanded.length == 1
              ? 'Edit Supplier Order Product'
              : 'Edit Selected Supplier Products'
          }
        >
          {store.TableStore.selectedExpanded.length && <EditSupplierProduct />}
        </SlidingPanel>
        <SlidingPanel
          contentId={'AddTagToSupplierProduct'}
          title={`${locales.buttons.addTags} selected Supplier Products`}
        >
          {store.TableStore.selectedExpanded.length && (
            <AssignSupplierProductsTagComponent />
          )}
        </SlidingPanel>
        <SlidingPanel
          contentId={'EditMultipleProducts'}
          title={HandleSlidingName()}
        >
          {store.TableStore.selectedRowId.length && getMutipleEdit()}
        </SlidingPanel>
        <SlidingPanel
          contentId={'AddMultipleSupplierProducts'}
          title={HandleSlidingName()}
        >
          {store.TableStore.selectedRowId.length && (
            <AddMultipleSupplierProductComponent />
          )}
        </SlidingPanel>
        <SlidingPanel contentId={'AddMultipleTags'} title={HandleSlidingName()}>
          <AssignMultipleTagsComponent />
        </SlidingPanel>
        <SlidingPanel
          contentId={'AddMultipleCollections'}
          title={HandleSlidingName()}
        >
          <AssignCollectionGenericMultipleComponent />
        </SlidingPanel>
        <SlidingPanel
          contentId={'RemoveMultipleTags'}
          title={HandleSlidingName()}
        >
          <RemoveMultipleTagsComponent />
        </SlidingPanel>
        <SlidingPanel
          contentId={'RemoveMultipleTransactions'}
          title={HandleSlidingName()}
        >
          <RemoveMultipleTransactionsComponent />
        </SlidingPanel>
      </>
    )
  },
)

export default Footer
