import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../../App'
import { Container } from './CalendarDay.styles'
import CalendarEntry from '../calendarEntry/CalendarEntry.component'
import { CreateOrders, CreateProducts } from '../calendar/Calendar.methods'

const CalendarDay = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const currentDate = store.CalendarStore.currentDate
  const dataForCurrentDate = store.CalendarStore.getDataForDate(currentDate)
  const day = currentDate.getDate()
  return (
    <Container>
      {day ? (
        <>
          <p>{day}</p>
          {store.CalendarStore.visibility.createdOrders ? (
            <CreateOrders
              title={'Created Orders:'}
              color={'#cdaa6e'}
              dataGetter={() =>
                store.CalendarStore.getOrderedDataForDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    day as number,
                  ),
                )
              }
            />
          ) : (
            <></>
          )}
          {store.CalendarStore.visibility.estimatedOrders ? (
            <CreateOrders
              title={'Estimated Delivery Orders:'}
              color={'blue'}
              dataGetter={() =>
                store.CalendarStore.getDataForDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    day as number,
                  ),
                )
              }
            />
          ) : (
            <></>
          )}
          {store.CalendarStore.visibility.deliveredOrders ? (
            <CreateOrders
              title={'Delivered Orders:'}
              color={'#234F1e'}
              deliveredHover={true}
              dataGetter={() =>
                store.CalendarStore.getDeliveredDataForDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    day as number,
                  ),
                )
              }
            />
          ) : (
            <></>
          )}
          {store.CalendarStore.visibility.deliveredProducts ? (
            <CreateProducts
              title={'Delivered Products:'}
              color={'green'}
              dataGetter={() =>
                store.CalendarStore.getDeliveredProductsForDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    day as number,
                  ),
                )
              }
            />
          ) : (
            <></>
          )}
          {store.CalendarStore.visibility.estimatedProducts ? (
            <CreateProducts
              title={'Estimated deliveries:'}
              color={'blue'}
              displayOrder={true}
              dataGetter={() =>
                store.CalendarStore.getEstimatedProductsForDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    day as number,
                  ),
                )
              }
            />
          ) : (
            <></>
          )}
          {store.CalendarStore.visibility.delayedProducts ? (
            <CreateProducts
              title={'Delayed Products:'}
              color={'red'}
              displayOrder={true}
              dataGetter={() =>
                store.CalendarStore.getDelayedProductsForDate(
                  new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    day as number,
                  ),
                )
              }
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Container>
  )
})

export default CalendarDay
