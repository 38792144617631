import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'
import Table from '../../_tableGeneric/table/Table.component'
import ParcelsComponent from './parcels/Parcels.component'
import SenderComponent from './sender/Sender.component'
import RecevierComponent from './receiver/Recevier.component'
import URL from '../../../assets/icons/url.png'
import Edit from '../../../assets/icons/edit.svg'
import TableDate from '../../_tableGeneric/tableDate/TableDate.component'
import { ActiveButton } from '../../_tableGeneric/tableLock/TableLock.styles'
import { NavLink } from 'react-router-dom'
import { MainTitle, SubTitle } from '../../headline/Headline.styles'
/* eslint-disable */
export const SinglePackagesTableComponent = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  const { Theme } = store.Theme
  const columns = {
    id: {
      canFilter: false,
    },
    user_reference_number: {
      Cell: (cell: any) => (
        <NavLink to={`/customerorders/sklep440800_${cell.getValue()}`}>
          <ActiveButton active={true} {...Theme.tableLockStyles.activeButton}>
            {cell.getValue()}
          </ActiveButton>
        </NavLink>
      ),
      canFilter: false,
    },
    service: {
      canFilter: true,
    },
    state: {
      canFilter: true,
    },
    name: {
      canFilter: false,
    },
    package_id: {
      canFilter: false,
    },
    sender: {
      Cell: (cell: any) => (
        <SenderComponent data={cell.getValue()} secondary={true} />
      ),
      canFilter: false,
    },
    pickup: {
      Cell: (cell: any) => (
        <RecevierComponent data={cell.getValue()} secondary={true} />
      ),
      canFilter: false,
    },
    receiver: {
      Cell: (cell: any) => (
        <RecevierComponent data={cell.getValue()} secondary={true} />
      ),
      canFilter: false,
    },
    parcels: {
      Cell: (cell: any) => (
        <div>
          {cell.getValue() &&
            cell.getValue().map((f: any, i: number) => {
              return <ParcelsComponent data={f} key={i} secondary={true} />
            })}
        </div>
      ),
      canFilter: false,
    },
    documents_url: {
      Cell: (cell: any) => (
        <a href={cell.getValue()} target={'_blank'} rel='noreferrer'>
          <img style={{ width: '24px' }} src={URL} />
        </a>
      ),
      canFilter: false,
    },
    add_similar_url: {
      Cell: (cell: any) => (
        <a href={cell.getValue()} target={'_blank'} rel='noreferrer'>
          <img style={{ width: '24px' }} src={URL} />
        </a>
      ),
      canFilter: false,
    },
    edit_url: {
      Cell: (cell: any) => (
        <a href={cell.getValue()} target={'_blank'} rel='noreferrer'>
          {cell.getValue() ? (
            <img style={{ width: '24px' }} src={Edit} />
          ) : (
            <></>
          )}
        </a>
      ),
      canFilter: false,
    },
    datetime_order: {
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: true,
    },
    datetime_add: {
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: false,
    },
    datetime_delivery: {
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: false,
    },
    delivery_time: {
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: false,
    },
  }

  useEffect(() => {
    store.PackagesStore.fetchDetailsData()
    return () => store.PackagesStore.clearData()
  }, [])
  return (
    <>
      {store.PackagesStore.loaded && !store.PackagesStore.data.length ? (
        <>
          <MainTitle
            style={{
              marginTop: '12px',
              marginBottom: '12px',
            }}
          >
            {'Packages List'}
          </MainTitle>
          <SubTitle
            style={{
              marginTop: '12px',
              marginBottom: '12px',
            }}
          >
            {'No packages assigned to this order!'}
          </SubTitle>
        </>
      ) : (
        <Table
          config={{
            tableName: 'packagesDetails',
            data: store.PackagesStore.data || [],
            fetchData: () => {},
            columnHeaders: columns,
            displayColumnVisibility: true,
            allowColumnResize: true,
            displaySearch: false,
            displaySelect: false,
            displayExpand: false,
            displayTooltip: false,
            displayEntry: false,
            displayPaginator: false,
            displayInfinitySwitch: false,
            displayOrdinalList: true,
            allowSort: true,
            bank: false,
            actions: {
              display: true,
              label: locales.tableLabels.packagesDetails,
              edit: null,
              create: null,
              remove: null,
              assignTag: null,
              custom: ['getPackageDocuments'],
            },
            actionsMultiple: {
              display: true,
              label: locales.tableLabelsMultiple.packagesDetails,
              edit: null,
              create: null,
              remove: null,
              assignTag: false,
              custom: ['exportDocuments', 'copyToClipboard', 'downloadFile'],
            },
            headline: {
              visible: true,
              title: 'Packages List',
              subTitle: 'Packages List',
            },
            breadcrumb: [],
            loaded: store.PackagesStore.loaded,
          }}
        ></Table>
      )}
    </>
  )
})
