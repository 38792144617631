import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'
import {
  Container,
  HeaderCont,
  HeaderInner,
  HeaderList,
  HeaderTitle,
  HeaderInnerRight,
  Body,
  LeftBody,
} from './ContactsDetails.styles'
import { StoreContext } from '../../App'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../styles/Loading.styles'
import ContactDataPanel from '../../components/contactObjectDataPanel/ContactObjectDataPanel.component'
import ContactArrayDataPanel from '../../components/contactArrayDataPanel/ContactArrayDataPanel.component'
//import TableObjectDisplay from '../../components/TableObjectDisplay'

const ContactsDetails = observer(() => {
  const store = useContext(StoreContext)
  const params = useParams()
  const currentPage = params.page
  const loaded = store.ContactsStore.loaded.details

  useEffect(() => {
    store.ContactsStore.mockSettingUpData()
  }, [store.ContactsStore])

  const getData = () => {
    switch (currentPage) {
      case 'addresses':
        return store.ContactsStore.addressDetails
      case 'contacts':
        return store.ContactsStore.contactDetails
      case 'identities':
        return store.ContactsStore.identityDetails
      case 'companies':
        return store.ContactsStore.companyDetails
      default:
        return null
    }
  }

  const data = getData()

  const renderPanelsForData = (data) => {
    if (!data) return null

    return Object.entries(data).map(([key, value]) => {
      if (Array.isArray(value)) {
        // Use ContactArrayDataPanel if the value is an array of objects
        return (
          <ContactArrayDataPanel
            key={key}
            title={key}
            data={value}
            rowDisplay={false}
            arrowProps={{ showArrow: true, expanded: true }}
          />
        )
      } else {
        // @ts-ignore
        const columns = Object.entries(value).map(([subKey, subValue]) => ({
          header: subKey,
          value:
            typeof subValue === 'object'
              ? JSON.stringify(subValue)
              : String(subValue),
        }))

        return (
          <ContactDataPanel
            key={key}
            title={key}
            columns={columns}
            rowDisplay={false}
            arrowProps={{ showArrow: true, expanded: true }}
          />
        )
      }
    })
  }

  return (
    <Container loading={loaded}>
      {loaded ? (
        <>
          <HeaderCont>
            <Breadcrumb treeArray={['Panel', 'Contacts', currentPage || '']} />
            <HeaderList>
              <HeaderInner>
                <HeaderTitle>
                  {currentPage} id: {params.id}
                </HeaderTitle>
              </HeaderInner>
              <HeaderInnerRight></HeaderInnerRight>
            </HeaderList>
          </HeaderCont>
          <Body className={'hide-print'}>
            <LeftBody>
              {data ? renderPanelsForData(data) : <p>No data available</p>}
            </LeftBody>
          </Body>
        </>
      ) : (
        <LoadingContainer>
          <Wrap></Wrap>
        </LoadingContainer>
      )}
    </Container>
  )
})

export default ContactsDetails
