import Table from '../../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'
import TableReturnStatusComponent from '../../_tableGeneric/tableReturnStatus/TableReturnStatus.component'
import URL from '../../../assets/icons/url.png'
import ExportReturnDocumentsComponent from '../../_tableGeneric/exportReturnDocuments/ExportReturnDocuments.component'

/* eslint-disable */

const ReturnsTable = observer(() => {
  const store = useContext(StoreContext)

  let columns: { [key: string]: any } = {
    id: {
      canFilter: false,
      editable: false,
      editType: 'string',
    },
    date: {
      canFilter: false,
      editable: false,
      editType: 'string',
    },
    order_id: {
      canFilter: false,
      editable: false,
      editType: 'string',
    },
    amount: {
      canFilter: false,
      editable: false,
      editType: 'string',
    },
    customer_email: {
      canFilter: false,
      editable: false,
      editType: 'string',
    },
    status: {
      canFilter: false,
      editable: false,
      editType: 'string',
      Cell: (cell: any) => <TableReturnStatusComponent cell={cell} />,
    },
    documents: {
      Cell: (cell: any) => <ExportReturnDocumentsComponent cell={cell} />,
      canFilter: false,
      canSort: false,
    },
  }

  useEffect(() => {
    return () => store.ReturnsStore.clearData()
  }, [])

  return (
    <Table
      config={{
        tableName: 'returns',
        data: store.ReturnsStore.data || [],
        fetchData: () => store.ReturnsStore.fetchData(),
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: true,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayOrdinalList: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: null,
        actionsMultiple: null,
        headline: {
          visible: true,
          title: 'List of Returns',
          subTitle: 'List of Returns',
        },
        breadcrumb: [],
        loaded: store.StatusesStore.loaded,
      }}
    />
  )
})

export default ReturnsTable
