import { RootStore } from './Root.store'
import { action, makeAutoObservable } from 'mobx'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'

export class AuditStore {
  rootStore
  data = []
  loaded = false
  pagination: {
    tableSize: number
    pageSize: number
    pageIndex: number
    totalCount: number
  } = { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound fetchData = async () => {
    this.loaded = false
    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/audit_view?size=${this.pagination.pageSize}&from=${
      (this.pagination.pageIndex - 1) * this.pagination.pageSize
    }`

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }

    const res = await axios.post(url, {}, config)
    this.data = res.data.audits
    this.pagination.tableSize = await res.data.count
    this.loaded = true
  }

  @action.bound clearData = async () => {
    this.data = []
  }

  @action.bound setPagination = (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number,
  ) => {
    this.rootStore.SlidingPanelState.setSelectedSlider('')
    this.pagination = {
      tableSize,
      pageSize,
      pageIndex,
      totalCount,
    }
    try {
      this.fetchData()
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast(e.message.toString(), { type: 'error' })
      } else {
        // Handle other types of errors, if necessary
        toast('An unknown error occurred', { type: 'error' })
      }
    }
  }
}
