import Table from '../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import { useContext, useEffect } from 'react'
import { StoreContext } from '../../App'
import TableObjectDisplayComponent from '../_tableGeneric/tableObjectDisplay/TableObjectDisplay.component'
import { toJS } from 'mobx'

const ContactTableCompanies = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  console.log(toJS(store.ContactsStore.addresses))

  const columns = {
    id: {
      canSort: false,
      Header: 'ID',
      Cell: (cell: any) => <a href={'/contacts/companies/1'}>details</a>,
    },
    general_information: {
      Header: 'General Information',
      columns: {
        company_id: {
          canSort: false,
          Header: 'Company ID',
        },
        name: {
          canSort: false,
          Header: 'Name',
        },
        industry: {
          canSort: false,
          Header: 'Industry',
        },
        status: {
          canSort: false,
          Header: 'Status',
        },
      },
    },
    organization_roles: {
      Header: 'Organization Roles',
      Cell: (cell: any) => (
        <div>
          {cell.getValue().map((role: any, index: number) => (
            <TableObjectDisplayComponent key={index} data={role} />
          ))}
        </div>
      ),
      canSort: false,
    },
    party_relationships: {
      Header: 'Party Relationships',
      Cell: (cell: any) => (
        <div>
          {cell.getValue().map((relationship: any, index: number) => (
            <TableObjectDisplayComponent key={index} data={relationship} />
          ))}
        </div>
      ),
      canSort: false,
    },
    financial_information: {
      Header: 'Financial Information',
      columns: {
        account_currency: {
          canSort: false,
          Header: 'Account Currency',
        },
        preferredLanguage: {
          canSort: false,
          Header: 'Preferred Language',
        },
      },
    },
    contact_information: {
      Header: 'Contact Information',
      Cell: (cell: any) => (
        <div>
          {cell.getValue().map((contact: any, index: number) => (
            <TableObjectDisplayComponent key={index} data={contact} />
          ))}
        </div>
      ),
      canSort: false,
    },
    external_references: {
      Header: 'External References',
      Cell: (cell: any) => (
        <div>
          {cell.getValue().map((reference: any, index: number) => (
            <TableObjectDisplayComponent key={index} data={reference} />
          ))}
        </div>
      ),
      canSort: false,
    },
  }

  useEffect(() => {
    store.ContactsStore.fetchCompanies()
    return () => {
      store.ContactsStore.clearCompanies()
    }
  }, [])

  return (
    <Table
      config={{
        tableName: 'contactsCompanies',
        data: store.ContactsStore.companies || [],
        fetchData: () => store.ContactsStore.fetchCompanies(),
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayOrdinalList: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: null,
        actionsMultiple: null,
        headline: {
          visible: true,
          title: 'Companies',
          subTitle: 'List of Companies',
        },
        breadcrumb: [],
        loaded: store.ContactsStore.loaded.companies,
      }}
    ></Table>
  )
})
export default ContactTableCompanies
