/* eslint-disable */
import { observer } from 'mobx-react'
import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { StoreContext } from '../../../App'
import { GlobalFilter } from '../../filters/Filters.components'
import TableHeaderOptions from '../tableHeaderOptions/TableHeaderOptions.component'

import {
  TableGroupRow,
  TableHead,
  TableHead2,
  TableHeaderRow,
  TableRow,
} from './TableHeader.styles'
import { ScrollSyncPane } from 'react-scroll-sync'
import HeaderFilter from '../../headerFilter/HeaderFilter.component'
import useWindowDimensions from '../../../methods/hooks/useWindowDimensions'
import TableGroupHeaderComponent from '../tableGroupHeader/TableGroupHeader.component'
import Paginator from '../paginator/Paginator.component'

interface Props extends PropsWithChildren {
  columnWidth: boolean
  fetchData: () => void
  filterSwitch: boolean
  search: boolean
  table: any
  tableName: string
  change: boolean
  loaded: boolean
  toggleChange: (val: boolean) => void
  setColumnOrder: (updater: Array<string>) => void
  pagination?: {
    tableSize: number
    pageSize: number
    pageIndex: number
    totalCount: number
  }
  setPagination?: (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number,
  ) => void
  infinity?: boolean
}

const TableHeader: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const { width } = useWindowDimensions()
  const locales = store.TranslationsState.translations
  const [clearFiltersVisible, setClearFiltersVisible] = useState(false)
  const handlePageChange = (val: number) => {
    if (props.pagination && props.setPagination) {
      props.setPagination(
        props.pagination.tableSize,
        props.pagination.pageSize,
        val,
        props.pagination.totalCount,
      )
    }
  }

  const handlePageSize = (e: ChangeEvent<HTMLSelectElement>) => {
    if (
      e.target.value === locales.buttons.selectAll &&
      props.pagination &&
      props.setPagination
    ) {
      props.setPagination(
        props.pagination.tableSize,
        props.pagination.tableSize,
        1,
        props.pagination.totalCount,
      )
    } else if (props.pagination && props.setPagination)
      props.setPagination(
        props.pagination.tableSize,
        parseInt(e.target.value),
        1,
        props.pagination.totalCount,
      )
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setClearFiltersVisible(true)
      } else {
        setClearFiltersVisible(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const headerGroups = props.table.getHeaderGroups()

  return (
    <TableHead {...Theme.tableStyles.tableHead}>
      {headerGroups.map((headerGroup: any, index: number) => (
        <ScrollSyncPane key={'headerGroup' + index}>
          {index === 1 || headerGroups.length === 1 ? (
            <TableRow {...Theme.tableStyles.tableRow}>
              {headerGroup.headers.map((header: any, index: number) => {
                return (
                  <TableHeaderRow
                    colSpan={1}
                    key={header.id}
                    width={header.getSize()}
                  >
                    <TableHeaderOptions
                      width={header.getSize()}
                      header={header}
                      //setSortBy={props.setSortBy}
                      columnWidth={props.columnWidth}
                      change={props.change}
                      toggleChange={props.toggleChange}
                      index={index}
                      identifier={'main'}
                      setColumnOrder={props.setColumnOrder}
                      keys={[
                        ...headerGroup.headers.map((e: { id: string }) => {
                          return e.id
                        }),
                      ]}
                    />
                    {header.column.columnDef &&
                      header.column.columnDef.headerCustomOptions &&
                      header.column.columnDef.headerCustomOptions.canFilter &&
                      !props.filterSwitch &&
                      props.search &&
                      header.id !== 'selection' &&
                      header.id !== '_options' && (
                        <HeaderFilter
                          column={header}
                          tableName={props.tableName}
                          fetchData={props.fetchData}
                        />
                      )}
                  </TableHeaderRow>
                )
              })}
            </TableRow>
          ) : (
            <TableGroupRow
              style={{
                background: 'transparent',
              }}
              {...Theme.tableStyles.tableRow}
            >
              {headerGroup.headers.map((header: any, index: number) => {
                return (
                  <TableHeaderRow
                    colSpan={header.colSpan}
                    key={header.id}
                    width={header.getSize()}
                  >
                    <TableGroupHeaderComponent
                      width={header.getSize()}
                      header={header}
                      //setSortBy={props.setSortBy}
                      columnWidth={props.columnWidth}
                      change={props.change}
                      toggleChange={props.toggleChange}
                      index={index}
                      identifier={'main'}
                      setColumnOrder={props.setColumnOrder}
                      keys={[
                        ...headerGroup.headers.map((e: { id: string }) => {
                          return e.id
                        }),
                      ]}
                    />
                  </TableHeaderRow>
                )
              })}
            </TableGroupRow>
          )}
        </ScrollSyncPane>
      ))}
      {!props.filterSwitch && props.search ? (
        <TableRow {...Theme.tableStyles.tableRow}>
          <TableHead2>
            <GlobalFilter
              tableName={props.tableName}
              fetchData={props.fetchData}
            />
            {/*{!props.infinity && (
              <Paginator
                tableName={props.tableName}
                loaded={props.loaded}
                infinity={store.TableStore.infinitySwitch}
              />
            )}*/}
          </TableHead2>
        </TableRow>
      ) : null}
    </TableHead>
  )
})
export default TableHeader
