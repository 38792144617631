import { RootStore } from './Root.store'
import { action, makeAutoObservable } from 'mobx'
import axios from '../axiosconfig'
import { RuleType } from 'react-querybuilder'

export class FiltersStore {
  rootStore
  allFilters: {
    action: {
      label: string
      query: {
        rules: RuleType[]
        combinator: string
        not: boolean
      }
    }
    access_group: string
    id: string
    view: string
    created_by: string
    created_at: string
  }[] = []
  currentFilters: { value: string; label: string }[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound fetchAllFilters = async (): Promise<void> => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/filter_view?from=0&size=1000&user=${this.rootStore.UserStore.userID}`
    //console.log(url)
    const data = {}
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.post(url, data, config)
    this.allFilters = await response.data.filters
    this.filterFilters()
  }

  @action.bound filterFilters = () => {
    const temparray = [
      ...this.allFilters.filter(
        (e) => e.view == this.rootStore.TableStore.getApiRequestFromUrl(),
      ),
    ]
    this.currentFilters = temparray.map((e) => {
      return {
        value: e.id,
        label: e.action.label,
      }
    })
  }
}
