import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useEffect } from 'react'
import { StoreContext } from '../../App'
import Change from '../../assets/icons/change.svg'
import {
  ChangesBottom,
  ChangesBottomText,
  ChangesCircle,
  ChangesCont,
  ChangesInner,
  ChangesLeft,
  ChangesLine,
  ChangesRight,
  ChangesTopText,
  Container,
  HeaderTitle,
} from './ActionsHistory.styles'

interface Props extends PropsWithChildren {
  title: string
  taskList: { value: string; label: string }[]
}

const ActionsHistory: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const history = store.TableStore.detailsData.checkListItems
  const List = history ? Object.keys(history) : []
  const { Theme } = store.Theme

  return (
    <Container {...Theme.actionsHistoryStyles.container}>
      <HeaderTitle {...Theme.actionsHistoryStyles.headerTitle}>
        {props.title}
      </HeaderTitle>
      <ChangesCont {...Theme.actionsHistoryStyles.changesCont}>
        {history ? (
          props.taskList.map((e, index) =>
            history[e.value].selected ? (
              <ChangesInner key={index}>
                <ChangesLeft>
                  <ChangesCircle {...Theme.actionsHistoryStyles.changesCircle}>
                    <img src={Change} alt={'<>'} />
                  </ChangesCircle>
                  <ChangesLine {...Theme.actionsHistoryStyles.changesLine} />
                </ChangesLeft>
                <ChangesRight {...Theme.actionsHistoryStyles.changesRight}>
                  <ChangesTopText
                    {...Theme.actionsHistoryStyles.changesTopText}
                  >
                    {e.label}
                  </ChangesTopText>
                  <ChangesBottom {...Theme.actionsHistoryStyles.changesBottom}>
                    <ChangesBottomText
                      {...Theme.actionsHistoryStyles.changesBottomText}
                    >
                      {new Date(history[e.value].date).toLocaleString()}
                    </ChangesBottomText>
                    <ChangesBottomText
                      {...Theme.actionsHistoryStyles.changesBottomText}
                    >
                      {history[e.value].username}
                    </ChangesBottomText>
                  </ChangesBottom>
                </ChangesRight>
              </ChangesInner>
            ) : (
              <></>
            ),
          )
        ) : (
          <p>{'No changes made yet'}</p>
        )}
      </ChangesCont>
    </Container>
  )
})
export default ActionsHistory
