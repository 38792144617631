import styled, { css } from 'styled-components'
import { breakpoints } from '../../static/breakpoints'
import { Button } from '../navButton/NavButton.styles'
import Select from 'react-select'
import { NavLink } from 'react-router-dom'

interface LayoutSwitchProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
}

export const Cont = styled.div`
  height: 8px;
`
export const LayoutSwitch = styled.button<LayoutSwitchProps>`
  outline: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '13px')};
  color: ${(props) => (props.color ? props.color : '#e0fbfc')};
  text-align: center;

  svg {
    margin-right: 4px;
  }
`

interface StyledSelectProps {
  color?: string
  bgColor?: string
  margin?: string
  border?: string
  borderRadius?: string
}

export const StyledSelect = styled.select<StyledSelectProps>`
  margin-top: 6px;
  font-size: 14px;
  min-width: min(110px, 80%);
  z-index: 10;
  overflow-x: visible;
  height: 40px;
  width: 100%;
  border: ${(props) => (props.border ? props.border : 'transparent')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#000000')};
  color: ${(props) => (props.color ? props.color : 'white')};
  cursor: pointer;
  outline: none;

  :hover {
    border-color: transparent;
  }

  :focus {
    border-color: transparent;
  }
`

/* eslint-enable */
interface ContainerProps {
  bgColor?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  boxShadow?: string
  borderBottom?: string
}

export const Container = styled.header<ContainerProps>`
  display: flex;
  height: 54px;
  justify-content: space-between;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#000000')};
  color: ${(props) => (props.color ? props.color : '#e0fbfc')};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : 'rgba(0, 0, 0, 0.24) 0px 3px 8px'};
  border-bottom: ${(props) =>
    props.borderBottom ? props.borderBottom : '1px solid #e0fbfc'};

  button${LayoutSwitch} {
    width: auto;
    margin: 0 15px;
  }

  @media print {
    display: none;
  }
`
export const SideContainer = styled(Container)`
  position: fixed;
`
export const MainPanel = styled.div`
  display: flex;
  position: relative;
`

export const Logo = styled(NavLink)`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: calc(12px + ((100vw - 1200px) * 0.06));
`

interface LeftPanelInterface {
  expanded: boolean
}

export const LeftPanel = styled.div<LeftPanelInterface>`
  display: flex;
  overflow: hidden;
  max-width: 40vw;
  transition: all 0s;
  flex-shrink: 100;
  @media screen and (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const AbsoluteLeftPanel = styled(LeftPanel)<LeftPanelInterface>`
  position: absolute;
  background-color: #000000;
  width: max-content;
  max-width: 100vw;
  height: 53px;
  left: 0;
  display: ${({ expanded }) => (!expanded ? 'flex' : 'none')};
  z-index: 500 !important;
  padding: 2px;
  padding-bottom: 0;
  border-radius: 2px;
`

interface InputPanel {
  borderRadius?: string
  border?: string
  noHide?: boolean
}

export const InputPanel = styled.div<InputPanel>`
  display: flex;
  height: 40px;
  margin-top: 6.5px;
  margin-left: 16px;
  border: 1px solid transparent;
  flex-shrink: 20;
  ${(props) => props.noHide && `width: 300px`};

  :focus-within {
    border: ${(props) => (props.border ? props.border : '1px solid #98c1d9')};
  }

  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};

  svg {
    margin: 10px;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    display: ${(props) => (props.noHide ? 'flex' : 'none')};
  }
`

interface InputProps {
  color?: string
  bgColor?: string
  margin?: string
  noHide?: boolean
}

/* eslint-disable */
export const Input = styled.input<InputProps>`
  height: 80%;
  ${(props) =>
    !props.noHide && `width: calc(100px + ((100vw - 1080px) * 0.3));`};
  outline: none;
  margin: ${(props) => (props.margin ? props.margin : '4px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#000000')};
  color: ${(props) => (props.color ? props.color : '#98c1d9')};
  border: none;

  @media screen and (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    ${(props) =>
      !props.noHide && `width: calc(100px + ((100vw - 1080px) * 0.3));`};
  }
`
export const RightCont = styled.div`
  display: flex;
  flex-shrink: 1;
`
export const InnerRight = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  @media screen and (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`
export const SettingsWrap = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  @media screen and (max-width: ${breakpoints.desktop}) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

interface SideBarInterface {
  active: boolean
  expanded: boolean
}

export const Sidebar = styled.div<SideBarInterface>`
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: center;
  overflow-x: visible;
  min-height: 100vh;
  height: max-content !important;
  scrollbar-width: none;
  overflow-y: auto;
  background-color: #000000;
  width: 130px;
  flex-shrink: 0;
  margin-left: ${(props) =>
    props.active ? (props.expanded ? 'none' : '-66px') : '-130px'};
  // no time to transition
  @media screen and(min-width: ${breakpoints.tablet}) {
    display: none;
  }

  @media print {
    display: none;
  }
`

export const SideBarCont = styled(Sidebar)`
  position: relative;
  visibility: hidden;
  width: ${(props) =>
    props.active ? (props.expanded ? '130px' : '64px') : '0'} !important;
  z-index: -1;
  margin-left: 0;

  transition: all ease 0.2s !important;

  * {
    transition: all ease 0.2s !important;
  }
`

interface SidebarShowInterface {
  active: boolean
  expanded: boolean
}

const calculateSidebarShowLeftTransform = (props: SidebarShowInterface) => {
  if (props.active && props.expanded) {
    return '0px'
  } else if (props.active) {
    return '-66px'
  } else {
    return '-130px'
  }
}
export const SidebarShow = styled.button<SidebarShowInterface>`
  position: fixed;
  z-index: 2;
  top: calc(50vh - 30px);
  left: ${(props) => calculateSidebarShowLeftTransform(props)};

  p {
    background-color: #000000;
    width: 60px;
    height: 60px;
    margin: 20px 20px 20px 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 0;

    img {
      margin-left: 23px;
      filter: invert(1);
    }
  }

  //transition: left 0.2s ease-in-out;
`

export const HeaderShow = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;

  img {
    margin-top: 14px;
    filter: invert(1);
  }

  transition: left 0.2s ease-in-out;
`

interface SidebarInnerProps {
  bgColor?: string
  width?: string
  active: boolean
  expanded: boolean
}

/* eslint-disable */
export const SidebarInner = styled.div<SidebarInnerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  left: 0;
  height: 100vh;
  width: 100%;
  padding-left: ${(props) => (props.expanded ? '0px' : '66px')};
  box-sizing: border-box;
  z-index: 2;
  background: ${(props) => (props.bgColor ? props.bgColor : '#000000')};

  ::-webkit-scrollbar {
    display: none;
  }
`

interface SiderbarBottomProps {
  padding?: string
}

export const SiderbarBottom = styled.div<SiderbarBottomProps>`
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : '0 10px 90px')};
  margin-bottom: 200px;
`

interface SidebarLogoProps {
  marginTop?: string
}

export const SidebarLogo = styled.div<SidebarLogoProps>`
  height: 58px !important;
  display: flex;
  align-items: center;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '15px')};
  padding: 6px;
`

export const SearchIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #3b475a;
  cursor: pointer;
`

export const SearchContainer = styled.div`
  position: absolute;
  left: 50px;
  background-color: #000000;
  padding: 16px;
  border-radius: 8px;
`

/* eslint-disable */
interface SidebarMenuProps {
  marginTop?: string
  fontSize?: string
  padding?: string
  borderBottom?: string
}

export const SidebarMenu = styled.div<SidebarMenuProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '30px')};
  flex-shrink: 1;

  a {
    border: none !important;
  }

  button${Button} {
    width: 100%;
    font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
    padding: ${(props) => (props.padding ? props.padding : '20px 10px')};
    border-bottom: ${(props) =>
      props.borderBottom ? props.borderBottom : '1px solid #3b475a'};
  }
`
export const StyledProfile = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin: auto;
  margin-right: 8px;
`
export const Hamburger = styled.div`
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  margin: 0.5rem 1rem;
  @media screen and (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`
export const HamburgerBox = styled.span`
  width: 2.5rem;
  height: 2rem;
  display: inline-block;
  position: relative;
`
const HamburgerLine = css`
  width: 100%;
  height: 0.25rem;
  left: 0;
  position: absolute;
  background-color: white;
  transition: all 0.2s;
`

interface HamburgerInnerInterface {
  active: boolean
}

export const HamburgerInner = styled.span<HamburgerInnerInterface>`
  ${HamburgerLine};
  top: 50%;
  background-color: ${(props) => (props.active ? 'transparent' : 'white')};

  &::before {
    ${HamburgerLine};
    top: -0.625rem;
    content: ' ';
    transform: ${(props) =>
      props.active ? 'translateY(0.625rem) rotate(+45deg)' : 'none'};
  }

  &::after {
    ${HamburgerLine};
    top: 0.625rem;
    content: ' ';
    transform: ${(props) =>
      props.active ? 'translateY(-0.625rem) rotate(-45deg)' : 'none'};
  }
`
export const HamburgerContentMain = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #000000;
`
export const HamburgerContentHeader = styled.div`
  position: relative;
  height: 54px;
  display: flex;
  justify-content: space-between;
`
export const HamburgerContentInner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: #000000;
`

export const ExpanderCont = styled.div`
  display: flex;
  margin: 12px 0;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  font-size: 12px;
  flex-wrap: wrap;
  cursor: pointer;

  p {
    text-wrap: wrap;
    width: min-content;
  }

  svg {
    height: 20px;
    width: 20px;
    filter: invert(1);
    margin-bottom: 2px;
  }
`
/* eslint-enable */
