import styled from 'styled-components'
import { darken } from 'polished'
import { breakpoints } from '../../../static/breakpoints'

interface OuterContProps {
  bgColor?: string
  padding?: string
  borderRadius?: string
  border?: string
  boxShadow?: string
  margin?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  marginBottom?: string
}

/*eslint-disable */
export const OuterCont = styled.div<OuterContProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 80;
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};

  input {
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : '4px'};
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
    padding: ${(props) => (props.padding ? props.padding : '8px 6px')};
    border: ${(props) => (props.border ? props.border : '1px solid #e5e5e5')};
    width: 95%;
    margin-bottom: ${(props) =>
      props.marginBottom ? props.marginBottom : '12px'};
    box-shadow: ${(props) =>
      props.boxShadow ? props.boxShadow : ' 0 1px 3px rgba(0, 0, 0, 0.06)'};
  }
`

/*eslint-enable */
interface ContainerProps {
  padding?: string
  borderRadius?: string
  boxShadow?: string
  margin?: string
  color?: string
  bgColor?: string
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  max-width: calc(100vw - 64px);
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  margin: ${(props) => (props.margin ? props.margin : '32px')};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1 1 0;
  padding: ${(props) => (props.padding ? props.padding : '32px')};
  max-height: 75vh;
`
export const TableCont = styled.div`
  display: flex;
  flex-direction: column;
`
export const TableSecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  flex-wrap: wrap;
`
export const SecondRowLeft = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;

  > * {
    margin-right: 16px;
  }

  @media screen and (max-width: ${breakpoints.vertical}) {
    flex-direction: column;
  }
`
export const SecondRowRight = styled.div`
  display: flex;

  > * {
    margin-left: 16px;
  }

  @media screen and (max-width: ${breakpoints.vertical}) {
    flex-direction: column;
    > * {
      margin-left: 0;
    }
  }
`
export const NotesInput = styled.textarea`
  height: 40px;
  width: 220px;
  resize: both;
  border: 1px solid black;
  border-radius: 6px;
`

interface StyledSelectInterface {
  activeBgColor?: string
  inactiveBgColor?: string
  boxShadow?: string
  borderRadius?: string
  padding?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
  active: boolean
}

export const StyledSelect = styled.select<StyledSelectInterface>`
  height: 32px;
  width: 140px;
  margin-top: 4px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

interface HeaderInterface {
  trackBgColor?: string
  trackBgRadius?: string
  thumbBgColor?: string
  thumbBgRadius?: string
  thumbBorder?: string
}

/* eslint-disable */
export const Header = styled.div<HeaderInterface>`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  margin-right: 16px;
  overflow-x: auto;
  background-color: transparent;

  * {
    display: flex;
    justify-content: center;
  }

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.trackBgColor ? props.trackBgColor : '#aaaaaa'};
    border-radius: ${(props) =>
      props.trackBgRadius ? props.trackBgRadius : '3px'};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.thumbBgColor ? props.thumbBgColor : '#303030'};
    border-radius: ${(props) =>
      props.thumbBgRadius ? props.thumbBgRadius : '6px'};
    border: ${(props) =>
      props.thumbBorder ? props.thumbBorder : '3px solid #aaaaaa'};
  }
`

interface BodyInterface {
  trackBgColor?: string
  trackBgRadius?: string
  thumbBgColor?: string
  thumbBgRadius?: string
  thumbBorder?: string
}

/* eslint-disable */
export const Body = styled.div<BodyInterface>`
  display: flex;
  flex-direction: column;
  max-height: 45vh !important;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.trackBgColor ? props.trackBgColor : '#aaaaaa'};
    border-radius: ${(props) =>
      props.trackBgRadius ? props.trackBgRadius : '3px'};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.thumbBgColor ? props.thumbBgColor : '#303030'};
    border-radius: ${(props) =>
      props.thumbBgRadius ? props.thumbBgRadius : '6px'};
    border: ${(props) =>
      props.thumbBorder ? props.thumbBorder : '3px solid #aaaaaa'};
  }
`

/* eslint-enable */
interface RowProps {
  border?: string
  odd?: boolean
  bgColor?: string
  lastChild?: boolean
  trackBgColor?: string
  trackBgRadius?: string
  thumbBgColor?: string
  thumbBgRadius?: string
  thumbBorder?: string
}

/* eslint-disable */
export const Row = styled.div<RowProps>`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${(props) => props.odd && (props.bgColor || '#e5e5e5')};
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 60px;
  height: max-content;
  scrollbar-width: ${(props) => (props.lastChild ? 'auto' : 'none')};

  ::-webkit-scrollbar {
    display: ${(props) => (props.lastChild ? 'block' : 'none')};
  }

  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.trackBgColor ? props.trackBgColor : '#aaaaaa'};
    border-radius: ${(props) =>
      props.trackBgRadius ? props.trackBgRadius : '3px'};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.thumbBgColor ? props.thumbBgColor : '#303030'};
    border-radius: ${(props) =>
      props.thumbBgRadius ? props.thumbBgRadius : '6px'};
    border: ${(props) =>
      props.thumbBorder ? props.thumbBorder : '3px solid #aaaaaa'};
  }
`
export const TableHeaderCell = styled.div`
  position: relative;
  padding-left: 0.5rem;
  display: inline-block;
  text-transform: capitalize;
  text-overflow: ellipsis;
  width: 100px;
  flex-grow: 1;
  min-width: 60px;
  height: fit-content;
  overflow-x: hidden;
  overflow-y: visible;
  white-space: nowrap;
  z-index: 10;
`
export const TableCell = styled.div`
  position: relative;
  padding: 2px;
  flex: 1;
  min-width: 60px;
  height: auto;
  line-break: anywhere;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    margin-bottom: 4px;
  }
`

interface StyledTableCellInterface {
  active: boolean
}

export const StyledTableCell = styled(TableCell)<StyledTableCellInterface>`
  color: ${(props) => props.active && 'rgba(255, 0, 0, 0.8)'};
  background-color: ${(props) => props.active && 'rgba(255, 0, 0, 0.1)'};
`

interface TableInputCellInterface {
  active: boolean
}

export const TableInputCell = styled(TableCell)<TableInputCellInterface>`
  input {
    color: ${(props) => props.active && 'rgba(255, 0, 0, 0.8)'};
    background-color: ${(props) => props.active && 'rgba(255, 0, 0, 0.1)'};
  }
`

export const HeaderIconCont = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

interface HeaderTitleProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const HeaderTitle = styled.p<HeaderTitleProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '26px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  margin-right: 16px;
  margin-bottom: 4px;
`
export const HeaderIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-bottom: 16px;
  right: 0;
  cursor: pointer;
  filter: invert(1);
  display: flex;
  justify-content: flex-end;
`

interface ErrorMessageProps {
  margin?: string
  fontSize?: string
}

export const ErrorMessage = styled.p<ErrorMessageProps>`
  color: red;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  text-align: center;
  text-overflow: ellipsis;
  line-break: anywhere;
`
