import styled from 'styled-components'
import Modal from '@mui/material/Modal'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface ActiveButtonProps {
  color?: string
  bgColor?: string
  borderRadius?: string
  border?: string
  padding?: string
  active: boolean
}

export const ActiveButton = styled.div<ActiveButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '4px 12px')};
  margin-bottom: 4px;
  border: ${(props) =>
    props.active
      ? props.border
        ? props.border
        : '1px solid #20c33a'
      : '1px solid #C21F40'};
  background-color: ${(props) =>
    props.active ? (props.bgColor ? props.bgColor : '#f6fef7') : '#FFF7FA'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  color: ${(props) =>
    props.active ? (props.color ? props.color : '#20c33a') : '#C21F40'};
  cursor: pointer;
`

interface TextProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const Text = styled.p<TextProps>`
  color: ${(props) => (props.color ? props.color : '#575757')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.35px'};
  font-feature-settings:
    'tnum' on,
    'lnum' on;
`
export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WarningCont = styled.div`
  position: absolute;
  max-width: 400px;
  background-color: white;

  p {
    padding: 16px;
  }
`

interface FooterProps {
  bgColor?: string
  borderRadius?: string
}

export const Footer = styled.div<FooterProps>`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0 0 4px 4px'};
`
