import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer'
import { ReturnType } from '../../stores/Returns.store'

interface ReturnsPrintInterface {
  data: ReturnType
  products: {
    [key: string]: {
      code: string
      delivery_time: number
      type: string
      price: string
      name: string
      image_url: string
      weight: string
      stock_quantity: number
      tax: string
      [key: string]: any // additional fields may be present
    }
  }
}

// Register the Roboto font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: 20,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  text: {
    fontSize: 10,
    marginBottom: 3,
  },
  tableHeader: {
    fontSize: 10,
    fontWeight: 'bold',
    flexDirection: 'row',
    borderBottomWidth: 1,
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    paddingVertical: 2,
  },
  cell: {
    fontSize: 8,
    paddingHorizontal: 4,
    paddingVertical: 2,
    flexGrow: 1,
  },
  cellSmall: {
    flexBasis: '15%',
  },
  cellLarge: {
    flexBasis: '35%',
  },
  mediaFile: {
    width: 100,
    height: 100,
    marginBottom: 5,
  },
})

const ReturnsPrint = ({ data, products }: ReturnsPrintInterface) => {
  return (
    <Document>
      <Page style={styles.page}>
        {/* Title */}
        <Text style={styles.title}>Return Document</Text>

        {/* Return Info */}
        <View style={styles.section}>
          <Text style={styles.text}>Return ID: {data.id}</Text>
          <Text style={styles.text}>Date: {data.date}</Text>
          <Text style={styles.text}>Order ID: {data.order_id}</Text>
          <Text style={styles.text}>Customer Email: {data.customer_email}</Text>
          <Text style={styles.text}>Status: {data.status}</Text>
          <Text style={styles.text}>Type: {data.type}</Text>
          <Text style={styles.text}>Reason: {data.reason}</Text>
          <Text style={styles.text}>Comment: {data.comment}</Text>
          <Text style={styles.text}>Amount: {data.amount.toFixed(2)}</Text>
        </View>

        {/* Product List as Table */}
        <View style={styles.section}>
          <Text style={styles.heading}>Products</Text>

          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={[styles.cell, styles.cellSmall]}>Code</Text>
            <Text style={[styles.cell, styles.cellLarge]}>Name</Text>
            <Text style={[styles.cell, styles.cellSmall]}>Status</Text>
            <Text style={[styles.cell, styles.cellSmall]}>Amount</Text>
            <Text style={[styles.cell, styles.cellSmall]}>Price</Text>
            <Text style={[styles.cell, styles.cellSmall]}>Delivery (Days)</Text>
          </View>

          {/* Table Rows */}
          {data.products.map((product, index) => {
            const productData = Object.values(products).find(
              (item) => item.code === product.code,
            )

            return (
              <View key={index} style={styles.tableRow}>
                <Text style={[styles.cell, styles.cellSmall]}>
                  {product.code}
                </Text>
                <Text style={[styles.cell, styles.cellLarge]}>
                  {productData?.name || 'N/A'}
                </Text>
                <Text style={[styles.cell, styles.cellSmall]}>
                  {product.status}
                </Text>
                <Text style={[styles.cell, styles.cellSmall]}>
                  {product.amount}
                </Text>
                <Text style={[styles.cell, styles.cellSmall]}>
                  {productData?.price || 'N/A'}PLN
                </Text>
                <Text style={[styles.cell, styles.cellSmall]}>
                  {productData?.delivery_time || 'N/A'}
                </Text>
              </View>
            )
          })}
        </View>

        {/* Media Files */}
        {data.mediaFiles.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.heading}>Media Files</Text>
            {data.mediaFiles.map((file, index) => (
              <Image key={index} src={file} style={styles.mediaFile} />
            ))}
          </View>
        )}
      </Page>
    </Document>
  )
}

export default ReturnsPrint
