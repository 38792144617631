import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
/* eslint-disable */
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'
import ArrowExpanded from '../../assets/icons/arrow-expanded.svg'
import PrintIcon from '../../assets/icons/print-icon.svg'
import {
  AddressBody,
  AddressCont,
  Body,
  Container,
  HeaderButton,
  HeaderCont,
  HeaderInner,
  HeaderInnerRight,
  HeaderList,
  HeaderTitle,
  LeftBody,
  PrintFooter,
  RightBody,
  SecondaryButton,
  StyledImg,
  SwitchContainer,
  ButtonCont,
} from './ProformsDetails.styles'

import { StoreContext } from '../../App'
import ActionsHistory from '../../components/actionsHistory/ActionsHistory.component'
import DetailsDropDown from '../../components/detailsDropDown/DetailsDropDown.component'
import DetailsTable from '../../components/detailsTable/DetailsTable.component'
import ProductListTable from '../../components/ListOfProductsTable/ListOfProductsTable.component'
import { socket } from '../../socket/socket'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../styles/Loading.styles'
import { Switch } from '@mui/material'
import ReactPDF from '@react-pdf/renderer'
import MainPrint from '../../methods/prints/mainPrint'
import { SinglePackagesTableComponent } from '../../components/_settings/packages/SinglePackagesTable.component'
import StepsButton from '../../components/stepsButton/StepsButton.component'

const BlobProvider = ReactPDF.BlobProvider

const ProformsDetails = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const params = useParams()
  const [priorityDropDown, setPriorityDropDown] = useState(false)
  const [editCustomerDetails, setEditCustomerDetails] = useState(false)
  const [priorityLoading, setPriorityLoading] = useState(false)

  const PriorityList = ['default', 'low', 'medium', 'high']
  const priorityView: any = {
    default: 'Default',
    low: 'High',
    medium: 'Very High',
    high: 'Extremly High',
  }
  const getViewInfo = (e: string) => {
    return priorityView[e]
  }
  const getPriorityColor = () => {
    switch (store.TableStore.detailsData.priority) {
      case 'low':
        return 'yellow'
      case 'medium':
        return 'orange'
      case 'high':
        return 'red'
      default:
        return ''
    }
  }

  const NewDetailsArray = [
    { header: locales.details.status, value: 'status', editable: 'text' },
    {
      header: 'Completable (stock)',
      value: 'completable',
    },
    {
      header: 'Completable After Packing other Invoices',
      value: 'completableMicroserviceInt',
    },
    { header: locales.details.tags, value: 'tags' },
    { header: locales.details.tags, value: 'object_tags' },
    { header: 'Shopper Id', value: 'order' },
    { header: 'Ordered date and time', value: 'ordered' },
    { header: 'Created By', value: 'Created by' },
    { header: 'Last Edit', value: 'lastEdit' },
    { header: 'Last Edited By:', value: 'lastEditedBy' },
  ]

  const PaymentArray = [
    {
      header: locales.details.paymentMethod,
      value: 'paymentMethod',
      editable: 'text',
    },
    { header: 'Currency', value: 'currency_name', editable: 'text' },
    { header: 'Payment Status', value: 'paymentStatus' },
    { header: locales.details.paid, value: 'paid', editable: 'text' },
    { header: locales.details.paymentDifference, value: 'Payment difference' },
    { header: 'Shipping Cost', value: 'shipping_cost', editable: 'text' },
    { header: 'Total Discounts', value: 'total discounts' },
    { header: 'Order total (without taxes)', value: 'orderWithOutTaxes' },
    { header: 'Tax Amount', value: 'taxAmount' },
    { header: 'Order total with taxes', value: 'Sum' },
  ]

  const ShippingArray = [
    { header: locales.details.shipping, value: 'shipping' },
    {
      header: locales.details.estimateDate,
      value: 'estimateDate',
    },
  ]
  const ConversationsArray = [
    { header: 'orderDescription', value: 'orderDescription' },
    { header: 'lastExternalComment', value: 'lastExternalComment' },
    { header: 'lastInternalComment', value: 'lastInternalComment' },
    {
      header: 'Source system notes',
      value: 'notes_priv',
    },
  ]

  const AddressArray = [
    {
      header: locales.address.firstname,
      value: 'firstname',
      editable: 'text',
      column: 'customer',
    },
    {
      header: locales.address.lastname,
      value: 'lastname',
      editable: 'text',
      column: 'customer',
    },
    {
      header: locales.address.company,
      value: 'company',
      editable: 'text',
      column: 'customer',
    },
    {
      header: locales.address.city,
      value: 'city',
      editable: 'text',
      column: 'customer',
    },
    {
      header: locales.address.postcode,
      value: 'postcode',
      editable: 'text',
      column: 'customer',
    },
    {
      header: locales.address.street1,
      value: 'street1',
      editable: 'text',
      column: 'customer',
    },
    {
      header: locales.address.street2,
      value: 'street2',
      editable: 'text',
      column: 'customer',
    },
    {
      header: locales.address.country,
      value: 'country',
      editable: 'text',
      column: 'customer',
    },
  ]
  const BillingArray = [
    ...AddressArray,
    { header: 'NIP', value: 'tax_id' },
    { header: 'vies', value: 'vies' },
    { header: 'Regon', value: 'Regon' },
    { header: 'Phone', value: 'phone', editable: 'text', column: 'customer' },
    { header: 'Email', value: 'email', editable: 'text', column: 'customer' },
    //{ header: 'regon', value: 'regon' },
    //{ header: 'vatId', value: 'vatId' },
  ]

  const GusArray = [
    { header: locales.address.company, value: 'Nazwa' },
    { header: locales.address.city, value: 'Miejscowosc' },
    { header: locales.address.postcode, value: 'KodPocztowy' },
    { header: locales.address.street1, value: 'street1' },
    { header: locales.address.street2, value: 'street2' },
    { header: locales.address.country, value: 'country' },
    { header: 'NIP', value: 'Nip' },
    { header: 'Regon', value: 'Regon' },
  ]
  const SystemArray = [
    { header: 'source system order number', value: 'pageId' },
    {
      header: 'source system',
      value: 'type',
      column: 'system',
    },
    {
      header: 'source system instance',
      value: 'url',
      column: 'system',
    },
    { header: 'domain', value: 'domain', column: 'system' },
  ]

  useEffect(() => {
    store.TableStore.fetchDetailsData(params.id)
    return () => {
      store.TableStore.clearDetailsData()
    }
  }, [])

  useEffect(() => {
    socket.emit('pageUnlock', socket.id)
    socket.emit('pageLock', {
      id: store.RouteStore.socketId,
      user: store.UserStore.user,
      lockId: store.RouteStore.currentDataId,
    })
  }, [store.UserStore.user])

  return (
    <Container
      loading={store.TableStore.detailsLoaded}
      {...Theme.proformsDetailsStyles.container}
    >
      {store.TableStore.detailsLoaded ? (
        <>
          <HeaderCont>
            <Breadcrumb
              treeArray={['Panel', 'Customer Orders', 'Order Details']}
            />
            <HeaderList>
              <HeaderInner>
                <HeaderTitle {...Theme.proformsDetailsStyles.headerTitle}>
                  {locales.headers.customerOrder}:{' '}
                  {store.TableStore.detailsData.order_number}
                </HeaderTitle>
                <ButtonCont>
                  <BlobProvider
                    document={
                      <MainPrint printTable={true} packingTable={false} />
                    }
                  >
                    {({ blob, url, loading, error }: any) => {
                      const handlePrint2 = async () => {
                        const fileURL = URL.createObjectURL(blob)
                        const a: HTMLAnchorElement = document.createElement('a')
                        a.href = fileURL
                        a.target = '_blank'
                        document.body.appendChild(a)
                        a.click()
                      }

                      return (
                        <HeaderButton
                          onClick={handlePrint2}
                          {...Theme.proformsDetailsStyles.headerButton}
                        >
                          <img src={PrintIcon} alt={'Print'} />
                          {locales.buttons.print}
                        </HeaderButton>
                      )
                    }}
                  </BlobProvider>
                  <BlobProvider
                    document={
                      <MainPrint printTable={false} packingTable={false} />
                    }
                  >
                    {({ blob, url, loading, error }: any) => {
                      const handlePrint2 = async () => {
                        const fileURL = URL.createObjectURL(blob)
                        const a: HTMLAnchorElement = document.createElement('a')
                        a.href = fileURL
                        a.target = '_blank'
                        document.body.appendChild(a)
                        a.click()
                      }

                      return (
                        <HeaderButton
                          onClick={handlePrint2}
                          {...Theme.proformsDetailsStyles.headerButton}
                        >
                          <img src={PrintIcon} alt={'Print'} />
                          {locales.buttons.printWithoutData}
                        </HeaderButton>
                      )
                    }}
                  </BlobProvider>
                </ButtonCont>
              </HeaderInner>
              <HeaderInnerRight>
                <SwitchContainer>
                  <p>{'Set Edit Mode'}</p>
                  <Switch
                    checked={editCustomerDetails}
                    onChange={() =>
                      setEditCustomerDetails(!editCustomerDetails)
                    }
                  />
                </SwitchContainer>
                <SecondaryButton
                  onClick={() => setPriorityDropDown(!priorityDropDown)}
                  bgColor={getPriorityColor()}
                  {...Theme.proformsDetailsStyles.headerButtonProps}
                  {...Theme.proformsDetailsStyles.secondaryButton}
                >
                  {!priorityLoading ? (
                    <p>
                      {store.TableStore.detailsData.priority !== 'default'
                        ? getViewInfo(store.TableStore.detailsData.priority)
                        : locales.buttons.setPriority}
                    </p>
                  ) : (
                    <p>
                      <LoadingContainer style={{ transform: 'scale(0.5)' }}>
                        <Wrap></Wrap>
                      </LoadingContainer>
                    </p>
                  )}
                  <StyledImg src={ArrowExpanded} alt={'->'} />
                  {priorityDropDown ? (
                    <DetailsDropDown
                      active={true}
                      setLoading={setPriorityLoading}
                      table={PriorityList}
                    />
                  ) : (
                    <></>
                  )}
                </SecondaryButton>
                <StepsButton />
              </HeaderInnerRight>
            </HeaderList>
          </HeaderCont>
          <Body className={'hide-print'}>
            <LeftBody>
              <DetailsTable
                editableMode={editCustomerDetails}
                title={locales.headers.details}
                columns={NewDetailsArray}
                data={store.TableStore.detailsData}
                showArrow={true}
                rowDisplay={true}
              />
              <DetailsTable
                editableMode={editCustomerDetails}
                title={'Payment Details'}
                columns={PaymentArray}
                data={store.TableStore.detailsData}
                showArrow={true}
                rowDisplay={true}
              />
              <AddressCont>
                <AddressBody>
                  <DetailsTable
                    title={'Shipping Details'}
                    columns={ShippingArray}
                    data={store.TableStore.detailsData}
                    showArrow={true}
                    editableMode={editCustomerDetails}
                  />
                  <DetailsTable
                    title={locales.headers.deliveryAddress}
                    columns={AddressArray}
                    data={store.TableStore.detailsData.customer || {}}
                    showArrow={true}
                    editableMode={editCustomerDetails}
                  />
                  <DetailsTable
                    title={'System'}
                    columns={SystemArray}
                    data={store.TableStore.detailsData.system || {}}
                    showArrow={true}
                    editableMode={editCustomerDetails}
                  />
                </AddressBody>
                <AddressBody>
                  <DetailsTable
                    title={`${locales.headers.billingAddress}`}
                    columns={BillingArray}
                    extradata={
                      store.TableStore.detailsData.gusData
                        ? store.TableStore.detailsData.gusData.dane
                        : {}
                    }
                    extraColumns={GusArray}
                    data={store.TableStore.detailsData.customer || {}}
                    showArrow={true}
                    editableMode={editCustomerDetails}
                  />
                  <DetailsTable
                    title={'Conversations and Notes'}
                    columns={ConversationsArray}
                    data={store.TableStore.detailsData}
                    showArrow={true}
                    editableMode={editCustomerDetails}
                  />
                </AddressBody>
              </AddressCont>
            </LeftBody>
            <RightBody>
              <ActionsHistory
                taskList={store.TableStore.getTaskList()}
                title={locales.headers.historyOfActions}
              />
            </RightBody>
          </Body>
          <ProductListTable
            view={'customerorder'}
            data={store.TableStore.detailsData}
          />
          <SinglePackagesTableComponent />
          <PrintFooter>
            Printed by {store.UserStore.user} on {new Date().toJSON()}
          </PrintFooter>
        </>
      ) : (
        <LoadingContainer>
          <Wrap></Wrap>
        </LoadingContainer>
      )}
    </Container>
  )
})

export default ProformsDetails
