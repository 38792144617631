import { observer } from 'mobx-react'
import { useContext, useState } from 'react'
import { StoreContext } from '../../../App'
import { ContButton } from './CopyToClipboard.styles'
import Button from '../../button/Button.component'

const CopyToClipboard = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [buttonClick, setButtonClick] = useState(false)

  const handleClick = () => {
    setButtonClick(true)
    window.prompt(
      'Copy to clipboard: Ctrl+C or ⌘ + C Enter',
      store.TableStore.selectedRowId.join('; '),
    )
    setTimeout(() => setButtonClick(false), 2000)
  }

  return (
    <Button
      type={'primary'}
      size={'small'}
      margin={'0 0 0 8px'}
      onClick={() => handleClick()}
    >
      {buttonClick ? locales.buttons.copied : locales.buttons.copyToClipboard}
    </Button>
  )
})
export default CopyToClipboard
