import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'
import { Header } from './Tags.styles'
import { RootTagsTable } from './rootTagsTable/RootTagsTable.component'
import { GroupTagsTable } from './groupTagsTable/GroupTagsTable.component'
import { TagsTable } from './tagsTable/TagsTable.component'

export const TagsComponent = observer(() => {
  const store = useContext(StoreContext)

  let TableComponent
  switch (store.TagsStore.view) {
    case 'root':
      TableComponent = RootTagsTable
      break
    case 'group':
      TableComponent = GroupTagsTable
      break
    case 'tag':
      TableComponent = TagsTable
      break
  }

  return (
    <div>
      <Header>
        {/*<HeaderLeft>
          <ViewButton
            view={'left'}
            selected={store.TagsStore.view === 'group'}
            onClick={() => store.TagsStore.setView('group')}
          >
            Group
          </ViewButton>
          <ViewButton
            selected={store.TagsStore.view === 'root'}
            onClick={() => store.TagsStore.setView('root')}
          >
            Root
          </ViewButton>
          <ViewButton
            view={'right'}
            selected={store.TagsStore.view === 'tag'}
            onClick={() => store.TagsStore.setView('tag')}
          >
            Tag
          </ViewButton>
        </HeaderLeft>*/}
      </Header>
      <TableComponent />
    </div>
  )
})
