import React, {
  PropsWithChildren,
  FC,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table'
import axios from '../../../axiosconfig'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'

import TableTags from '../tableTags/TableTags.component'
import { TableRenderedCheckbox } from '../../checkboxSelect/CheckboxSelect.component'

import {
  Body,
  CellStyles,
  Container,
  Header,
  HeaderColumn,
  HeaderGroup,
  RowStyle,
  Table,
  HeaderSearch,
  HeaderText,
  FooterCont,
  FooterValue,
  OptionsContainer,
} from './TableRendered.styles'
import { socket } from '../../../socket/socket'

import { observer } from 'mobx-react'
import { StoreContext } from '../../../App'
import TableDifferentValues from '../TableDifferentValues/TableDifferentValues.component'
import TableMoreActions from '../tableMoreActions/TableMoreActions.component'
import EditRenderedProductComponent from '../../editRenderedProduct/EditRenderedProduct.component'
import { TableQuickEditComponent } from '../TableQuickEdit/TableQuickEdit.component'
import RemoveRenderedProduct from '../../removeSupplierProduct/RemoveRenderedProduct.component'
import TableHeaderCheckboxes from '../tableHeaderCheckboxes/TableHeaderCheckboxes.component'
import TableRenderedCheckboxesComponent from '../tableRenderedCheckboxes/TableRenderedCheckboxes.component'
import { toJS } from 'mobx'
import FilterIcon from '../../../assets/icons/filter-icon.svg'
import Menu, { SubMenu } from 'rc-menu'
import { IconChevronDown, IconSquareChevronDown } from '@tabler/icons-react'
import {
  SwitchCont,
  SwitchInput,
  SwitchInputText,
  SwitchStyled,
  TableOptionsCont,
} from '../table/Table.styles'
import ColumnVisibility from '../columnVisibility/ColumnVisibility.component'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'
import TableOptions from '../../../assets/icons/table-options.svg'
import TableHeaderOptions from '../tableHeaderOptions/TableHeaderOptions.component'
import TableNewTagsComponent from '../tableNewTags/TableNewTags.component'
import AddSupplierProduct from '../../addSupplierProduct/AddSupplierProduct.component'
import { SingleTag } from '../tableTags/TableTags.styles'
import AssignOrderLineTagComponent from '../../_settings/tags/edits/assignOrderLineTag/AssignOrderLineTagAllTags.component'
import TableCellFirstColumn from '../tableCellFirstColumn/TableCellFirstColumn.component'
import TableActionsComponent from '../tableActions/TableActions.component'
import { toast } from 'react-toastify'
import TableDefaultCellComponent from '../tableDefaultCell/tableDefaultCell.component'

/* eslint-disable */
interface Props extends PropsWithChildren {
  id: number
  row: any
  selectedMainRows: number
  data?: any
  change: boolean
  toggleChange: (val: boolean) => void
}

const TableRenderedRapper: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const [data, setData] = useState<Array<object>>([])
  const [loaded, setLoaded] = useState<boolean>(false)

  const mapData = async (data: any) => {
    const dataKeys = Object.keys(toJS(data)).filter((e) => e !== 'translations')
    let responseData = [...dataKeys.map((e) => (data[e] ? toJS(data[e]) : {}))]
    responseData = responseData.filter((e) => Object.keys(e).length !== 0)
    responseData.map((e) => delete e.translations)
    setData(responseData)
  }

  const handleDefaultRowSelect = () => {
    const id = props.row
      .getAllCells()
      .find((e: any) => e.column.id === 'id')
      ?.getValue()
    const selectedDefaultRows = store.TableStore.selectedExpanded
      .filter((e) => e.rowId === id)
      .map((e: any) => e.values.ProductID)
    let arrayIndexes: string[] = []
    data.map((e: any, index: number) => {
      if (selectedDefaultRows.includes(e.ProductID)) {
        arrayIndexes = [`${index}`, ...arrayIndexes]
      }
    })
    let selectedObject = {}
    arrayIndexes.map((e) => {
      selectedObject = { ...selectedObject, [e]: 'true' }
    })
    //console.log(selectedObject)
    return selectedObject
  }
  const getData = async () => {
    try {
      const url = `${
        process.env.REACT_APP_BASE_URL
      }experience/v1/supplier_order/${props.row
        .getAllCells()
        .find((e: any) => e.column.id === 'id')
        ?.getValue()}`
      console.log(url)
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      })
      await mapData(response?.data.products)
    } catch (e) {
      setLoaded(true)
    }
  }

  useEffect(() => {
    let dataId = 0
    while (JSON.stringify(data[dataId]) == '{}') {
      dataId++
    }
    handleDefaultRowSelect()
    //console.log(data)
  }, [data])

  useEffect(() => {
    getData()
    socket.on('orderChange', (object) => {
      if (
        object.id ===
        props.row
          .getAllCells()
          .find((e: any) => e.column.id === 'id')
          ?.getValue()
      ) {
        console.log('getData')
        getData()
      }
    })

    store.TableStore.setExpandedRows({
      [props.row
        .getAllCells()
        .find((e: any) => e.column.id === 'id')
        ?.getValue()]: true,
      ...store.TableStore.expandedRows,
    })

    return () => {
      socket.off('orderChange')
      const expanded = Object.keys(store.TableStore.expandedRows).filter(
        (key) =>
          key !==
          props.row
            .getAllCells()
            .find((e: any) => e.column.id === 'id')
            ?.getValue(),
      )
      let expandedObject = {}
      expanded.map(
        (e: string) => (expandedObject = { [e]: true, ...expandedObject }),
      )
      console.log(expandedObject)
      if (store.TableStore.loaded) {
        console.log('here')
        store.TableStore.setExpandedRows(expandedObject)
      }
    }
  }, [])

  return (
    <TableRendered
      setData={setData}
      selectedRowIds={handleDefaultRowSelect()}
      {...props}
      data={data}
    />
  )
})

interface InnerProps extends PropsWithChildren {
  row: any
  selectedMainRows: number
  selectedRowIds: Record<string, boolean>
  data: Array<object>
  setData: any
}

const TableRendered: FC<InnerProps> = observer(
  ({ row, selectedMainRows, data, setData, selectedRowIds }) => {
    const store = useContext(StoreContext)
    const locales = store.TranslationsState.translations
    const { Theme } = store.Theme
    const [change, toggleChange] = useState(false)
    const [expanded, setExpanded] = useState([])
    const columnHeaders: {
      [e: string]: {
        Cell?: (cell: any) => JSX.Element
        Header?: string | JSX.Element
        filter?: string
        columns?: any
        Filter?: any
        canFilter?: boolean
        canSort?: boolean
        aggregate?: string
        Aggregated?: (val: any) => string
        editable?: boolean
        editType?: string
        dateFormat?: string
        mandatory?: boolean
        canBeNull?: boolean
        selectValues?: { value: string; label: string }[]
        arrayValues?: string[]
        mandatoryLanguage?: string
        regex?: string
        isVisible?: boolean
      }
    } = {
      ProductID: {
        canFilter: true,
        editable: false,
        editType: 'text',
      },
      ProductCode: {
        canFilter: true,
        editable: false,
        editType: 'text',
      },
      Name: {
        canFilter: true,
        editable: false,
        editType: 'text',
      },
      ProducerName: {
        canFilter: true,
        editable: false,
        editType: 'text',
      },
      SupplierDeliveryCode: {
        canFilter: true,
        editable: true,
        editType: 'text',
      },
      OrderLink: {
        canFilter: true,
        editable: true,
        editType: 'text',
      },
      ean: {
        canFilter: true,
        editable: false,
        editType: 'text',
      },
      PurchasePrice: {
        canFilter: true,
        editable: true,
        editType: 'text',
      },
      Tags: {
        canFilter: false,
        editable: false,
        Cell: (cell: any) => (
          <TableTags key={cell.getValue()} tags={cell.getValue()} />
        ),
      },
      OrderLineTags: {
        canFilter: false,
        editable: false,
        Cell: (cell: any) => (
          <TableNewTagsComponent
            key={cell.getValue()}
            tags={cell.getValue()}
            orderLine={true}
          />
        ),
      },
      SupplierDeliveryDate: {
        canFilter: true,
        editable: true,
        editType: 'date',
        dateFormat: 'DD.MM.YYYY',
        Header: 'Estimated Delievery Date',
        Cell: (cell: any) => (
          <>
            {cell.row
              .getAllCells()
              .find((e: any) => e.column.id === 'OrderedQuantity')
              ?.getValue() !==
            cell.row
              .getAllCells()
              .find((e: any) => e.column.id === 'DeliveredQuantity')
              ?.getValue() ? (
              <TableDifferentValues
                key={cell.getValue()}
                value={cell.getValue()}
                fromValue={row
                  .getAllCells()
                  .find((e: any) => e.column.id === 'estimateDate')
                  ?.getValue()}
              />
            ) : (
              cell.getValue()
            )}
          </>
        ),
      },
      OrderedQuantity: {
        canFilter: true,
        editable: true,
        editType: 'text',
      },
      DeliveredQuantity: {
        canFilter: true,
        editable: true,
        editType: 'text',
        Cell: (cell: any) => (
          <TableDifferentValues
            key={cell.getValue()}
            value={cell.getValue()}
            form={true}
            fromValue={cell.row
              .getAllCells()
              .find((e: any) => e.column.id === 'OrderedQuantity')
              ?.getValue()}
            productId={cell.row
              .getAllCells()
              .find((e: any) => e.column.id === 'ProductCode')
              ?.getValue()}
            orderId={row
              .getAllCells()
              .find((e: any) => e.column.id === 'id')
              ?.getValue()}
            columnName={'DeliveredQuantity'}
            type={'number'}
          />
        ),
      },
      DeliveredDate: {
        canFilter: true,
        editable: true,
        editType: 'date',
        dateFormat: 'DD.MM.YYYY',

        Cell: (cell: any) =>
          cell.row
            .getAllCells()
            .find((e: any) => e.column.id === 'SupplierDeliveryDate')
            ?.getValue() &&
          cell.row
            .getAllCells()
            .find((e: any) => e.column.id === 'SupplierDeliveryDate')
            ?.getValue() < cell.getValue() ? (
            <TableDifferentValues
              key={cell.getValue()}
              value={cell.getValue()}
              form={true}
              fromValue={cell.row
                .getAllCells()
                .find((e: any) => e.column.id === 'SupplierDeliveryDate')
                ?.getValue()}
              productId={cell.row
                .getAllCells()
                .find((e: any) => e.column.id === 'ProductCode')
                ?.getValue()}
              orderId={row
                .getAllCells()
                .find((e: any) => e.column.id === 'id')
                ?.getValue()}
              columnName={'DeliveredDate'}
              type={'date'}
            />
          ) : (
            <TableQuickEditComponent
              productId={cell.row
                .getAllCells()
                .find((e: any) => e.column.id === 'ProductCode')
                ?.getValue()}
              orderId={row
                .getAllCells()
                .find((e: any) => e.column.id === 'id')
                ?.getValue()}
              defaultValue={cell.getValue()}
              columnName={'DeliveredDate'}
              type={'date'}
            />
          ),
      },
      Notes: {
        canFilter: true,
        editable: true,
        editType: 'text',
        Cell: (cell: any) => (
          <TableQuickEditComponent
            type={'textarea'}
            productId={cell.row
              .getAllCells()
              .find((e: any) => e.column.id === 'ProductCode')
              ?.getValue()}
            orderId={row
              .getAllCells()
              .find((e: any) => e.column.id === 'id')
              ?.getValue()}
            defaultValue={cell.getValue()}
            columnName={'Notes'}
          />
        ),
      },
      CreatedBy: {
        canFilter: true,
        editable: false,
        editType: 'text',
      },
      LastEditAuthor: {
        canFilter: true,
        editable: false,
        editType: 'text',
      },
    }

    const id = row
      .getAllCells()
      .filter((e: any) => e.column.id === 'id')[0]
      .getValue()

    const actions = [
      { title: 'Edit Single Product', component: EditRenderedProductComponent },
      {
        title: 'Remove this Product',
        component: RemoveRenderedProduct,
      },
      {
        title: 'Assign Tag to OrderLine',
        component: AssignOrderLineTagComponent,
      },
    ]

    const defaultColumn = React.useMemo(
      () => ({
        size: 70,
        headerCustomOptions: {
          sort: {
            desc: true,
          },
          settings: {
            wrapText: true,
          },
        },
      }),
      [],
    )

    const columns: any = useMemo(() => {
      let baseColumns = Object.keys(columnHeaders).map((e) => {
        return {
          header: columnHeaders[e].Header ? columnHeaders[e].Header : e,
          accessorKey: e,
          defaultWidth: 70,
          size:
            JSON.parse(
              localStorage.getItem(
                `${store.RouteStore.currentPage}-columnWidths`,
              )!,
            )?.[e] || 70,
          id: e,
          ...(columnHeaders[e].Filter && { Filter: columnHeaders[e].Filter }),
          ...(columnHeaders[e].Header && { header: columnHeaders[e].Header }),
          ...(columnHeaders[e].filter && {
            filter: { name: columnHeaders[e].filter },
          }),
          ...(columnHeaders[e].Cell && { cell: columnHeaders[e].Cell }),
          ...(columnHeaders[e].columns && {}),
          ...(columnHeaders[e].aggregate && {
            aggregate: columnHeaders[e].aggregate,
          }),
          ...(columnHeaders[e].Aggregated && {
            Aggregated: columnHeaders[e].Aggregated,
          }),
          ...(columnHeaders[e].isVisible && {
            isVisible: columnHeaders[e].isVisible,
          }),
          headerCustomOptions: {
            canFilter: columnHeaders[e].canFilter !== false ? true : false,
            canSort: columnHeaders[e].canSort !== false ? true : false,
            sort: {
              desc: true,
            },
            settings: {
              wrapText: !store.TableStore.clipTextColumns.includes(e),
              color:
                store.ViewStore.columnColors.find((c) => c.column === e)
                  ?.color || 'transparent',
              border:
                store.ViewStore.columnBorder.find((c) => c.column === e)
                  ?.border || false,
            },
          },
          editCustomOptions: {
            ...(columnHeaders[e].hasOwnProperty('editable') && {
              editable: columnHeaders[e].editable,
            }),
            ...(columnHeaders[e].editType && {
              editType: columnHeaders[e].editType,
            }),
            ...(columnHeaders[e].mandatory && {
              mandatory: columnHeaders[e].mandatory,
            }),
            ...(columnHeaders[e].canBeNull && {
              canBeNull: columnHeaders[e].canBeNull,
            }),
            ...(columnHeaders[e].selectValues && {
              selectValues: columnHeaders[e].selectValues,
            }),
            ...(columnHeaders[e].dateFormat && {
              dateFormat: columnHeaders[e].dateFormat,
            }),
            ...(columnHeaders[e].arrayValues && {
              arrayValues: columnHeaders[e].arrayValues,
            }),
            ...(columnHeaders[e].mandatoryLanguage && {
              mandatoryLanguage: columnHeaders[e].mandatoryLanguage,
            }),
            ...(columnHeaders[e].regex && {
              regex: columnHeaders[e].regex,
            }),
          },
        }
      })

      baseColumns = [
        {
          id: 'lp',
          //@ts-ignore
          size: 20,
          cell: ({ row }: any) => <div>{`${parseInt(row.id) + 1}.`}</div>,
        },
        {
          id: 'selection',
          size: 40,
          //@ts-ignore
          header: () => (
            <TableRenderedCheckboxesComponent id={id} data={data} />
          ),
          cell: ({ cell }: any) => (
            <TableRenderedCheckbox
              //@ts-ignore
              checked={
                !!store.TableStore.selectedExpanded.filter(
                  (e) =>
                    e.rowId === id &&
                    cell.row
                      .getAllCells()
                      .find((e: any) => e.column.id === 'ProductID')
                      //@ts-ignore
                      .getValue() === e.values.ProductID,
                ).length
              }
              selectedMainRows={selectedMainRows}
              handleClick={(e: any) => {
                const valuesID = cell.row
                  .getAllCells()
                  .find((e: any) => e.column.id === 'ProductID')
                  .getValue()
                const values: any = data.find(
                  (e: any) => e.ProductID === valuesID,
                )

                if (e.target.checked)
                  store.TableStore.setSelectedExpanded([
                    ...store.TableStore.selectedExpanded,
                    { rowId: id, values: values },
                  ])
                else
                  store.TableStore.setSelectedExpanded(
                    store.TableStore.selectedExpanded.filter(
                      (e: any) =>
                        !(e.rowId === id && e.values.ProductID === valuesID),
                    ),
                  )

                store.TableStore.setAllSelectedRows(false)
                store.TableStore.setSelectedRows([])
              }}
            />
          ),
        },
        ...baseColumns,
      ]

      baseColumns.push({
        id: '_options',
        //@ts-ignore
        size: 40,
        enableHiding: false,
        disableSortBy: true,
        cell: ({ row }: any) => (
          <TableMoreActions
            loaded={store.TableStore.loaded}
            actions={actions}
            columns={Object.keys(columnHeaders)}
            row={row}
            rendered={true}
            tableRow={id}
          />
        ),
      })

      return baseColumns
    }, [
      data,
      store.TableStore.selectedRowId.length,
      store.TableStore.allSelectedRows,
      store.TableStore.selectedRowId,
      store.TableStore.selectedExpanded,
      store.TableStore.expandedRows,
    ])

    const table = useReactTable({
      columns,
      data,
      defaultColumn,
      initialState: {
        selectedRowIds: selectedRowIds,
        hiddenColumns: ['ProductID'],
      },
      getCoreRowModel: getCoreRowModel(),
    })

    const getOrderedProductsSum = () => {
      return data.reduce(
        (total, obj: any) => total + parseInt(obj.OrderedQuantity),
        0,
      )
    }
    const getDeliveredProductsSum = () => {
      return data.reduce(
        (total, obj: any) => total + parseInt(obj.DeliveredQuantity),
        0,
      )
    }

    /*useEffect(() => {
                                                                                                                                                                                                                                                                                                                                              const id = row
                                                                                                                                                                                                                                                                                                                                                .getAllCells()
                                                                                                                                                                                                                                                                                                                                                .filter((e: any) => e.column.id === 'id')[0].value
                                                                                                                                                                                                                                                                                                                                              const selectedArray = [
                                                                                                                                                                                                                                                                                                                                                ...store.TableStore.selectedExpanded.filter((a) => a.rowId !== id),
                                                                                                                                                                                                                                                                                                                                                ...expanded.map((d: any) => ({
                                                                                                                                                                                                                                                                                                                                                  rowId: id,
                                                                                                                                                                                                                                                                                                                                                  values: d,
                                                                                                                                                                                                                                                                                                                                                })),
                                                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                                                              //store.TableStore.setSelectedExpanded(selectedArray)
                                                                                                                                                                                                                                                                                                                                            }, [store.TableStore.selectedExpanded])*/

    useEffect(() => {
      if (selectedMainRows) store.TableStore.setSelectedExpanded([])
    }, [selectedMainRows])

    return (
      <Container>
        <OptionsContainer>
          <TableOptionsCont
            style={{
              position: 'absolute',
              width: '40px',
              right: '6px',
              zIndex: '10',
              justifyContent: 'flex-end',
            }}
          >
            <img
              onClick={() =>
                store.SlidingPanelState.setSelectedSlider(
                  `optionsList-rendered-${id}`,
                )
              }
              src={TableOptions}
            />
          </TableOptionsCont>
        </OptionsContainer>
        <ScrollSync>
          <Table>
            <Header
              zIndex={
                !/^tableHeader-.*-main$/.test(
                  store.SlidingPanelState.selectedSlider,
                )
              }
            >
              {table.getHeaderGroups().map((headerGroup: any, i: any) => (
                <ScrollSyncPane key={`table-rendered-row-${i}`}>
                  <HeaderGroup>
                    {headerGroup.headers.map((header: any, index: number) => (
                      <HeaderColumn width={header.getSize()} key={index}>
                        <TableHeaderOptions
                          header={header}
                          columnWidth={true}
                          change={change}
                          toggleChange={toggleChange}
                          index={index}
                          identifier={id}
                          width={header.getSize()}
                          setColumnOrder={() => console.log('teest')}
                          keys={[
                            ...headerGroup.headers.map((e: { id: string }) => {
                              return e.id
                            }),
                          ]}
                        />
                      </HeaderColumn>
                    ))}
                  </HeaderGroup>
                </ScrollSyncPane>
              ))}
            </Header>
            <Body>
              {table.getRowModel().rows.map((rows: any, i: number) => {
                if (data[i])
                  return (
                    <ScrollSyncPane key={`table-rendered-row-${i}`}>
                      <RowStyle
                        select={false}
                        odd={i % 2 == 0}
                        lastChild={i + 1 === data.length}
                      >
                        {rows
                          .getVisibleCells()
                          .map((cell: any, index: number) => {
                            return (
                              <CellStyles
                                wrapText={
                                  cell.column.columnDef.headerCustomOptions
                                    ? cell.column.columnDef.headerCustomOptions
                                        .settings.wrapText
                                    : false
                                }
                                key={index}
                                width={cell.column.getSize()}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </CellStyles>
                            )
                          })}
                      </RowStyle>
                    </ScrollSyncPane>
                  )
              })}
            </Body>
          </Table>
        </ScrollSync>
        <FooterCont>
          <FooterValue>
            Sum of ordered products: {getOrderedProductsSum()}
          </FooterValue>
          <FooterValue>
            Sum of delievered products: {getDeliveredProductsSum()}
          </FooterValue>
        </FooterCont>
        <SlidingPanel
          contentId={`optionsList-rendered-${id}`}
          title={'Options List'}
          icon={FilterIcon}
        >
          <Menu
            mode='inline'
            style={{ margin: 0, width: '100%' }}
            expandIcon={<IconChevronDown size='18px' />}
            defaultOpenKeys={['1']}
          >
            <SubMenu title={locales.options.columnVisibility} key={'2'}>
              <ColumnVisibility
                table={table}
                change={change}
                toggleChange={() => toggleChange(!change)}
              />
            </SubMenu>
          </Menu>
        </SlidingPanel>
      </Container>
    )
  },
)
export default TableRenderedRapper
