import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { StoreContext } from '../../App'
import ArrowExpanded from '../../assets/icons/arrow-expanded.svg'

import {
  ItemsTable,
  SingleItemCont,
  SingleItemTitle,
  SingleItemValue,
  StyledTable,
  TableHead,
  Title,
} from './DetailsTable.styles'
import { toJS } from 'mobx'
import TableTags from '../_tableGeneric/tableTags/TableTags.component'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { EditInput } from '../editInput/EditInput.component'
import TableNewTagsComponent from '../_tableGeneric/tableNewTags/TableNewTags.component'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'

/* eslint-disable */
interface Props extends PropsWithChildren {
  data: {
    [key: string]: string | number | Date | object
  }
  extradata?: {
    [key: string]: string | number | Date | object
  }
  columns: Array<{ header: string; value: string; editable?: string }>
  extraColumns?: Array<{ header: string; value: string }>
  title: string | JSX.Element
  showArrow: boolean
  rowDisplay?: boolean
  editableMode?: boolean
}

const DetailsTable: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)

  const { Theme } = store.Theme
  const params = useParams()

  const locales = store.TranslationsState.translations
  const [expandedArrow, setExpandedArrow] = useState(true)
  const { width } = useWindowDimensions()
  const [displayRow, setDisplayRow] = useState(props.rowDisplay)

  const getValue = (e: string) => {
    if (e === 'tags') {
      //@ts-ignore
      return <TableTags tags={props.data[e] ? props.data[e] : {}} />
    }
    if (e === 'object_tags') {
      //@ts-ignore
      return <TableNewTagsComponent tags={props.data[e] ? props.data[e] : []} />
    }
    if (e === 'notes_priv' && props.data[e]) {
      const value = props.data[e].toString()
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: value.replace(/\r\n|\r|\n/g, '<br>'),
          }}
        />
      )
    }
    if (e === 'completableMicroserviceInt') {
      switch (props.data[e]) {
        case '0':
          return `${locales.packable.allMissing} (0)`
        case '1':
          return `${locales.packable.someMissing} (1)`
        case '2':
          return `${locales.packable.packable} (2)`
        case null:
          return 'null'
        default:
          return `${typeof props.data[e]} ${props.data[e]}`
      }
    } else if (
      e === 'Sum' ||
      e === 'Payment difference' ||
      e === 'tax' ||
      e === 'taxAmount' ||
      e === 'orderWithOutTaxes'
    ) {
      const data: any = props.data
      let sum = parseFloat(data.shipping_cost)
      let nettoSum = parseFloat(data.shipping_cost)
      const products: any = store.TableStore.detailsData.products
        ? Object.keys(
            //@ts-ignore
            store.TableStore.detailsData.products,
            //@ts-ignore
          ).map((key) => store.TableStore.detailsData.products[key])
        : []

      products?.map((e: any) => {
        sum = sum + parseFloat(e.price) * parseInt(e.quantity)
        nettoSum =
          nettoSum +
          (parseFloat(e.price) * parseInt(e.quantity) * parseInt(e.tax_value)) /
            100
      })

      if (e === 'Sum') return Math.round(sum * 100) / 100
      else if (e === 'Payment difference')
        return Math.round((sum - parseFloat(data['paid'])) * 100) / 100
      else if (e === 'taxAmount') return Math.round(nettoSum * 100) / 100
      else if (e === 'orderWithOutTaxes')
        return Math.round((sum - nettoSum) * 100) / 100
      else return Math.round((sum - parseFloat(data['paid'])) * 100) / 100
    } else if (
      (e === 'user_id' ||
        e === 'email' ||
        e === 'phone' ||
        e === 'country_code') &&
      props.data.customer
    ) {
      //@ts-ignore
      return props.data.customer[e]
    } else if (e === 'lastEdit' || e === 'lastEditedBy') {
      let newestDate: any = null
      let newestField: any = null
      const checkListItems: any = toJS(props.data.checkListItems)
      for (const field in checkListItems) {
        if (
          Object.prototype.hasOwnProperty.call(checkListItems, field) &&
          checkListItems[field].selected
        ) {
          const currentDate = new Date(checkListItems[field].date)

          if (newestDate === null || currentDate > newestDate) {
            newestDate = currentDate
            newestField = field
          }
        }
      }
      if (newestField && e === 'lastEdit')
        return checkListItems[newestField].date
      if (newestField && e === 'lastEditedBy')
        return checkListItems[newestField].username
    } else if (e === 'pageId') {
      return store.TableStore.detailsData.order
    } else if (e === 'vies') {
      const viesObj: any = store.TableStore.detailsData.vies
      if (viesObj !== null && viesObj !== undefined) {
        const viesObj: any = store.TableStore.detailsData.vies
        return (
          <a
            href={`${viesObj.blob_url}?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwlacupitfx&se=2030-01-05T19:45:37Z&st=2023-01-05T11:45:37Z&spr=https&sig=de5llLawOuvgxJoZ6XC2bSNQitD5spBwV2kFijl%2F9j4%3D`}
            target='_blank'
            rel='noreferrer'
          >
            {viesObj.valid}
          </a>
        )
      } else return 'No VIES data'
    } else if (props.data[e] && props.data[e] !== null)
      return props.data[e].toString()
  }

  const getExtraValue = (e: string) => {
    if (props.extraColumns && props.extradata) {
      const extraHeader = props.columns.find((x) => x.value === e)?.header
      const extraColumn = props.extraColumns.find(
        (x) => x.header === extraHeader,
      )
      if (extraColumn) {
        console.log(e)
        const dataValue = getValue(e)
        let extraDataValue = props.extradata[extraColumn.value]
        if (e === 'street1') {
          extraDataValue = `${props.extradata.Ulica} ${props.extradata.NrNieruchomosci}`
        } else if (e === 'street2') {
          extraDataValue = `${props.extradata.NrLokalu}`
        } else if (e === 'Regon') return extraDataValue
        if (dataValue !== extraDataValue && extraDataValue)
          return (
            <div>
              <b>{extraDataValue.toString()}</b> ({dataValue})
            </div>
          )
        else return dataValue
      } else return getValue(e)
    }
  }

  useEffect(() => {
    if (width < 600) setDisplayRow(false)
    else setDisplayRow(props.rowDisplay)
  }, [width])

  return (
    <StyledTable {...Theme.detailsDropDownStyles.styledTable}>
      <TableHead active={expandedArrow}>
        <Title {...Theme.detailsDropDownStyles.title}>{props.title}</Title>
        {props.showArrow ? (
          <img
            onClick={() => setExpandedArrow(!expandedArrow)}
            src={ArrowExpanded}
            alt={'>'}
          />
        ) : (
          <></>
        )}
      </TableHead>
      {expandedArrow ? (
        <ItemsTable
          rowDisplay={displayRow}
          {...Theme.detailsDropDownStyles.itemsTable}
        >
          {props.columns.map((e, index) => {
            if (props.columns.includes(e))
              return (
                <SingleItemCont
                  rowDisplay={displayRow}
                  onClick={() => {
                    if (!props.editableMode) {
                      navigator.clipboard.writeText(getValue(e.value))
                      toast('Copied', {
                        type: 'success',
                        autoClose: 500,
                      })
                    }
                  }}
                  key={index}
                  {...Theme.detailsDropDownStyles.singleItemCont}
                >
                  <SingleItemTitle
                    rowDisplay={displayRow}
                    {...Theme.detailsDropDownStyles.singleItemTitle}
                  >
                    {e.header}
                  </SingleItemTitle>
                  {props.editableMode && e.editable ? (
                    <SingleItemValue
                      rowDisplay={displayRow}
                      {...Theme.detailsDropDownStyles.singleItemValue}
                    >
                      <EditInput
                        columnName={e.value}
                        object={e}
                        orderId={store.RouteStore.currentDataId}
                        defaultValue={props.data[e.value].toString()}
                      />
                    </SingleItemValue>
                  ) : (
                    <SingleItemValue
                      rowDisplay={displayRow}
                      {...Theme.detailsDropDownStyles.singleItemValue}
                    >
                      {props.extraColumns &&
                      props.extradata &&
                      Object.keys(props.extradata).length
                        ? getExtraValue(e.value)
                        : getValue(e.value)}
                    </SingleItemValue>
                  )}
                </SingleItemCont>
              )
          })}
        </ItemsTable>
      ) : (
        <></>
      )}
    </StyledTable>
  )
})
export default DetailsTable
