import Table from '../_tableGeneric/table/Table.component'
import { observer } from 'mobx-react'
import { useContext, useEffect } from 'react'
import { StoreContext } from '../../App'
import TableObjectDisplayComponent from '../_tableGeneric/tableObjectDisplay/TableObjectDisplay.component'
import { toJS } from 'mobx'

const ContactTableContacts = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  console.log(toJS(store.ContactsStore.contacts))

  const columns = {
    id: {
      canSort: false,
      Header: 'ID',
      Cell: (cell: any) => <a href={'/contacts/contacts/1'}>details</a>,
    },
    basic_information: {
      Header: 'Basic Information',
      columns: {
        contact_id: {
          canSort: false,
          Header: 'Contact ID',
        },
        identity_id: {
          canSort: false,
          Header: 'Identity ID',
        },
        company_id: {
          canSort: false,
          Header: 'Company ID',
        },
      },
    },
    role_information: {
      Header: 'Role Information',
      columns: {
        role_type: {
          canSort: false,
          Header: 'Role Type',
        },
        status: {
          canSort: false,
          Header: 'Status',
        },
        valid_for: {
          Header: 'Validity Period',
          Cell: (cell: any) => (
            <TableObjectDisplayComponent data={cell.getValue()} />
          ),
        },
      },
    },
    communication_preferences: {
      Header: 'Communication Preferences',
      columns: {
        preferred_contact_method: {
          canSort: false,
          Header: 'Preferred Contact Method',
        },
        email: {
          canSort: false,
          Header: 'Email',
        },
        phone: {
          canSort: false,
          Header: 'Phone',
        },
        secondary_phone: {
          canSort: false,
          Header: 'Secondary Phone',
        },
        preferred_time_to_contact: {
          canSort: false,
          Header: 'Preferred Time to Contact',
        },
      },
    },
    address_references: {
      Header: 'Address References',
      columns: {
        mailing_address: {
          canSort: false,
          Header: 'Mailing Address',
          Cell: (cell: any) => (
            <TableObjectDisplayComponent data={cell.getValue()} />
          ),
        },
        visiting_address: {
          canSort: false,
          Header: 'Visiting Address',
          Cell: (cell: any) => (
            <TableObjectDisplayComponent data={cell.getValue()} />
          ),
        },
      },
    },
    assigned_representatives: {
      Header: 'Assigned Representatives',
      columns: {
        sales_rep_id: {
          canSort: false,
          Header: 'Sales Rep ID',
        },
        support_rep_id: {
          canSort: false,
          Header: 'Support Rep ID',
        },
      },
    },
    engagement_information: {
      Header: 'Engagement Information',
      columns: {
        last_contacted: {
          canSort: false,
          Header: 'Last Contacted',
        },
        engagement_score: {
          canSort: false,
          Header: 'Engagement Score',
        },
      },
    },
    external_references: {
      Header: 'External References',
      Cell: (cell: any) => (
        <div>
          {cell.getValue().map((reference: any, index: number) => (
            <TableObjectDisplayComponent key={index} data={reference} />
          ))}
        </div>
      ),
      canSort: false,
    },
  }

  useEffect(() => {
    store.ContactsStore.fetchContacts()
    return () => {
      store.ContactsStore.clearContacts()
    }
  }, [])

  return (
    <Table
      config={{
        tableName: 'contactsContacts',
        data: store.ContactsStore.contacts || [],
        fetchData: () => store.ContactsStore.fetchContacts(),
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayOrdinalList: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: null,
        actionsMultiple: null,
        headline: {
          visible: true,
          title: 'Contacts',
          subTitle: 'List of contacts',
        },
        breadcrumb: [],
        loaded: store.ContactsStore.loaded.contacts,
      }}
    />
  )
})

export default ContactTableContacts
