/* eslint-disable */
import axios from '../../axiosconfig'
import { observer } from 'mobx-react'
import { useContext, useEffect, useRef, useState } from 'react'

import { ValidateArray } from '../../columnsConfig'
import { StoreContext } from '../../App'

import { InnerCont, Footer } from './EditMultipleProductsByQuery.styles'
import { socket } from '../../socket/socket'
import { toast } from 'react-toastify'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'
import { getEmptyValue } from '../createGeneric/CreateGeneric.component'
import Button from '../button/Button.component'

interface formInterface {
  url: string
  columns: Array<any>
}

const EditMultipleProductsByQuery = observer(
  ({ url, columns }: formInterface) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations

    const divRef = useRef<any>()
    const [triggerChange, setTriggerChange] = useState(false)
    const [isAbleToSave, setIsAbleToSave] = useState(false)
    const initialState = [
      ...columns.map((e) => {
        return {
          header: e.id,
          value: getEmptyValue(e.editCustomOptions?.editType, e),
          error: '',
          rules: ValidateArray[e] ? ValidateArray[e] : [],
          isActive: false,
        }
      }),
    ]
    const [form, setForm] = useState(initialState)

    const handleSetForm = (
      tempForm: Array<{
        header: string
        value: string
        error: string
        rules: Array<string>
        isActive: boolean
      }>,
    ) => {
      setForm([...tempForm])
    }

    useEffect(() => {
      let changed = false

      form.map((f: any, index) => {
        if (f.isActive) {
          changed = true
        }
      })
      setIsAbleToSave(changed)
    }, [triggerChange])

    const handleSave = async () => {
      let bodyData: any = {}
      form.map((f: any, index) => {
        if (f.isActive) {
          bodyData = { ...bodyData, [f.header]: f.value }
        }
      })
      const data = {
        updateJSON: store.TableStore.formatRequestData(bodyData),
        includeIds: store.TableStore.selectedRowId,
        filterGroups: {
          rules: [],
          combinator: 'or',
          not: false,
        },
        excludeIds: [],
        searchAll: `**`,
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          username: store.UserStore.user,
        },
      }
      const res = await axios.post(url, data, config)
      console.log(res)

      store.TableStore.setLoaded(false)
      setTimeout(async () => await store.TableStore.fetchData(), 2000)
      store.SlidingPanelState.setVisibility(false)
      toast(`${res.data.updated} of ${res.data.total} has been changed`, {
        type: 'success',
      })
      if (res.data.failures.length > 0) {
        toast(`Failed to update: ${JSON.stringify(res.data.failures)}`, {
          type: 'error',
        })
      }
      setTimeout(() => store.TableStore.fetchData(), 2000)
      setTimeout(
        () =>
          store.TableStore.selectedRowId.forEach((e: any) => {
            socket.emit('editOrder', {
              id: e,
              user: store.UserStore.user,
              page: store.TableStore.getApiRequestFromUrl(),
            })
          }),
        2000,
      )
    }

    useEffect(() => {
      let changed = false
      form.map((e, index) => {
        if (e.value !== initialState[index].value) {
          changed = true
          return
        }
      })
      form.map((e, index) => {
        if (e.error !== '') {
          changed = false
          return
        }
      })
    }, [form])

    return (
      <div>
        <InnerCont cols={2} ref={divRef}>
          {form.length
            ? form.map((e, index) => {
                const customOptions = columns.find(
                  (col) => col.id === e.header,
                )?.editCustomOptions
                if (customOptions.editable)
                  return (
                    <DataInputGeneric
                      type={'create'}
                      vals={{ header: e.header, value: e.value }}
                      editOptions={customOptions}
                      key={`data-input--${index}`}
                      index={index}
                      form={form}
                      setForm={handleSetForm}
                      triggerChange={() => setTriggerChange(!triggerChange)}
                    />
                  )
              })
            : null}
        </InnerCont>
        <Footer {...Theme.editProductsStyles.styledFooter}>
          <Button
            type={'secondary'}
            onClick={() => store.SlidingPanelState.setVisibility(false)}
          >
            {locales.buttons.cancel}
          </Button>
          <Button
            type={'primary'}
            onClick={async () => {
              if (isAbleToSave) await handleSave()
            }}
            active={isAbleToSave}
          >
            {locales.buttons.save}
          </Button>
        </Footer>
      </div>
    )
  },
)
export default EditMultipleProductsByQuery
