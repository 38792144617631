import { observer } from 'mobx-react'
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { StoreContext } from '../../App'
/* eslint-disable */

import {
  FooterCont,
  SaveInput,
  SelectCont,
  SelectInput,
  SelectLabel,
  ViewButton,
  Header,
} from './Views.styles'
import { toast } from 'react-toastify'
import SearchSelectComponent from '../searchSelect/SearchSelect.component'
import Button from '../button/Button.component'
import { ViewOption } from '../../stores/View.store'

interface Props extends PropsWithChildren {
  tableName: string
  pagination?: number
  fetchData: any
  setLoaded?: (loaded: boolean) => void
}

const Views: FC<Props> = observer(
  ({ tableName, pagination, fetchData, setLoaded }) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations

    const [createMode, setCreateMode] = useState(false)
    const [saveValue, setSaveValue] = useState('')
    const [defaultViewValueInInput, setDefaultViewValueInInput] = useState('')

    const [reset, setReset] = useState(false)

    const setDefaultView = async (e) => {
      await store.ViewStore.setDefaultView(e.value, tableName)
    }
    const setSaveView = async () => {
      try {
        await store.ViewStore.saveView(saveValue, tableName, true, pagination)
        store.SlidingPanelState.setVisibility(false)
        const views = await store.ViewStore.getViews(tableName)
        await store.ViewStore.getViewsList(views, true)
      } catch (e) {
        toast('Error', {
          type: 'error',
        })
      }
    }

    const deleteView = async (view: { value: string; label: string }) => {
      console.log('testviwe', view)
      try {
        await store.ViewStore.deleteView(view.value)
        toast('Deleted', {
          type: 'success',
        })
        const views = await store.ViewStore.getViews(tableName)
        await store.ViewStore.getViewsList(views, true)
      } catch (e) {
        toast('Error', {
          type: 'error',
        })
      }
    }

    const initViews = async () => {
      const views = await store.ViewStore.getViews(tableName)
      await store.ViewStore.getViewsList(views, true)
    }
    useEffect(() => {
      initViews()
    }, [])

    useEffect(() => {
      if (store.TableStore.loaded) {
        if (!reset) setReset(true)
        else store.ViewStore.setSelectedView('')
      }
    }, [
      store.ViewStore.hiddenColumns,
      store.ViewStore.columnOrder,
      store.ViewStore.columnColors,
      store.ViewStore.columnBorder,
      store.TableStore.clipTextColumns,
      store.TableStore.query,
      store.TableStore.appliedPredefinedQuery,
    ])

    return (
      <SelectCont>
        <Header>
          <ViewButton
            selected={createMode}
            view={'left'}
            onClick={() => setCreateMode(false)}
          >
            Predefined View
          </ViewButton>
          <ViewButton
            view={'right'}
            selected={!createMode}
            onClick={() => setCreateMode(true)}
          >
            New View
          </ViewButton>
        </Header>
        {createMode ? (
          <>
            <SelectLabel>Set a name for this view</SelectLabel>
            <SaveInput
              value={saveValue}
              onChange={(e) => setSaveValue(e.target.value)}
            />
            <FooterCont>
              <Button
                type={'secondary'}
                onClick={() => store.SlidingPanelState.setVisibility(false)}
                {...Theme.buttons.secondaryButton}
              >
                {locales.buttons.cancel}
              </Button>
              <Button
                type={'primary'}
                onClick={async () => {
                  await setSaveView()
                }}
                {...Theme.buttons.primaryButton}
              >
                {locales.buttons.save}
              </Button>
            </FooterCont>
          </>
        ) : (
          <>
            <SelectLabel>Select Default view:</SelectLabel>
            <SearchSelectComponent
              Container={SelectInput}
              value={defaultViewValueInInput}
              options={store.ViewStore.viewsList}
              onClick={async (e) => {
                await setDefaultViewValueInInput(e.value)
              }}
              deleteFunction={async (e) => await deleteView(e)}
            />
            <SelectLabel>Select predefined view:</SelectLabel>
            <SearchSelectComponent
              Container={SelectInput}
              value={store.ViewStore.selectedView}
              options={store.ViewStore.viewsList}
              onClick={async (e) => {
                await store.ViewStore.setSelectedView(e.value)
              }}
              deleteFunction={async (e) => await deleteView(e)}
            />
            {store.ViewStore.selectedView.length ||
            defaultViewValueInInput !== store.ViewStore.selectedView ? (
              <FooterCont>
                <Button
                  type={'secondary'}
                  onClick={() => store.SlidingPanelState.setVisibility(false)}
                  {...Theme.buttons.secondaryButton}
                >
                  {locales.buttons.cancel}
                </Button>
                {store.ViewStore.selectedView.length ? (
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      if (setLoaded) await setLoaded(false)
                      await store.ViewStore.applyView(
                        store.ViewStore.selectedView,
                      )
                      await fetchData()
                      if (setLoaded) await setLoaded(true)
                    }}
                  >
                    {'APPLY'}
                  </Button>
                ) : (
                  <></>
                )}
                {defaultViewValueInInput !== store.ViewStore.selectedView ? (
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      await store.ViewStore.setDefaultView(
                        defaultViewValueInInput,
                        tableName,
                      )
                    }}
                    margin={'0 0 0 10px'}
                  >
                    {'SAVE DEFAULT'}
                  </Button>
                ) : (
                  <></>
                )}
              </FooterCont>
            ) : (
              <></>
            )}
          </>
        )}
      </SelectCont>
    )
  },
)
export default Views
