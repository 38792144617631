import styled from 'styled-components'

export const Container = styled.div`
  width: 250px !important;
  flex: 1;
  min-height: 350px !important;
  overflow: hidden;
`

interface FilterLabelProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
  marginTop?: string
}

export const FilterLabel = styled.label<FilterLabelProps>`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '20px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '20px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  color: ${(props) => (props.color ? props.color : '#424242')};
  max-width: 180px;
  word-break: break-all;
  display: inline-block;
`

export const InputCont = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  padding-left: 16px;
`

interface StyledInputInterface {
  activeStyle: boolean
  activeBgColor?: string
  inactiveBgColor?: string
  boxShadow?: string
  borderRadius?: string
  padding?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
}

export const Input = styled.input<StyledInputInterface>`
  height: 40px;
  width: 180px;
  margin-bottom: 8px;
  background-color: ${(props) => (props.activeStyle ? '#ffffff' : '#f5f5f5')};
  border: ${(props) =>
    props.activeStyle
      ? props.activeBgColor || '1px solid #D6D6D6'
      : props.inactiveBgColor || '1px solid #575757'};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

interface StyledSelectInterface {
  activeStyle: boolean
  activeBgColor?: string
  inactiveBgColor?: string
  boxShadow?: string
  borderRadius?: string
  padding?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
}

export const StyledSelect = styled.select<StyledSelectInterface>`
  height: 40px;
  width: 180px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '16px')};
  background-color: ${(props) => (props.activeStyle ? '#ffffff' : '#f5f5f5')};
  border: ${(props) =>
    props.activeStyle
      ? props.activeBgColor || '1px solid #D6D6D6'
      : props.inactiveBgColor || '1px solid #575757'};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

interface ErrorMessage {
  margin?: string
}

export const ErrorMessage = styled.p<ErrorMessage>`
  color: red;
  margin: ${(props) => (props.margin ? props.margin : '2px auto 0')};
`

interface StyledCheckbox {
  bgColor?: string
  border?: string
  boxShadow?: string
  borderRadius?: string
  checkedColor?: string
  marginLeft?: string
}

export const Checkbox = styled.input<StyledCheckbox>`
  width: 20px;
  height: 20px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
  border: ${(props) => (props.border ? props.border : '2px solid #575757')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '8px')};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};

  :checked {
    color: ${(props) => (props.checkedColor ? props.checkedColor : '#f5f5f5')};
  }
`
